import { getStatusCode } from 'libs/request-utils';
import { operations as notificationOperations } from 'modules/notifications';
import { MESSAGES_NOTIFICATIONS } from 'modules/notifications/constants';
import { getDateFormated, getOffsetTimezone, getDateInTimezone, subDays, set } from 'libs/date';
import { actions as actionsReports } from '../reports';
import * as actions from './actions';
import * as selectors from './selectors';
import { ITEMS_PER_PAGE } from './constants';
import { DEFAULT_MODAL_STATE } from './reducers';
import {
  getEquipment,
  deleteEquipment,
  updateEquipment,
  listEquipments,
  createEquipment,
  listFootprintsChroma,
  dataAnalysisChroma,
  footprintChroma,
  footprintsChroma,
  footprintEvolution,
  setFootprintChroma,
  gasQualityAssignment,
  analysisFootprintChroma,
  updatePatternEquipmentAreas,
} from './api';
import { transformEquipment, formatParamsToRequest, transformAnalysisChroma, transformAssignment } from './utils';

const listEquipmentsRequest = (equipmentType, query) => (dispatch) => {
  const params = {
    order: 'ASC',
    sort: 'None',
    limit: ITEMS_PER_PAGE,
    offset: 0,
    ...query,
  };

  dispatch(actions.listRequested({ equipmentType, query: params }));

  return listEquipments(equipmentType, {
    params,
  })
    .then(({ data: { items, total } }) => {
      const equipment = equipmentType === 'patternEquipment' ? 'patternArea' : equipmentType;
      dispatch(
        actions.listSucceeded({
          equipmentType,
          entities: items.map((item) => transformEquipment(item, equipment)),
          total,
        })
      );
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(actions.listFailed({ error, equipmentType }));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:List', `entitiesNames:${equipmentType}`)[statusCode],
        })
      );
    });
};

const showEquipmentRequest = (equipmentType, id) => (dispatch) => {
  dispatch(actions.showRequested({ equipmentType }));
  getEquipment(equipmentType, { id })
    .then(({ data }) => {
      dispatch(actions.showSucceeded({ entity: transformEquipment(data, equipmentType), equipmentType }));
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(actions.showFailed({ error, equipmentType }));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:ViewEntity', `entitiesNames:${equipmentType}`)[statusCode],
        })
      );
    });
};

const createEquipmentRequest = ({ history, goBack, ...params }) => (dispatch, getState) => {
  const state = getState();
  const modalState = selectors.getModalState(state);
  const { equipmentType } = modalState;

  dispatch(actions.createRequested({ equipmentType }));
  createEquipment(equipmentType, formatParamsToRequest({ ...params, equipmentType }))
    .then(({ data, status }) => {
      dispatch(actions.createRequested({ entity: transformEquipment(data, equipmentType), equipmentType }));
      dispatch(actions.setModalFormState(DEFAULT_MODAL_STATE));
      if (goBack) {
        history.push(`/infrastructure/equipments?equipmentType=${equipmentType}`);
        dispatch(listEquipmentsRequest(equipmentType, {}));
      } else {
        history.push(`/infrastructure/equipments/${equipmentType}/${data}`);
        dispatch(showEquipmentRequest(equipmentType, data));
      }
      dispatch(
        notificationOperations.successNotification({
          title: '',
          message: MESSAGES_NOTIFICATIONS('messages:participles:created', `entitiesNames:${equipmentType}`)[status],
        })
      );
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(actions.createFailed({ error, equipmentType }));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS(
            'actions:Create',
            `entitiesNamesWithArticles:${equipmentType}`,
            params.name ? 'forms:fields:name' : 'forms:fields:serialNumber',
            params.name || params.serialNumber
          )[statusCode],
        })
      );
    });
};

const updateEquipmentRequest = ({ history, goBack, ...params }) => (dispatch) => {
  const { equipmentType } = params;
  dispatch(actions.updateRequested({ equipmentType }));

  updateEquipment(equipmentType, formatParamsToRequest({ ...params, equipmentType }))
    .then(({ data, status }) => {
      dispatch(actions.updateSucceeded({ entity: transformEquipment(data, equipmentType), equipmentType }));
      if (goBack) {
        dispatch(actions.setModalFormState(DEFAULT_MODAL_STATE));
        history.push(`/infrastructure/equipments?equipmentType=${equipmentType}`);
        dispatch(listEquipmentsRequest(equipmentType, {}));
      } else {
        dispatch(showEquipmentRequest(equipmentType, data));
      }
      dispatch(
        notificationOperations.successNotification({
          title: '',
          message: MESSAGES_NOTIFICATIONS('messages:participles:updated', `entitiesNames:${equipmentType}`)[status],
        })
      );
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(actions.updateFailed({ error, equipmentType }));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:UpdateEntity', `entitiesNames:${equipmentType}`)[statusCode],
        })
      );
    });
};

const deleteEquipmentRequest = (branchsId, equipmentType) => (dispatch) => {
  dispatch(actions.deleteRequested({ equipmentType }));

  const deleteEquipmentPromise = branchsId.map((id) => deleteEquipment(equipmentType, { id }));
  Promise.all(deleteEquipmentPromise)
    .then(([data]) => {
      const { status } = data;
      dispatch(actions.deleteSucceeded({ equipmentType }));
      dispatch(listEquipmentsRequest(equipmentType, {}));
      dispatch(
        notificationOperations.successNotification({
          title: '',
          message: MESSAGES_NOTIFICATIONS('messages:participles:removed', `entitiesNames:${equipmentType}`)[status],
        })
      );
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(actions.deleteFailed({ error, equipmentType }));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:DeleteEntity', `entitiesNames:${equipmentType}`)[statusCode],
        })
      );
    })
    .finally(() => {
      dispatch(listEquipmentsRequest(equipmentType, {}));
    });
};

const listFootprintRequest = (query) => (dispatch) => {
  const params = {
    order: 'ASC',
    sort: 'None',
    limit: ITEMS_PER_PAGE,
    ho: getOffsetTimezone(),
    offset: 0,
    ...query,
  };
  const equipmentType = 'footprintChroma';
  dispatch(actions.listRequested({ equipmentType, query: params }));

  return listFootprintsChroma({
    params,
  })
    .then(({ data: { items, total } }) => {
      dispatch(
        actions.listSucceeded({
          equipmentType,
          entities: items,
          total,
        })
      );
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(actions.listFailed({ error, equipmentType }));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:List', `entitiesNames:${equipmentType}`)[statusCode],
        })
      );
    });
};

const dataFootprintRequest = (query) => (dispatch) => {
  const params = {
    ...query,
    ho: getOffsetTimezone(),
  };
  dispatch(actions.getDataRequested());
  const equipmentType = 'footprintChroma';

  return dataAnalysisChroma({
    params,
  })
    .then(({ data }) => {
      dispatch(actions.getDataSucceeded({ entities: transformAnalysisChroma({ entities: data }) }));
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(actions.getDataFailed({ error }));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:List', `entitiesNames:${equipmentType}`)[statusCode],
        })
      );
    });
};

const footprintChromaRequest = (query, messageFootprint) => (dispatch) => {
  const params = {
    ...query,
    ho: getOffsetTimezone(),
  };

  dispatch(actions.getFootprintRequested({ query: params }));

  const equipmentType = 'footprintChroma';

  return footprintChroma({
    params,
  })
    .then(({ data }) => {
      if (data === 'redirect' || data === 'not_available') {
        messageFootprint(data);
        dispatch(actions.getFootprintSucceeded({ footprint: {} }));
        return;
      }
      dispatch(actions.getFootprintSucceeded({ footprint: data }));

      if (messageFootprint) messageFootprint('');
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:List', `entitiesNames:${equipmentType}`)[statusCode],
        })
      );
    });
};

const footprintsChromaRequest = (query, messageFootprint) => (dispatch) => {
  const params = {
    ...query,
    ho: getOffsetTimezone(),
  };

  dispatch(actions.getFootprintRequested({ query: params }));

  const equipmentType = 'footprintChroma';

  return footprintsChroma({
    params,
  })
    .then(({ data }) => {
      if (data === 'redirect' || data === 'not_available') {
        messageFootprint(data);
        dispatch(actions.getFootprintSucceeded({ footprints: {} }));
        return;
      }
      dispatch(actions.getFootprintSucceeded({ footprints: data }));

      if (messageFootprint) messageFootprint('');
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:List', `entitiesNames:${equipmentType}`)[statusCode],
        })
      );
    });
};

const setFootprintChromaRequest = (query) => (dispatch) => {
  const params = {
    ...query,
    ho: getOffsetTimezone(),
  };
  const equipmentType = 'footprintChroma';

  setFootprintChroma({
    params,
  })
    .then(() => {
      dispatch(listFootprintRequest({ chroma: params.id_chroma }));
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:List', `entitiesNames:${equipmentType}`)[statusCode],
        })
      );
    });
};

const getAnalysisFootprintChromaRequest = ({ id, date }) => (dispatch) => {
  const params = { id_model: id, date: getDateFormated(date, 'yyyy-MM-dd HH:mm:ss'), ho: getOffsetTimezone() };
  const equipmentType = 'footprintChroma';
  dispatch(actions.getAnalysisRequested());

  analysisFootprintChroma({
    params,
  })
    .then(({ data }) => {
      dispatch(actions.getAnalysisSucceeded({ footprint: data?.footprint, analysis: data?.analysis }));
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(actions.getAnalysisFailed(error));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:List', `entitiesNames:${equipmentType}`)[statusCode],
        })
      );
    });
};

const getfootprintEvolutionRequest = ({ start_date: startDate, end_date: endDate, id_chroma: id, variables }) => (
  dispatch
) => {
  const params = {
    id_chroma: id,
    start_date: getDateInTimezone(startDate),
    end_date: getDateInTimezone(endDate),
    variables: JSON.stringify(variables),
    ho: 0,
  };

  dispatch(actionsReports.getEvolutionRequested({ query: params }));

  const equipmentType = 'footprintChroma';

  return footprintEvolution({
    params,
  })
    .then(({ data }) => {
      dispatch(actionsReports.getEvolutionSucceeded({ report: data }));
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);

      dispatch(actionsReports.getEvolutionFailed({ error }));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:List', `entitiesNames:${equipmentType}`)[statusCode],
        })
      );
    });
};

const updatePatternEquipmentAreasRequest = (body) => {
  return (dispatch) => {
    dispatch(actions.updateRequested(body));
    updatePatternEquipmentAreas(body)
      .then(({ data, status }) => {
        dispatch(actions.updateSucceeded(data));
        showEquipmentRequest('patternEquipment', body.id)(dispatch);
        dispatch(
          notificationOperations.successNotification({
            title: '',
            message: MESSAGES_NOTIFICATIONS('messages:participles:updatedF', 'PatternPage:AreasByPatternEquipment')[
              status
            ],
          })
        );
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.updateFailed({ body, error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:UpdateEntity', 'PatternPage:AreasByPatternEquipment')[statusCode],
          })
        );
      });
  };
};

const transformToQuery = (queryToTransform) => {
  const query = {
    pageSize: queryToTransform.pageSize,
    limit: queryToTransform.limit,
    offset: queryToTransform.offset,
    measurement_unit: queryToTransform.measurementUnit,
    chromatograph: queryToTransform.chromatograph,
    show_differences: queryToTransform.showDifferences,
    start_date: queryToTransform.startDate
      ? getDateInTimezone(set(queryToTransform.startDate, { hours: 0, minutes: 0, seconds: 0 }))
      : set(new Date(), { hours: 0, minutes: 0, seconds: 0 }),
    end_date: queryToTransform.endDate
      ? getDateInTimezone(set(queryToTransform.endDate, { hours: 0, minutes: 0, seconds: 0 }))
      : subDays(set(new Date(), { hours: 0, minutes: 0, seconds: 0 }), 1),
  };
  return query;
};

const getAssignmentRequest = (query) => {
  return (dispatch) => {
    const transformParams = { ...transformToQuery(query) };

    dispatch(actionsReports.listAssignmentRequested({ ...transformParams, isLoading: true }));

    const equipmentType = 'gasQuality';

    gasQualityAssignment({ params: transformParams })
      .then(({ data: { items, total } }) => {
        dispatch(
          actionsReports.listAssignmentSucceeded(
            items.map((item) => transformAssignment(item)),
            total
          )
        );
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);

        dispatch(actionsReports.listAssignmentFailed({ error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:List', `entitiesNames:${equipmentType}`)[statusCode],
          })
        );
      });
  };
};

export {
  createEquipmentRequest,
  listEquipmentsRequest,
  deleteEquipmentRequest,
  showEquipmentRequest,
  updateEquipmentRequest,
  updatePatternEquipmentAreasRequest,
  listFootprintRequest,
  dataFootprintRequest,
  footprintChromaRequest,
  footprintsChromaRequest,
  setFootprintChromaRequest,
  getAnalysisFootprintChromaRequest,
  getfootprintEvolutionRequest,
  getAssignmentRequest,
};
