/* eslint-disable react/display-name */
import React from 'react';
import SelectLib from 'react-select';
import AsyncSelect from 'react-select/async';

import { mergeDeepRight } from 'libs/toolkits';
import Label from '../label';
import ContainerTags, { ContainerTagOption } from '../../tag/ContainerTags';
import { FormFielStyles } from '../styles';
import ErrorInput from '../Error';

const stylesForSelectsDown = {
  multiValue: () => ({
    display: 'none',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
};

const selectStyles = {
  option: (provided: Record<string, unknown>, state: { [key: string]: any }) => ({
    ...provided,
    borderBottom: '1px dotted #ccc',
    color: state?.isSelected ? '#000' : '#000',
    padding: 10,
    fontSize: '14px',
    background: state?.isSelected ? '#17c6d540' : '#fff',
  }),

  placeholder: (base: Record<string, unknown>) => ({
    ...base,
    fontFamily: 'Lato',
    fontSize: '13px',
    color: '#7f8a9d',
    fontWeight: 400,
  }),

  control: (_: any, { selectProps: { hasError } }: { selectProps: { hasError: boolean } }) => ({
    // none of react-select's styles are passed to <Control />
    background: '#fafbfc',
    border: '1px solid #e0e1e6',
    borderRadius: '4px',
    ...(hasError ? { borderColor: 'red' } : { borderColor: '#e0e1e6' }),
    display: 'flex',
  }),

  singleValue: () => ({
    fontSize: '13px',
    textOverflow: 'ellipsis',
    maxHeight: '100%',
    whiteSpace: 'nowrap',
    position: 'absolute',
  }),

  input: () => ({
    position: 'relative',
    top: '0px',
  }),
};

interface Props extends Partial<HTMLSelectElement> {
  isMulti?: boolean;
  isSearchable?: boolean;
  isAsync?: boolean;
  name: string;
  onChange?: (itemSelected: any, actionInfo: any) => any;
  options?: any;
  components?: any;
  loadOptions?: (props: any) => void;
  width?: string | number;
  maxWidth?: string;
  styles?: Record<string, unknown>;
  noSeparation?: boolean;
  isRounded?: boolean;
  isPlain?: boolean;
  textLabel?: string;
  instanceId?: number;
  placeholder?: string;
  value?: any;
  isDisabled?: boolean;
  isClearable?: boolean;
  hasError?: string;
  isRequired?: boolean;
  id?: string;
  isLoading?: boolean;
  defaultValue?: any;
  menuPosition?: 'fixed' | 'absolute';
  maxMenuHeight?: number;
  customStyles?: Record<string, unknown>;
  selectedsOut?: boolean;
  outSideSelecteds?: boolean;
  hadleOnRemove?: (v: any, t: ContainerTagOption[]) => any;
  error?: JSX.Element;
  errors?: Record<string, unknown>;
  cacheOptions?: boolean;
  required?: boolean;
  margin?: string;
}

const Select = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      isMulti,
      isSearchable,
      name,
      options,
      isAsync,
      loadOptions,
      width,
      noSeparation,
      selectedsOut,
      customStyles,
      value,
      outSideSelecteds,
      hadleOnRemove,
      error,
      errors,
      ...props
    },
    ref
  ) => {
    const componentProps = {
      value,
      isMulti,
      isSearchable,
      id: name,
      name,
      ref,
      options,
      styles: mergeDeepRight({ ...selectStyles, ...(outSideSelecteds ? stylesForSelectsDown : {}) }, customStyles),
      ...(isAsync ? { loadOptions } : {}),
      ...props,
    };

    return (
      <FormFielStyles noSeparation={noSeparation} width={width}>
        {outSideSelecteds && isMulti ? (
          <ContainerTags tagsOptions={value || []} name={name} onClose={hadleOnRemove} />
        ) : (
          ''
        )}
        <ErrorInput errors={errors} name={name} />
        {/* @ts-ignore */}
        {isAsync ? <AsyncSelect {...componentProps} /> : <SelectLib {...componentProps} />}
        <Label {...props} />
      </FormFielStyles>
    );
  }
);

Select.defaultProps = {
  customStyles: {},
  width: 'auto',
  noSeparation: false,
  selectedsOut: false,
  cacheOptions: false,
  isAsync: false,
  isMulti: false,
  isSearchable: false,
  options: [],
  required: false,
  loadOptions: undefined,
  name: '',
  value: undefined,
  outSideSelecteds: false,
  hadleOnRemove: () => {},
  error: undefined,
  errors: {},
};

export default Select;
