import { generateEntityQueries, toFormData } from 'libs/request-utils';
import { uploadLinearSchemaRequest } from 'modules/linearSchema/api';
import { getTimeZone } from 'libs/date';
import { transformMeasurementUnitLineBody } from './utils';

const { deleteEntity, getEntity, createEntity, updateEntity } = generateEntityQueries({
  nameForLists: 'measurementUnit',
});

const { saveEntity: saveLines } = generateEntityQueries({
  nameForLists: 'measurementUnit/lines',
});

const { saveEntity: saveChromas } = generateEntityQueries({
  nameForLists: 'measurementUnit/chromas',
});

const { saveEntity: saveFlowComputers } = generateEntityQueries({
  nameForLists: 'measurementUnit/flowComputers',
});

const { getEntities: searchEntities } = generateEntityQueries({ nameForLists: 'measurementUnit/search' });

const { getEntitiesMultipleCalls: getMeasurementUnitsData } = generateEntityQueries({
  nameForLists: 'measurementUnit',
});
const createMeasurementUnit = ({
  name,
  code,
  type,
  positionId,
  selfConsumption = false,
  selfConsumptionBranch,
  inputBranch,
  outputBranch,
  bidirectional = false,
  bidirectionalUnit,
  beBackUp = false,
  backUpUnit,
  lines = [],
  active = true,
}) => {
  const body = {
    name,
    code,
    type,
    position: positionId,
    self_consumption: selfConsumption,
    self_consumption_branch: selfConsumptionBranch,
    in_branch: inputBranch,
    out_branch: outputBranch,
    bidirectional,
    bidirectional_unit: bidirectionalUnit,
    be_backup: beBackUp,
    backup: backUpUnit,
    lines,
    active,
  };
  const params = toFormData(body);
  return createEntity({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};
const updateMeasurementUnit = ({
  id,
  name,
  code,
  type,
  position,
  selfConsumption = false,
  selfConsumptionBranch,
  inputBranch,
  outputBranch,
  bidirectional = false,
  bidirectionalUnit,
  beBackUp = false,
  backUpUnit,
  active = true,
}) => {
  const body = {
    id,
    name,
    code,
    type: type.value,
    position: position.value,
    self_consumption: selfConsumption,
    self_consumption_branch: selfConsumptionBranch ? selfConsumptionBranch.value : '',
    in_branch: inputBranch ? inputBranch.value : '',
    out_branch: outputBranch ? outputBranch.value : '',
    bidirectional,
    bidirectional_unit: bidirectionalUnit ? bidirectionalUnit.value : '',
    be_backup: beBackUp,
    backup: backUpUnit ? backUpUnit.value : '',
    active,
  };
  const params = toFormData(body);

  return updateEntity({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const updateMeasurementUnitLines = ({ id, lines = [] }) => {
  const body = {
    id,
    lines: lines.map((line, index) => transformMeasurementUnitLineBody({ ...line, orderLine: index + 1 }, id)) || [],
    timezone: getTimeZone,
  };

  return saveLines({
    body: toFormData(body),
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const updateMeasurementUnitChromas = ({
  id,
  chromaActive,
  chromaConfig,
  chroma,
  chromatographMeasurementUnit,
  chromatographMeasurementUnitR,
}) => {
  const body = {
    id,

    croma: chroma,
    cromatograph: chromatographMeasurementUnit ? chromatographMeasurementUnit.value : '',
    cromatograph_r: chromatographMeasurementUnitR ? chromatographMeasurementUnitR.value : '',

    chroma_config: JSON.stringify(chromaConfig),
    active_chroma: chromaActive,
  };

  return saveChromas({
    body: toFormData(body),
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const updateMeasurementUnitFlowComputers = ({ id, flowComputers }) => {
  const body = {
    id,
    flow_computers: JSON.stringify(flowComputers),
  };

  return saveFlowComputers({
    body: toFormData(body),
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const deleteMeasurementUnit = ({ id }) => {
  return deleteEntity({
    id,
  });
};

const getMeasurementUnit = ({ id }) => {
  return getEntity({
    id,
  });
};

const searchMeasurementUnits = (params) => {
  return searchEntities({
    ...params,
  });
};

const getMultipleMeasurementUnits = (params) => {
  return getMeasurementUnitsData({
    paramsList: params,
  });
};

export {
  createMeasurementUnit,
  updateMeasurementUnit,
  updateMeasurementUnitLines,
  updateMeasurementUnitChromas,
  updateMeasurementUnitFlowComputers,
  getMeasurementUnit,
  deleteMeasurementUnit,
  uploadLinearSchemaRequest,
  searchMeasurementUnits,
  getMultipleMeasurementUnits,
};
