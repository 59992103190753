import { getDateInTimezone, getDateFormated } from 'libs/date';
import { isEmpty } from 'libs/toolkits';
import { extractorActionsResponse } from 'libs/request-utils';
import selectors from '../selectors';

const actionsTranslate = {
  import: 'show',
  delete: 'delete',
  setSimosReport: 'program',
  getSignature: 'show',
  getSignatureFile: 'download',
  able: 'program',
  getReportCronoFiles: 'show',
  download: 'download',
  rename: 'rename',
};

const reportExtractorActions = extractorActionsResponse(actionsTranslate);

const transformVariablesReport = (report) => report;

const transformMeasure = ({ id, lines, name }) => {
  return {
    lines: lines.map((l) => ({ ...l, selected: true, measurementId: String(id) })),
    id,
    name,
  };
};

const transformComparativeReport = (report) => {
  const result = {
    ...report,
    variables: report.variables.map((data) => ({
      ...data,
      key_timestamp: getDateFormated(
        getDateInTimezone(getDateFormated(new Date(data.key_timestamp), 'yyyy-MM-dd HH:mm:ss')),
        'yyyy-MM-dd HH:mm:ss'
      ),
    })),
  };
  return result;
};

const transformUltrasonicReport = (report) => {
  const resultReport = {
    ...report,
    ...(report?.reference_values &&
    !isEmpty(report.reference_values) &&
    report?.reference_values.ts &&
    report?.reference_values.creation_date
      ? {
          reference_values: {
            ...report.reference_values,
            ts: getDateFormated(getDateInTimezone(report.reference_values.ts), 'yyyy-MM-dd HH:mm:ss'),
            creation_date: getDateFormated(getDateInTimezone(report.reference_values.creation_date)),
          },
        }
      : {}),
    ...(report?.report && !isEmpty(report.report)
      ? {
          report: {
            ...report.report,
            date: getDateFormated(getDateInTimezone(report.report.date), 'yyyy-MM-dd HH:mm:ss'),
          },
        }
      : {}),
  };
  return resultReport;
};

const transformLineReport = (report) =>
  Array.isArray(report)
    ? report.reduce((acc, data) => {
        const dataWithTzDates = {
          ...data,
          value: {
            ...data.value,
            data: data.value.data.map((values) => [getDateInTimezone(values[0]), values[1]]),
          },
        };
        return {
          ...acc,
          ...(Object.keys(acc).includes(data.name)
            ? { [data.name]: { ...acc[data.name], [data.var]: dataWithTzDates } }
            : { [data.name]: { [data.var]: dataWithTzDates } }),
        };
      }, {})
    : report;

const transformControlReport = (report) =>
  Array.isArray(report) && report.length === 1 ? report[0] : transformLineReport(report);

const transformSavedReports = (type) => ({
  name,
  id,
  creation_date: creationDate,
  actions,
  type: reportType,
  config_ob: config,
  is_scheduled: isScheduled,
  frequencies,
  receiveBool,
}) => {
  return {
    id,
    name,
    type: reportType,
    creationDate: new Date(creationDate),
    isScheduled: isScheduled === 'yes',
    frequencies,
    receiveBool: receiveBool || false,
    config,
    actions: ['instant', 'advanced', 'gasQualityAssignment', 'comparative-consumption'].includes(type)
      ? reportExtractorActions(actions)
      : ['show', 'delete', 'rename'],
  };
};

const transformSignatureReport = (report, state, query) => {
  const extractName = (str) => str.replace(/\s\(\d+\)/, '');
  const isGreen = (str) => str.includes('green');
  const { cursors = {} } = selectors.getReport(state);
  return {
    variables: report.items.map(({ id, muName, lName, TS, div_class: divClass }) => {
      const date = getDateInTimezone(new Date(TS));
      return {
        id,
        measurementUnitName: extractName(muName),
        lineName: extractName(lName),
        date,
        isVerified: isGreen(divClass.verified),
        isCurrentKeyGood: isGreen(divClass.current_key),
        actions: isGreen(divClass.verified) ? ['show', 'download'] : [],
      };
    }),
    total: report.total,
    cursors: {
      ...cursors,
      [query.offset + query.limit]: report.next_cursor,
    },
  };
};

const transformSignatureAudit = ({ ob2 = {}, verified = false, ob = {} }) => {
  const withSignature = JSON.parse(ob).message || {};
  const current = ob2;
  const omitVariables = ['lName', 'packet', 'posName', 'eqName', 'muName', 'eqType', 'measureDateTime', 'TS'];
  const info = [...new Set([...Object.keys(withSignature), ...Object.keys(current)])].reduce((acc, variable) => {
    if (omitVariables.includes(variable)) {
      return acc;
    }
    return [
      ...acc,
      {
        variable,
        current: Number(current[variable]),
        withSignature: withSignature[variable],
      },
    ];
  }, []);
  return {
    verified,
    info,
  };
};

const transformDataHistoryReferenceValue = (data) =>
  Object.entries(data).reduce(
    (acc, [key, values]) => ({
      ...acc,
      [key]: { ...values },
    }),
    {}
  );

const transformHistoryReferenceValue = (referenceValue) => ({
  ...referenceValue,
  creationDate: referenceValue.creation_date,
});

const transformScheduleReports = (scheduleReport) => ({
  ...scheduleReport,
  creationDate: scheduleReport.creation_date,
  actions: reportExtractorActions(scheduleReport.actions),
});

export {
  transformVariablesReport,
  transformMeasure,
  transformComparativeReport,
  transformUltrasonicReport,
  transformControlReport,
  transformSavedReports,
  transformSignatureReport,
  transformSignatureAudit,
  transformHistoryReferenceValue,
  transformScheduleReports,
};
