import styled from 'styled-components';

const ItemStyles = styled.div`
  text-align: center;
  background: #fff;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  padding: 0 15px;
  margin-right: 8px;
  margin-top: 10px;
  width: auto;
  height: 38px;
  display: flex;
  align-items: center;

  ${({ state, theme }) => theme.statesSummary.state[state]};

  h3 {
    font-size: 21px;
    font-weight: bold;
    margin-right: 6px;
    margin-bottom: 0px;
    margin-top: -2px;
    padding: 0px;
    color: inherit;
  }

  p {
    font-size: 13px;
    margin: 0;
  }
`;

export { ItemStyles };
