import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Tabs, TabPane, Drawer, Title, RowFlex, Button } from 'ui';
import { useToggle, useFormulaState } from 'components/hooks';
import { AlertsSavedTable, FormAlert, IncidencesTable, Incidence } from 'components/alerts';
import { operations, selectors } from 'modules/alerts';
import { DEFAULT_VALUES } from 'components/alerts/constants';

const VALID_TYPES = { saved: 'saved', incidences: 'incidences', templates: 'templates' };

const AlertsPage = () => {
  const { t } = useTranslation();
  const [isOpen, , open, close] = useToggle(false);
  const { type, id: alertId } = useParams();
  const history = useHistory();
  const formRef = React.useRef();
  const [tabSelected, setSelectedTab] = useState(VALID_TYPES[type] || 'incidences');
  const { formState, getValues, handleSubmit } = useFormContext();
  const { isValid: isValidFormula, getOperations } = useFormulaState();
  const operationsRef = useRef([]);

  const dispatch = useDispatch();
  const { alertSaved, alertHistory } = useSelector(
    (state) => ({
      alertSaved: selectors.getAlertsSavedEntity(state),
      alertHistory: selectors.getAlertsGeneratedEntity(state),
    }),
    shallowEqual
  );

  const [isLoadingButton, setLoadingButton] = useState(false);
  const handleOnSaveAlert = useCallback(
    (config) => {
      const params = getValues();
      const paramsFormated = {
        ...params,
        ...(alertSaved && alertSaved.id && { id: alertSaved.id }),
        ...(config.formula && config.formula.length && { formula: config.formula }),
        setLoadingButton: () => setLoadingButton(false),
      };
      dispatch(
        paramsFormated.id
          ? operations.updateAlertRequest(paramsFormated)
          : operations.createAlertRequest(paramsFormated)
      );
    },
    [getValues, alertSaved, dispatch, setLoadingButton]
  );

  const MAP_SIDEBAR_OPTIONS = {
    incidences: {
      title: (
        <>
          <Title level={4}>{alertHistory ? alertHistory.name : ''}</Title>
        </>
      ),
      action: operations.getHistoryAlertRequest,
      sidebarSizes: 500,
    },
    saved: {
      title: <Title level={3}>{alertSaved ? alertSaved.name : t('pages:alerts-newAlert-title')}</Title>,
      action: operations.getAlertRequest,
      sidebarSizes: 700,
      contentPadding: true,
    },
    templates: {
      children: <div>{t('pages:alerts-template-title')}</div>,
      title: <div>{t('pages:alerts-template-text')}</div>,
      action: console.log,
      sidebarSizes: 400,
    },
  };

  const { title = '', sidebarSizes, action: actionBeforeToogle } = MAP_SIDEBAR_OPTIONS[tabSelected];

  useEffect(() => {
    if (!alertId) {
      close();
      return;
    }
    if (alertId !== 'new') {
      dispatch(actionBeforeToogle({ id: alertId }));
    }
    open();
  }, [alertId, open, close, dispatch, actionBeforeToogle]);

  const handleToogleSidebar = useCallback(
    (id) => {
      if (id) {
        history.push(`/alerts/${tabSelected}/${id}`);
      }
    },
    [history, tabSelected]
  );
  const handleCloseSidebar = useCallback(() => {
    setLoadingButton(false);
    history.push(`/alerts/${tabSelected}`);
  }, [history, tabSelected]);

  const handleOnSubmit = (dataForm) => {
    const { receiver, ...restDataForm } = dataForm;
    setLoadingButton(true);
    handleOnSaveAlert({
      ...DEFAULT_VALUES,
      ...restDataForm,
      formula: getOperations && getOperations(),
      receiver: receiver || '',
    });
  };
  return (
    <>
      <Title level={2} style={{ position: 'fixed', top: '18px', fontSize: '22px', left: '100px' }}>
        {t('entitiesNames:Alert_plural')}
      </Title>
      <Tabs
        defaultActiveKey={tabSelected}
        onChange={(tabKey) => {
          setSelectedTab(tabKey);
          history.push(`/alerts/${tabKey}`);
        }}
      >
        <TabPane tab={t('AlertPage:incidences')} key="incidences" style={{ marginTop: '-17px', height: '100vh' }}>
          <IncidencesTable handleToogleSidebar={handleToogleSidebar} />
          <Drawer width={sidebarSizes} visible={isOpen} onClose={handleCloseSidebar} position="right" title={title}>
            {isOpen && <Incidence incidence={alertHistory} />}
          </Drawer>
        </TabPane>
        <TabPane tab={t('pages:alerts-tab-list-title')} key="saved" style={{ marginTop: '-17px', height: '100vh' }}>
          <AlertsSavedTable handleToogleSidebar={handleToogleSidebar} />

          <Drawer
            bodyStyle={{ padding: 0 }}
            width={sidebarSizes}
            visible={isOpen}
            onClose={handleCloseSidebar}
            position="right"
            title={title}
            footer={
              tabSelected === 'saved' && (
                <RowFlex justify="flex-end">
                  <Button onClick={handleCloseSidebar} variant="plain" color="danger">
                    {t('actions:Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    loading={isLoadingButton}
                    disabled={
                      (alertSaved && !Object.keys(formState.touched).length && !isValidFormula) ||
                      (!alertSaved &&
                        ((!Object.keys(formState.touched).length && !formState.touched.selectVariable) ||
                          (!formState.touched.selectVariable && !isValidFormula)))
                    }
                    onClick={() => formRef.current.click()}
                    variant="primary"
                  >
                    {t('actions:Accept')}
                  </Button>
                </RowFlex>
              )
            }
          >
            {isOpen && (
              <form onSubmit={handleSubmit(handleOnSubmit)}>
                <FormAlert alertSaved={alertSaved} operationsRef={operationsRef} />
                <button ref={formRef} type="submit" style={{ display: 'none' }} />
              </form>
            )}
          </Drawer>
        </TabPane>
      </Tabs>
    </>
  );
};

export default AlertsPage;
