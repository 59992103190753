import { useCallback } from 'react';

import { listApi } from 'modules/formulas/api';

const useSearchCustomVariables = () =>
  useCallback((text) => {
    return listApi({ q: text });
  }, []);

export { useSearchCustomVariables };
