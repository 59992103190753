import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocalStorageState } from 'components/hooks';
import { getDateFormated, subDays } from 'libs/date';
import { sum } from 'libs/math/operations';

import { Badge, Icon, Popover, RowFlex, Text, Tooltip } from 'ui';
import { operations, selectors } from 'modules/messages';
import { Notification, ContainerNotifications } from './styles';
import EmptyMessages from './EmptyMessages';

const MessagesInfo = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [dateConsultIncidences, setDateConsultIncidences] = useLocalStorageState('dateConsultIncidences', null);
  const [dateConsultReports, setDateConsultReports] = useLocalStorageState('dateConsultReports', null);
  const { totalReports, totalIncidences } = useSelector(
    (state) => ({
      totalReports: selectors.getTotalReports(state),
      totalIncidences: selectors.getTotalIncidences(state),
    }),
    shallowEqual
  );

  const requestMessages = useCallback(() => {
    dispatch(operations.getMessageList({ dateReports: dateConsultReports, dateIncidences: dateConsultIncidences }));
  }, [dispatch, dateConsultReports, dateConsultIncidences]);

  const totalMessages = sum(totalReports, totalIncidences);

  useEffect(() => {
    requestMessages();
  }, [dispatch, requestMessages, dateConsultReports, dateConsultIncidences]);

  const goToIncidences = () => {
    history.push(`/alerts/incidences`);
    setDateConsultIncidences(getDateFormated(new Date(), 'yyyy-MM-dd HH:mm'));
  };

  const goToReports = () => {
    history.push(`/reports`);
    setDateConsultReports(getDateFormated(new Date(), 'yyyy-MM-dd HH:mm'));
  };

  const IncidencesInfo = () => {
    return totalIncidences ? (
      <>
        <RowFlex>
          <div>
            <Text onClick={goToIncidences} color="primary" strong>
              {totalIncidences === 1
                ? t('TopBar:MessagesInfo:CountIncidence', { count: totalIncidences })
                : t('TopBar:MessagesInfo:CountIncidence_plural', { count: totalIncidences })}
            </Text>
            <Text onClick={goToIncidences} size="small">
              {t('TopBar:MessagesInfo:FromDate', {
                date: dateConsultIncidences
                  ? getDateFormated(new Date(dateConsultIncidences), 'dd-MM-yyyy HH:mm')
                  : getDateFormated(subDays(new Date(), 7), 'dd-MM-yyyy HH:mm'),
              })}
            </Text>
          </div>
        </RowFlex>
      </>
    ) : (
      ''
    );
  };
  const ReportsInfo = () => {
    return totalReports ? (
      <>
        <RowFlex>
          <div>
            <Text onClick={goToReports} color="primary" strong>
              {totalReports === 1
                ? t('TopBar:MessagesInfo:CountReport', { count: totalReports })
                : t('TopBar:MessagesInfo:CountReport_plural', { count: totalReports })}
            </Text>
            <Text onClick={goToReports} size="small">
              {t('TopBar:MessagesInfo:FromDate', {
                date: dateConsultReports
                  ? getDateFormated(new Date(dateConsultReports), 'dd-MM-yyyy HH:mm')
                  : getDateFormated(subDays(new Date(), 7), 'dd-MM-yyyy HH:mm'),
              })}
            </Text>
          </div>
        </RowFlex>
      </>
    ) : (
      ''
    );
  };

  return (
    <Tooltip title={t('entitiesNames:Notification_plural')}>
      <Popover
        placement="bottomRight"
        trigger="click"
        content={
          <>
            {totalMessages ? (
              <ContainerNotifications>
                <Notification>{totalIncidences ? <IncidencesInfo /> : ''}</Notification>
                <Notification>{totalReports ? <ReportsInfo /> : ''}</Notification>
              </ContainerNotifications>
            ) : (
              <EmptyMessages text={t('TopBar:MessagesInfo:EmptyResults')} />
            )}
          </>
        }
      >
        <Badge count={totalMessages}>
          <Icon iconName="icon-notification" />
        </Badge>
      </Popover>
    </Tooltip>
  );
};

MessagesInfo.propTypes = {};

MessagesInfo.defaultProps = {};

export default MessagesInfo;
