import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
`;

const Main = styled.main`
  height: calc(100vh - 50px);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 82px);
  background: #f7f7fc;
`;

export { Layout, Main, Container };
