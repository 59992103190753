import React from 'react';
import { HeaderImgStyles } from 'ui/menu';
import LogoNeptuno from 'assets/images/icon-logo.svg';
import { getGroupMenuOptions } from './companies';

const getCommonOptions = (t) => [
  {
    to: '/',
    text: 'Dashboard',
    iconName: 'icon-dashboard',
    id: 1,
    exact: true,
  },
  {
    to: '/explore',
    text: t('menu:explorar'),
    iconName: 'icon-explore',
    id: 2,
  },
  {
    to: '/infrastructure',
    text: t('menu:infrastructure'),
    iconName: 'icon-infrastructures',
    id: 3,
  },
  {
    to: '/measures',
    text: t('menu:measurement'),
    iconName: 'icon-graph_bar',
    id: 4,
  },

  {
    to: '/balances',
    text: t('menu:balances'),
    iconName: 'icon-balance',
    id: 5,
  },
  {
    to: '/reports',
    text: t('menu:reports'),
    iconName: 'icon-reports',
    id: 6,
  },

  {
    to: '/alerts',
    text: t('menu:alerts'),
    iconName: 'icon-alerts',
    id: 7,
  },
];

export default (company, role, t) => ({
  HeaderComponent: () => <HeaderImgStyles src={LogoNeptuno} alt="Logo Neptuno" />,
  body: [...getCommonOptions(t), ...getGroupMenuOptions(company, role, t)],
  bottom: [
    {
      to: '/config',
      text: t('menu:configuration'),
      iconName: 'icon-config',
      id: 9,
    },
  ],
});
