import React from 'react';
import { ErrorMessage } from 'react-hook-form';

import Text from '../tipography/text';

const ErrorInput = ({ errors, name }) => {
  if (!name) {
    return null;
  }
  return (
    <ErrorMessage errors={errors} name={name} as={<Text data-cy={`error-${name}`} size="small" type="danger" />} />
  );
};

export default ErrorInput;
