import styled from 'styled-components';

import { flexGrow, height, width } from '../../utils';

const ColumnStyles = styled.div`
  max-width: 100%;
  padding: ${(props) => (props.noPadding ? '0' : '30px 20px 20px')};
  ${flexGrow};
  ${width};
  ${height};
  border-right: ${(props) => (props.separation ? '1px solid #e6e6ff' : null)};
  margin-right: ${(props) => (props.separation ? '10px' : '0px')};
`;

export { ColumnStyles };
