import styled from 'styled-components';
import { Datepicker, RangePicker } from 'ui';

const ContainerGraphs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ContainerGraph = styled.div`
  display: inline-block;
  position: relative;
  box-shadow: 0 0px 10px 0 #e4e4e4;
  background: white;
  padding: 12px;
  border-radius: 5px;
  margin: 5px;
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? 'calc(100% - 8px)' : 'calc(50% - 11px)')};
`;

const NoResultsContainer = styled.div`
  position: absolute;
  top: 64px;
  width: 95%;
  height: 85%;
  z-index: 4;
  background: white;
  > div {
    position: initial;
  }
`;

const ContainerDates = styled.div`
  display: inline-block;
  box-shadow: 0 0px 10px 0 #e4e4e4;
  border-radius: 5px;
  margin: 5px;
  div {
    display: inline-block;
  }
`;

const StickyRow = styled.div`
  position: sticky;
  top: 0;
  z-index: 8;
  background: #f7f7fc;
  padding-bottom: 10px;

  h3 {
    margin-bottom: 0;
  }
`;
const ContainerPanelReport = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 15px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(51, 52, 79, 0.07);
  border-radius: 3px;
`;

const RangeDatePicker = styled(RangePicker)`
  padding-left: 0px;
  border-color: #fafbfc;
  height: auto;

  :hover {
    box-shadow: none;
  }

  :focus {
    outline: 0;
    border: none;
    color: #5f78ff;
  }
`;

const DatePicker = styled(Datepicker)`
  padding-left: 0px;
  border-color: #fafbfc;
  height: auto;

  :hover {
    box-shadow: none;
  }

  :focus {
    outline: 0;
    border: none;
    color: #5f78ff;
  }
`;

const ContainerSelect = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;

  > div {
    margin-right: 6px;
    min-width: 230px;
  }

  input {
    max-width: 90px;
  }
`;

const ConfigPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-left: 2px solid #f7f7fc;
  margin-left: 10px;
  padding-left: 10px;
`;

const ContainerCheckbox = styled.div``;

const ContainerVariables = styled.div`
  padding: 10px;
  margin-bottom: 12px;

  > div {
    border-bottom: 1px solid #e2e2e2;
  }
`;

const OptionsView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  span {
    margin-left: 5px;
  }
`;
const EmptyWrapper = styled.div`
  position: relative;
  max-height: calc(100vh - 110px);
  margin-top: 10px;
  overflow: hidden;
`;

const ImagesEmpty = styled.div`
  img {
    filter: blur(6px);
    max-width: 100%;
    margin-top: 20px;
  }
`;

const MessageEmpty = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7fc61;
  svg {
    width: 100px;
    height: 100px;
    margin: 20px;
    display: inline-block;
  }

  div {
    padding: 14px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 23px 8px rgba(21, 27, 38, 0.05);
    text-align: center;

    h2 {
      margin: 0px;
      font-size: 18px;
      color: #7171a6;
    }
  }
`;
export {
  ContainerGraphs,
  ContainerGraph,
  ContainerDates,
  ContainerPanelReport,
  ContainerSelect,
  StickyRow,
  ContainerCheckbox,
  ContainerVariables,
  ConfigPanel,
  OptionsView,
  EmptyWrapper,
  ImagesEmpty,
  MessageEmpty,
  RangeDatePicker,
  DatePicker,
  NoResultsContainer,
};
