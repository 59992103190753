import { generateActions } from 'libs/generate-redux-utils';
import types from './types';

const ACTIONS_ALERTS_SAVED = generateActions(types.ALERTS_SAVED);
const ACTIONS_ALERTS_GENERATED = generateActions(types.ALERTS_GENERATED);
const ACTIONS_ALERTS_TEMPLATES = generateActions(types.ALERTS_TEMPLATES);

export default {
  ACTIONS_ALERTS_SAVED,
  ACTIONS_ALERTS_GENERATED,
  ACTIONS_ALERTS_TEMPLATES,
};
