import types from './types';

const initialState = {
  reports: 0,
  incidences: 0,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.LIST_MESSAGES_REQUESTED:
      return { ...state, isLoading: true };
    case types.LIST_MESSAGES_SUCCEEDED:
      return { ...state, reports: payload.reports, incidences: payload.incidences, isLoading: false };
    case types.LIST_MESSAGES_FAILED:
      return {
        ...state,
        error: payload.error,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
