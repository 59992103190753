import React from 'react';
import { Link } from 'react-router-dom';

import { prop, concatEntities } from 'libs/toolkits';
import { Tag, Text } from 'ui';
import { translateTypes } from './common';

const createColumnsConfig = (t) => [
  {
    Header: t('AlertPage:table:saved:type'),
    accessor: 'alertType',
    width: '15%',
    maxWidth: 150,
    Cell: ({
      row: {
        values: { alertType },
      },
    }) => {
      return <Tag color="purple">{t(`AlertPage:types:${translateTypes(alertType)}`)}</Tag>;
    },
  },
  {
    Header: t('AlertPage:table:saved:name'),
    accessor: 'name',
    width: '25%',
    Cell: ({
      row: {
        original: { name, id },
      },
    }) => <Link to={`/alerts/saved/${id}`}>{name}</Link>,
  },
  {
    Header: t('AlertPage:table:saved:um/lines'),
    accessor: 'measurementUnits',
    width: '25%',
    Cell: ({
      row: {
        original: { measurementUnits = [], lines = [] },
      },
    }) => `${lines.length ? concatEntities(lines, prop('name')) : concatEntities(measurementUnits, prop('name'))}`,
  },
  {
    Header: t('AlertPage:table:saved:variables'),
    accessor: 'variables',
    width: '25%',
    Cell: ({
      row: {
        original: { variables = [] },
      },
    }) => concatEntities(variables, prop('value')),
  },
  {
    Header: t('AlertPage:table:saved:status'),
    accessor: 'active',
    Cell: ({
      row: {
        original: { active },
      },
    }) => <Text textTransform="capitalize">{active ? t('commonTexts:active') : t('commonTexts:inactive')}</Text>,
  },
  {
    Header: t('AlertPage:table:saved:creator'),
    accessor: 'creator',
  },
];
export { createColumnsConfig };
