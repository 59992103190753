import types from './types';
import { COORDINATES_DEFAULT, ZOOM_DEFAULT, VISUALIZE_DEFAULT } from './constants';

const initialState = {
  zoom: ZOOM_DEFAULT,
  coordinates: COORDINATES_DEFAULT,
  positions: [],
  showPositions: [],
  totalPositions: 0,
  totalMeasurementUnits: 0,
  totalStreams: 0,
  totalChromas: 0,
  FC: {
    ok: 0,
    notCompleted: 0,
    fail: 0,
    total: 0,
  },
  CR: {
    ok: 0,
    notCompleted: 0,
    fail: 0,
    total: 0,
  },
  positionById: {},
  KMZs: [],
  newKMZ: '',
  optionVisualize: VISUALIZE_DEFAULT,
};

const reducer = (state = initialState, action) => {
  const { type, payload, positionById } = action;
  switch (type) {
    case types.LIST_COORDINATES_SUCCEEDED:
      return {
        ...state,
        positions: payload.positions,
        showPositions: payload.positions,
        totalPositions: payload.positions.length,
        totalMeasurementUnits: payload.totalMeasurementUnits,
        totalStreams: payload.totalStreams,
        totalChromas: payload.totalChromas,
        isLoading: false,
      };
    case types.SHOW_POSITIONS: {
      const {
        termSearch,
        allPositions,
        readErrors,
        flowComputerErrors,
        chromatographs,
        chromatographsErrors,
      } = payload;
      const regex = new RegExp(termSearch, 'i');
      const searchPositions = payload.termSearch
        ? state.positions.filter(({ name }) => regex.test(name))
        : state.positions;

      if (allPositions) {
        return {
          ...state,
          showPositions: searchPositions,
          isLoading: false,
        };
      }
      if (readErrors) {
        return {
          ...state,
          showPositions: searchPositions.filter(
            ({ statusFC, statusChroma, chroma }) => statusFC !== 'ok' || (chroma && statusChroma !== 'ok')
          ),
          isLoading: false,
        };
      }
      if (flowComputerErrors) {
        return {
          ...state,
          showPositions: searchPositions.filter(({ statusFC }) => statusFC !== 'ok'),
          isLoading: false,
        };
      }
      if (chromatographs) {
        return {
          ...state,
          showPositions: searchPositions.filter(({ chroma }) => chroma),
          isLoading: false,
        };
      }
      if (chromatographsErrors) {
        return {
          ...state,
          showPositions: searchPositions.filter(({ statusChroma, chroma }) => chroma && statusChroma !== 'ok'),
          isLoading: false,
        };
      }
      return {
        ...state,
        showPositions: searchPositions,
        isLoading: false,
      };
    }
    case types.OPTION_VISUALIZE:
      return {
        ...state,
        optionVisualize: payload,
        isLoading: false,
      };
    case types.LIST_COORDINATES_FAILED:
    case types.LIST_KMZ_FAILED:
    case types.UPLOAD_KMZ_FAILED:
      return {
        ...state,
        error: payload.error,
        isLoading: false,
      };
    case types.LIST_COORDINATES_REQUESTED:
    case types.LIST_KMZ_REQUESTED:
    case types.UPLOAD_KMZ_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case types.SHOW_INFO_REQUESTED:
    case types.SHOW_INFO_SUCCEEDED:
    case types.SHOW_INFO_FAILED:
    case types.CHANGE_POSITION:
    case types.GET_SUMMARY_REQUESTED:
    case types.GET_SUMMARY_SUCCEEDED:
      return {
        ...state,
        ...payload,
      };
    case types.POSITION_BY_ID_SUCCEEDED:
      return { ...state, positionById };

    case types.LIST_KMZ_SUCCEEDED:
      return {
        ...state,
        KMZs: payload.KMZs,
      };
    case types.UPLOAD_KMZ_SUCCEEDED:
      return {
        ...state,
        newKMZ: payload,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
