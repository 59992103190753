import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Input from '../../form/input';
import Button from '../../button';
import Popconfirm from '../../popConfirm';
import { Search, Filters } from '../styles';

const SearchDeleteCreate = ({
  handleDelete,
  handleSearch,
  handleOnCreate,
  searchPlaceholder = 'commonTexts:introduceSearch',
  createText = 'actions:NewEntity',
  removeText = 'actions:DeleteEntity',
  selecteds,
  toggleAllRowsSelected,
  pageSize,
  pageIndex,
  children,
  title,
  titleConfirm = 'commonTexts:confirmRemove',
}) => {
  const { t } = useTranslation();

  return (
    <>
      {title && title}
      <Filters>
        {handleSearch && (
          <Search>
            <Input onChange={handleSearch} data-cy="searchTable" placeholder={t(searchPlaceholder)} />
          </Search>
        )}
        {children &&
          (typeof children === 'function'
            ? children({ selecteds, toggleAllRowsSelected, pageSize, pageIndex, title })
            : children)}
      </Filters>
      {selecteds.length && handleDelete ? (
        <Popconfirm
          placement="left"
          title={t(titleConfirm)}
          onConfirm={() => {
            handleDelete(
              selecteds.map(({ original: { id } }) => id),
              { pageSize, pageIndex }
            );
            toggleAllRowsSelected(false);
          }}
          okText={t('commonTexts:yes')}
          cancelText={t('commonTexts:no')}
        >
          <Button variant="link" color="danger" data-cy="btn-DelTable">
            {t(removeText)}
          </Button>
        </Popconfirm>
      ) : (
        ''
      )}

      {handleOnCreate && (
        <Button data-cy="btn-Add" color="white" variant="primary" onClick={handleOnCreate}>
          {t(createText)}
        </Button>
      )}
    </>
  );
};

SearchDeleteCreate.propTypes = {
  selecteds: PropTypes.arrayOf(
    PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    })
  ).isRequired,
  toggleAllRowsSelected: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSearch: PropTypes.func,
  handleOnCreate: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  createText: PropTypes.string,
  removeText: PropTypes.string,
  pageSize: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  children: PropTypes.node,
  title: PropTypes.node,
  titleConfirm: PropTypes.string,
};

SearchDeleteCreate.defaultProps = {
  searchPlaceholder: 'commonTexts:introduceSearch',
  createText: 'actions:NewEntity',
  removeText: 'actions:DeleteEntity',
  handleDelete: null,
  handleSearch: null,
  handleOnCreate: null,
  children: '',
  title: '',
  toggleAllRowsSelected: () => {},
  titleConfirm: 'commonTexts:confirmRemove',
};

export default SearchDeleteCreate;
