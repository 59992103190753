import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text, Tag } from 'ui';
import { InputStyled, InputCaretStyled, InputPlaceholder } from './styles';

const PrintOperation = ({ type, entity, value, onClick, inputAccess }) => {
  const { t } = useTranslation();

  if (type === 'math') {
    return (
      <span data-focus-formula={inputAccess} onClick={onClick}>
        {value}
      </span>
    );
  }
  if (type === 'varLine') {
    return (
      <Text style={{ marginBottom: '0px' }} onClick={onClick} data-focus-formula={inputAccess}>
        <Tag data-focus-formula={inputAccess} color="#D699B4">
          {`${value}#${t('entitiesNames:Line')} ${entity ? `${entity}` : ''}`} {!entity}
        </Tag>
      </Text>
    );
  }
  if (type === 'varMeasurementUnit') {
    return (
      <Text onClick={onClick} data-focus-formula={inputAccess}>
        <Tag data-focus-formula={inputAccess} color="#7171A6">
          {`${value}#UM ${entity ? `${entity}` : ''}`} {!entity}
        </Tag>
      </Text>
    );
  }
  return '';
};

PrintOperation.propTypes = {
  entity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      toString: PropTypes.func,
    }),
  ]),
  type: PropTypes.oneOf(['math', 'varLine', 'varMeasurementUnit']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func.isRequired,
};

const Input = ({ operations, input, cursorPosition, setCursorPosition, inputAccess, isFocus }) => {
  const { t } = useTranslation();
  return (
    <InputStyled data-focus-formula={inputAccess}>
      {cursorPosition === -1 && <span>{input}</span>}
      {cursorPosition === -1 && isFocus && <InputCaretStyled />}
      {operations.map(({ type, value, entity, key }, index) => {
        const hasPlaceholder = entity === null && type !== 'math';
        return (
          <Fragment key={key}>
            <PrintOperation
              key={key}
              inputAccess={inputAccess}
              type={type}
              entity={entity}
              value={value}
              onClick={() => setCursorPosition(index)}
            />
            {cursorPosition === index && <span>{input}</span>}
            {cursorPosition === index && isFocus && <InputCaretStyled />}
            {hasPlaceholder && (
              <InputPlaceholder>
                {type === 'varMeasurementUnit'
                  ? t('CustomVarPage:IndicateNumberMeasurementUnit')
                  : t('CustomVarPage:IndicateNumberLines')}
              </InputPlaceholder>
            )}
          </Fragment>
        );
      })}
    </InputStyled>
  );
};

Input.propTypes = {
  operations: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          toString: PropTypes.func,
        }),
      ]),
      type: PropTypes.oneOf(['math', 'varLine', 'varMeasurementUnit']),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      key: PropTypes.number,
    })
  ).isRequired,
  input: PropTypes.string.isRequired,
  cursorPosition: PropTypes.number.isRequired,
  setCursorPosition: PropTypes.func.isRequired,
  inputAccess: PropTypes.string.isRequired,
  isFocus: PropTypes.bool.isRequired,
};

export default Input;
