import types from './types';

const showRequested = ({ measurementUnit, call }) => {
  return {
    type: types.SHOW_REQUESTED,
    payload: { id: measurementUnit, loading: true, requestCall: call },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const showSucceeded = (result) => {
  return {
    type: types.SHOW_SUCCEEDED,
    payload: { ...result, loading: false },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const showFailed = ({ id, error }) => {
  return {
    type: types.SHOW_FAILED,
    payload: { id, error },
    error,
    meta: {
      timestamp: new Date(),
      error,
    },
  };
};

const showConfigUserRequested = () => {
  return {
    type: types.CONFIG_USER_REQUESTED,
    payload: { loading: true },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const showConfigUserSucceeded = (result) => {
  return {
    type: types.CONFIG_USER_SUCCEED,
    payload: { data: result, loading: false },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const showConfigUserFailed = ({ error }) => {
  return {
    type: types.CONFIG_USER_FAILED,
    payload: { error },
    error,
    meta: {
      timestamp: new Date(),
      error,
    },
  };
};
const setEntity = (entity) => ({
  type: types.SET_ENTITY_ID,
  payload: { ...entity, loading: false },
  meta: {
    timestamp: new Date(),
  },
});

const setDateRange = (dates) => ({
  type: types.SET_DATE_RANGE,
  payload: { ...dates, loading: false },
  meta: {
    timestamp: new Date(),
  },
});

const setRequestCall = (call) => ({
  type: types.SET_REQUEST_CALL,
  payload: { ...{ requestCall: call }, loading: false },
  meta: {
    timestamp: new Date(),
  },
});

export default {
  showRequested,
  showSucceeded,
  showFailed,
  showConfigUserRequested,
  showConfigUserSucceeded,
  showConfigUserFailed,
  setEntity,
  setDateRange,
  setRequestCall,
};
