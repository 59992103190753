import { css } from 'styled-components';

export default {
  size: {
    xsmall: css`
      font-size: 10px;
    `,
    small: css`
      font-size: 12px;
    `,
    medium: css`
      font-size: 14px;
    `,
    large: css`
      font-size: 16px;
    `,
  },

  align: {
    left: css`
      text-align: left;
    `,
    center: css`
      text-align: center;
    `,
    right: css`
      text-align: right;
    `,
  },

  color: {
    primary: css`
      color: ${(props) => props.theme.colors.primary}!important;
    `,
    secondary: css`
      color: ${(props) => props.theme.colors.secondary};
    `,
    success: css`
      color: ${(props) => props.theme.colors.success};
    `,
    danger: css`
      color: ${(props) => props.theme.colors.danger};
    `,
    ghost: css`
      color: ${(props) => props.theme.colors.darkGrey};
    `,
    white: css`
      color: ${(props) => props.theme.colors.white};
    `,
    warning: css`
      color: orange;
    `,
    light: css`
      color: ${(props) => props.theme.colors.light};
    `,
  },
};
