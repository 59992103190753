import styled, { keyframes } from 'styled-components';

const AnimationCaret = keyframes`
   from, to{
		border-color: transparent
    }

    50% {
			border:solid 0.5px black;
    }
  `;

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const InputStyled = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #ccc;
  background: #ececf1;
  height: 45px;
  padding: 0 10px;
  cursor: text;
  overflow-y: scroll;

  :checked {
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25);
  }

  .ant-tag {
    margin-left: 3px;
    margin-bottom: 0px;
  }
`;

const InputCaretStyled = styled.span`
  display: inline-block;
  width: 2px;
  animation: ${AnimationCaret} 1s step-end infinite;
  height: 18px;
  margin-left: 2px;
`;

const CalculatorStyles = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 390px;

  button {
    font-weight: bold;
    min-width: 45px;
    height: 45px;
    margin: 2px;
    padding: 0 10px;
  }
`;

const InputPlaceholder = styled.span`
  font-size: 12px;
  margin-left: 4px;
  margin-top: 2px;
`;

const SelectVar = styled.ul`
  background: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  margin-top: 2px;
  padding: 10px 0;
  list-style: none;
  position: absolute;
  width: 100%;
  z-index: 1;
  max-height: 280px;
  overflow: hidden;
  overflow-y: scroll;

  li {
    padding: 10px 20px;
    cursor: pointer;

    :hover {
      background: #f1f1f1;
    }
  }
`;

const ContentForm = styled.div`
  max-width: 820px;
  min-width: 100%;
  background: #ffffff;
  border-radius: 6px;
  height: auto;
  flex: 1;
`;

const HelpColumn = styled.div`
  width: 490px;
  margin-right: 70px;
`;

export {
  InputContainer,
  InputStyled,
  InputCaretStyled,
  InputPlaceholder,
  CalculatorStyles,
  SelectVar,
  ContentForm,
  HelpColumn,
};
