import {
  isEmpty,
  identity,
  equals,
  pipeWith,
  then,
  pathOr,
  mergeDeepRight,
  omit,
  uniqBy,
  prop,
  props,
  flatten,
  pipe,
  groupBy,
  update,
  clone,
  propOr,
  splitEvery,
  sortBy,
  pick,
  comparator,
  isNil,
  reduce,
  filter,
} from 'ramda/es';
import { string } from 'yup';
import debounce from 'lodash.debounce';

export const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1);

const pipeWithPromises = (...fns) => pipeWith(then)(fns);

const validator = {
  string,
};

const concatEntities = (entities = [], extractor = identity) =>
  entities.reduce((acc, entity) => `${acc.length ? `${acc}, ` : acc}${extractor(entity)}`, '');

export default {
  isEmpty,
  identity,
  pipeWithPromises,
  pathOr,
  debounce,
  mergeDeepRight,
  omit,
  uniqBy,
  prop,
  props,
  propOr,
  flatten,
  equals,
  pipe,
  groupBy,
  update,
  clone,
  validator,
  splitEvery,
  concatEntities,
  sortBy,
  pick,
  comparator,
  isNil,
  reduce,
  filter,
};

export {
  identity,
  pipeWithPromises,
  pathOr,
  debounce,
  mergeDeepRight,
  omit,
  uniqBy,
  prop,
  props,
  flatten,
  equals,
  pipe,
  groupBy,
  update,
  clone,
  propOr,
  validator,
  isEmpty,
  splitEvery,
  concatEntities,
  sortBy,
  pick,
  comparator,
  isNil,
  reduce,
  filter,
};
