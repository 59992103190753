import React, { ChangeEvent, ReactNode } from 'react';

import Label from 'ui/form/label';
import { FormFielStyles } from '../styles';
import ErrorInput from '../Error';
import { InputStyles } from './styles';

interface Props {
  id?: string;
  disabled?: boolean;
  dataHelp?: string | null;
  type?: 'text' | 'number';
  required?: boolean;
  width?: number;
  placeholder?: string;
  name?: string;
  textLabel?: string | null;
  value?: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any;
  noSeparation?: boolean;
  hidden?: boolean;
  hasError?: boolean;
  error?: ReactNode;
  errors?: Record<string, unknown>;
  step?: string;
  separation?: boolean;
}

const InputForm = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      width = 100,
      placeholder,
      disabled,
      required,
      name,
      dataHelp,
      textLabel,
      value,
      noSeparation,
      onChange = () => {},
      type = 'text',
      hidden,
      hasError,
      error,
      errors = {},
      ...props
    },
    ref
  ) => (
    <FormFielStyles {...{ noSeparation, width, hidden }}>
      <ErrorInput errors={errors} name={name} />
      <InputStyles
        type={type}
        placeholder={placeholder}
        id={name}
        name={name}
        required={required}
        disabled={disabled}
        value={value}
        onChange={onChange}
        hasError={hasError}
        {...(ref && { ref })}
        {...props}
      />
      {textLabel ? <Label {...{ textLabel, dataHelp }} /> : null}
    </FormFielStyles>
  )
);

InputForm.displayName = 'InputForm';

export default InputForm;
