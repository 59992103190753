import { createSelector } from 'reselect';

const getEquipmentsState = (state) => state.equipments;

const getModalState = createSelector([getEquipmentsState], (equipmentState) => equipmentState.modalState);

const factoryGetEquipmentInfoByType = (type) =>
  createSelector([getEquipmentsState], (equipmentState) => equipmentState[type]);

const factoryGetEquipmentDetailsByType = (type, id) =>
  createSelector([factoryGetEquipmentInfoByType(type)], (equipmentInfo) =>
    equipmentInfo.entities.find((equipment) => equipment.id === id)
  );

const getFootprintsChroma = createSelector([getEquipmentsState], (state) => state.footprintChroma);
const getAnalysisChroma = createSelector([getEquipmentsState], (state) => state.analysisChroma);
const getEvolutionChroma = createSelector([getEquipmentsState], (state) => state.evolutionChroma);

export {
  getEquipmentsState,
  getModalState,
  factoryGetEquipmentInfoByType,
  factoryGetEquipmentDetailsByType,
  getFootprintsChroma,
  getAnalysisChroma,
  getEvolutionChroma,
};
