import React from 'react';

import { useTranslation } from 'react-i18next';
import RowFlex from '../../common';
import Title from '../../tipography/title';
import { Tag } from '../../tag';

import { HeaderDetailsStyles } from './styles';

const HeaderDetails = ({
  children,
  title,
  state = false,
}: {
  children?: React.ReactNode;
  title?: string;
  state?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <HeaderDetailsStyles>
      <RowFlex>
        <Title level={4}>
          {title} {state ? <Tag color="red">{t('MeasurementUnitPages:NoMeasurements')}</Tag> : null}
        </Title>
      </RowFlex>

      <div>{children}</div>
    </HeaderDetailsStyles>
  );
};

export default HeaderDetails;
