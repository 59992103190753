import styled from 'styled-components';
import { Breadcrumb } from 'antd';

const BreadcrumbStyles = styled(Breadcrumb)`
  position: fixed;
  top: 16px;
  z-index: 99;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }

  .ant-breadcrumb-link {
    button {
      margin: 0 8px;
      position: relative;
      top: 2px;
    }
  }
`;

export { BreadcrumbStyles };
