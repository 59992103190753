import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { actions, operations as operationsReports } from 'modules/reports';
import { operations as operationsEquipments, actions as actionsEquipments } from 'modules/equipments';
import { getDateFormated, addHours, subHours } from 'libs/date';

const extractMapVariablesEntitiesFromFormula = ({ formula, measurementUnits }) => {
  return formula.reduce((acc, operation) => {
    if (operation.type === 'math') {
      return acc;
    }
    if (operation.type === 'varLine') {
      const measureUnit = measurementUnits.find((measurementUnit) =>
        measurementUnit.lines.some((line) => Number(line.id) === Number(operation.entityId))
      );
      return [
        ...acc,
        {
          id: operation.key,
          measureId: measureUnit.id,
          measureName: measureUnit.name,
          lineId: operation.entityId,
          lineName: operation.entity.label,
          type: 'line',
          actions: ['delete'],
          variable: operation.value,
        },
      ];
    }
    return [
      ...acc,
      {
        id: operation.key,
        measureId: operation.entityId,
        measureName: operation.entity.label,
        type: 'measurementUnit',
        actions: ['delete'],
        variable: operation.value,
      },
    ];
  }, []);
};

const extractMapVariablesEntitiesFromLines = ({ lines, measurementUnits, variables }) => {
  const lineIds = lines.map((line) => line.id);
  return measurementUnits.reduce((acc, measurementUnit) => {
    const linesAdded = measurementUnit.lines.reduce((linesAcc, line) => {
      if (!lineIds.includes(line.id)) {
        return linesAcc;
      }
      const base = {
        measureId: measurementUnit.id,
        measureName: measurementUnit.name,
        lineId: line.id,
        lineName: line.name,
        type: 'line',
        actions: ['delete'],
      };
      return [
        ...linesAcc,
        ...variables.map(({ value }) => ({
          ...base,
          id: `${base.measureId}|${value}${base.lineId}`,
          variable: value,
        })),
      ];
    }, []);
    return [...acc, ...linesAdded];
  }, []);
};

const extractMapVariablesEntitiesFromMeasureUnits = ({ measurementUnits, variables }) => {
  return measurementUnits.reduce((acc, measurementUnit) => {
    const base = {
      measureId: measurementUnit.id,
      measureName: measurementUnit.name,
      type: 'measurementUnit',
      actions: ['delete'],
    };
    return [
      ...acc,
      ...variables.map(({ value }) => ({
        ...base,
        id: `${base.measureId}|${value}`,
        variable: value,
      })),
    ];
  }, []);
};

const getMapVariablesEntities = ({ formula, measurementUnits, lines, variables }) => {
  if (formula) {
    return extractMapVariablesEntitiesFromFormula({ formula, measurementUnits });
  }
  if (lines.length) {
    return extractMapVariablesEntitiesFromLines({ lines, measurementUnits, variables });
  }
  return extractMapVariablesEntitiesFromMeasureUnits({ measurementUnits, variables });
};

const getDateRange = ({ creationDate }) => {
  const hourStartDate = getDateFormated(subHours(creationDate, 1), 'HH:00');
  const hourEndDate = getDateFormated(addHours(creationDate, 1), 'HH:00');
  return {
    startDate: creationDate,
    startHour: { label: hourStartDate, value: hourStartDate },
    endDate: creationDate,
    endHour: { label: hourEndDate, value: hourEndDate },
  };
};

const useAnalyzeIncidence = ({ formula, measurementUnits, lines, variables, creationDate, alertType, equipmentId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const setMapVariablesEntities = (entities) => {
    dispatch(actions.setMapVariablesEntities(entities));
  };

  const handleAnalizeFootprint = () => {
    dispatch(actionsEquipments.setChromatographFootprint({ id: String(equipmentId) }));
    dispatch(
      operationsEquipments.getAnalysisFootprintChromaRequest({
        id: equipmentId,
        date: creationDate,
        reditect: true,
      })
    );
    history.push(`/reports/footprint?openWhenReady=true&type=analysis`);
  };

  const handleAnalizeAdvanced = () => {
    const mapVariablesEntities = getMapVariablesEntities({ formula, measurementUnits, lines, variables });
    setMapVariablesEntities(mapVariablesEntities);
    dispatch(actions.setDateRange(getDateRange({ creationDate })));
    dispatch(actions.setReportLayoutStatusOpen(false));
    dispatch(operationsReports.generateAdvancedReportRequest({}));
    history.push('/reports/advanced');
  };

  return { handleAnalize: alertType === 'footprint_chroma' ? handleAnalizeFootprint : handleAnalizeAdvanced };
};

export default useAnalyzeIncidence;
