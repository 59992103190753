import React from 'react';

import { debounce } from 'libs/toolkits';

type Query = {
  limit: number;
  offset?: number;
  q?: string;
};

function useQueryTable<T>(initialValue: Query & T) {
  const [query, setQuery] = React.useState<Query & T>(initialValue);

  const handleOnChange = React.useCallback(
    ({ pageIndex, pageSize, ...rest }) => {
      setQuery({ ...query, offset: pageIndex * pageSize, pageSize, ...rest });
    },
    [query, setQuery]
  );

  const deabounceSearch = React.useCallback(
    debounce((q: string) => {
      setQuery({ ...query, q });
    }, 300),
    [setQuery, query]
  );

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    deabounceSearch(value);
  };

  return {
    handleOnChange,
    handleSearch,
    query,
    setQuery,
  };
}

export default useQueryTable;
