import { getStatusCode } from 'libs/request-utils';
import { operations } from '../notifications';
import { MESSAGES_NOTIFICATIONS } from '../notifications/constants';
import { transformResults } from './transforms';

import * as actions from './actions';
import { getCall } from './selectors';
import getEntity from './api';

const globalSearch = (query) => (dispatch, getState) => {
  const state = getState();
  const cancelCall = getCall(state);
  dispatch(actions.globalSearch({ isLoading: true, items: [], noResults: false }));

  if (!query || (query && query.length < 2)) {
    dispatch(actions.globalSearch({ items: [], isLoading: false, noResults: false }));
    return new Promise((resolve) => resolve());
  }

  const setCall = (tokenCall) => {
    dispatch(actions.setRequestCall(tokenCall));
  };

  return getEntity({ params: { terms: query }, cancelCall, setCall })
    .then(({ data }) =>
      dispatch(
        actions.globalSearch({ ...transformResults(data), ...(!data?.items?.length ? { noResults: true } : {}) })
      )
    )
    .catch((error) => {
      const statusCode = getStatusCode(error);

      if (!cancelCall) {
        dispatch(
          operations.errorNotification({
            key: 'error-search-topbar',
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:Search')[statusCode],
          })
        );
      }
    })
    .finally(() => dispatch(actions.globalSearch({ isLoading: false })));
};

export default globalSearch;
