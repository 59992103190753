import styled, { keyframes } from 'styled-components';

const SpinnerAnimation = keyframes`
from{
  top: 18px;
  left: 18px;
  width: 0;
  height: 0;
  opacity: 1;
}

to{
  top: -1px;
  left: -1px;
  width: 38px;
  height: 38px;
  opacity: 0;
}
`;

export const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 45px;

  div {
    position: absolute;
    border: 3px solid #ccc;
    opacity: 1;
    border-radius: 50%;
    animation: ${SpinnerAnimation} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  div:nth-child(2) {
    animation-delay: -0.5s;
  }
`;
