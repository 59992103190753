const transformResults = (data) => {
  const result = data.items;

  const positions = result
    .filter(({ link, position, type }) => type === 'position' && position && link === position.toString())
    .reduce(
      (acc, { position, ...rest }) => [
        ...acc,
        {
          ...rest,
          mus: result.filter(({ position: positionMu, type }) => type === 'measurementunit' && positionMu === position),
        },
      ],
      []
    );
  const positionIds = positions.map(({ link: id }) => id);

  const measurementUnitIdsTaken = positions.reduce((acc, { mus }) => [...acc, ...mus.map(({ link }) => link)], []);

  return {
    items: [
      ...positions,
      ...result.filter(({ link }) => !positionIds.includes(link) && !measurementUnitIdsTaken.includes(link)),
    ],
  };
};

export { transformResults };
