const actionTypes = {
  CHANGE_POSITION: '@MAP/CHANGE_POSITION',
  LIST_COORDINATES_REQUESTED: '@MAP/LIST_COORDINATES_REQUESTED',
  LIST_COORDINATES_SUCCEEDED: '@MAP/LIST_COORDINATES_SUCCEEDED ',
  LIST_COORDINATES_FAILED: '@MAP/LIST_COORDINATES_FAILED',
  LIST_KMZ_REQUESTED: '@MAP/LIST_KMZ_REQUESTED',
  LIST_KMZ_SUCCEEDED: '@MAP/LIST_KMZ_SUCCEEDED ',
  LIST_KMZ_FAILED: '@MAP/LIST_KMZ_FAILED',
  UPLOAD_KMZ_REQUESTED: '@MAP/UPLOAD_KMZ_REQUESTED',
  UPLOAD_KMZ_SUCCEEDED: '@MAP/UPLOAD_KMZ_SUCCEEDED ',
  UPLOAD_KMZ_FAILED: '@MAP/UPLOAD_KMZ_FAILED',
  SHOW_INFO_REQUESTED: '@MAP/SHOW_INFO_REQUESTED',
  SHOW_INFO_SUCCEEDED: '@MAP/SHOW_INFO_SUCCEEDED',
  SHOW_INFO_FAILED: '@MAP/SHOW_INFO_FAILED',
  POSITION_BY_ID_SUCCEEDED: '@MAP/POSITION_BY_ID_SUCCEEDED',
  SHOW_POSITIONS: '@MAP/SHOW_POSITIONS',
  OPTION_VISUALIZE: '@MAP/OPTION_VISUALIZE',
  GET_SUMMARY_REQUESTED: '@MAP/GET_SUMMARY_REQUESTED',
  GET_SUMMARY_SUCCEEDED: '@MAP/GET_SUMMARY_SUCCEEDED',
};

export default actionTypes;
