import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { Modal, ModalHeader, Icon, Title, ModalContent, RowFlex, Iframe } from 'ui';
import { selectors } from 'modules/auth';

const SupportModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { auth } = useSelector((state) => ({
    auth: selectors.getUserAuth(state),
  }));

  if (!isOpen) {
    return null;
  }

  return (
    <Modal>
      <ModalContent maxWidth="860px">
        <ModalHeader>
          <RowFlex justify="space-between" align="flex-start" flex={1}>
            <Title color="primary" level={3}>
              {t('TopBar:AccountInfo:Support')}
            </Title>
            <Icon iconName="icon-close" pointer onClick={onClose} />
          </RowFlex>
        </ModalHeader>
        <Iframe
          width="100%"
          minHeight="500px"
          src={`https://smartenergyassets.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Ayuda+y+soporte+&submitTitle=Enviar&submitThanks=Gracias+por+tu+comentario&screenshot=No&helpdesk_ticket[requester]=${auth.email}`}
        />
      </ModalContent>
    </Modal>
  );
};

SupportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SupportModal.defaultValues = {
  isOpen: false,
};
export default SupportModal;
