/* eslint-disable react/display-name */

import React from 'react';
import PropTypes from 'prop-types';

import Label from '../label';
import { RadioButtonStyles } from './styles';

const RadioButton = React.forwardRef(({ id, textLabel, checked, onChange, name, disabled, variant }, ref) => {
  return (
    <RadioButtonStyles variant={variant} onClick={!disabled ? onChange : () => {}}>
      <input
        {...(id && { id })}
        type="radio"
        value={id}
        onClick={onChange}
        onChange={onChange}
        name={name}
        checked={checked}
        disabled={disabled}
        {...(ref && { ref })}
      />
      <Label htmlFor={id} textLabel={textLabel} />
    </RadioButtonStyles>
  );
});

RadioButton.propTypes = {
  id: PropTypes.string,
  textLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'colored']),
};

RadioButton.defaultProps = {
  id: '',
  onChange: () => {},
  checked: false,
  disabled: false,
  variant: 'default',
};

export default RadioButton;
