import { useDispatch } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';

import { debounce } from 'libs/toolkits';

const useTableCommonActions = ({ listOperation, deleteOperation, PAGE_SIZE }) => {
  const dispatch = useDispatch();

  const [termsSearch, setTermsSearch] = useState('');

  const requestSections = useCallback(
    (query = {}) => {
      dispatch(listOperation({ limit: PAGE_SIZE, ...query, ...(termsSearch ? { q: termsSearch } : {}) }));
    },
    [PAGE_SIZE, dispatch, termsSearch, listOperation]
  );

  useEffect(() => {
    requestSections();
  }, [requestSections]);

  const handleChangeTable = useCallback(
    ({ pageIndex, pageSize }) => {
      requestSections({ offset: pageIndex * pageSize, limit: pageSize });
    },
    [requestSections]
  );

  const handleSearch = ({ target: { value } }) => {
    const deabounceSearch = debounce(() => {
      setTermsSearch(value);
    }, 300);
    deabounceSearch();
  };

  const handleRemove = useCallback(
    (ids, { tableState }) => {
      dispatch(
        deleteOperation(ids, {
          ...(termsSearch && { q: termsSearch }),
          offset: tableState ? tableState.pageIndex * tableState.pageSize : 0,
          limit: tableState ? tableState.pageSize : PAGE_SIZE,
        })
      );
    },
    [dispatch, deleteOperation, termsSearch, PAGE_SIZE]
  );

  return {
    handleChangeTable,
    handleSearch,
    handleRemove,
    termsSearch,
  };
};

export default useTableCommonActions;
