import { useCallback } from 'react';
import { generateEntityQueries } from 'libs/request-utils';
import { transformFillResponseToSelectOptions } from './utils';

const { searchEntities, fillEntities } = generateEntityQueries({
  nameForLists: 'position',
});

const useSearchPositions = () =>
  useCallback((text) => {
    return searchEntities({
      params: { q: text, order: 'ASC', limit: 20, ho: 0 },
    });
  }, []);

const useFillPositions = () => {
  return fillEntities({
    params: {},
  })
    .then(
      ({
        data: { positions } = {
          positions: [],
        },
      }) => {
        return transformFillResponseToSelectOptions(positions);
      }
    )
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export { useSearchPositions, useFillPositions };
