import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as firebase from 'firebase/app';
import 'firebase/auth';

import { useTranslation } from 'react-i18next';
import Request from 'libs/request-utils';
import { DEFAULT_LANGUAGE } from 'modules/auth/constants';
import { actions, selectors } from 'modules/auth';
import firebaseConfig from '../../config/firebase';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const provider = new firebase.auth.GoogleAuthProvider();

provider.addScope('email');

const TIME_TO_REFRESH_TOKEN = 900000;

const useAuth = () => {
  const { i18n } = useTranslation();
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userAuth } = useSelector((state) => ({
    userAuth: selectors.getUserAuth(state),
  }));
  const dispatch = useDispatch();
  const handleUserInfo = useCallback(
    (user) => {
      dispatch(actions.setUserInfo(user));
    },
    [dispatch]
  );

  useEffect(() => {
    i18n.changeLanguage(userAuth.language.toLowerCase() || DEFAULT_LANGUAGE);
  }, [userAuth, i18n]);

  function login() {
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch(() => firebase.auth().getRedirectResult())
      .then(function redirecResultHandle(result) {
        if (!result.user) {
          firebase.auth().signInWithRedirect(provider);
        }
      })
      .catch(() => {
        setIsAuth(false);
        setIsLoading(false);
      });
  }

  function getToken(userInfo) {
    let token = null;

    firebase
      .auth()
      .currentUser.getIdToken(false)
      .then((idToken) => {
        token = idToken;
        return Request.post(
          `${process.env.REACT_APP_API_URL}/check`,
          {},
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
      })
      .then(({ data }) => {
        localStorage.setItem('sea-token', token);
        setIsAuth(true);
        setIsLoading(false);
        setTimeout(getToken, TIME_TO_REFRESH_TOKEN);
        handleUserInfo({
          ...userInfo,
          company: data.user_company,
          role: data.info_user.role_user,
          language: data.language || DEFAULT_LANGUAGE,
        });
      })
      .catch(() => {
        setIsAuth(false);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((isAuthInGoogle) => {
      if (!isAuthInGoogle) {
        login();
      } else {
        const userInfo = {
          photoURL: isAuthInGoogle.photoURL,
          email: isAuthInGoogle.email,
          name: isAuthInGoogle.displayName,
        };
        getToken(userInfo);
      }
    });
  }, []);

  return {
    isAuth,
    isLoading,
  };
};

export default useAuth;
