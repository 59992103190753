import styled from 'styled-components';

import { Button, ButtonProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface Props extends Partial<Omit<ButtonProps, 'size'>> {
  styled: {
    size: SizeType | 'default';
    variant: 'primary' | 'default' | 'ghost' | 'success' | 'link' | 'plain' | 'danger';
    color: 'primary' | 'secondary' | 'ghost' | 'success' | 'light' | 'white' | 'danger' | 'success';
    shape?: 'quadrate' | 'rounded';
  };
}

const StyledButton = styled(Button)<Props>`
  font-weight: normal;
  border-radius: 4px;
  display: flex;
  align-items: center;

  & > span {
    pointer-events: none;
  }
  .dropdown-mode {
    min-width: 150px;
  }
  &.ant-btn-link {
    padding: 0px;
    color: ${(props) => props.theme.colors.primary};
    border: none;
  }
  &:after {
    animation: 0s !important;
  }
  &:focus {
    animation: 0s !important;
    background: none;
  }
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: no-drop;
  }
  ${({ styled: { size }, theme }) => {
    return theme.button.size[size as keyof typeof theme.button.size] || '';
  }};
  ${({ styled: { variant }, theme }) => theme.button.variant[variant]};
  ${({ styled: { shape }, theme }) => (shape ? theme.button.shape[shape] : '')};
  ${({ styled: { color }, theme }) => theme.button.color[color]};
`;

export { StyledButton };
