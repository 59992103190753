import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../button';

const DownloadRows = ({ handleDownload, downloadText, selecteds, toggleAllRowsSelected }) => {
  return (
    <>
      {selecteds.length ? (
        <Button
          variant="link"
          color="primary"
          onClick={() => {
            handleDownload(selecteds.map(({ original: { id } }) => id));
            toggleAllRowsSelected(false);
          }}
        >
          {downloadText}
        </Button>
      ) : (
        ''
      )}
    </>
  );
};

DownloadRows.propTypes = {
  selecteds: PropTypes.arrayOf(
    PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    })
  ).isRequired,
  toggleAllRowsSelected: PropTypes.func.isRequired,
  handleDownload: PropTypes.func,
  downloadText: PropTypes.string,
};

DownloadRows.defaultProps = {
  downloadText: 'Descargar',
  handleDownload: () => {},
};

export default DownloadRows;
