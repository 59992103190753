const SUCCESS_NOTIFICATION = '@notifications/SUCCESS_NOTIFICATION';
const ERROR_NOTIFICATION = '@notifications/ERROR_NOTIFICATION';
const WARNING_NOTIFICATION = '@notifications/WARNING_NOTIFICATION';
const INFO_NOTIFICATION = '@notifications/INFO_NOTIFICATION';
const SUCCESS_MESSAGE = '@notifications/SUCCESS_MESSAGE';
const ERROR_MESSAGE = '@notifications/ERROR_MESSAGE';
const WARNING_MESSAGE = '@notifications/WARNING_MESSAGE';
const INFO_MESSAGE = '@notifications/INFO_MESSAGE';

export {
  SUCCESS_NOTIFICATION,
  ERROR_NOTIFICATION,
  WARNING_NOTIFICATION,
  INFO_NOTIFICATION,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  WARNING_MESSAGE,
  INFO_MESSAGE,
};
