import { extractorActionsResponse } from 'libs/request-utils';
import { getDateInTimezone, getDateFormated, differenceIn } from 'libs/date';

const actionsTranslate = {
  load: 'show',
  delete: 'delete',
  select: 'select',
};

const getStatusCertificate = (expirationDate) => {
  let status;
  if (differenceIn(new Date(), new Date(expirationDate)) >= 30) status = 'valid';
  if (differenceIn(new Date(), new Date(expirationDate)) < 0) status = 'lapsed';
  return status || 'next';
};

const equipmentExtractorActions = extractorActionsResponse(actionsTranslate);

const transformCounter = ({
  type,
  beam,
  size,
  hardware,
  order_beam: orderBeam,
  q_t: qT,
  q_min: qMin,
  q_max: qMax,
}) => ({
  ...(type === 'ultrasonic' ? { beam, hardware, orderBeam } : {}),
  size,
  type,
  qMax,
  qMin,
  qT,
});

const transformChromatograph = ({
  name,
  enabled,
  parser_name: parserName,
  client_type: clientType,
  serial_number: serialNumber,
  measurement_units: measurementUnits,
  stream,
  stream_config: streamConfig,
  instalation_date: instalationDate,
  calibration_date: lastCalibrationDate,
  calibration_validation_date: calibrationValidationDate,
}) => ({
  name,
  enabled,
  serialNumber,
  measurementUnits,
  parserName,
  clientType,
  stream,
  streamConfig,
  instalationDate: instalationDate ? new Date(instalationDate) : undefined,
  lastCalibrationDate: lastCalibrationDate ? new Date(lastCalibrationDate) : undefined,
  calibrationValidationDate: calibrationValidationDate ? new Date(calibrationValidationDate) : undefined,
});

const transformFlowComputer = ({ conversion_type: conversionType, serial_number: serialNumber, lines = [] }) => ({
  conversionType,
  serialNumber,
  lines,
});

const transformPatternEquipment = ({
  organizational_unit: organizationalUnit,
  organizational_unit_label: organizationalUnitName,
  serial_number: serialNumber,
  pattern_areas: patternAreas,
}) => ({
  organizationalUnit,
  organizationalUnitName,
  serialNumber,
  patternAreas,
});
const transformCalibrationBottle = ({
  serial_number: serialNumber,
  type,
  pressure,
  instalation_date: instalationDate,
  preparation_date: preparationDate,
  certificate_code: certificateCode,
  issue_date: issueDate,
  validity_time: validityTime,
  expiration_date: expirationDate,
  associated_chroma_id: chromaID,
  associated_chroma_name: chromaName,
  chromatograph_measurementunits: chromaMUs = [],
}) => ({
  serialNumber,
  type,
  pressure: pressure || '',
  certificateCode,
  chromatograph: chromaID ? { label: chromaName, value: chromaID } : '',
  chromaName,
  chromaMUs,
  issueDate: issueDate ? new Date(issueDate) : '',
  validityTime: validityTime || 12,
  instalationDate: instalationDate ? new Date(instalationDate) : '',
  preparationDate: preparationDate ? new Date(preparationDate) : '',
  expirationDate: expirationDate ? new Date(expirationDate) : '',
  status: expirationDate ? getStatusCertificate(expirationDate) : '',
});

const transformPatternArea = ({
  serial_number: serialNumber,
  area_name: areaName,
  organizational_unit: organizationalUnit,
  organizational_unit_label: organizationalUnitName,
  instalation_date: instalationDate,
  certificate_code: certificateCode,
  expiration_date: expirationDate,
  issue_date: issueDate,
}) => ({
  serialNumber,
  areaName,
  organizationalUnit,
  organizationalUnitName,
  certificateCode,
  instalationDate: instalationDate || '',
  expirationDate: expirationDate || '',
  issueDate: issueDate || '',
  status: getStatusCertificate(expirationDate),
});

const transformFootprintChroma = ({ date, data, stream }) => ({
  date,
  data,
  stream,
});

const transformAnalysisChroma = ({ entities }) =>
  Object.entries(entities).map(([, data]) => ({ ...data, actions: equipmentExtractorActions([{ func: 'select' }]) }));

const transformMaps = {
  counter: transformCounter,
  chromatograph: transformChromatograph,
  flowComputer: transformFlowComputer,
  patternEquipment: transformPatternEquipment,
  patternArea: transformPatternArea,
  calibrationBottle: transformCalibrationBottle,
  footprintChroma: transformFootprintChroma,
};

const transformEquipment = (
  {
    id,
    company,
    company_label: companyName,
    type,
    brand,
    model,
    modification_date: modificationDate,
    creation_date: creationDate,
    actions,
    first_analysis_date: firstAnalysis,
    ...rest
  },
  equipmentType
) => {
  const result = {
    id,
    companyName,
    companyId: company,
    type,
    brand,
    model,
    firstAnalysis: firstAnalysis ? getDateInTimezone(firstAnalysis) : '',
    modificationDate: getDateInTimezone(modificationDate),
    creationDate: getDateInTimezone(creationDate),
    actions: equipmentExtractorActions(actions),
    ...transformMaps[equipmentType]({ ...rest, type }),
  };
  return result;
};

const transformAssignment = ({
  id,
  company,
  company_label: companyName,
  assignment_date: assignmentDate,
  chroma_default_label: chromaDefault,
  measurement_unit_label: measurementUnitName,
  quality_daily_label: qualityDailyName,
}) => ({
  id,
  company,
  companyName,
  assignmentDate,
  chromaDefault,
  measurementUnitName,
  qualityDailyName,
});

const formatParamsToRequest = ({ equipmentType, ...rest }) => {
  if (equipmentType === 'chromatograph') {
    const {
      id,
      name,
      company,
      parserName,
      clientType,
      enabled,
      brand,
      model,
      serialNumber,
      streams,
      instalationDate,
      lastCalibrationDate,
      calibrationValidationDate,
    } = rest;

    return {
      id: id || '',
      name,
      owner: company.value,
      parser_name: parserName.value,
      client_type: clientType.value,
      time_interval: 60000,
      enabled,
      config: '',
      brand: brand.value,
      model: model.value,
      serial_number: serialNumber,
      stream: streams?.length || 1,
      stream_config: JSON.stringify(streams || []),
      instalation_date: instalationDate ? getDateFormated(instalationDate, 'yyyy-MM-dd HH:mm:ss') : '',
      calibration_date: lastCalibrationDate ? getDateFormated(lastCalibrationDate, 'yyyy-MM-dd HH:mm:ss') : '',
      calibration_validation_date: calibrationValidationDate
        ? getDateFormated(calibrationValidationDate, 'yyyy-MM-dd HH:mm:ss')
        : '',
    };
  }
  if (equipmentType === 'counter') {
    const { id, type, model, size, hardware, qMin, qMax, qT, company, beam, orderBeam, brand } = rest;
    return {
      company: company.value,
      id: id || '',
      type: type.value,
      beam,
      order_beam: orderBeam,
      brand: brand.value ? brand.value : brand,
      model: model.value ? model.value : model,
      size: size.value ? size.value : size,
      hardware: (hardware && hardware.value) || '',
      q_min: qMin,
      q_max: qMax,
      q_t: qT,
    };
  }
  if (equipmentType === 'flowComputer') {
    const { id, conversionType, model, company, brand, serialNumber } = rest;
    return {
      company: company.value,
      id: id || '',
      conversion_type: conversionType.value,
      brand: brand.value,
      model: model.value,
      serial_number: serialNumber,
    };
  }
  if (equipmentType === 'patternEquipment') {
    const { id, organizationalUnit, model, brand, serialNumber } = rest;
    return {
      id: id || '',
      organizational_unit: organizationalUnit.value,
      brand: brand.value,
      model: model.value,
      serial_number: serialNumber,
    };
  }
  if (equipmentType === 'calibrationBottle') {
    const {
      id,
      type,
      model,
      brand,
      serialNumber,
      pressure,
      associatedChroma,
      instalationDate,
      preparationDate,
      issueDate,
      validityTime,
      expirationDate,
      certificateCode,
    } = rest;

    return {
      id: id || '',
      bottle_type: type.value,
      brand: brand.value,
      model: model.value,
      serial_number: serialNumber,
      pressure: pressure || '',
      associated_chroma: associatedChroma?.value || '',
      instalation_date: instalationDate ? getDateFormated(instalationDate, 'yyyy-MM-dd HH:mm:ss') : '',
      preparation_date: preparationDate ? getDateFormated(preparationDate, 'yyyy-MM-dd HH:mm:ss') : '',
      issue_date: issueDate ? getDateFormated(issueDate, 'yyyy-MM-dd HH:mm:ss') : '',
      validity_time: validityTime || '',
      expiration_date: expirationDate ? getDateFormated(expirationDate, 'yyyy-MM-dd HH:mm:ss') : '',
      certificate_code: certificateCode || '',
    };
  }
  return {};
};

export {
  transformEquipment,
  transformAssignment,
  formatParamsToRequest,
  transformAnalysisChroma,
  getStatusCertificate,
};
