import { getDateISOString, set, gasStartEndDayTime } from 'libs/date';
import transformResult from 'modules/measures/utils';
import actions from './actions';
import { getMeasures } from './api';

const getVariableData = ({ measurementUnit, startDate, selectedWindow, endDate, variable }) => {
  const startDateUTC = getDateISOString(set(startDate, gasStartEndDayTime));
  const endDateUTC = getDateISOString(set(endDate, gasStartEndDayTime));

  return (dispatch) => {
    dispatch(actions.showRequested({ measurementUnit, variable, selectedWindow }));

    getMeasures({
      measurementUnit: measurementUnit.id,
      startDate: startDateUTC,
      selectedWindow,
      endDate: endDateUTC,
      variable,
    })
      .then(({ data }) => {
        const result = transformResult({ data, variables: [variable] });
        dispatch(actions.showSucceeded({ measurementUnit, variable, selectedWindow, result }));
        return result;
      })
      .catch((error) => {
        dispatch(actions.showFailed({ measurementUnit, variable, selectedWindow, error }));
      });
  };
};

export default { getVariableData };
