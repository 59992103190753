import React from 'react';
import PropTypes from 'prop-types';

import { Button, RowFlex } from 'ui';
import { splitEvery } from 'libs/toolkits';
import { CalculatorStyles } from './styles';
import { DEFAULT_OPERATORS } from './constants';

const Calculator = ({
  handleDigit,
  handleMathOperator,
  handleRemove,
  inputAccess,
  operatorsConfig,
  handleCustomOperation,
}) => {
  const MAP_OPERATORS_HANDLER = {
    digit: handleDigit,
    math: handleMathOperator,
    remove: handleRemove,
  };

  const operatorsRows = splitEvery(operatorsConfig.perRow, operatorsConfig.operators);
  return (
    <CalculatorStyles>
      {operatorsRows.map((operatorsRow) => {
        const keyRow = operatorsRow.reduce((acc, { label }) => `${acc}|${label}`, '');
        return (
          <RowFlex key={keyRow} noWrap>
            {operatorsRow.map(({ value, label, handler, extraProps = {} }) => (
              <Button
                key={label}
                data-focus-formula={inputAccess}
                variant="default"
                color="ghost"
                onClick={(event) =>
                  MAP_OPERATORS_HANDLER[handler]
                    ? MAP_OPERATORS_HANDLER[handler](value, event)
                    : handleCustomOperation(handler, { value, label, event })
                }
                {...extraProps}
              >
                {label}
              </Button>
            ))}
          </RowFlex>
        );
      })}
    </CalculatorStyles>
  );
};

Calculator.propTypes = {
  handleDigit: PropTypes.func.isRequired,
  handleMathOperator: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleCustomOperation: PropTypes.func.isRequired,
  inputAccess: PropTypes.string.isRequired,
  operatorsConfig: PropTypes.shape({
    perRow: PropTypes.number,
    operators: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
        handler: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf(['digit', 'remove', 'math'])]),
        extraProps: {},
      })
    ),
  }),
};

Calculator.defaultProps = {
  operatorsConfig: {
    operators: DEFAULT_OPERATORS,
    perRow: 5,
  },
};

export default Calculator;
