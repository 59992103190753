import { generateEntityQueries } from 'libs/request-utils';
import { transformFillResponseToSelectOptions } from './utils';

const { fillEntities } = generateEntityQueries({
  nameForLists: 'usergroup',
});

const useFillUsergroups = ({ type: companyId }) => {
  return fillEntities({
    params: { company: companyId },
  })
    .then(({ data: { usergroups } = { usergroups: [] } }) => {
      return transformFillResponseToSelectOptions(usergroups);
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export { useFillUsergroups };
