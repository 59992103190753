import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './EN.json';
import esTranslation from './ES.json';

const resources = {
  en: enTranslation,
  es: esTranslation,
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'es',
  defaultNS: 'commonTexts',
  cleanCode: true,
});

export default i18n;
