import { useCallback } from 'react';

const useVariablesSelect = (variables = {}, setVariables) => {
  const search = useCallback(
    (key, searchText) => {
      const regex = new RegExp(searchText, 'i');
      setVariables({
        ...variables,
        [key]: variables[key].map((item) => ({ ...item, hidden: !regex.test(item.value) })),
      });
    },
    [setVariables, variables]
  );
  const selectAll = useCallback(
    (key, selected) => {
      setVariables({
        ...variables,
        [key]: variables[key].map((item) => ({ ...item, selected })),
      });
    },
    [setVariables, variables]
  );
  const selectOne = useCallback(
    (key, value, selected) => {
      setVariables({
        ...variables,
        [key]: variables[key].map((item) => ({
          ...item,
          selected: item.value === value ? selected : item.selected,
        })),
      });
    },
    [setVariables, variables]
  );

  return {
    variables,
    setVariables,
    search,
    selectAll,
    selectOne,
  };
};

export default useVariablesSelect;
