import { extractorActionsResponse } from 'libs/request-utils';

const actionsTranslate = {
  goToCustomVariable: 'show',
  delete: 'delete',
};

const formulaExtractorActions = extractorActionsResponse(actionsTranslate);

const operatorsToRequest = (ops) => {
  const addedLines = new Set();
  const addedMeasureUnits = new Set();
  let maxId = 0;
  return ops.reduce((acc, operator) => {
    if (operator.type === 'math') {
      return acc + operator.value;
    }
    if (operator.type === 'varLine') {
      if (addedMeasureUnits.has(operator.entity)) {
        maxId += 1;
        addedLines.add(maxId);
        return `${acc}${operator.value}||${maxId}`;
      }
      addedLines.add(operator.entity);

      maxId = Number(operator.entity) > maxId ? Number(operator.entity) : maxId;
      return `${acc}${operator.value}||${operator.entity}`;
    }

    if (addedLines.has(operator.entity)) {
      maxId += 1;
      addedMeasureUnits.add(maxId);
      return `${acc}${operator.value}||${maxId}`;
    }
    addedMeasureUnits.add(operator.entity);

    maxId = Number(operator.entity) > maxId ? Number(operator.entity) : maxId;
    return `${acc + operator.value}||${operator.entity}`;
  }, '');
};

const extractParams = ({ operators, ...rest }) => {
  return {
    ...rest,
    formulaSplited: operators,
    formula: operatorsToRequest(operators),
  };
};

const transformOperators = (ops, formula) => {
  if (!ops.length || typeof ops[0] === 'string') {
    return formula;
  }
  return ops.reduce((acc, operator) => {
    if (operator.type === 'math') {
      return acc + operator.value;
    }
    return `${acc + operator.value}#${operator.entity}`;
  }, '');
};

// eslint-disable-next-line camelcase
const transformFormula = ({ formula_splited: operators, formula_string: formula, id, name, creator, actions }) => ({
  id,
  name,
  formula: transformOperators(operators, formula),
  operators,
  creator,
  actions: formulaExtractorActions(actions),
});

export { transformFormula, extractParams, operatorsToRequest };
