const cogaOptions = (t) => [
  {
    to: '/coga/prediction',
    text: t('menu:prediction:coga'),
    iconName: 'icon-predictions',
    id: 9,
  },
];

export default cogaOptions;
