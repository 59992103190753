import XLSX from 'libs/xlsx';

const hackedTable = ({ idTable, isCustomTable }) => {
  const table = document.getElementById(idTable);
  if (!isCustomTable) {
    return table;
  }

  const { getElementsByTagName } = table;
  // eslint-disable-next-line no-param-reassign
  table.getElementsByTagName = (access) => {
    if (access === 'tr') {
      return table.querySelectorAll('[role="row"]');
    }
    return getElementsByTagName(access);
  };

  return table;
};

const useExportTableEnchanced = ({ rows, headers, fileName, title, subject, sheetName, footers }) => {
  const handleExport = (extension) => {
    const newRows = footers && rows.concat(footers);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(newRows ?? rows, { header: headers });
    const name = `${fileName}.${extension}`;

    ws.Props = {
      Title: title,
      Subject: subject,
      CreatedDate: new Date(),
    };

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    return XLSX.writeFile(wb, name);
  };

  const handleExportBase64 = (extension) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows, { header: headers });

    ws.Props = {
      Title: title,
      Subject: subject,
      CreatedDate: new Date(),
    };

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    return XLSX.write(wb, {
      bookType: extension,
      bookSST: false,
      type: 'base64',
    });
  };

  return [handleExport, handleExportBase64];
};

const useExportTable = ({ fileName, idTable, isCustomTable = true }) => {
  const handleExport = (extension) => {
    const elt = hackedTable({ idTable, isCustomTable });

    const wb = XLSX.utils.table_to_book(elt, { sheet: 'Sheet JS' });

    return XLSX.writeFile(wb, `${fileName}.${extension}`);
  };

  const handleBase64Content = (extension) => {
    const elt = hackedTable({ idTable, isCustomTable });
    const wb = XLSX.utils.table_to_book(elt, { sheet: 'Sheet JS' });

    return XLSX.write(wb, {
      bookType: extension,
      bookSST: false,
      type: 'base64',
    });
  };

  return [handleExport, handleBase64Content];
};

export default useExportTable;
export { useExportTableEnchanced };
