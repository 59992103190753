import { STATUS } from 'libs/generate-redux-utils';
import { mergeDeepRight } from 'libs/toolkits';
import types from './types';

const initialState = {
  alertsSaved: {
    entities: [],
    status: STATUS.READY,
    total: 0,
    selected: null,
  },
  alertsGenerated: {
    entities: [],
    status: STATUS.READY,
    total: 0,
    selected: null,
  },
  alertsTemplates: {
    entities: [],
    status: STATUS.READY,
    total: 0,
    selected: null,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.ALERTS_SAVED.GET_REQUESTED:
    case types.ALERTS_SAVED.GET_SUCCEEDED:
    case types.ALERTS_SAVED.GET_FAILED:
    case types.ALERTS_SAVED.CREATE_SUCCEEDED:
    case types.ALERTS_SAVED.CREATE_REQUESTED:
    case types.ALERTS_SAVED.CREATE_FAILED:
    case types.ALERTS_SAVED.UPDATE_SUCCEEDED:
    case types.ALERTS_SAVED.UPDATE_REQUESTED:
    case types.ALERTS_SAVED.UPDATE_FAILED:
    case types.ALERTS_SAVED.DELETE_SUCCEEDED:
    case types.ALERTS_SAVED.DELETE_REQUESTED:
    case types.ALERTS_SAVED.DELETE_FAILED:
    case types.ALERTS_SAVED.LIST_REQUESTED:
    case types.ALERTS_SAVED.LIST_SUCCEEDED:
    case types.ALERTS_SAVED.LIST_FAILED:
      return mergeDeepRight(state, { alertsSaved: payload });
    case types.ALERTS_GENERATED.GET_FAILED:
      return { ...state, alertsGenerated: { ...state.alertsGenerated, entity: undefined } };
    case types.ALERTS_GENERATED.GET_REQUESTED:
    case types.ALERTS_GENERATED.GET_SUCCEEDED:
    case types.ALERTS_GENERATED.CREATE_SUCCEEDED:
    case types.ALERTS_GENERATED.CREATE_REQUESTED:
    case types.ALERTS_GENERATED.CREATE_FAILED:
    case types.ALERTS_GENERATED.UPDATE_SUCCEEDED:
    case types.ALERTS_GENERATED.UPDATE_REQUESTED:
    case types.ALERTS_GENERATED.UPDATE_FAILED:
    case types.ALERTS_GENERATED.DELETE_SUCCEEDED:
    case types.ALERTS_GENERATED.DELETE_REQUESTED:
    case types.ALERTS_GENERATED.DELETE_FAILED:
    case types.ALERTS_GENERATED.LIST_REQUESTED:
    case types.ALERTS_GENERATED.LIST_SUCCEEDED:
    case types.ALERTS_GENERATED.LIST_FAILED:
      return mergeDeepRight(state, { alertsGenerated: payload });
    case types.ALERTS_TEMPLATES.GET_REQUESTED:
    case types.ALERTS_TEMPLATES.GET_SUCCEEDED:
    case types.ALERTS_TEMPLATES.GET_FAILED:
    case types.ALERTS_TEMPLATES.CREATE_SUCCEEDED:
    case types.ALERTS_TEMPLATES.CREATE_REQUESTED:
    case types.ALERTS_TEMPLATES.CREATE_FAILED:
    case types.ALERTS_TEMPLATES.UPDATE_SUCCEEDED:
    case types.ALERTS_TEMPLATES.UPDATE_REQUESTED:
    case types.ALERTS_TEMPLATES.UPDATE_FAILED:
    case types.ALERTS_TEMPLATES.DELETE_SUCCEEDED:
    case types.ALERTS_TEMPLATES.DELETE_REQUESTED:
    case types.ALERTS_TEMPLATES.DELETE_FAILED:
    case types.ALERTS_TEMPLATES.LIST_REQUESTED:
    case types.ALERTS_TEMPLATES.LIST_SUCCEEDED:
    case types.ALERTS_TEMPLATES.LIST_FAILED:
      return mergeDeepRight(state, { alertsTemplates: payload });
    default:
      return state;
  }
};

export default reducer;
