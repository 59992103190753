import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { omit } from 'libs/toolkits';
import { useFillCompanies } from './useCompanies';
import { useFillMeasures } from './useMeasures';
import { useFillBranches } from './useBranches';
import { useFillPositions } from './usePositions';
import { useFillSections } from './useSections';
import { useFillEntities } from './useEntities';
import { useFillTypeMu } from './useTypeMu';
import { useFillChromatographs } from './useChromatographs';
import { useFillCounterModels } from './useCounterModels';
import { useFillFlowComputers } from './useFlowComputers';
import { useFillProvinces } from './useProvinces';
import { useFillUsergroups } from './useUsergroups';
import { useFillOrganizations } from './useOrganizations';
import SelectorSync, { propTypes, defaultProps } from './SelectorSync';

const mapFillOptions = {
  measures: useFillMeasures,
  companies: useFillCompanies,
  branches: useFillBranches,
  positions: useFillPositions,
  sections: useFillSections,
  entities: useFillEntities,
  typeMU: useFillTypeMu,
  counterModels: useFillCounterModels,
  flowComputers: useFillFlowComputers,
  chromatographs: useFillChromatographs,
  provinces: useFillProvinces,
  usergroups: useFillUsergroups,
  organizations: useFillOrganizations,
};

const withFillOptions = ({ type }) => {
  const WithFillOptionsWrapped = (props) => {
    const fillOptions = useCallback((params) => {
      return mapFillOptions[type](params);
    }, []);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <SelectorSync {...props} fillOptions={fillOptions} />;
  };

  WithFillOptionsWrapped.displayName = `withFillOptions(SelectorSync)`;
  WithFillOptionsWrapped.propTypes = omit(['fillOptions'], propTypes);
  WithFillOptionsWrapped.defaultProps = defaultProps;

  return WithFillOptionsWrapped;
};

withFillOptions.propTypes = {
  type: PropTypes.oneOf([
    'measures',
    'companies',
    'branches',
    'positions',
    'sections',
    'entities',
    'typeMU',
    'counterModels',
    'chromatographs',
    'flowComputers',
    'provinces',
    'usergroups',
  ]).isRequired,
};

export default withFillOptions;
