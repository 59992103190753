import { generateEntityQueries, toFormData } from 'libs/request-utils';

const {
  searchEntities: listAlerts,
  deleteEntity: deleteAlert,
  createEntity,
  getEntity: getAlert,
} = generateEntityQueries({
  nameForLists: 'alert',
});

const { searchEntities: listHistoryAlerts, getEntity: getHistoryAlert } = generateEntityQueries({
  nameForLists: 'incidence',
});

const createAlert = (body) => {
  const params = toFormData(body);

  return createEntity({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export { listAlerts, deleteAlert, createAlert, getAlert, listHistoryAlerts, getHistoryAlert };
