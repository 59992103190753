import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb as A, BreadcrumbProps, BreadcrumbItemProps } from 'antd';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import BreadcrumbSeparator from 'antd/lib/breadcrumb/BreadcrumbSeparator';

import { BreadcrumbStyles } from './styles';

interface Props extends BreadcrumbProps {
  breadcrumb: {
    label: string;
    to?: string;
    component?: typeof React.Component;
    menu?: BreadcrumbItemProps['overlay'];
  }[];
}

interface BreadcrumbInterface extends React.FC<Props> {
  Item: typeof BreadcrumbItem;
  Separator: typeof BreadcrumbSeparator;
}

const Breadcrumb: BreadcrumbInterface = ({ breadcrumb }) => {
  return (
    <BreadcrumbStyles>
      {breadcrumb.map(({ label, to, menu, component: Component }) => {
        if (Component) {
          return <Component key={label} />;
        }
        return to ? (
          <BreadcrumbStyles.Item key={label} overlay={menu}>
            <Link to={to}>{label}</Link>
          </BreadcrumbStyles.Item>
        ) : (
          <BreadcrumbStyles.Item key={label} overlay={menu}>
            {label}
          </BreadcrumbStyles.Item>
        );
      })}
    </BreadcrumbStyles>
  );
};

Breadcrumb.Item = BreadcrumbStyles.Item;
Breadcrumb.Separator = BreadcrumbStyles.Separator;

export default Breadcrumb;
