import { mergeDeepRight } from 'libs/toolkits';
import common from '../common';
import button from './button';
import colors from './colors';
import menu from './menu';

const theme = {
  button,
  colors,
  menu,
};

export default mergeDeepRight(common, theme);
