import actionTypes from './types';

const initialState = {
  topbarSearch: { isLoading: false, items: [], noResults: false },
  requestCall: '',
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.TOP_BAR_SEARCH:
      return { ...state, topbarSearch: { ...state.topbarSearch, ...payload } };

    case actionTypes.SET_REQUEST_CALL_SEARCH:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default reducer;
