import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import Menu from 'ui/menu';
import config from 'config/menu';
import TopBar from 'ui/topBar';
import { selectors, operations } from 'modules/auth';
import { operations as operationsNotifications } from 'modules/notifications';
import { ChangeLogSidebar } from 'components/topbar';
import { Layout, Main, Container } from './styles';

const MainLayout = ({ children, auth }) => {
  const history = useHistory();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const { changelogStatus } = useSelector((state) => ({
    changelogStatus: selectors.getChangelogStatus(state),
  }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const translateConfig = useMemo(() => config(auth.company, auth.role, t), [language, auth]);
  useEffect(
    () =>
      history.listen((location) => {
        dispatch(operationsNotifications.cleanAllNotifications());
        return ReactGA.pageview(location.pathname);
      }),
    [dispatch, history]
  );

  const toggleModal = () => dispatch(operations.saveChangelogStatus(false));

  return (
    <Layout>
      <Menu config={translateConfig} />
      <Container>
        <ChangeLogSidebar isOpen={changelogStatus} toggleModal={toggleModal} />
        <TopBar auth={auth} />
        <Main>{children}</Main>
      </Container>
    </Layout>
  );
};

MainLayout.propTypes = {
  auth: PropTypes.shape({
    name: PropTypes.string,
    photoURL: PropTypes.string,
    language: PropTypes.string,
  }),
  children: PropTypes.node,
};

MainLayout.defaultProps = {
  auth: { name: '', photoURL: '', language: 'EN' },
  children: null,
};

export default MainLayout;
