import { STATUS } from 'libs/generate-redux-utils';
import { addDays, subDays, isSameDate, set, gasStartEndDayTime } from 'libs/date';
import { mergeDeepRight, pathOr } from 'libs/toolkits';

import i18n from 'config/i18n';
import { COUNTER, COUNTER_ERROR, CROMA, FC, ULTRASONIC, FOOTPRINT_TR, FOOTPRINT_FR } from 'config/variables';
import { generateOptionsGasHours } from './utils/generate';
import types from './types';

const addNotSelected = (items) => items.map((item) => ({ ...item, selected: false }));
const HOUR_OPTIONS = generateOptionsGasHours();
const initialState = {
  variables: {
    flowComputer: addNotSelected(FC),
    croma: addNotSelected(CROMA),
    counter: addNotSelected(COUNTER),
    counterError: addNotSelected(COUNTER_ERROR),
    ultrasonic: addNotSelected(ULTRASONIC),
    footprintTR: addNotSelected(FOOTPRINT_TR),
    footprintFR: addNotSelected(FOOTPRINT_FR),
  },
  measurentUnit: {
    lines: [],
  },
  measurentUnitToCompare: {
    lines: [],
  },
  referenceValues: {
    entities: [],
    total: 0,
    status: STATUS.READY,
  },
  referenceValue: { data: {}, name: '', type: '', date: '' },
  referenceValueCurrent: { data: {}, name: '', type: '', date: '' },
  dateInfo: {
    date: subDays(new Date(), 1),
    period: 'daily',
    hour: { label: '05:00-06:00', value: '06:00' },
    hourOptions: [
      { value: '06:00', label: '05:00-06:00' },
      { value: '07:00', label: '06:00-07:00' },
      { value: '08:00', label: '07:00-08:00' },
      { value: '09:00', label: '08:00-09:00' },
      { value: '10:00', label: '09:00-10:00' },
      { value: '11:00', label: '10:00-11:00' },
      { value: '12:00', label: '11:00-12:00' },
      { value: '13:00', label: '12:00-13:00' },
      { value: '14:00', label: '13:00-14:00' },
      { value: '15:00', label: '14:00-15:00' },
      { value: '16:00', label: '15:00-16:00' },
      { value: '17:00', label: '16:00-17:00' },
      { value: '18:00', label: '17:00-18:00' },
      { value: '19:00', label: '18:00-19:00' },
      { value: '20:00', label: '19:00-20:00' },
      { value: '21:00', label: '21:00-22:00' },
      { value: '22:00', label: '22:00-23:00' },
      { value: '23:00', label: '23:00-24:00' },
      { value: '24:00', label: '24:00-00:00' },
      { value: '00:00', label: '00:00-01:00' },
      { value: '02:00', label: '01:00-02:00' },
      { value: '03:00', label: '02:00-03:00' },
      { value: '04:00', label: '03:00-04:00' },
      { value: '05:00', label: '04:00-05:00' },
    ],
  },
  dateRange: {
    startDate: set(subDays(new Date(), 1), gasStartEndDayTime),
    startHour: { label: '06:00', value: '06:00' },
    endDate: set(new Date(), gasStartEndDayTime),
    endHour: { label: '06:00', value: '06:00' },
    hourOptions: HOUR_OPTIONS,
    hourOptionsEnd: HOUR_OPTIONS,
  },
  advancedPeriod: {
    period: {
      value: { shiftType: 'minutes', shiftValue: 5 },
      label: i18n.t('modules:reports-select-fiveMinutes-label'),
    },
    customShiftType: { value: 'minutes', label: i18n.t('modules:reports-select-minutes-label') },
    customShiftValue: '',
  },
  status: STATUS.READY,
  report: {},
  savedReports: {
    entities: [],
    total: 0,
    status: STATUS.READY,
  },
  scheduleReports: {
    entities: [],
    total: 0,
    status: STATUS.READY,
  },
  scheduleReportsFiles: {
    entities: [],
    total: 0,
    status: STATUS.READY,
  },
  signatureAudit: {
    status: STATUS.LOADING,
  },
  evolutionChroma: {
    chromatograph: {},
    report: {},
    status: STATUS.LOADING,
  },
  mapVariablesEntities: [],
  isOpen: true,
  type: 'saturation',
  correlation: false,
  onlyRepresentationTable: false,
  representation: 'graph',
  lastRepresentation: 'graph',
  lastMeasure: false,
  isDifference: false,
  chromatograph: { label: '', name: '' },
  measurementUnit: {
    value: 'all',
    label: i18n.t('components:footprintPage:assignment-report:all-measurement-units'),
  },
  statusCode: 0,
  reportName: '',
  frequencies: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_VARIABLES:
      return {
        ...state,
        variables: {
          ...state.variables,
          ...payload.variables,
        },
      };
    case types.RESTORE_VARIABLES:
      return {
        ...state,
        variables: initialState.variables,
      };
    case types.RESTORE_REPORTS:
      return initialState;
    case types.RESTORE_ADVANCED_FORM:
      return {
        ...state,
        measurentUnit: initialState.measurentUnit,
        variables: initialState.variables,
        isOpen: initialState.isOpen,
      };

    case types.GET_REQUESTED:
      return {
        ...state,
        status: payload.status || state.status,
      };

    case types.SET_REFERENCES_VALUES:
      return {
        ...state,
        ...payload,
      };
    case types.GET_SUCCEEDED:
    case types.GET_FAILED:
    case types.SET_REPORT:
    case types.CREATE_REQUESTED:
    case types.CREATE_FAILED:
    case types.SET_REPORT_LAYOUT_STATUS_OPEN:
    case types.MERGE_STORE:
      return typeof payload === 'object' && Object.keys(payload).length === 0
        ? { ...state, report: {} }
        : mergeDeepRight(mergeDeepRight(state, { report: {} }), payload);
    case types.CREATE_SUCCEEDED:
      return {
        ...state,
        report: mergeDeepRight(state.report, payload.report),
      };

    case types.CLEAN_REPORT:
      return { ...state, report: {} };
    case types.MEASURE.SET_MEASURE:
    case types.MEASURE.SHOW_SUCCEEDED: {
      const key = payload.isCompareMeasure ? 'measurentUnitToCompare' : 'measurentUnit';
      const nextState = payload.entity.id
        ? {
            ...state[key],
            ...payload.entity,
          }
        : {
            lines: [],
          };
      return {
        ...state,
        [key]: nextState,
      };
    }
    case types.SIGNATURE_AUDIT.SHOW_REQUESTED:
    case types.SIGNATURE_AUDIT.SHOW_FAILED:
    case types.SIGNATURE_AUDIT.SHOW_SUCCEEDED: {
      return {
        ...state,
        signatureAudit: {
          ...state.signatureAudit,
          ...payload.entity,
          status: payload.status,
        },
      };
    }
    case types.SET_DATE_INFO: {
      return {
        ...state,
        dateInfo: { ...state.dateInfo, ...payload.dateInfo },
      };
    }
    case types.SET_TYPE: {
      return {
        ...state,
        ...payload.type,
      };
    }
    case types.SET_MEASUREMENT_UNIT: {
      return {
        ...state,
        ...payload.measurementUnit,
      };
    }
    case types.SET_CHROMATOGRAPH: {
      return {
        ...state,
        ...payload.chromatograph,
      };
    }
    case types.SET_DIFFERENCES: {
      return {
        ...state,
        ...payload.isDifference,
      };
    }
    case types.SET_CORRELATION: {
      return {
        ...state,
        correlation: payload,
      };
    }
    case types.SET_LAST_MEASURE: {
      return {
        ...state,
        lastMeasure: payload,
      };
    }
    case types.SET_REPRESENTATION: {
      return {
        ...state,
        representation: payload,
      };
    }
    case types.SET_LAST_REPRESENTATION: {
      return { ...state, lastRepresentation: payload };
    }
    case types.SET_ONLY_REPRESENTATION_TABLE: {
      return {
        ...state,
        onlyRepresentationTable: payload,
      };
    }
    case types.SET_TYPE_WINDOW: {
      return {
        ...state,
        type: payload.typeWindow,
      };
    }
    case types.SET_DATE_RANGE: {
      const newDateRangeState = { ...state.dateRange, ...payload.dateRange };
      if (isSameDate(newDateRangeState.startDate, newDateRangeState.endDate)) {
        const extractHour = (val) => Number(val.value.split(':')[0]);
        const startDate = extractHour(newDateRangeState.startHour);
        const endHoursOptions = newDateRangeState.hourOptions.filter((option) => extractHour(option) > startDate);
        const newEndHourValue = pathOr(state.dateRange.endHour.value, ['dateRange', 'endHour', 'value'], payload);
        const newEndHour = pathOr(state.dateRange.endHour, ['dateRange', 'endHour'], payload);

        return {
          ...state,
          dateRange: {
            ...state.dateRange,
            ...newDateRangeState,
            endDate: !endHoursOptions.length ? addDays(newDateRangeState.startDate, 1) : newDateRangeState.endDate,
            endHour: !endHoursOptions.some(({ value }) => value === newEndHourValue)
              ? endHoursOptions[0] || { label: '06:00', value: '06:00' }
              : newEndHour,
            hourOptionsEnd: !endHoursOptions.length ? newDateRangeState.hourOptions : endHoursOptions,
          },
        };
      }
      return {
        ...state,
        dateRange: { ...state.dateRange, ...newDateRangeState },
      };
    }
    case types.LIST_REQUESTED:
    case types.LIST_SUCCEEDED:
    case types.LIST_FAILED:
      return {
        ...state,
        savedReports: {
          ...state.savedReports,
          ...payload,
        },
      };
    case types.LIST_ASSIGNMENT_REQUESTED:
    case types.LIST_ASSIGNMENT_SUCCEEDED:
    case types.LIST_ASSIGNMENT_FAILED:
      return {
        ...state,
        qualityAssignment: {
          ...state.qualityAssignment,
          ...payload,
        },
      };
    case types.LIST_REFERENCE_VALUES_REQUESTED:
    case types.LIST_REFERENCE_VALUES_SUCCEEDED:
    case types.LIST_REFERENCE_VALUES_FAILED:
      return {
        ...state,
        referenceValues: {
          ...state.referenceValues,
          ...payload,
        },
      };

    case types.SAVE_REFERENCE_VALUES_SUCCEEDED:
      return {
        ...state,
      };
    case types.LIST_SCHEDULE_REPORTS_REQUESTED:
    case types.LIST_SCHEDULE_REPORTS_SUCCEEDED:
    case types.LIST_SCHEDULE_REPORTS_FAILED:
      return {
        ...state,
        scheduleReports: {
          ...state.scheduleReports,
          ...payload,
          entities: payload.params?.isReset ? [] : state.scheduleReports.entities.concat(payload?.entities || []),
        },
      };

    case types.LIST_SCHEDULE_REPORTS_FILES_REQUESTED:
    case types.LIST_SCHEDULE_REPORTS_FILES_SUCCEEDED:
    case types.LIST_SCHEDULE_REPORTS_FILES_FAILED:
      return {
        ...state,
        scheduleReportsFiles: {
          ...state.scheduleReportsFiles,
          ...payload,
          entities: payload.params?.isReset ? [] : state.scheduleReportsFiles.entities.concat(payload?.entities || []),
        },
      };
    case types.ADVANCED.SET_MAP_VARIABLES_ENTITIES: {
      return {
        ...state,
        mapVariablesEntities: payload.mapVariablesEntities,
      };
    }
    case types.ADVANCED.SET_ADVANCED_PERIOD: {
      return {
        ...state,
        advancedPeriod: mergeDeepRight(state.advancedPeriod, payload),
      };
    }
    case types.GET_EVOLUTION_REQUESTED:
    case types.GET_EVOLUTION_SUCCEEDED:
    case types.GET_EVOLUTION_FAILED:
      return { ...state, evolutionChroma: { ...state.evolutionChroma, ...payload } };
    case types.RESET_STATUS:
      return { ...state, status: STATUS.READY };
    case types.SET_STATUS_CODE:
      return { ...state, statusCode: payload };
    case types.SET_REPORT_INFO:
      return {
        ...state,
        frequencies: payload.frequencies,
        reportName: payload.name,
        receiveBool: payload.receiveBool || false,
      };
    default:
      return state;
  }
};

export default reducer;
