import actionTypes from './types';

const globalSearch = (props) => ({
  type: actionTypes.TOP_BAR_SEARCH,
  payload: { ...props },
});

const setRequestCall = (call) => ({
  type: actionTypes.SET_REQUEST_CALL_SEARCH,
  payload: { ...{ requestCall: call }, loading: false },
  meta: {
    timestamp: new Date(),
  },
});
// eslint-disable-next-line import/prefer-default-export
export { globalSearch, setRequestCall };
