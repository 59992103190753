import { css } from 'styled-components';

export default {
  layoutReport: {
    default: css`
      > div {
        border-right: 1px solid #e6e6ff;
        max-width: 460px;
        width: 100%;

        :last-child {
          border-width: 0px;
        }
      }
    `,
    advanced: css`
      background: transparent;
      > div {
        width: 100%;
        max-width: initial;
      }
    `,
  },
};
