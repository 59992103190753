import styled, { css } from 'styled-components';

const BoxStyles = styled.div<{
  size?: 'small';
  shadow?: boolean;
  flexAuto?: boolean;
  fullHeight?: boolean;
  noPadding?: boolean;
  overflowHidden?: boolean;
  overflowAuto?: boolean;
  transparent?: boolean;
}>`
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  height: auto;
  flex: 2;
  max-width: 100%;
  ${({ size, theme }) => (size ? theme.box.size[size] : '')};

  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      margin: 10px;
    `};

  ${({ flexAuto }) =>
    flexAuto &&
    css`
      flex: inherit;
    `};
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0px;
    `};
  ${({ overflowHidden }) =>
    overflowHidden &&
    css`
      overflow: hidden;
    `};
  ${({ overflowAuto }) =>
    overflowAuto &&
    css`
      overflow-y: auto;
    `};

  ${({ transparent }) =>
    transparent &&
    css`
      background: transparent;
    `};
`;

export { BoxStyles };
