import { css } from 'styled-components';

export default {
  state: {
    success: css`
      color: #52c41a;
      background: #f6ffed;
      border-color: #b7eb8f;
    `,
    error: css`
      color: #f5222d;
      background: #fff1f0;
      border-color: #ffa39e;
    `,
    incomplete: css`
      color: #faad14;
      background: #fffbe6;
      border-color: #faad14c9;
    `,
  },
};
