import actionType from './types';

const initialState = {
  companies: { items: [], isLoading: false, total: 0, error: null },
  companyById: { company: null, isLoading: false, error: null },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LIST_REQUESTED:
    case actionType.LIST_SUCCEEDED:
    case actionType.LIST_FAILED:
      return { ...state, companies: { ...state.companies, ...action.payload } };

    case actionType.GET_BY_ID_REQUESTED:
    case actionType.GET_BY_ID_SUCCEEDED:
    case actionType.GET_BY_ID_FAILED:
      return { ...state, companyById: { ...state.companyById, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
