import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Modal, ModalContent, ModalHeader, RowFlex, Icon, Title, Text, Tag } from 'ui';

const ChangelogModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  if (!isOpen) {
    return null;
  }

  return (
    <Modal>
      <ModalContent maxWidth="800px">
        <ModalHeader>
          <RowFlex justify="space-between" align="flex-strat" flex={1}>
            <RowFlex direction="column">
              <Title level={3} color="primary" style={{ margin: '0', display: 'flex' }}>
                {t('TopBar:AccountInfo:Changelog')}
              </Title>
              <Text color="ghost"> {t('TopBar:AccountInfo:ChangelogInfo')}</Text>
            </RowFlex>
            <Icon iconName="icon-close" pointer onClick={onClose} />
          </RowFlex>
        </ModalHeader>

        <div>
          <Tag color="#6078FF"> 16 Dic, 2020</Tag>
          <p>
            El proceso de la medición es una de las partes principales en la gestión de infraestructuras de gas natural,
            y en Smart Energy Assets estamos empeñados en aumentar día a día las capacidades de Neptuno. En esta versión
            encontrarás:
          </p>
          <ul>
            <li>Inventario VUM (equipos patrón, botellas, turbinas, conversores,...)</li>
            <li>Mejoras en el reporte de ultrasónicos</li>
            <li>Nuevo interfaz con mejoras en UX y velocidad.</li>
            <li>Datos históricos de lecturas.</li>
            <li>Posibilidad de creación de variables compuestas en informes.</li>
            <li>Nuevos gráficos más potentes.</li>
            <li>Una gestión más personalizada de favoritos.</li>
            <li>Nuevos informes de huella de croma y ultrasónicos.</li>
            <li>Mejoras en el módulo de balances</li>
            <li>Resolución de errores menores</li>
          </ul>
        </div>
        <hr />
      </ModalContent>
    </Modal>
  );
};

ChangelogModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

ChangelogModal.defaultValues = {
  isOpen: false,
};
export default ChangelogModal;
