import { generateEntityQueries, toFormData } from 'libs/request-utils';

const {
  searchEntities: searchEntitiesCounter,
  createEntity: createEntityCounter,
  deleteEntity: deleteEntityCounter,
  getEntity: getEntityCounter,
  updateEntity: updateEntityCounter,
} = generateEntityQueries({
  nameForLists: 'LineMeasuringConfig',
});

const {
  searchEntities: searchEntitiesChroma,
  createEntity: createEntityChroma,
  deleteEntity: deleteEntityChroma,
  getEntity: getEntityChroma,
  updateEntity: updateEntityChroma,
} = generateEntityQueries({
  nameForLists: 'cromatograph',
});

const {
  searchEntities: searchEntitiesFlowComputer,
  createEntity: createEntityFlowComputer,
  deleteEntity: deleteEntityFlowComputer,
  getEntity: getEntityFlowComputer,
  updateEntity: updateEntityFlowComputer,
} = generateEntityQueries({
  nameForLists: 'flowComputer',
});

const {
  searchEntities: searchEntitiesPatternEquipment,
  createEntity: createEntityPatternEquipment,
  deleteEntity: deleteEntityPatternEquipment,
  getEntity: getEntityPatternEquipment,
  updateEntity: updateEntityPatternEquipment,
} = generateEntityQueries({
  nameForLists: 'patternEquipment',
});

const { saveEntity: savePatternAreas } = generateEntityQueries({
  nameForLists: 'patternEquipment/patternAreas',
});

const {
  searchEntities: searchEntitiesCalibrationBottle,
  createEntity: createEntityCalibrationBottle,
  deleteEntity: deleteEntityCalibrationBottle,
  getEntity: getEntityCalibrationBottle,
  updateEntity: updateEntityCalibrationBottle,
} = generateEntityQueries({
  nameForLists: 'calibrationBottle',
});
const { searchEntities: searchEntitiesFootprintChroma } = generateEntityQueries({
  nameForLists: 'footprintChroma',
});

const { getEntity: getDataChroma } = generateEntityQueries({
  nameForLists: 'footprintChroma/data',
});

const { getEntity: getFooprint } = generateEntityQueries({
  nameForLists: 'footprintChroma/get',
});

const { getEntity: getAnalysisFootprintChroma } = generateEntityQueries({
  nameForLists: 'footprintChroma/analysis',
});
const { getEntity: getFooprints } = generateEntityQueries({
  nameForLists: 'footprintChroma/getSeveral',
});

const { createEntity: setFooprint } = generateEntityQueries({
  nameForLists: 'footprintChroma/set',
});

const { getEntity: getFooprintEvolution } = generateEntityQueries({
  nameForLists: 'footprintChroma/evolution',
});

const { searchEntities: getGasQualityAssignment } = generateEntityQueries({
  nameForLists: 'qualityAssignment',
});

const EQUIPMENT_MAP_REQUESTS = {
  get: {
    counter: getEntityCounter,
    chromatograph: getEntityChroma,
    flowComputer: getEntityFlowComputer,
    patternEquipment: getEntityPatternEquipment,
    calibrationBottle: getEntityCalibrationBottle,
  },
  delete: {
    counter: deleteEntityCounter,
    chromatograph: deleteEntityChroma,
    flowComputer: deleteEntityFlowComputer,
    patternEquipment: deleteEntityPatternEquipment,
    calibrationBottle: deleteEntityCalibrationBottle,
  },
  update: {
    counter: updateEntityCounter,
    chromatograph: updateEntityChroma,
    flowComputer: updateEntityFlowComputer,
    patternEquipment: updateEntityPatternEquipment,
    calibrationBottle: updateEntityCalibrationBottle,
  },
  list: {
    counter: searchEntitiesCounter,
    chromatograph: searchEntitiesChroma,
    flowComputer: searchEntitiesFlowComputer,
    patternEquipment: searchEntitiesPatternEquipment,
    calibrationBottle: searchEntitiesCalibrationBottle,
  },
  create: {
    counter: createEntityCounter,
    chromatograph: createEntityChroma,
    flowComputer: createEntityFlowComputer,
    patternEquipment: createEntityPatternEquipment,
    calibrationBottle: createEntityCalibrationBottle,
  },
};

const getEquipment = (equipmentType, query) => EQUIPMENT_MAP_REQUESTS.get[equipmentType](query);
const deleteEquipment = (equipmentType, query) => EQUIPMENT_MAP_REQUESTS.delete[equipmentType](query);
const updateEquipment = (equipmentType, query) =>
  EQUIPMENT_MAP_REQUESTS.update[equipmentType]({
    body: toFormData(query),
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
const listEquipments = (equipmentType, query) => EQUIPMENT_MAP_REQUESTS.list[equipmentType](query);
const createEquipment = (equipmentType, query) =>
  EQUIPMENT_MAP_REQUESTS.create[equipmentType]({
    body: toFormData(query),
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

const listFootprintsChroma = (query) => searchEntitiesFootprintChroma(query);

const dataAnalysisChroma = (query) => getDataChroma({ ...query });

const footprintChroma = (query) => getFooprint({ ...query });

const footprintsChroma = (query) => getFooprints({ ...query });

const footprintEvolution = (query) => getFooprintEvolution({ ...query });

const setFootprintChroma = (query) => setFooprint({ ...query });

const analysisFootprintChroma = (query) => getAnalysisFootprintChroma({ ...query });

const gasQualityAssignment = (query) => getGasQualityAssignment(query);

const updatePatternEquipmentAreas = ({ id, patternAreas }) => {
  const body = {
    id,
    patternAreas: JSON.stringify(patternAreas),
  };

  return savePatternAreas({
    body: toFormData(body),
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export {
  getEquipment,
  deleteEquipment,
  updateEquipment,
  listEquipments,
  createEquipment,
  listFootprintsChroma,
  dataAnalysisChroma,
  footprintChroma,
  footprintsChroma,
  footprintEvolution,
  setFootprintChroma,
  analysisFootprintChroma,
  gasQualityAssignment,
  updatePatternEquipmentAreas,
};
