import button from './button';
import box from './box';
import colors from './colors';
import dropdown from './dropdown';
import fonts from './fonts';
import sidebar from './sidebar';
import tabs from './tabs';
import alerts from './alerts';
import statesTable from './statesTable';
import tipography from './tipography';
import statesSummary from './statesSummary';
import layouts from './layouts';
import checkbox from './checkbox';
import radioButton from './radioButton';
import table from './table';

export default {
  alerts,
  box,
  button,
  colors,
  dropdown,
  fonts,
  sidebar,
  tabs,
  statesTable,
  tipography,
  statesSummary,
  layouts,
  checkbox,
  radioButton,
  table,
};
