import { css } from 'styled-components';

export default {
  variant: {
    default: css`
      margin: 10px 19px 10px 0px;

      input {
        width: 16px;
        height: 16px;
        margin: 0;
        margin-right: 5px;

        display: inline-block;
        padding: 0;
        border: 2px solid #ccc;
        border-radius: 50%;

        cursor: pointer;
        :checked {
          border: 2px solid #5e78ff;
          background: #5e78ff;
        }

        :checked + label:before {
          opacity: 1;
        }
      }
    `,
    colored: css`
      padding: 8px 13px;
      margin-left: 10px;

      label {
        font-size: 0.8rem;
      }

      input[type='radio'] {
        appearance: none;
        background: transparent;
        width: 100%;
        position: absolute;
        padding: 5px;
        height: 36px;
        left: 0;
        background: #f1f1f14d;
        border: 1px solid #ccc;
        border-radius: 3px;
        cursor: pointer;
        :checked {
          border: 1px solid ${(props) => props.theme.colors.primary};
          background: #5f78ff1f;
        }
        :checked + label {
          color: ${(props) => props.theme.colors.primary};
        }
      }
    `,
  },
};
