const STATUS_MEASUREMENT_UNITS = (equipment, t) => ({
  green: {
    color: 'success',
    text: `${t('ExplorePage:ShowMeasures', {
      equipment,
    })}`,
  },
  yellow: {
    color: 'danger',
    text: `${t('ExplorePage:ErrorMeasures', {
      equipment,
    })}`,
  },
  red: {
    color: 'danger',
    text: `${t('ExplorePage:ErrorMeasures', {
      equipment,
    })}`,
  },
  notMeasures: {
    color: 'danger',
    text: `${t('ExplorePage:NoMeasures', {
      equipment,
    })}`,
  },
  None: {
    color: '',
    text: '',
  },
});

const OPTIONS_MAP = (mapType) => ({
  fullscreenControl: false,
  mapTypeControl: false,
  mapTypeId: mapType,
});

const COORDINATES_DEFAULT = {
  latitude: 40.122954337327315,
  longitude: -4.443303481026639,
  name: '',
  id: '',
};
const ZOOM_DEFAULT = 7;
const VISUALIZE_DEFAULT = 'allPositions';

const PARAMS_OPTIONS = {
  allPositions: { allPositions: true },
  readErrors: { readErrors: true },
  flowComputerErrors: { flowComputerErrors: true },
  chromatographs: { chromatographs: true },
  chromatographsErrors: { chromatographsErrors: true },
};
export { STATUS_MEASUREMENT_UNITS, OPTIONS_MAP, COORDINATES_DEFAULT, ZOOM_DEFAULT, VISUALIZE_DEFAULT, PARAMS_OPTIONS };
