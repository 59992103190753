import { useCallback } from 'react';

import { generateEntityQueries } from 'libs/request-utils';
import { transformFillResponseToSelectOptions } from './utils';

const { fillEntities, searchEntities } = generateEntityQueries({
  nameForLists: 'branch',
});

const useSearchSections = () =>
  useCallback((text) => {
    return searchEntities({
      params: { q: text, type: 'section' },
    });
  }, []);

const useFillSections = () => {
  return fillEntities({
    params: { type: 'section' },
  })
    .then(({ data: { sections } = { sections: [] } }) => {
      return transformFillResponseToSelectOptions(sections);
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export { useFillSections, useSearchSections };
