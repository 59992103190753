import { update } from 'libs/toolkits';
import { subMinutes, getDateFormated, subDays, subHours, subMonths, getDateISOString } from 'libs/date';
import { ULTRASONIC } from 'config/variables';
import selectors from '../selectors';

const extractSelectedIdLines = (measurementUnit) =>
  measurementUnit.lines.reduce((acc, line) => (line.selected ? [...acc, String(line.id)] : acc), []);

const extractCustomVariable = (formula, id) => {
  const addedLines = new Set();
  const addedMeasureUnits = new Set();
  let maxId = 0;
  const formulasEntities = formula.filter(({ type }) => type !== 'math');
  const initial = Array.from({ length: formulasEntities.length + 1 });
  const entities = formulasEntities
    .reduce((acc, operator) => {
      if (operator.type === 'math') {
        return acc;
      }
      if (operator.type === 'varLine') {
        if (addedMeasureUnits.has(operator.entity)) {
          maxId += 1;
          addedLines.add(maxId);
          return update(
            maxId,
            {
              measure_unit: Number(operator.measurementId),
              line: Number(operator.lineId),
            },
            acc
          );
        }
        addedLines.add(operator.entity);

        maxId = Number(operator.entity) > maxId ? Number(operator.entity) : maxId;

        return update(
          Number(operator.entity),
          {
            measure_unit: Number(operator.measurementId),
            line: Number(operator.lineId),
          },
          acc
        );
      }

      if (addedLines.has(operator.entity)) {
        maxId += 1;
        addedMeasureUnits.add(maxId);
        return update(
          Number(maxId),
          {
            measure_unit: Number(operator.measurementId),
          },
          acc
        );
      }
      addedMeasureUnits.add(operator.entity);

      maxId = Number(operator.entity) > maxId ? Number(operator.entity) : maxId;
      return update(
        Number(operator.entity),
        {
          measure_unit: Number(operator.measurementId),
        },
        acc
      );
    }, initial)
    .filter((op) => op);

  return {
    id,
    entities,
  };
};

const extractVariablesFromMapVariables = (mapVariablesEntities) => {
  return mapVariablesEntities.reduce(
    ({ variables, customVariables }, { variable, type, lineId, measureId, customVariable }) => {
      if (type === 'customVariable') {
        return {
          variables,
          customVariables: [
            ...customVariables,
            extractCustomVariable(customVariable.formula_splited, customVariable.id),
          ],
        };
      }
      const formatted = {
        type_name: type === 'measurementUnit' ? 'mu' : 'line',
        ...(lineId && { line_id: Number(lineId) }),
        measure_unit_id: Number(measureId),
        variable,
        ...(['VoSMeterDev', 'VoS'].includes(variable) ? { beam: 1 } : {}),
      };

      return {
        customVariables,
        variables: [...variables, formatted],
      };
    },
    { variables: [], customVariables: [] }
  );
};

const generateMapVariablesEntities = (state, reportType) => {
  const lineVariables = reportType === 'ultrasonic' ? ULTRASONIC : selectors.getLineVariableSelecteds(state);
  const measurementUnitVariables = selectors.getMeasurementUnitVariableSelecteds(state);
  const measure = selectors.getMeasurementUnitReport(state);
  const measureToCompare = selectors.getMeasurementUnitToCompareReport(state);
  const lines = extractSelectedIdLines(measure);
  const linesToCompare = extractSelectedIdLines(measureToCompare);
  const linesMap = [
    ...(lines || []).map((lineId) => ({ type: 'lines', lineId, measureId: measure.id })),
    ...(linesToCompare || []).map((lineId) => ({ type: 'lines', lineId, measureId: measureToCompare.id })),
  ].reduce((acc, lineInfo) => [...acc, ...lineVariables.map(({ value: variable }) => ({ ...lineInfo, variable }))], []);
  const measurementUnitMap = [
    { type: 'measurementUnit', measureId: measure.id },
    ...(measureToCompare.id ? [{ type: 'measurementUnit', measureId: measureToCompare.id }] : []),
  ].reduce(
    (acc, measurementUnit) => [
      ...acc,
      ...measurementUnitVariables.map(({ value: variable }) => ({ ...measurementUnit, variable })),
    ],
    []
  );
  return [...linesMap, ...measurementUnitMap];
};

const getDatesReportParamsFromStore = (state, reportType) => {
  if (reportType === 'instant') {
    return {
      step: 'minutes',
      step_time: 15,
      start_date: getDateFormated(subMinutes(new Date(), 15), 'yyyy-MM-dd HH:mm:00'),
      end_date: getDateFormated(new Date(), 'yyyy-MM-dd HH:mm:00'),
    };
  }
  if (reportType === 'ultrasonic') {
    const dateInfo = selectors.getDateInfo(state);

    return {
      step: 'daily',
      step_time: 1,
      start_date: getDateFormated(subMinutes(dateInfo.date, 1), 'yyyy-MM-dd 00:00:00'),
      end_date: getDateFormated(dateInfo.date, 'yyyy-MM-dd 00:00:00'),
    };
  }
  if (reportType === 'comparative') {
    const dateInfo = selectors.getDateInfo(state);
    const startDate =
      dateInfo.period === 'daily'
        ? getDateFormated(subDays(dateInfo.date, 1), 'yyyy-MM-dd 06:00:00')
        : getDateFormated(subHours(dateInfo.date, 1), 'yyyy-MM-dd HH:00:00');
    const endDate =
      dateInfo.period === 'daily'
        ? getDateFormated(dateInfo.date, 'yyyy-MM-dd 06:00:00')
        : getDateFormated(dateInfo.date, 'yyyy-MM-dd HH:00:00');
    return {
      step: dateInfo.period,
      step_time: 1,
      start_date: startDate,
      end_date: endDate,
    };
  }

  if (reportType === 'saturation') {
    const dateInfo = selectors.getDateInfo(state);
    const endDate = getDateFormated(dateInfo.date, 'yyyy-MM-dd 00:00:00');
    const startDate = getDateFormated(subMonths(dateInfo.date, 6), 'yyyy-MM-dd 00:00:00');
    return {
      step: 'hourly',
      step_time: 1,
      start_date: startDate,
      end_date: endDate,
    };
  }
  if (reportType === 'minimum') {
    const dateRange = selectors.getDateRange(state);
    const startDate = getDateFormated(dateRange.startDate, 'yyyy-MM-dd 06:00:00');
    const endDate = getDateFormated(dateRange.endDate, 'yyyy-MM-dd 06:00:00');
    return {
      step: 'hourly',
      step_time: 1,
      start_date: startDate,
      end_date: endDate,
    };
  }
  if (reportType === 'advanced') {
    const dateRange = selectors.getDateRange(state);
    const advancedPeriod = selectors.getAdvancedPeriod(state);
    return {
      start_date: getDateFormated(dateRange.startDate, `yyyy-MM-dd HH:mm:ss`),
      end_date: getDateFormated(dateRange.endDate, `yyyy-MM-dd HH:mm:ss`),
      step_time:
        advancedPeriod.period.value.shiftType === 'custom'
          ? advancedPeriod.customShiftValue
          : advancedPeriod.period.value.shiftValue,
      step:
        advancedPeriod.period.value.shiftType === 'custom'
          ? advancedPeriod.customShiftType.value
          : advancedPeriod.period.value.shiftType,
    };
  }
  if (reportType === 'gasQualityAssignment') {
    const dateRange = selectors.getDateRange(state);

    return {
      start_date: getDateFormated(dateRange.startDate, `yyyy-MM-dd ${dateRange.startHour.value}:00`),
      end_date: getDateFormated(dateRange.endDate, `yyyy-MM-dd ${dateRange.endHour.value}:00`),
    };
  }
  return {};
};
const extractReportParamsFromStoreForSave = (state, reportType) => {
  const mapVariablesEntities = selectors.getMapVariablesEntities(state);
  const variablesFormatted = extractVariablesFromMapVariables([
    ...generateMapVariablesEntities(state, reportType),
    ...mapVariablesEntities,
  ]);
  const dateInfo = getDatesReportParamsFromStore(state, reportType);

  return {
    ...variablesFormatted,
    ...dateInfo,
  };
};

const extractInstantReportParamsFromStore = (state) => {
  const variables = selectors.getInstantReportVariables(state);
  const measure = selectors.getMeasurementUnitReport(state);
  return {
    lines: extractSelectedIdLines(measure),
    measurementUnitId: measure.id,
    variables: [...variables.croma, ...variables.flowComputer].reduce(
      (acc, variable) => (variable.selected ? [...acc, variable.value] : acc),
      []
    ),
  };
};

const extractComparativeReportParamsFromStore = (state) => {
  const variables = selectors.getComparativeReportVariables(state);
  const measure = selectors.getMeasurementUnitReport(state);
  const measureToCompare = selectors.getMeasurementUnitToCompareReport(state);
  const dateInfo = selectors.getDateInfo(state);
  return {
    lines: extractSelectedIdLines(measure),
    measurementUnitId: measure.id,
    linesToCompare: extractSelectedIdLines(measureToCompare),
    measurementUnitIdToCompare: measureToCompare.id,
    variables: variables.counter.reduce((acc, variable) => (variable.selected ? [...acc, variable.value] : acc), []),
    date: dateInfo.date,
    time: dateInfo.hour.value,
    dateType: dateInfo.period,
  };
};

const extractUltrasonicReportParamsFromStore = (state) => {
  const measure = selectors.getMeasurementUnitReport(state);
  const dateInfo = selectors.getDateInfo(state);
  const lastMeasure = selectors.getLastMeasure(state);
  const date = getDateISOString(getDateFormated(new Date(dateInfo.date), 'yyyy-MM-dd 00:00:00'));
  return {
    lineId: extractSelectedIdLines(measure)[0],
    measurementUnitId: measure.id,
    date,
    lastMeasure,
  };
};

const extractControlReportParamsFromStore = (state) => {
  const measure = selectors.getMeasurementUnitReport(state);
  const dateInfo = selectors.getDateInfo(state);
  const dateRange = selectors.getDateRange(state);
  const type = selectors.getTypeReport(state);
  return {
    measurementUnitId: measure.id,
    date: dateInfo.date,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    type,
  };
};

const extractSignatureAuditsReportParamsFromStore = (state, query) => {
  const measurementUnit = selectors.getMeasurementUnitReport(state);
  const dateRange = selectors.getDateRange(state);
  const { startDate, endDate } = dateRange;
  const { cursors = {} } = selectors.getReport(state);
  const cursor = cursors[query.offset];

  return {
    startDate: getDateFormated(startDate, 'yyyy-MM-dd HH:mm:ss'),
    endDate: getDateFormated(endDate, 'yyyy-MM-dd HH:mm:ss'),
    measureUnitId: measurementUnit.id,
    lines: extractSelectedIdLines(measurementUnit),
    cursor,
  };
};

const extractAdvancedReportParamsFromStore = (state) => {
  const dateRange = selectors.getDateRange(state);
  const mapVariablesEntities = selectors.getMapVariablesEntities(state);
  const advancedPeriod = selectors.getAdvancedPeriod(state);
  const { startDate, endDate } = dateRange;

  const formattedVariables = extractVariablesFromMapVariables(mapVariablesEntities);

  const correlation = selectors.getCorrelationReport(state);
  const representation = selectors.getRepresentationReport(state);
  return {
    startDate: getDateFormated(startDate, 'yyyy-MM-dd HH:mm:ss'),
    endDate: getDateFormated(endDate, 'yyyy-MM-dd HH:mm:ss'),
    startTime: dateRange.startHour.value,
    endTime: dateRange.endHour.value,
    shiftValue:
      advancedPeriod.period.value.shiftType === 'custom'
        ? advancedPeriod.customShiftValue
        : advancedPeriod.period.value.shiftValue,
    shiftType:
      advancedPeriod.period.value.shiftType === 'custom'
        ? advancedPeriod.customShiftType.value
        : advancedPeriod.period.value.shiftType,
    correlation,
    type: representation,
    representation: representation === 'table' ? 'graph' : representation,
    ...formattedVariables,
  };
};

export {
  extractInstantReportParamsFromStore,
  extractComparativeReportParamsFromStore,
  extractSelectedIdLines,
  extractSignatureAuditsReportParamsFromStore,
  extractUltrasonicReportParamsFromStore,
  extractControlReportParamsFromStore,
  extractAdvancedReportParamsFromStore,
  extractReportParamsFromStoreForSave,
};
