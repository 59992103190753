import React from 'react';
import { Link } from 'react-router-dom';

import { prop, concatEntities } from 'libs/toolkits';
import { Tag } from 'ui';
import { getDateFormated } from 'libs/date';
import { translateTypes, COLORS_STATUS } from './common';

const createColumnsConfig = (t, onClick) => [
  {
    Header: t('AlertPage:table:saved:type'),
    accessor: 'alertType',
    cellOnClick: onClick,
    width: '18%',

    Cell: ({
      row: {
        values: { alertType },
      },
    }) => {
      return <Tag color="purple">{t(`AlertPage:types:${translateTypes(alertType)}`)}</Tag>;
    },
  },

  {
    Header: t('AlertPage:table:saved:name'),
    accessor: 'name',
    cellOnClick: onClick,
    width: '25%',
    Cell: ({ row: { original } }) => <Link to={`alerts/incidences/${original.id}`}>{original.name}</Link>,
  },
  {
    Header: t('AlertPage:table:saved:um/lines'),
    accessor: 'measurementUnits',
    cellOnClick: onClick,
    width: '15%',
    maxWidth: 350,
    Cell: ({
      row: {
        original: { measurementUnits = [], lines = [] },
      },
    }) => `${lines.length ? concatEntities(lines, prop('name')) : concatEntities(measurementUnits, prop('name'))}`,
  },
  {
    Header: t('AlertPage:table:saved:variables'),
    accessor: 'variables',
    cellOnClick: onClick,
    width: '20%',

    Cell: ({
      row: {
        original: { variables = [] },
      },
    }) => concatEntities(variables, prop('value')),
  },
  {
    Header: t('Headers:responsable'),
    accessor: 'creator',
    cellOnClick: onClick,
    width: '15%',
  },
  {
    Header: t('Headers:CommonCreationDate'),
    accessor: 'creationDate',
    cellOnClick: onClick,
    width: '15%',

    Cell: ({
      row: {
        original: { creationDate },
      },
    }) => getDateFormated(creationDate, 'dd-MM-yyyy HH:mm'),
  },
  {
    Header: t('AlertPage:table:saved:status'),
    accessor: 'status',
    cellOnClick: onClick,
    width: '20%',
    maxWidth: 100,
    Cell: ({
      row: {
        original: { status },
      },
    }) => (
      <Tag rounded color={COLORS_STATUS[status]}>
        {t(`AlertPage:status:${status}`)}{' '}
      </Tag>
    ),
  },
];
export { createColumnsConfig };
