import styled from 'styled-components';
import { Empty } from 'antd';

const EmptyStyles = styled(Empty)`
  margin: 0;
  p {
    max-width: 400px;
    color: #7f8a9d;
    margin: 0 auto;
    font-size: 16px;
  }

  .ant-empty-image {
    margin: 30px auto 20px;
  }

  .ant-empty-footer {
    display: flex;
    justify-content: center;
  }
`;

const Container = styled.div`
  padding: 20px;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export { EmptyStyles, Container };
