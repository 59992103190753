export default {
  lightGrey: '#ECE9F1',
  lightIcon: '#a7adb2',
  light: '#a7adb2',
  dark: '#2f2e49',
  darkGrey: '#5C5B5B',
  bgDark: '#292929',
  bgLight: '#F9F9F9',
  textColor: '#3F3356',
  danger: '#ff6985',
  success: '#46d4a9',
  focusField: '#999',
  white: '#fff',
};
