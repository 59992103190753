import styled from 'styled-components';

const ContainerStyles = styled.div`
  width: 82px;
  transition: all 0.5s;
  transition: width 1s;
`;

const FixedContainerStyles = styled.div`
  width: 82px;
  height: 100vh;
  background: #2f2e48;
  box-shadow: 2px 0 5px 0 rgba(51, 51, 79, 0.0375);
  position: sticky;
  top: 0;
  transition: all 0.5s;
  transition: width 1s;
  overflow: hidden;
`;

const HeaderImgStyles = styled.img`
  width: 54px;
`;

const NavStyles = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 14px;
`;

const MenuStyles = styled.ul`
  padding: 0;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 100px);

  li.beta :after {
    width: 33px;
    background: #ff5f5f;
    content: 'Beta';
    position: absolute;
    top: 34px;
    border-radius: 3px;
    text-align: center;
    color: #fff;
    font-size: 11px;
    left: 17px;
  }
  li.beta span:after {
    display: none;
  }
`;

const ItemMenuStyles = styled.li`
  margin: 0 0 10px;
  list-style: none;
  position: relative;

  a {
    color: white;
    background: #a7a7a73d;
    border-radius: 10px;
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
`;

const ItemsDropdownStyles = styled.ul`
  height: ${({ isSelected }) => (isSelected ? 'auto' : '0px')};
  margin-left: 32px;
  list-style: none;
  padding: 0;
  overflow: hidden;
  transition: height 5s linear;
`;

const BurgerStyles = styled.button`
  position: absolute;
  top: 21px;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.8rem;
  height: 1.3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 1.3rem;
    height: 1px;
    background: #effffa;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
      transform: ${({ isOpen }) => (isOpen ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export {
  BurgerStyles,
  NavStyles,
  ItemMenuStyles,
  MenuStyles,
  FixedContainerStyles,
  ItemsDropdownStyles,
  HeaderImgStyles,
  ContainerStyles,
};
