import { useCallback } from 'react';
import { getOffsetTimezone } from 'libs/date';
import { generateEntityQueries } from 'libs/request-utils';
import { transformFillResponseToSelectOptions } from './utils';

const { searchEntities, fillEntities } = generateEntityQueries({
  nameForLists: 'company',
});

const useSearchCompanies = () =>
  useCallback((text) => {
    return searchEntities({
      params: { q: text, order: 'ASC', limit: 20, ho: getOffsetTimezone() },
    });
  }, []);

const useFillCompanies = () => {
  return fillEntities({
    params: {},
  })
    .then(
      ({
        data: { companies } = {
          companies: [],
        },
      }) => {
        return transformFillResponseToSelectOptions(companies);
      }
    )
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export { useSearchCompanies, useFillCompanies };
