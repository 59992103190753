import { useState, useCallback } from 'react';

const useToggle = (initialState) => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => {
    setState(!state);
  }, [setState, state]);
  const close = useCallback(() => {
    setState(false);
  }, [setState]);
  const open = useCallback(() => {
    setState(true);
  }, [setState]);
  return [state, toggle, open, close, setState];
};

export default useToggle;
