import styled from 'styled-components';

import { Badge } from 'antd';

const BadgetStyles = styled(Badge)`
  cursor: pointer;
  display: flex;

  .ant-badge-multiple-words {
    padding: 0 5px;
  }

  .ant-badge-count {
    font-size: 10px;
  }

  .ant-badge-count,
  .ant-badge-dot,
  .ant-badge .ant-scroll-number-custom-component {
    top: 7px;
    right: 7px;
  }
`;

export { BadgetStyles };
