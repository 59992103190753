import { STATUS } from 'libs/generate-redux-utils';
import actionTypes from './types';
import {
  DEFAULT_EQUIPMENT_CHROMATOGRAPH_ENTITY,
  DEFAULT_EQUIPMENT_COUNTER_ENTITY,
  DEFAULT_EQUIPMENT_FLOW_COMPUTER_ENTITY,
  DEFAULT_EQUIPMENT_PATTERN_ENTITY,
  DEFAULT_EQUIPMENT_BOTTLE_ENTITY,
} from './reducers';

const listRequested = ({ equipmentType }) => ({
  type: actionTypes.LIST_REQUESTED,
  payload: { equipmentType, status: STATUS.LOADING },
});
const listSucceeded = ({ equipmentType, entities, total }) => {
  return { type: actionTypes.LIST_SUCCEEDED, payload: { equipmentType, entities, total, status: STATUS.READY } };
};
const listFailed = ({ error, equipmentType }) => ({
  type: actionTypes.LIST_FAILED,
  payload: { equipmentType, status: STATUS.ERROR },
  error,
});

const getDataRequested = () => ({
  type: actionTypes.GET_DATA_REQUESTED,
  payload: { status: STATUS.LOADING },
});

const getDataFailed = ({ error }) => ({
  type: actionTypes.GET_DATA_FAILED,
  payload: { status: STATUS.ERROR },
  error,
});

const getDataSucceeded = ({ entities }) => {
  return { type: actionTypes.GET_DATA_SUCCEEDED, payload: { entities, status: STATUS.READY } };
};

const getFootprintRequested = () => ({
  type: actionTypes.GET_FOOTPRINT_REQUESTED,
  payload: { status: STATUS.LOADING },
});

const getFootprintFailed = ({ error }) => ({
  type: actionTypes.GET_FOOTPRINT_FAILED,
  payload: { status: STATUS.ERROR },
  error,
});

const getFootprintSucceeded = ({ footprint }) => {
  return { type: actionTypes.GET_FOOTPRINT_SUCCEEDED, payload: { footprint, status: STATUS.READY } };
};

const showRequested = ({ equipmentType }) => ({
  type: actionTypes.SHOW_REQUESTED,
  payload: {
    status: STATUS.LOADING,
    equipmentType,
  },
});
const showSucceeded = ({ entity, equipmentType }) => ({
  type: actionTypes.SHOW_REQUESTED,
  payload: {
    status: STATUS.READY,
    entity,
    equipmentType,
  },
});
const showFailed = ({ error, equipmentType }) => ({
  type: actionTypes.SHOW_FAILED,
  error,
  payload: {
    status: STATUS.ERROR,
    ...(equipmentType === 'chromatograph' && { entity: DEFAULT_EQUIPMENT_CHROMATOGRAPH_ENTITY }),
    ...(equipmentType === 'counter' && { entity: DEFAULT_EQUIPMENT_COUNTER_ENTITY }),
    ...(equipmentType === 'flowComputer' && { entity: DEFAULT_EQUIPMENT_FLOW_COMPUTER_ENTITY }),
    ...(equipmentType === 'patternEquipment' && { entity: DEFAULT_EQUIPMENT_PATTERN_ENTITY }),
    ...(equipmentType === 'calibrationBottle' && { entity: DEFAULT_EQUIPMENT_BOTTLE_ENTITY }),
  },
});
const updateRequested = ({ equipmentType }) => ({
  type: actionTypes.UPDATE_REQUESTED,
  payload: {
    status: STATUS.LOADING,
    equipmentType,
  },
});
const updateSucceeded = ({ entity, equipmentType }) => ({
  type: actionTypes.UPDATE_REQUESTED,
  payload: {
    status: STATUS.READY,
    entity,
    equipmentType,
  },
});
const updateFailed = ({ error, equipmentType }) => ({
  type: actionTypes.UPDATE_FAILED,
  error,
  payload: {
    status: STATUS.ERROR,
    ...(equipmentType === 'chromatograph' && { entity: DEFAULT_EQUIPMENT_CHROMATOGRAPH_ENTITY }),
    ...(equipmentType === 'counter' && { entity: DEFAULT_EQUIPMENT_COUNTER_ENTITY }),
    ...(equipmentType === 'flowComputer' && { entity: DEFAULT_EQUIPMENT_FLOW_COMPUTER_ENTITY }),
    ...(equipmentType === 'patternEquipment' && { entity: DEFAULT_EQUIPMENT_PATTERN_ENTITY }),
    ...(equipmentType === 'calibrationBottle' && { entity: DEFAULT_EQUIPMENT_BOTTLE_ENTITY }),
  },
});

const createRequested = ({ equipmentType }) => ({
  type: actionTypes.CREATE_REQUESTED,
  payload: {
    status: STATUS.LOADING,
    equipmentType,
  },
});
const createSucceeded = ({ entity, equipmentType }) => ({
  type: actionTypes.CREATE_REQUESTED,
  payload: {
    status: STATUS.READY,
    entity,
    equipmentType,
  },
});
const createFailed = ({ error, equipmentType }) => ({
  type: actionTypes.CREATE_FAILED,
  error,
  payload: {
    status: STATUS.ERROR,
    ...(equipmentType === 'chromatograph' && { entity: DEFAULT_EQUIPMENT_CHROMATOGRAPH_ENTITY }),
    ...(equipmentType === 'counter' && { entity: DEFAULT_EQUIPMENT_COUNTER_ENTITY }),
    ...(equipmentType === 'flowComputer' && { entity: DEFAULT_EQUIPMENT_FLOW_COMPUTER_ENTITY }),
    ...(equipmentType === 'patternEquipment' && { entity: DEFAULT_EQUIPMENT_PATTERN_ENTITY }),
    ...(equipmentType === 'calibrationBottle' && { entity: DEFAULT_EQUIPMENT_BOTTLE_ENTITY }),
  },
});

const deleteRequested = ({ equipmentType }) => ({
  type: actionTypes.DELETE_REQUESTED,
  payload: {
    status: STATUS.LOADING,
    equipmentType,
  },
});
const deleteSucceeded = ({ equipmentType }) => ({
  type: actionTypes.DELETE_REQUESTED,
  payload: {
    status: STATUS.READY,
    equipmentType,
  },
});
const deleteFailed = ({ error, equipmentType }) => ({
  type: actionTypes.DELETE_FAILED,
  error,
  payload: {
    status: STATUS.ERROR,
    ...(equipmentType === 'chromatograph' && { entity: DEFAULT_EQUIPMENT_CHROMATOGRAPH_ENTITY }),
    ...(equipmentType === 'counter' && { entity: DEFAULT_EQUIPMENT_COUNTER_ENTITY }),
    ...(equipmentType === 'flowComputer' && { entity: DEFAULT_EQUIPMENT_FLOW_COMPUTER_ENTITY }),
    ...(equipmentType === 'patternEquipment' && { entity: DEFAULT_EQUIPMENT_PATTERN_ENTITY }),
    ...(equipmentType === 'calibrationBottle' && { entity: DEFAULT_EQUIPMENT_BOTTLE_ENTITY }),
  },
});

const setModalFormState = (newState) => ({
  type: actionTypes.SET_MODAL_INFO,
  payload: { ...newState },
});

const setChromatographFootprint = (newState) => ({
  type: actionTypes.SET_CHROMA_FOOTPRINT,
  payload: { ...newState },
});
const setDataAnalysis = (data) => ({
  type: actionTypes.SET_DATA_ANALYSIS,
  payload: data,
});

const getEvolutionRequested = () => ({
  type: actionTypes.GET_EVOLUTION_REQUESTED,
  payload: { status: STATUS.LOADING },
});

const getEvolutionFailed = ({ error }) => ({
  type: actionTypes.GET_EVOLUTION_FAILED,
  payload: { status: STATUS.ERROR },
  error,
});

const getEvolutionSucceeded = ({ report }) => {
  return { type: actionTypes.GET_EVOLUTION_SUCCEEDED, payload: { report, status: STATUS.READY } };
};

const getAnalysisRequested = () => ({
  type: actionTypes.GET_ANALYSIS_REQUESTED,
  payload: { status: STATUS.LOADING },
});

const getAnalysisFailed = ({ error }) => ({
  type: actionTypes.GET_ANALYSIS_FAILED,
  payload: { status: STATUS.ERROR },
  error,
});

const getAnalysisSucceeded = (payload) => {
  return { type: actionTypes.GET_ANALYSIS_SUCCEEDED, payload: { ...payload, status: STATUS.READY } };
};

export {
  listRequested,
  listSucceeded,
  listFailed,
  showRequested,
  showSucceeded,
  showFailed,
  getDataRequested,
  getDataFailed,
  getDataSucceeded,
  getFootprintRequested,
  getFootprintFailed,
  getFootprintSucceeded,
  updateRequested,
  updateSucceeded,
  updateFailed,
  setModalFormState,
  createRequested,
  createSucceeded,
  createFailed,
  deleteRequested,
  deleteSucceeded,
  deleteFailed,
  setChromatographFootprint,
  setDataAnalysis,
  getEvolutionRequested,
  getEvolutionFailed,
  getEvolutionSucceeded,
  getAnalysisRequested,
  getAnalysisFailed,
  getAnalysisSucceeded,
};
