import styled from 'styled-components';

const DetailsStyles = styled.div`
  background: transparent;
  padding: 20px;
  border-right: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
  min-height: 100%;
  min-width: 330px;
  max-width: 330px;
  overflow: hidden;
  overflow-y: visible;

  h4 {
    margin: 0;
  }
`;

export { DetailsStyles };
