import React from 'react';
import PropTypes from 'prop-types';

import { WrapperStyles, HeaderStyles, ContentStyles, SubHeaderStyles } from './styles';

const BalanceDetails = ({ header, subHeader, children }) => (
  <WrapperStyles>
    <HeaderStyles>{header}</HeaderStyles>
    <SubHeaderStyles>{subHeader}</SubHeaderStyles>
    <ContentStyles>{children}</ContentStyles>
  </WrapperStyles>
);

BalanceDetails.propTypes = {
  header: PropTypes.node.isRequired,
  subHeader: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default BalanceDetails;
