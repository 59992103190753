import styled from 'styled-components';

import { Tooltip } from 'antd';

const TooltipStyles = styled(Tooltip)`
  .ant-tooltip-inner {
    padding: 10px;
  }
  height: 29px;
  display: flex;
  align-items: center;
`;

export { TooltipStyles };
