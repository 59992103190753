const useCopyToClipboard = (text) => {
  const action = () => {
    const elem = document.createElement('input');
    elem.setAttribute('type', 'text');
    document.body.appendChild(elem);
    elem.style.position = 'absolute';
    elem.style.left = '-1000px';
    elem.style.top = '-1000px';
    elem.value = text;
    elem.select();
    elem.setSelectionRange(0, 99999);
    document.execCommand('copy');
    elem.parentNode.removeChild(elem);
  };
  return action;
};

export default useCopyToClipboard;
