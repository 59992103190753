import types from './types';

const initialState = {
  isOnError: false,
  isLoading: false,
  error: null,
  result: [],
  configUser: {
    loading: false,
    isOnError: false,
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case types.SHOW_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isOnError: !!error,
      };
    case types.SHOW_SUCCEEDED:
      return {
        ...state,
        ...payload,
        isLoading: false,
        isOnError: !!error,
      };
    case types.SHOW_FAILED:
      return {
        ...state,
        error: payload.error,
        isOnError: !!error,
      };

    case types.CONFIG_USER_REQUESTED:
    case types.CONFIG_USER_SUCCEED:
    case types.CONFIG_USER_FAILED:
      return {
        ...state,
        configUser: { ...state.configUser, ...payload, error: payload.error, isOnError: !!error },
      };

    case types.SET_REQUEST_CALL:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default reducer;
