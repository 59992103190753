import { generateEntityQueries, toFormData } from 'libs/request-utils';
import { getOffsetTimezone } from 'libs/date';

const { createEntity: callMeasures } = generateEntityQueries({
  nameForLists: 'dashboard',
});

const getMeasures = ({ measurementUnit, startDate, selectedWindow, endDate, variable }) => {
  const body = {
    start_date: startDate,
    end_date: endDate,
    datetime_type: selectedWindow,
    totalBoolean: true,
    measurementunit: measurementUnit,
    variables: [variable],
    ho: getOffsetTimezone(),
  };

  const params = toFormData(body);

  return callMeasures({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export { getMeasures };
