import styled, { css } from 'styled-components';

const namesWithContent = {
  'icon-alerts': css`
    content: '\\e900';
  `,
  'icon-analysis': css`
    content: '\\e901';
  `,
  'icon-attached': css`
    content: '\\e932';
  `,
  'icon-config': css`
    content: '\\e904';
  `,
  'icon-copy': css`
    content: '\\e94c';
  `,
  'icon-dashboard': css`
    content: '\\e903';
  `,
  'icon-delete': css`
    content: '\\e92f';
  `,
  'icon-explore': css`
    content: '\\e905';
  `,
  'icon-explore-map': css`
    content: '\\e938';
  `,
  'icon-filter': css`
    content: '\\e939';
  `,
  'icon-info': css`
    content: '\\e92c';
  `,
  'icon-infrastructures': css`
    content: '\\e907';
  `,
  'icon-news': css`
    content: '\\e93f';
  `,
  'icon-reports': css`
    content: '\\e941';
  `,
  'icon-reload': css`
    content: '\\e94a';
  `,
  'icon-close': css`
    content: '\\e909';
  `,
  'icon-grid': css`
    content: '\\e90a';
  `,
  'icon-history': css`
    content: '\\e937';
  `,
  'icon-link': css`
    content: '\\e936';
  `,
  'icon-resize': css`
    content: '\\e90b';
  `,
  'icon-rows': css`
    content: '\\e93b';
  `,
  'icon-search': css`
    content: '\\e911';
  `,
  'icon-send': css`
    content: '\\e92e';
  `,
  'icon-open': css`
    content: '\\e934';
  `,
  'icon-open-drawer': css`
    content: '\\e94b';
  `,
  'icon-upload': css`
    content: '\\e935';
  `,
  'icon-left': css`
    content: '\\e929';
  `,
  'icon-right': css`
    content: '\\e92a';
  `,
  'icon-arrow-left': css`
    content: '\\e90d';
  `,
  'icon-arrow-right': css`
    content: '\\e928';
  `,
  'icon-arrow-down': css`
    content: '\\e90e';
  `,
  'icon-arrow-up': css`
    content: '\\e90f';
  `,
  'icon-calendar': css`
    content: '\\e913';
  `,
  'icon-clock': css`
    content: '\\e912';
  `,
  'icon-plus': css`
    content: '\\e910';
  `,
  'icon-more-info': css`
    content: '\\e914';
  `,
  'icon-notification': css`
    content: '\\e93e';
  `,
  'icon-balance': css`
    content: '\\e915';
  `,
  'icon-predictions': css`
    content: '\\e902';
  `,
  'icon-graph_bar': css`
    content: '\\e931';
  `,
  'icon-download': css`
    content: '\\e92d';
  `,
  'icon-kmz': css`
    content: '\\e933';
  `,
  'icon-schedule': css`
    content: '\\e93c';
  `,
  'icon-cancel-schedule': css`
    content: '\\e93d';
  `,
  'icon-favorite-selected': css`
    content: '\\e90c';
  `,
  'icon-favorite': css`
    content: '\\e940';
  `,
  positions: css`
    content: '\\e916';
  `,
  branches: css`
    content: '\\e917';
  `,
  um: css`
    content: '\\e927';
  `,
  sections: css`
    content: '\\e926';
  `,
  equipments: css`
    content: '\\e918';
  `,
  'icon-edit': css`
    content: '\\e942';
  `,
  'icon-pattern': css`
    content: '\\e943';
  `,
  'icon-warning': css`
    content: '\\e944';
  `,
  'icon-bottle': css`
    content: '\\e945';
  `,
  'icon-counter': css`
    content: '\\e946';
  `,
  'icon-cromatograph': css`
    content: '\\e947';
  `,
  'icon-flow-computer': css`
    content: '\\e948';
  `,
  news: css`
    content: '\\e949';
  `,
};

const fontSize = (size) => css`
  font-size: ${`${size}px`};
`;

const IconStyles = styled.span`
  font-family: '${(props) => props.theme.fonts.iconFont}';
  font-weight: 100;
  ${({ size }) => fontSize(size)};
  line-height: 100%;
  text-transform: none;
  ${({ color, theme }) => theme.button.color[color]};

  &:before {
    ${({ icon }) => namesWithContent[icon]};
    ${({ color, theme }) => theme.button.color[color]};
  }

  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `};
`;

export { IconStyles };
