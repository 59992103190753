import { generateEntityQueries, toFormData } from 'libs/request-utils';

const { getEntity: getConfigUserRequest } = generateEntityQueries({
  nameForLists: 'user/getconfig',
});
const { createEntity: setGraphConfigUserRequest } = generateEntityQueries({
  nameForLists: 'user/config',
});
const { createEntity: deleteGraphConfigUserRequest } = generateEntityQueries({
  nameForLists: 'user/deleteconfig',
});

const getConfigUser = () => {
  return getConfigUserRequest({
    params: {},
  });
};

const addGraphUser = ({ idMu, nameMu, variable, datetimeType }) => {
  const params = toFormData({
    params: { id_mu: idMu.toString(), name_mu: nameMu, variable, datetime_type: datetimeType },
  });
  return setGraphConfigUserRequest({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const deleteGraphUser = ({ idMu, nameMu, variable, datetimeType }) => {
  const params = toFormData({
    graph_home: { id_mu: idMu.toString(), name_mu: nameMu, variable, datetime_type: datetimeType },
  });
  return deleteGraphConfigUserRequest({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export { getConfigUser, addGraphUser, deleteGraphUser };
