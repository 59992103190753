import styled from 'styled-components';

const TableStyles = styled.table`
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.05);
  border-radius: 7px;

  width: 100%;

  th,
  td {
    font-size: 12px;
    color: #575f6d;
    margin: 0;
    padding: 0.5rem 0.8rem;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;

    :last-child {
      border-right: 0;
    }
  }

  tbody {
    tr {
      background: rgba(232, 243, 255, 0.4);

      :last-child > td {
        border-bottom: 0;
      }
    }
  }
`;

const TheadStyles = styled.thead`
  th {
    text-transform: uppercase;
    font-size: 11px;
    color: #5f94c5;
    text-align: left;
  }
`;

const ContainerTrStyles = styled.div`
  tr {
    height: 42px;
  }
`;

export { TableStyles, TheadStyles, ContainerTrStyles };
