import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CONFIG_VARIABLES, VARIABLES_BARS } from 'config/variables';
import { operations as operationsUser } from 'modules/preferences';
import { isEmpty } from 'libs/toolkits';
import { Title, RowFlex, Button, Text, Tooltip } from 'ui';
import EmptyResultsGraphMeasures from '../measuresPanel/EmptyResultsGraphMeasures';
import { NoResultsContainer } from '../measuresPanel/styles';
import { ContainerGraphs, ContainerGraph, TitleGraph } from './styles';
import GraphDashboard from './graph/GraphDashboard';

const ResultPanelGraphs = ({ variables, dates }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const graphDashboard = (variable, result, measurementUnit, loading) => (
    <GraphDashboard
      data={{ [variable]: result && result[variable] ? result[variable] : {} }}
      s
      dates={dates}
      style={{ height: '460px', width: '100%' }}
      measurementUnitName={measurementUnit?.name}
      className="graph-measures"
      showLoading={loading}
    />
  );
  const deleteFavoriteGraph = ({ variable, measurementUnit, selectedWindow }) => {
    dispatch(
      operationsUser.deleteGraphConfigUserRequest({
        idMu: measurementUnit.id,
        nameMu: measurementUnit.name,
        variable,
        datetimeType: selectedWindow,
      })
    );
  };

  return (
    <>
      <ContainerGraphs>
        {variables.map(({ variable, measurementUnit, result, selectedWindow, loading }, index) => {
          const deltaLabel = VARIABLES_BARS.includes(variable) && variable !== 'qb' ? 'Δ ' : '';
          return (
            <ContainerGraph
              key={`${variable}-${measurementUnit.name}`}
              fullWidth={variables.length % 2 !== 0 && variables.length === index + 1}
            >
              <TitleGraph>
                <Title level={3}>
                  {measurementUnit.name}{' '}
                  <Text>{`${deltaLabel} ${t(`variables:${CONFIG_VARIABLES[variable].name}`)}`}</Text>
                </Title>
              </TitleGraph>

              <RowFlex>
                <Tooltip placement="bottomRight" title={t('MeasuresPage:RemoveFavorite')}>
                  <Button
                    style={{ position: 'absolute', right: '26px', top: '30px', zIndex: '9' }}
                    customIcon="icon-favorite-selected"
                    variant="plain"
                    color="primary"
                    onClick={() => {
                      deleteFavoriteGraph({ variable, measurementUnit, selectedWindow });
                    }}
                  />
                </Tooltip>
              </RowFlex>
              {isEmpty(result) || (result && isEmpty(result[variable])) ? (
                <NoResultsContainer>
                  <EmptyResultsGraphMeasures />
                </NoResultsContainer>
              ) : (
                ''
              )}
              {graphDashboard(variable, result, measurementUnit, loading)}
            </ContainerGraph>
          );
        })}
      </ContainerGraphs>
    </>
  );
};

ResultPanelGraphs.propTypes = {
  variables: PropTypes.shape([]).isRequired,
  dates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
};

GraphDashboard.defaultProps = {
  dates: {},
};

export default ResultPanelGraphs;
