import { extractorActionsResponse } from 'libs/request-utils';
import { getDateInTimezone } from 'libs/date';
import { CROMA, FC, ULTRASONIC } from 'config/variables';
import { operatorsToRequest } from 'modules/formulas/utils';

const actionsTranslate = {
  delete: 'delete',
  load: 'show',
};

const alertsSavedExtractorActions = extractorActionsResponse(actionsTranslate);

const VARIABLES = [...CROMA, ...FC, ...ULTRASONIC];

const GRADIENT_PERIODS = {
  15: { label: '15 min', value: 15 },
  30: { label: '30 min', value: 30 },
  60: { label: '1 hora', value: 60 },
};

const transformAlert = ({
  name,
  id,
  active,
  equipment_id: equipmentId,
  value_type: alertType,
  actions,
  variable: variables,
  measurement_units: measurementUnits,
  lines,
  period,
  max_threshold: thresholdMax,
  min_threshold: thresholdMin,
  pcs_value: pcsValue,
  gradient_value: gradientValue,
  delta_value: deltaValue,
  receiver,
  consolidation_time: consolidationTime,
  formula_compound: formula,
}) => {
  return {
    id,
    name,
    active,
    alertType,
    equipmentId,
    gradientValue,
    consolidationTime,
    gradientPeriod: GRADIENT_PERIODS[period] || { label: '15 min', value: 15 },
    deltaValue,
    variables: VARIABLES.filter((v) => variables.includes(v.value))[0],
    thresholdMax,
    thresholdMin,
    pcsValue,
    formula,
    actions: alertsSavedExtractorActions(actions),
    measurementUnits: (measurementUnits || []).map((m) => ({ value: String(m.id), label: m.name, item: { ...m } })),
    lines: (lines || []).map((line) => ({ label: String(line.name), value: line.id })),
    entitySelected: lines && lines.length ? 'line' : 'mu',
    receiver: receiver.join(';'),
  };
};

const transformSavedAlert = ({
  name,
  id,
  active,
  value_type: alertType,
  actions,
  variable: variables,
  mu: measurementUnits,
  line,
  value,
  period,
  max_threshold: thresholdMax,
  min_threshold: thresholdMin,
  receiver,
  formula_compound: formula,
  consolidation_time: consolidationTime,
  creator,
}) => {
  return {
    id,
    name,
    active,
    alertType,
    gradientValue: alertType === 'gradient' ? value : null,
    gradientPeriod: alertType === 'gradient' ? GRADIENT_PERIODS[period] || { label: '15 min', value: 15 } : null,
    deltaValue: alertType === 'delta' ? value : null,
    variables: VARIABLES.filter((v) => variables.includes(v.value)),
    thresholdMax,
    thresholdMin,
    pcsValue: alertType === 'delta_pcs' ? value : null,
    actions: alertsSavedExtractorActions(actions),
    measurementUnits,
    consolidationTime,
    formula,
    lines: line,
    entitySelected: line && line.length ? 'line' : 'mu',
    receiver: (receiver || []).join(';'),
    creator,
  };
};

const formatSaveAlert = (params) => {
  const lines = (params.selectLines || []).map(({ value }) => String(value));
  const variable =
    !params.selectVariable && params.formula && params.formula.length
      ? params.formula.reduce((acc, { type, value }) => (type !== 'math' ? acc.concat(value) : acc), [])
      : [params.selectVariable.value];

  const receiver = (params.receiver || '').split(';');

  const commonParams = {
    mu: params.selectMeasureunits.map(({ value }) => String(value)),
    formula_compound: !params.selectVariable && params.formula && params.formula.length ? params.formula : '',
    formula_text:
      !params.selectVariable && params.formula && params.formula.length ? operatorsToRequest(params.formula) : '',
    ...(lines.length && { line: lines }),
    ...(params.id && { id: params.id }),
    variable,
    consolidation_time: params.consolidationTime,
    name: params.alertName,
    active: params.active,
    value_type: params.alertType.value,
    ...(receiver.length && { receiver, type_message: 'email' }),
  };

  if (params.alertType.value === 'gradient') {
    return {
      ...commonParams,
      value: Number(params.gradientValue),
      period: params.gradientPeriod.value,
    };
  }
  if (params.alertType.value === 'threshold') {
    return {
      ...commonParams,
      max_threshold: Number(params.thresholdMax),
      min_threshold: Number(params.thresholdMin),
    };
  }
  if (params.alertType.value === 'delta_pcs') {
    return {
      ...commonParams,
      value: Number(params.pcsValue),
    };
  }
  return commonParams;
};

const transformAlertFromViewToRedux = (params, id) => {
  return {
    ...params,
    name: params.alertName,
    alertType: params.alertType.value,
    variables: params.selectVariable,
    measurementUnits: params.selectMeasureunits,
    lines: params.selectLines,
    id,
  };
};

const transformAlertHistory = ({
  name,
  id,
  close_date: closeDate,
  creation_date: creationDate,
  creator,
  creator_id: creatorId,
  alert_id: alertId,
  alert,
  status,
  formula,
  value_type: alertType,
  actions,
  measurement_units: measurementUnits,
  equipments,
  lines,
  trigger_value: triggerValue,
  variables = [],
  history = [],
  magnitudes = [],
}) => {
  return {
    id,
    name,
    alertType,
    alert: alert ? transformAlert(alert) : null,
    closeDate: closeDate ? getDateInTimezone(closeDate) : null,
    history: history.map(transformAlertHistory),
    creationDate: getDateInTimezone(creationDate),
    variables: VARIABLES.filter((v) => variables.includes(v.value)),
    measurementUnits: measurementUnits || [],
    equipments: equipments || [],
    lines: lines || [],
    triggerValue,
    formula,
    status,
    creator,
    creatorId,
    alertId,
    actions,
    magnitude: magnitudes.join(', '),
  };
};

export { transformSavedAlert, formatSaveAlert, transformAlert, transformAlertFromViewToRedux, transformAlertHistory };
