import React, { Suspense } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Spinner } from 'ui';

const Infrastructure = React.lazy(() => import('./Infrastructure'));
const Branches = React.lazy(() => import('./Branches'));
const Sections = React.lazy(() => import('./Sections'));
const Positions = React.lazy(() => import('./Positions'));
const MeasurementUnits = React.lazy(() => import('./MeasurementUnits'));
const Equipments = React.lazy(() => import('./Equipments'));

const InfrastuctureRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path={path}>
          <Infrastructure />
        </Route>
        <Route path={`${path}/branches`}>
          <Branches />
        </Route>
        <Route path={`${path}/sections`}>
          <Sections />
        </Route>
        <Route path={`${path}/measurement-units`}>
          <MeasurementUnits />
        </Route>
        <Route path={`${path}/positions`}>
          <Positions />
        </Route>
        <Route path={`${path}/equipments`}>
          <Equipments />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default InfrastuctureRoutes;
