import styled from 'styled-components';

const WrapperStyles = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: height 1s ease-in-out;
  min-height: calc(100vh - 116px);
`;

const HeaderStyles = styled.div`
  padding: 15px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(51, 52, 79, 0.07);
  border-radius: 3px;
  margin-bottom: 20px;
  position: sticky;
  top: -20px;
  z-index: 8;

  form {
    width: 100%;
  }

  button {
    margin-left: auto;
  }
`;

const ContentStyles = styled.div`
  h4 {
    font-size: 15px;
    font-weight: 500;
  }

  p {
    font-size: 12px;
    color: #808080;
  }
`;
const SubHeaderStyles = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContainerForm = styled.div`
  width: 500px;
  display: flex;
  > div {
    margin-right: 10px;
  }
`;

const ContainerSelect = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;

  > div {
    margin-right: 6px;
    min-width: 230px;
  }

  input {
    max-width: 90px;
  }
`;

export { WrapperStyles, HeaderStyles, ContentStyles, SubHeaderStyles, ContainerForm, ContainerSelect };
