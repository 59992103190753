import { createSelector } from 'reselect';

const getAlertsState = (state) => state.alerts;

const getAlertsSaved = createSelector([getAlertsState], ({ alertsSaved }) => alertsSaved);
const getAlertsSavedEntity = createSelector([getAlertsState], ({ alertsSaved }) => alertsSaved.entity);
const getAlertsSavedListParams = createSelector([getAlertsState], ({ alertsSaved }) => alertsSaved.oldParamsList || {});
const getAlertsGenerated = createSelector([getAlertsState], ({ alertsGenerated }) => alertsGenerated);
const getAlertsGeneratedEntity = createSelector([getAlertsState], ({ alertsGenerated }) => alertsGenerated.entity);
const getAlertsGeneratedListParams = createSelector(
  [getAlertsState],
  ({ alertsGenerated }) => alertsGenerated.oldParamsList || {}
);
const getAlertsTemplates = createSelector([getAlertsState], ({ alertsTemplates }) => alertsTemplates);

export { getAlertsSaved, getAlertsGenerated, getAlertsSavedListParams, getAlertsTemplates, getAlertsSavedEntity };

export default {
  getAlertsSaved,
  getAlertsSavedEntity,
  getAlertsSavedListParams,
  getAlertsGenerated,
  getAlertsGeneratedEntity,
  getAlertsGeneratedListParams,
  getAlertsTemplates,
};
