import { createGlobalStyle } from 'styled-components';

import andtCss from 'antd/dist/antd.css';
import IconFont from 'assets/fonts/iconFont.ttf';

export default createGlobalStyle`
${andtCss}
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');


@font-face {
  font-family: 'iconFont';
  src: url(${IconFont}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: Lato, sans-serif;
}

body {
    padding: 0;
    margin: 0;
    font-family: Lato, sans-serif;
    overflow: hidden;
  }
  
  input:disabled{
    cursor: no-drop;
    opacity: 0.7;
  }

  input:hover{
    box-shadow: 0 0 3px rgba(0,0,0,.1);
  }

  input::placeholder, textarea::placeholder{
    color: #ccc;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
  border: 1px solid #dfdfdf;
  transition: background-color 5000s ease-in-out 0s;
}

.echarts-for-react{
  background: #FFFFFF;
    border-radius: 4px 4px 0 0;
    padding-top: 20px;
}

.echarts-for-react{
  padding-top:0;
}

.graph-measures, .graph-footprint{
  border-bottom: none;
}
  
iframe{
  border: none;
}

hr{
  border: none;
  border-bottom: 1px solid #eaeaea;
  box-shadow: none;
  height: 5px;
  margin: 10px 0;
  outline: none;
}

::-webkit-scrollbar {
  background: transparent;
  height: 8px;
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: transparent;

}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border: 4px solid white;
  box-shadow: none;
  background: #dadce0;
  border-radius: 8px;
  min-height: 40px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bdc1c6;
}

code{
  background-color: #f6f8f9;
    border: 1px solid #cbd4db;
    border-radius: 4px;
    box-sizing: border-box;
    color: #6f7782;
    display: inline-flex;
    flex-shrink: 0;
    font-size: 14px;
    height: 20px;
    justify-content: center;
    line-height: 18px;
    min-width: 20px;
    padding: 0 4px;
}


`;
