import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MainLayout, Spinner } from 'ui';

import { selectors } from 'modules/auth';
import { getUserCompanyName } from 'config/companies';
import useAuth from './useAuth';
import NotAuthorized from '../notAuthorized';

function CompanyRoute({ children, company, ...rest }) {
  const { isLoading, isAuth } = useAuth();
  const { auth } = useSelector((state) => ({
    auth: selectors.getUserAuth(state),
  }));
  const companyName = getUserCompanyName(auth.company);

  return (
    <Route
      {...rest}
      render={() => {
        if (isLoading) {
          return <Spinner />;
        }

        return isAuth && (auth.role === 'superadmin' || company === companyName) ? (
          <MainLayout auth={auth}>{children}</MainLayout>
        ) : (
          <NotAuthorized />
        );
      }}
    />
  );
}

export default CompanyRoute;
