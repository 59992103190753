const URL_MAP = {
  branch: '/infrastructure/branches/',
  position: '/infrastructure/positions/',
  cromatograph: '/infrastructure/equipments/chromatograph/',
  measurementunit: '/infrastructure/measurement-units/',
  alert: '/alerts/saved/',
};

const InitialState = {
  data: [],
  keys: [],
  initLoading: true,
  loading: false,
};

const translateDict = {
  measurementunit: 'MeasurementUnit',
  position: 'Position',
  cromatograph: 'chromatograph',
  branch: 'Branch',
  alert: 'Alert',
};

export { URL_MAP, InitialState, translateDict };
