import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import getConfigGraph from './useGenerateGraphConfig';

const GraphMeasure = ({ data, className, style, dates, showLoading, measurementUnitName, variableLabel }) => {
  const { t } = useTranslation();
  const instanceGraph = useRef();
  useEffect(() => {
    if (instanceGraph) {
      const instanceEchartsGraph = instanceGraph.current.getEchartsInstance();
      instanceEchartsGraph.group = 'measures';
    }
  }, [instanceGraph]);

  return (
    <ReactEcharts
      ref={instanceGraph}
      option={getConfigGraph({
        data,
        dates,
        t,
        measurementUnitName,
        variableLabel,
      })}
      notMerge
      showLoading={showLoading}
      style={style}
      className={className}
      loadingOption={{
        text: t('components:measuresPanel:charts-loading'),
        color: '#6078FF',
        textColor: '#000',
        maskColor: '#fff',
        zlevel: 0,
        fontSize: 12,
        showSpinner: true,
        spinnerRadius: 10,
        lineWidth: 3,
      }}
    />
  );
};

GraphMeasure.propTypes = {
  data: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired,
  dates: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  style: PropTypes.shape({
    width: PropTypes.string,
    heigth: PropTypes.string,
  }),
  showLoading: PropTypes.bool.isRequired,
  measurementUnitName: PropTypes.string,
  variableLabel: PropTypes.string,
};
GraphMeasure.defaultProps = {
  dates: {},
  style: {},
  measurementUnitName: '',
  variableLabel: '',
};
export default GraphMeasure;
