const useFormWatchParams = ({ getValues, watch }) => {
  const status = getValues();
  const linesIdsSelecteds = (status.selectLines || []).map(({ value }) => value);
  const entitySelected = watch('entitySelected');
  const alertType = watch('alertType');
  const linesSelecteds = watch('selectLines');
  const measures = watch('selectMeasureunits');
  const measureLines = (measures || []).reduce((acc, { item: { lines = [] } }) => [...acc, ...lines], []);
  const optionsLines = measureLines.reduce(
    (accLines, { name, id, ...rest }) =>
      linesIdsSelecteds.includes(id) ? accLines : [...accLines, { value: id, label: name, ...rest }],
    []
  );
  return {
    status,
    linesIdsSelecteds,
    entitySelected,
    alertType,
    linesSelecteds,
    measures,
    measureLines,
    optionsLines,
  };
};

export default useFormWatchParams;
