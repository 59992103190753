import styled from 'styled-components';

const BoxStyles = styled.div`
  text-align: center;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 10px;
  cursor: pointer;
  width: 30%;
  min-height: 190px;
  height: auto;
  color: #8a8eab;

  :hover {
    box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.2);
  }

  h4 {
    margin-top: 8px;
    line-height: 17px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgb(138, 142, 171);
  }
`;

export { BoxStyles };
