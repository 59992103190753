import { useCallback } from 'react';
import { generateEntityQueries } from 'libs/request-utils';
import { transformFillResponseToSelectOptions } from './utils';

const { searchEntities, fillEntities } = generateEntityQueries({
  nameForLists: 'cromatograph',
});

const useSearchChromatographs = () =>
  useCallback((text) => {
    return searchEntities({
      params: { q: text, order: 'ASC', limit: 20, ho: 0 },
    });
  }, []);

const useFillChromatographs = () => {
  return fillEntities({
    params: {},
  })
    .then(
      ({
        data: { cromatographs } = {
          cromatographs: [],
        },
      }) => {
        return transformFillResponseToSelectOptions(cromatographs);
      }
    )
    .catch((error) => {
      console.error(error);
      return [];
    });
};
export { useSearchChromatographs, useFillChromatographs };
