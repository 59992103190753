import styled from 'styled-components';

const SCBoxSearch = styled.div`
  height: 40px;
  width: 40px;
  transition: 0.4s;
  display: flex;
  overflow: hidden;
  position: relative;
  top: 0;

  span {
    height: 40px;
    display: flex;
    align-items: center;
    box-shadow: none;
    border: 0px;

    span {
      span {
        svg {
          height: 1.2em;
          width: 1.2em;
        }
      }
    }
  }

  input {
    border: 0px;

    :hover {
      box-shadow: none;
    }
    :focus {
      box-shadow: none;
    }
  }

  &:hover {
    width: 300px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    background: #fff;

    input {
      opacity: 1;
    }
  }

  .icon-search {
    min-width: 40px;
  }

  ${({ showSearch }) =>
    showSearch
      ? `
		width: 300px;
		background: #fff;
		border-radius: 5px;
		box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
   
      
		input {
			opacity: 1;
	}`
      : ''}

  ${({ showResults }) => (showResults ? `height:300px; top:131px;` : '')}
`;

const Results = styled.div``;

const ResultSearch = styled.div`
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  width: 100%;
  height: calc(100% - 42px);
  position: absolute;
  top: 42px;
  overflow-y: overlay;
  overflow-x: hidden;
  margin-bottom: 40px;

  .no-result {
    max-width: 222px;
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
    color: #8a8a8a;
  }

  .divider-search {
    margin: 4px 0;
  }

  span {
    color: #a3a3a3;
  }

  ${({ showResults }) => (!showResults ? 'display:none' : '')}
`;

export { SCBoxSearch, ResultSearch, Results };
