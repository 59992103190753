import { generateEntityQueries, transformQuery, getStatusCode } from 'libs/request-utils';
import { operations as notificationOperations } from 'modules/notifications';
import { MESSAGES_NOTIFICATIONS } from 'modules/notifications/constants';
import * as actions from './actions';

const { searchEntities: searchMessages } = generateEntityQueries({
  nameForLists: 'messages',
});

const getMessageList = (query) => (dispatch) => {
  dispatch(actions.listMessagesRequested(query));
  const transformedQuery = {
    ...transformQuery({ ...query }),
  };
  return searchMessages({
    params: { ...transformedQuery },
  })
    .then(({ data }) => {
      dispatch(actions.listMessagesSucceeded(data.reports, data.incidences));
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(actions.listMessagesFailed({ error }));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:List', 'entitiesNames:Notification_plural')[statusCode],
        })
      );
    });
};

export { getMessageList };
