import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { EmptyWrapper, MessageEmpty, ImagesEmpty } from './styles';

const EmptyResultsGraphMeasures = ({ Image }) => {
  const { t } = useTranslation();

  return (
    <EmptyWrapper>
      <ImagesEmpty>
        <img src={Image} alt="" />
      </ImagesEmpty>

      <MessageEmpty>
        <div>
          <h2>{t('ReportPages:NoResults')}</h2>
        </div>
      </MessageEmpty>
    </EmptyWrapper>
  );
};
EmptyResultsGraphMeasures.propTypes = {
  Image: PropTypes.string.isRequired,
};
export default EmptyResultsGraphMeasures;
