import React from 'react';
import { operations as notificationOperations } from 'modules/notifications';
import { MESSAGES_NOTIFICATIONS } from 'modules/notifications/constants';
import { getTimeZone } from 'libs/date';
import { getStatusCode } from 'libs/request-utils';
import { Button } from 'ui';
import {
  generateInstantReport,
  generateComparativeReport,
  generateUltrasonicReport,
  getMeasurementRequest,
  sendReportToEmail,
  sendAssignmentReportToEmail,
  saveReferenceValue,
  setEntityReferenceValueCall,
  listSavedReports,
  deleteSavedReport,
  deleteScheduledReport,
  getSavedReport,
  getSignatureAudits,
  downloadSignatuAudits,
  getSignatureAuditApi,
  getReferenceValues,
  deleteReferenceValue,
  getAdvancedReport,
  generateControlReport,
  saveReport,
  saveScheduledReport as saveScheduledReportRequest,
  saveRenameReport as saveRenameReportRequest,
  getScheduleReports,
  getScheduleReportFiles,
  downloadReportFile,
  ableScheduleReportCall,
  downloadAssignmentReportAPI,
} from './api';
import actions from './actions';
import selectors from './selectors';
import {
  transformVariablesReport,
  transformMeasure,
  transformComparativeReport,
  transformUltrasonicReport,
  transformControlReport,
  transformSavedReports,
  transformSignatureReport,
  transformSignatureAudit,
  transformHistoryReferenceValue,
  transformScheduleReports,
} from './utils/transforms';
import {
  extractInstantReportParamsFromStore,
  extractComparativeReportParamsFromStore,
  extractSignatureAuditsReportParamsFromStore,
  extractUltrasonicReportParamsFromStore,
  extractControlReportParamsFromStore,
  extractAdvancedReportParamsFromStore,
  extractReportParamsFromStoreForSave,
} from './utils/extracts';

const listSavedReportsRequest = (query) => {
  return (dispatch) => {
    dispatch(actions.listRequested(query));
    listSavedReports(query)
      .then(({ data }) => {
        dispatch(actions.listSucceeded(data.items.map(transformSavedReports(query.type)), data.total));
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.listFailed(query));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:List', 'entitiesNames:Report_plural')[statusCode],
          })
        );
      });
  };
};

const sendReportToEmailRequest = ({ filename = 'report', contents, extensions, emails, ...rest }, keyNotification) => {
  const sendFunction = rest.typeReport === 'gasQualityAssignment' ? sendAssignmentReportToEmail : sendReportToEmail;
  return (dispatch) => {
    sendFunction({ filename, content: contents, type: extensions, emails, ...rest })
      .then(({ status }) => {
        notificationOperations.closeNotification(keyNotification);
        dispatch(
          notificationOperations.successNotification({
            title: '',
            message: MESSAGES_NOTIFICATIONS('messages:participles:sent', 'entitiesNames:Report')[status],
          })
        );
        notificationOperations.closeNotification('sendEmail');
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        notificationOperations.closeNotification(keyNotification);
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:SendEntity', 'entitiesNames:Report')[statusCode],
          })
        );
        notificationOperations.closeNotification('sendEmail');
      });
  };
};

const saveReportRequestNew = (reportType, history, pathname) => ({ name, idReport = '', setInfo }) => {
  const footprintReports = {
    analysisFootprint: 'analysis',
    evolutionFootprint: 'evolution',
    gasQualityAssignment: 'assigment',
  };
  const controlReports = ['minimum', 'saturation'];
  return (dispatch, getState) => {
    const state = getState();
    const clientConfig = selectors.getReportStoreToSave(state);
    const requestConfig = extractReportParamsFromStoreForSave(state, reportType);
    const params = { name, id: idReport, config: { client: clientConfig, request: requestConfig }, type: reportType };
    saveReport(params)
      .then(({ data, status }) => {
        setInfo();
        dispatch(actions.createReportSucceeded({ id: data }));
        dispatch(
          notificationOperations.successNotification({
            title: '',
            message: MESSAGES_NOTIFICATIONS('messages:participles:saved', 'entitiesNames:Report')[status],
          })
        );
        if (Object.keys(footprintReports).includes(reportType))
          history.push(`${pathname}/${data}?type=${footprintReports[reportType]}`);
        else if (controlReports.includes(reportType)) history.push(`/reports/control/${data}?typeReport=${reportType}`);
        else history.push(`/reports/${reportType}/${data}`);
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.createReportFailed({ query: params, error }));
        dispatch(actions.setStatusCode(statusCode));
      });
  };
};

const saveScheduledReport = (
  { name, config = null, frequency, type, reportId = '', receiveBool, setScheduleInfo },
  history,
  t
) => {
  return (dispatch, getState) => {
    const state = getState();
    const clientConfig = config ? config.client : selectors.getReportStoreToSave(state);
    const requestConfig = config ? config.request : extractReportParamsFromStoreForSave(state, type);
    const frequenciesArray = Object.entries(frequency)
      .reduce((acc, [freq, status]) => [...acc, status && freq], [])
      .filter(Boolean);
    const params = {
      name,
      report_id: reportId,
      config_report: {
        client: clientConfig,
        request: { ...requestConfig, timezone: getTimeZone },
      },
      type,
      frequencies: frequenciesArray,
      receiveBool,
    };
    saveScheduledReportRequest(params)
      .then(({ data: { report_id: id, activated, paused } }) => {
        dispatch(actions.createReportSucceeded({ id }));
        dispatch(actions.setStatusCode(200));
        dispatch(actions.setReportInfo({ name, frequencies: frequenciesArray, receiveBool }));

        if (activated.length)
          dispatch(
            notificationOperations.successNotification({
              title: t('actions:NewEntity', {
                entity: t('ReportPages:ScheduledReport'),
              }),
              message: t('messages:scheduled-report', {
                frequency: activated.reduce((acc, freq) => [...acc, t(`ReportPages:min${freq}`)], []).join(', '),
                name,
              }),
            })
          );

        const keyNotification = Math.random();
        if (paused.length)
          dispatch(
            notificationOperations.warningNotification({
              key: keyNotification,
              title: t('ReportPages:PausedReport', {
                count: paused.length,
              }),
              duration: null,
              btn: (
                <Button
                  variant="primary"
                  size="small"
                  onClick={() => {
                    history.push('/reports');
                    notificationOperations.closeNotification(keyNotification);
                  }}
                >
                  {t('actions:ViewMyScheduledReports')}
                </Button>
              ),
              message: t('messages:paused-report', {
                frequency: paused.reduce((acc, freq) => [...acc, t(`ReportPages:min${freq}`)], []).join(', '),
                count: paused.length,
                name,
              }),
            })
          );
        listSavedReportsRequest({ type })(dispatch);

        if (id !== reportId) history.push(`/reports/${type}/${id}`);

        setScheduleInfo();
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.setStatusCode(statusCode));
        dispatch(actions.createReportFailed({ query: params, error }));
      });
  };
};

const saveReferenceValueRequest = (data, callback) => {
  return (dispatch, getState) => {
    dispatch(actions.createReferenceValueRequest(data));
    saveReferenceValue({ data })
      .then(({ data: values, status }) => {
        dispatch(actions.createReferenceValueSucceeded({ id: values }));
        const report = selectors.getReport(getState());
        if (data?.active) report.reference_values.data = data.data;

        dispatch(actions.generateReportSucceeded(report));
        callback();
        dispatch(
          notificationOperations.successNotification({
            title: '',
            message: MESSAGES_NOTIFICATIONS('messages:participles:saved', 'entitiesNames:ReferenceValue')[status],
          })
        );
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.createReferenceValueFailed({ query: data, error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:SaveEntity', 'entitiesNames:ReferenceValue')[statusCode],
          })
        );
      });
  };
};

const generateReportRequest = (requestFunction, transformFunction, paramsExtractor) => (query) => {
  return (dispatch, getState) => {
    const state = getState();
    const params = { ...query, ...paramsExtractor(state, query) };
    dispatch(actions.setStatusCode(0));
    if (params?.representation) {
      dispatch(actions.setLastRepresentation(params.representation));
    }
    dispatch(actions.generateReportRequest(params, dispatch));

    if (query.keepCurrentReport !== true) {
      dispatch(actions.cleanReport());
    }

    requestFunction(params)
      .then(({ data }) => {
        if (params.representation === 'email') {
          dispatch(actions.setRepresentation('table'));
        }
        dispatch(actions.setStatusCode(200));
        dispatch(actions.generateReportSucceeded(transformFunction(data, state, query)));
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.generateReportFailed({ query, error, statusCode }));
      });
  };
};

const generateInstantReportRequest = generateReportRequest(
  generateInstantReport,
  transformVariablesReport,
  extractInstantReportParamsFromStore
);

const generateComparativeReportRequest = generateReportRequest(
  generateComparativeReport,
  transformComparativeReport,
  extractComparativeReportParamsFromStore
);

const generateUltrasonicReportRequest = generateReportRequest(
  generateUltrasonicReport,
  transformUltrasonicReport,
  extractUltrasonicReportParamsFromStore
);
const generateControlReportRequest = generateReportRequest(
  generateControlReport,
  transformControlReport,
  extractControlReportParamsFromStore
);

const generateAuditSignaturesReportRequest = generateReportRequest(
  getSignatureAudits,
  transformSignatureReport,
  extractSignatureAuditsReportParamsFromStore
);

const generateAdvancedReportRequest = generateReportRequest(
  getAdvancedReport,
  transformVariablesReport,
  extractAdvancedReportParamsFromStore
);

const changeMeasureReportRequest = ({ id, name, hasChroma, hasUltrasonic, isCompareMeasure = false }) => {
  return (dispatch) => {
    if (!id) {
      dispatch(actions.setMeasure({ id, name }, isCompareMeasure));
      return;
    }
    dispatch(actions.getMeasureRequested({ id }));
    dispatch(actions.setMeasure({ id, name, hasChroma, hasUltrasonic }, isCompareMeasure));
    getMeasurementRequest({ id })
      .then(({ data }) => {
        dispatch(actions.getMeasureSucceeded(transformMeasure(data), isCompareMeasure));
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.generateReportFailed({ query: { id }, error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:UpdateEntity', 'entitiesNames:Measures')[statusCode],
          })
        );
      });
  };
};

const deleteSavedReportRequest = (ids, type) => {
  return (dispatch) => {
    dispatch(actions.deleteSavedReportsRequested(ids));
    Promise.all(ids.map((id) => deleteSavedReport({ id })))
      .then(([data]) => {
        const { status } = data;
        listSavedReportsRequest({ type })(dispatch);
        dispatch(actions.deleteSavedReportsSucceeded(ids));
        dispatch(
          notificationOperations.successNotification({
            title: '',
            message: MESSAGES_NOTIFICATIONS('messages:participles:removed', 'entitiesNames:Report')[status],
          })
        );
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.deleteSavedReportsFailed({ ids, error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:DeleteEntity', 'entitiesNames:Report')[statusCode],
          })
        );
      });
  };
};

const getSavedReportRequest = ({ id }) => {
  return (dispatch) => {
    getSavedReport({ id })
      .then(({ data }) => {
        const savedStore = data.config_ob.client;
        savedStore.dateInfo.date = new Date(savedStore.dateInfo.date);
        savedStore.dateRange.startDate = new Date(savedStore.dateRange.startDate);
        savedStore.dateRange.endDate = new Date(savedStore.dateRange.endDate);
        dispatch(
          actions.setReportInfo({ name: data.name, frequencies: data.frequencies || [], receiveBool: data.receiveBool })
        );
        dispatch(actions.mergeStore(data.config_ob.client));
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:ViewEntity', 'ReportPages:ReportsSaved')[statusCode],
          })
        );
      });
  };
};

const getSignatureAuditRequest = ({ id }) => {
  return (dispatch) => {
    dispatch(actions.getSignatureAuditRequested(id));
    getSignatureAuditApi({
      id,
    })
      .then(({ data }) => {
        dispatch(actions.getSignatureAuditSucceeded(transformSignatureAudit(data)));
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.getSignatureAuditFailed({ id, error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:ViewEntity', 'ReportPages:SignatureAudit')[statusCode],
          })
        );
      });
  };
};

const listReferenceValues = (query) => (dispatch) => {
  dispatch(actions.listReferenceValuesRequested(query));
  getReferenceValues(query)
    .then((result) => {
      dispatch(
        actions.listReferenceValuesSucceeded(
          result.data.items.map((e) => transformHistoryReferenceValue(e)),
          result.data.total
        )
      );
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(actions.listReferenceValuesFailed({ query, error }));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:List', 'entitiesNames:ReferenceValues_plural')[statusCode],
        })
      );
    });
};

const deleteReferenceValueRequest = (ids, active, callback) => {
  return (dispatch) => {
    dispatch(actions.deleteReferenceValueRequested(ids));
    Promise.all(ids.map((id) => deleteReferenceValue({ id })))
      .then(([data]) => {
        const { status } = data;
        dispatch(actions.deleteReferenceValueSucceeded(ids));
        callback();
        if (active) {
          dispatch(
            actions.setReferenceValue({
              referenceValue: {},
            })
          );
        }
        dispatch(
          notificationOperations.successNotification({
            title: '',
            message: MESSAGES_NOTIFICATIONS('messages:participles:removed', 'entitiesNames:ReferenceValue_plural')[
              status
            ],
          })
        );
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.deleteReferenceValueFailed({ ids, error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:DeleteEntity', 'entitiesNames:ReferenceValue_plural')[statusCode],
          })
        );
      });
  };
};
const setReferenceValuesRequest = (params, callback) => (dispatch, getState) => {
  setEntityReferenceValueCall(params).then(({ data }) => {
    const report = selectors.getReport(getState());
    if (report && report.reference_values) report.reference_values = data;
    dispatch(actions.setReport(report));
    callback();
  });
};

const downloadSignatureAuditsRequest = (ids = []) => {
  return (dispatch) => {
    downloadSignatuAudits(ids)
      .then(() => {})
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:DownloadEntity', 'ReportPages:SignatureAudit')[statusCode],
          })
        );
      });
  };
};

const listScheduleReports = (query) => (dispatch) => {
  dispatch(actions.listScheduleReportsRequested(query));
  getScheduleReports(query)
    .then((result) => {
      dispatch(
        actions.listScheduleReportsSucceeded(
          result.data.items.map((e) => transformScheduleReports(e)),
          result.data.total
        )
      );
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(actions.listScheduleReportsFailed({ query, error }));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:List', 'ReportPages:ScheduledReports')[statusCode],
        })
      );
    });
};

const listScheduleReportFiles = (query) => (dispatch) => {
  dispatch(actions.listScheduleReportsFilesRequested(query));
  getScheduleReportFiles(query)
    .then((result) => {
      dispatch(
        actions.listScheduleReportsFilesSucceeded(
          result.data.items.map((e) => transformScheduleReports(e)),
          result.data.total
        )
      );
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(actions.listScheduleReportsFilesFailed({ query, error }));
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS('actions:ViewEntity', 'ReportPages:ScheduledReport')[statusCode],
        })
      );
    });
};
const getScheduleReportFile = (query) => () => {
  downloadReportFile(query).then(() => {});
};

const getAssignmentReportRequest = ({ filename = 'report', extensions, ext, ...rest }, keyNotification) => {
  return (dispatch) => {
    downloadAssignmentReportAPI({ filename, type: ext, ...rest })
      .then(() => notificationOperations.closeNotification(keyNotification))
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:DownloadEntity', 'entitiesNames:Report')[statusCode],
          })
        );
        notificationOperations.closeNotification(keyNotification);
      });
  };
};

const ableScheduleReportRequest = (query) => (dispatch) => {
  const { type, mode } = query;
  const actionText = mode === 'able' ? 'messages:participles:activated' : 'messages:participles:paused';
  ableScheduleReportCall(query)
    .then(({ status }) => {
      dispatch(listScheduleReports({ type, isReset: true }));
      dispatch(
        notificationOperations.successNotification({
          title: MESSAGES_NOTIFICATIONS(actionText, 'entitiesNames:Report')[status],
          message: '',
        })
      );
    })
    .catch((error) => {
      const statusCode = getStatusCode(error);
      dispatch(
        notificationOperations.errorNotification({
          title: 'actions:Error',
          message: MESSAGES_NOTIFICATIONS(actionText, 'ReportPages:ScheduledReport')[statusCode],
        })
      );
    });
};

const deleteScheduledReportRequest = (ids, type) => {
  return (dispatch) => {
    dispatch(actions.deleteSavedReportsRequested(ids));
    Promise.all(ids.map((id) => deleteScheduledReport({ id })))
      .then(([data]) => {
        const { status } = data;
        listScheduleReports({ type, isReset: true })(dispatch);
        dispatch(actions.deleteSavedReportsSucceeded(ids));
        dispatch(
          notificationOperations.successNotification({
            title: '',
            message: MESSAGES_NOTIFICATIONS('messages:participles:removed', 'ReportPages:ScheduledReport')[status],
          })
        );
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.deleteSavedReportsFailed({ ids, error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:DeleteEntity', 'ReportPages:ScheduledReport')[statusCode],
          })
        );
      });
  };
};

const saveRenameReport = ({ name, reportId = '', setRenameInfo, renameInfo }) => {
  return (dispatch) => {
    const params = {
      name,
      type: renameInfo.type,
      id: reportId,
    };
    saveRenameReportRequest(params)
      .then(({ data, status }) => {
        dispatch(actions.createReportSucceeded({ id: data }));
        dispatch(
          notificationOperations.successNotification({
            title: '',
            message: MESSAGES_NOTIFICATIONS('messages:participles:saved', 'entitiesNames:Report', name)[status],
          })
        );
        listSavedReportsRequest({ type: renameInfo.type })(dispatch);
        setRenameInfo();
      })
      .catch((error) => dispatch(actions.createReportFailed({ query: params, error })));
  };
};

export default {
  generateInstantReportRequest,
  generateComparativeReportRequest,
  generateUltrasonicReportRequest,
  generateControlReportRequest,
  changeMeasureReportRequest,
  sendReportToEmailRequest,
  saveReferenceValueRequest,
  setReferenceValuesRequest,
  listSavedReportsRequest,
  listReferenceValues,
  deleteReferenceValueRequest,
  deleteSavedReportRequest,
  deleteScheduledReportRequest,
  getSavedReportRequest,
  generateAuditSignaturesReportRequest,
  downloadSignatureAuditsRequest,
  getAssignmentReportRequest,
  getSignatureAuditRequest,
  generateAdvancedReportRequest,
  saveReportRequestNew,
  saveScheduledReport,
  saveRenameReport,
  listScheduleReports,
  listScheduleReportFiles,
  getScheduleReportFile,
  ableScheduleReportRequest,
};
