import styled from 'styled-components';

const DatesStyles = styled.div`
  display: flex;
  margin-top: 6px;

  p {
    margin: 5px 12px 5px 0px;
    color: ${(props) => props.theme.colors.secondary};
  }
  span {
    margin-right: 10px;
    color: #8a8eab;
    display: flex;
    align-items: center;

    span {
      margin-right: 4px;
    }
  }
`;

export { DatesStyles };
