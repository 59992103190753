import styled, { css } from 'styled-components';
import DatePickerLib from 'react-datepicker';

const WidthContainer = (width) => css`
  width: ${`${width}%`};
`;

const ContainerStyles = styled.div`
  width: auto;
  ${({ width }) => WidthContainer(width)};
  display: flex;
  flex-direction: column;
  position: relative;
  margin: ${({ noSeparation }) => (noSeparation ? '0px' : '12px 0')};

  .react-datepicker-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .react-datepicker {
    box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15), 0 5px 20px 0 rgba(21, 27, 38, 0.08);

    .react-datepicker__triangle {
      border-bottom-color: #fff;
    }
  }

  .react-datepicker-popper {
    z-index: 10;
  }

  .react-datepicker__header {
    background: #fff;
    color: #ccc;
    font-size: 10px;
  }

  .react-datepicker__day--selected {
    background: ${(props) => props.theme.colors.primary};
  }

  span {
    position: absolute;
    z-index: 9;
    right: 4px;
    bottom: 2px;
    color: #c3c3c3;
    display: ${(props) => (props.noIcon ? 'none' : 'initial')};
  }
`;

const DataPickerStyles = styled(DatePickerLib)`
  font-size: 0.8rem;
  padding-left: 10px;
  border: 1px solid #e0e1e6;
  border-radius: 4px;
  height: 38px;
  width: 100%;
  background: #fafbfc;

  :focus {
    outline: 0;
    border: 1px solid ${(props) => props.theme.colors.focusField};
  }

  :disabled {
    cursor: no-drop;
    opacity: 0.6;
  }

  :hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  }

  :required {
    border: 1px solid ${(props) => props.theme.colors.danger};
  }
`;

export { DataPickerStyles, ContainerStyles };
