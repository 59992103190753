import React from 'react';
import PropTypes from 'prop-types';

const EmptyMessages = ({ text }) => {
  return <div>{text}</div>;
};

EmptyMessages.propTypes = { text: PropTypes.string };

EmptyMessages.defaultProps = { text: '' };
export default EmptyMessages;
