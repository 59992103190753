const enagasOptions = (t) => [
  {
    to: '/monitoring',
    text: t('menu:monitoring'),
    iconName: 'icon-grid',
    className: 'beta',
    id: 8,
  },
];

export default enagasOptions;
