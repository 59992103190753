import formatNumber from './format';

const EXP_MAP = {
  0: '⁰',
  1: '¹',
  2: '²',
  3: '³',
  4: '⁴',
  5: '⁵',
  6: '⁶',
  7: '⁷',
  8: '⁸',
  9: '⁹',
};
const numberToScientificNotation = (value, expNumber) => {
  if (!value) return value;
  const valueExp = value.toExponential(expNumber);
  const valueLeft = valueExp.split('e+')[0];
  const exp = valueExp.split('e+')[1];
  return exp
    ? `${formatNumber(valueLeft, undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })} * 10${exp.split('').reduce((acc, val) => {
        return `${acc}${EXP_MAP[String(val)]}`;
      }, '')}`
    : valueExp;
};

const getNumberToScientificNotation = (value) => {
  return Math.log10(value) >= 3 ? numberToScientificNotation(value) : value;
};
export default getNumberToScientificNotation;
