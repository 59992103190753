import { useRef } from 'react';

import { equals } from 'libs/toolkits';

function useDeepCompareMemoize(value) {
  const ref = useRef(value);

  if (!equals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export default useDeepCompareMemoize;
