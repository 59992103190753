import React, { useRef } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from '../icons';
import { useOutsideClick } from '../../components/hooks';
import { SCBoxSearch, ResultSearch } from './styles';

const MetaSearch = function ({ onChange, showSearch, showResults, inputValue, content, onClickOutSide, ...props }) {
  const { t } = useTranslation();
  const ref = useRef();

  useOutsideClick(ref, onClickOutSide, showResults);

  return (
    <SCBoxSearch ref={ref} {...props} showSearch={showSearch} showResults={showResults}>
      <span className="icon-search">
        <Icon iconName="icon-search" />
      </span>
      <Input
        placeholder={t('commonTexts:introduceSearch')}
        allowClear
        onChange={({ target: { value } }) => {
          onChange(value);
        }}
        value={inputValue}
      />

      <ResultSearch className="results-search" showResults={showResults}>
        {content}
      </ResultSearch>
    </SCBoxSearch>
  );
};

MetaSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  content: PropTypes.instanceOf(Element),
  showSearch: PropTypes.bool.isRequired,
  showResults: PropTypes.bool.isRequired,
  onClickOutSide: PropTypes.func,
};
MetaSearch.defaultProps = {
  content: '',
  onClickOutSide: () => {},
};

export default MetaSearch;
