import useHandleSearch from './useHandleSearch';
import useSelect from './useSelect';

const useAsyncOptinsOnType = ({
  onChangeSelect,
  useSearchEntities,
  keyId,
  labelKey,
  debounceTime,
  isMulti = false,
  initialValue,
  fillParams,
}) => {
  const handleTypeInSelect = useSearchEntities(fillParams);
  const handleOptions = useHandleSearch({ handleTypeInSelect, keyId, labelKey, debounceTime });
  const [selecteds, onChange] = useSelect(initialValue, onChangeSelect);

  return [onChange, handleOptions, selecteds];
};

export default useAsyncOptinsOnType;
