import React from 'react';
import PropTypes from 'prop-types';
import RowFlex from '../common';

const Wrapper = ({ isNew, children }) => {
  return isNew ? <RowFlex justify="space-between">{children}</RowFlex> : <>{children}</>;
};
Wrapper.propTypes = {
  isNew: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Wrapper.defaultProps = {
  isNew: false,
};
export default Wrapper;
