import * as React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
import { Spinner } from 'ui';

import { FormulaProvider } from 'components/hooks';
import Alerts from './Home';

const AlertsRoutes = () => {
  const { path } = useRouteMatch();
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      entitySelected: 'mu',
      gradientPeriod: { label: '15 min', value: 15 },
      selectMeasureunits: undefined,
      selectLines: undefined,
      gradientValue: undefined,
      thresholdMax: undefined,
      thresholdMin: undefined,
      pcsValue: undefined,
      selectVariable: null,
      formula: [],
      active: true,
      consolidationTime: 5,
    },
  });

  return (
    <React.Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path={path}>
          <Redirect to="/alerts/incidences" />
        </Route>
        <Route path={[`${path}/:type/:id`, `${path}/:type`]}>
          <FormulaProvider>
            <FormContext {...methods}>
              <Alerts />
            </FormContext>
          </FormulaProvider>
        </Route>
      </Switch>
    </React.Suspense>
  );
};

export default AlertsRoutes;
