import React from 'react';
import PropTypes from 'prop-types';

import AvatarImage from 'assets/images/avatar.svg';
import RowFlex from '../common';
import Popover from '../popover';
import Text from '../tipography/text';

import { AvatarStyles, MenuAccount, Options } from './styles';

const Avatar = ({ photo, name, options }) => {
  const photoUrl = photo || AvatarImage;

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      content={
        <MenuAccount>
          <RowFlex align="center" direction="column">
            <img src={photoUrl} alt="" />
            <Text>{name}</Text>
          </RowFlex>
          <Options>{options}</Options>
        </MenuAccount>
      }
    >
      <AvatarStyles src={photoUrl} alt="Mi cuenta" />
    </Popover>
  );
};
Avatar.propTypes = {
  options: PropTypes.arrayOf(PropTypes.node),
  name: PropTypes.string,
  photo: PropTypes.string,
};

Avatar.defaultProps = {
  options: [],
  name: '',
  photo: '',
};
export default Avatar;
