const ALERT_TYPES_OPTIONS = (t) => [
  {
    label: t('AlertPage:types:threshold'),
    value: 'threshold',
  },
  {
    label: t('AlertPage:types:gradient'),
    value: 'gradient',
  },
  {
    label: t('AlertPage:types:delta_pcs'),
    value: 'delta_pcs',
  },
];
const INCIDENCES_TYPES_OPTIONS = (t) => [
  {
    label: t('AlertPage:types:footprint_chroma'),
    value: 'footprint_chroma',
  },
  {
    label: t('AlertPage:types:checksum_flowcomputer'),
    value: 'checksum_flowcomputer',
  },
  {
    label: t('AlertPage:types:checksum_chroma'),
    value: 'checksum_chroma',
  },
];

const INCIDENCES_OPTIONS = (t) => [
  {
    label: t('AlertPage:status:open'),
    value: 'open',
  },
  {
    label: t('AlertPage:status:closed'),
    value: 'closed',
  },
];

const ALERTS_TYPES_OPTIONS_TABLE = (t, type) => {
  return [
    {
      label: t('AlertPage:types:all'),
      value: '',
    },
    ...ALERT_TYPES_OPTIONS(t),
    ...(type === 'incidence' ? INCIDENCES_TYPES_OPTIONS(t) : []),
  ];
};

const INCIDENCES_OPTIONS_TABLE = (t) => {
  return [
    {
      label: t('AlertPage:status:all'),
      value: '',
    },
    ...INCIDENCES_OPTIONS(t),
  ];
};

const DEFAULT_VALUES = {
  entitySelected: 'mu',
  gradientPeriod: { label: '15 min', value: 15 },
  selectMeasureunits: undefined,
  selectLines: undefined,
  gradientValue: undefined,
  thresholdMax: undefined,
  thresholdMin: undefined,
  pcsValue: undefined,
  selectVariable: null,
  formula: [],
  active: true,
  consolidationTime: 5,
};

const GRADIENT_PERIODS_OPTIONS = [
  { label: '15 min', value: 15 },
  { label: '30 min', value: 30 },
  { label: '1 hora', value: 60 },
];

export {
  ALERT_TYPES_OPTIONS,
  DEFAULT_VALUES,
  ALERTS_TYPES_OPTIONS_TABLE,
  GRADIENT_PERIODS_OPTIONS,
  INCIDENCES_OPTIONS,
  INCIDENCES_OPTIONS_TABLE,
};
