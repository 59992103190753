import styled from 'styled-components';

const HeaderDetailsStyles = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 12px 20px 0;
  border-top: 1px solid #e8e8e8;
  width: 100%;

  h4 {
    font-weight: bold;
    margin-bottom: 0px;
    margin-right: 5px;

    span {
      margin: 0 10px;
    }
  }

  button {
    margin: 0 4px;
  }
`;

export { HeaderDetailsStyles };
