import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TableWithActions } from 'ui';
import { operations, selectors } from 'modules/alerts';
import { useHandleActionsTable, useDeepCompareMemoize } from 'components/hooks';
import { createIncidencesColumns } from 'config/table/alerts';
import { useFiltersTable } from './hooks';

const PAGE_SIZE = Math.floor((window.innerHeight - 200 - 20 - 43) / 43);

const AlertsHistoryTable = ({ handleToogleSidebar }) => {
  const dispatch = useDispatch();
  const { infoSelected, FiltersComponent } = useFiltersTable({ type: 'incidence' });

  const { savedAlerts } = useSelector(
    (state) => ({
      savedAlerts: selectors.getAlertsGenerated(state),
    }),
    shallowEqual
  );

  const requestIncidences = useCallback((query) => {
    dispatch(
      operations.listHistoryAlertsRequest({
        ...query,
        ...(infoSelected.type && { type: infoSelected.type }),
        ...(infoSelected.status && { status: infoSelected.status }),
      })
    );
  }, useDeepCompareMemoize([dispatch, infoSelected]));

  const { handleOnChange, handleSearch } = useHandleActionsTable({
    listRequest: requestIncidences,
    initialPageSize: PAGE_SIZE,
  });

  const handleOnClickRow = useCallback(
    (
      event,
      {
        row: {
          original: { id },
        },
      }
    ) => {
      event.stopPropagation();
      handleToogleSidebar(id);
    },
    [handleToogleSidebar]
  );

  const { t } = useTranslation();

  return (
    <TableWithActions
      actionsComponents={TableWithActions.factoryActionsCreator({
        handleSearch,
        createText: t('actions:NewEntity', { entity: t('entitiesNames:Alert') }),
        children: FiltersComponent,
      })}
      height="calc(100vh - 220px)"
      isLoading={savedAlerts.status === 'loading'}
      filterBarWithFrame
      total={savedAlerts.total}
      pageSize={PAGE_SIZE}
      data={savedAlerts.entities}
      rowSelect={false}
      columns={TableWithActions.factoryActionsCreator.ROWS.generateActionRows(
        createIncidencesColumns(t, handleOnClickRow),
        TableWithActions.factoryActionsCreator.ROWS.defaultGeneratorActionRow({
          onShow: (id) => handleToogleSidebar(id),
          t,
        })
      )}
      onChange={handleOnChange}
    />
  );
};

AlertsHistoryTable.propTypes = {
  handleToogleSidebar: PropTypes.func.isRequired,
};

export default AlertsHistoryTable;
