import React from 'react';
import { ThemeProvider } from 'styled-components';

import themeDefault from './themeDefault';
import WithGlobalsStyles from './globalStyles';

export const themes = {
  themeDefault,
};

export const WithThemeStoryBook = (theme) => (story) => (
  <ThemeProvider theme={theme}>
    <>
      {story()}
      <WithGlobalsStyles />
    </>
  </ThemeProvider>
);

export const WithTheme = (theme) => ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      {children}
      <WithGlobalsStyles />
    </>
  </ThemeProvider>
);
