import styled from 'styled-components';

import { Collapse } from 'antd';

const { Panel } = Collapse;

const CollapseStyles = styled(Collapse)`
  .ant-collapse-item {
    margin-bottom: 14px;

    :last-child > .ant-collapse-header {
      border-radius: 6px;
    }
  }
  .ant-collapse-item > .ant-collapse-header {
    font-size: 1rem;
    color: #7171a6;
    font-weight: 600;
    background-color: #f7f7fc;
    border-radius: 6px;
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 6px;
  }

  .ant-collapse-header {
    box-shadow: 0 2px 5px 0 rgba(51, 51, 79, 0.07);
    border: 1px solid #e6e6ff;
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
    background-color: #f9f9f9;
  }
`;

const PanelStyles = styled(Panel)`
  .ant-collapse-content {
    overflow: initial;
    border: 1px solid #e6e6ff;
    border-radius: 6px;
  }
`;

export { CollapseStyles, PanelStyles };
