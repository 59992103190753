import React, { Suspense } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Spinner } from 'ui';

const Balances = React.lazy(() => import('./Balances'));

const BalancesRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route path={`${path}`}>
          <Balances />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default BalancesRoutes;
