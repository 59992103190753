import {
  SUCCESS_NOTIFICATION,
  ERROR_NOTIFICATION,
  WARNING_NOTIFICATION,
  INFO_NOTIFICATION,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  WARNING_MESSAGE,
  INFO_MESSAGE,
} from './types';

/*

{
  onRetry?
  message
  title
  id?
  onClose?
  placement: bottomRight
  duration: 4.5 // 0 not remove
  icon?
}

*/

const DURATION_NULL_TYPES = ['warning', 'error'];

const GENERATE_DEFAULT_PARAMS = () => ({
  onRetry: null,
  message: '',
  title: '',
  id: Math.random(),
  onClose: null,
  placement: 'bottomRight',
  duration: 4.5,
  icon: null,
});

const generateAction = (type, typeNotification) => (params) => {
  return {
    type,
    payload: {
      ...GENERATE_DEFAULT_PARAMS(),
      ...params,
      type: typeNotification,
      ...(DURATION_NULL_TYPES.includes(typeNotification) ? { duration: null } : {}),
    },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const successNotification = generateAction(SUCCESS_NOTIFICATION, 'success');
const errorNotification = generateAction(ERROR_NOTIFICATION, 'error');
const warningNotification = generateAction(WARNING_NOTIFICATION, 'warning');
const infoNotification = generateAction(INFO_NOTIFICATION, 'info');
const successMessage = generateAction(SUCCESS_MESSAGE, 'success');
const errorMessage = generateAction(ERROR_MESSAGE, 'error');
const warningMessage = generateAction(WARNING_MESSAGE, 'warning');
const infoMessage = generateAction(INFO_MESSAGE, 'info');

export default {
  successNotification,
  errorNotification,
  warningNotification,
  infoNotification,
  successMessage,
  errorMessage,
  warningMessage,
  infoMessage,
};
