import styled, { css, keyframes } from 'styled-components';

const helpAnimation = keyframes`
0% {
  opacity: 0;
  transform: translateY(-1.4em);
}
100% {
  opacity: 1;
  transform: translateY(0);
}
`;

const WidthContainer = (width?: string | number) => css`
  width: ${`${width}%`};
`;

const FormFielStyles = styled.div<{
  width?: string | number;
  noSeparation?: boolean;
}>`
  width: auto;
  ${({ width }) => WidthContainer(width)};
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column-reverse;
  margin: ${({ noSeparation }) => (noSeparation ? '0px' : '10px 0')};
  display: inline-flex;

  input:disabled + label {
    opacity: 0.5;
    cursor: no-drop;
  }

  input:focus + label {
    :after {
      content: '' attr(data-help) '';
      font-size: 12px;
      color: #20b5f3;
      margin-left: 5px;
      animation: ${helpAnimation} 4s;
    }
  }

  .react-select-container:disabled + label {
    opacity: 0.5;
    cursor: no-drop;
  }

  .react-select-container:focus + label {
    :after {
      content: '' attr(data-help) '';
      font-size: 12px;
      color: #20b5f3;
      margin-left: 5px;
      animation: ${helpAnimation} 4s;
    }
  }
`;

export { FormFielStyles };
