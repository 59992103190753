import styled from 'styled-components';
import { List } from './SearchBox';

const Notification = styled.div`
  min-width: 250px;
  padding-left: 10px;
`;

const ContainerNotifications = styled.div`
  height: 200px;
  overflow: auto;
  > div {
    border-top: 1px solid #eaeaea;
  }

  :first-child div {
    border-top: none;
  }
`;
const ContainerSpinner = styled.div`
  text-align: center;
`;
export { List, Notification, ContainerNotifications, ContainerSpinner };
