import types from './types';

const initialState = {
  name: '',
  photoURL: '',
  email: '',
  language: 'en',
  isOpenChangelog: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_USER_INFO:
      return {
        ...state,
        ...payload.user,
      };
    case types.CHANGE_LANGUAGE:
      return {
        ...state,
        language: payload,
      };
    case types.SHOW_CHANGELOG:
      return {
        ...state,
        isOpenChangelog: payload,
      };
    default:
      return state;
  }
};

export default reducer;
