import {
  SUCCESS_NOTIFICATION,
  ERROR_NOTIFICATION,
  WARNING_NOTIFICATION,
  INFO_NOTIFICATION,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  WARNING_MESSAGE,
  INFO_MESSAGE,
} from './types';

const initialState = {
  notification: null,
  message: null,
  notifications: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SUCCESS_NOTIFICATION:
    case ERROR_NOTIFICATION:
    case WARNING_NOTIFICATION:
    case INFO_NOTIFICATION:
      return {
        ...state,
        notification: payload,
        notifications: { ...(payload?.key ? { [payload.key]: { ...payload } } : {}) },
      };
    case SUCCESS_MESSAGE:
    case ERROR_MESSAGE:
    case WARNING_MESSAGE:
    case INFO_MESSAGE:
      return { ...state, message: payload };

    default:
      return state;
  }
};

export default reducer;
