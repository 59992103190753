import { useCallback } from 'react';
import { generateEntityQueries } from 'libs/request-utils';
import { transformFillResponseToSelectOptions } from './utils';

const { searchEntities, fillEntities } = generateEntityQueries({
  nameForLists: 'branch',
});

const useSearchBranches = () =>
  useCallback((text) => {
    return searchEntities({
      params: { q: text, order: 'ASC', limit: 20, ho: 0 },
    });
  }, []);

const useFillBranches = ({ type }) => {
  return fillEntities({
    params: { type },
  })
    .then(({ data }) => {
      if (type === 'section') return transformFillResponseToSelectOptions(data.sections);
      return transformFillResponseToSelectOptions(data.branches);
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export { useSearchBranches, useFillBranches };
