import types from './types';

const initialState = {
  isOnError: false,
  isLoading: false,
  error: null,
  result: [],
  averagesResult: '',
  measurementUnit: '',
  dates: {
    startDate: '',
    endDate: '',
  },
  variablesSelected: [],
  requestCall: '',
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case types.SHOW_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isOnError: !!error,
      };
    case types.SHOW_SUCCEEDED:
      return {
        ...state,
        ...payload,
        isLoading: false,
        isOnError: !!error,
      };
    case types.SHOW_SUCCEEDED_AVERAGES:
      return {
        ...state,
        ...payload,
        isOnError: !!error,
      };

    case types.CLEAN_AVERAGES:
      return {
        ...state,
        averagesResult: '',
      };
    case types.SHOW_FAILED:
      return {
        ...state,
        error: payload.error,
        isLoading: false,
        isOnError: !!error,
      };
    case types.SET_ENTITY_ID:
      return {
        ...state,
        measurementUnit: payload.measurementUnit,
        error: payload.error,
        isOnError: !!error,
      };
    case types.SET_DATE_RANGE:
      return {
        ...state,
        ...payload,
        error: payload.error,
        isOnError: !!error,
      };

    case types.SET_REQUEST_CALL:
      return {
        ...state,
        ...payload,
      };

    case types.RESET_STORE:
      return { ...initialState, dates: state.dates };

    default:
      return state;
  }
};

export default reducer;
