import React from 'react';
import { useSelector } from 'react-redux';
import generatePicker from 'antd/es/date-picker/generatePicker';
import esEs from 'antd/es/locale/es_ES';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { selectors as selectorsAuth } from 'modules/auth';
import { subDays, getDateFormated } from 'libs/date';
import { Label } from '../form';
import { RangePickerStyles } from './styles';
import { CONFIG_BUTTONS } from './config';

esEs.DatePicker.lang.locale = 'es';

const DatePickerFns = generatePicker(dateFnsGenerateConfig);

const RangePicker = ({
  dates,
  format,
  setDates,
  setDatesMethod,
  maxDate,
  minDate,
  disabledDate,
  options,
  textLabel,
  name,
  errors,
  ...props
}) => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => ({ language: selectorsAuth.getLanguage(state) }));

  if (!setDates && !setDatesMethod) return '';

  const limitDefaultMaxDate = () => new Date(getDateFormated(subDays(new Date(), -2), 'yyyy-MM-dd 00:00:00'));
  const setDatesAction = ({ startDate, endDate }) => {
    if (setDates) {
      const [setStartDate, setEndDate] = setDates;
      setStartDate(startDate);
      setEndDate(endDate);
    } else {
      setDatesMethod({ startDate, endDate });
    }
  };

  const getButtons = (opts) => opts.reduce((acc, opt) => ({ ...acc, [t(`dates:${opt}`)]: CONFIG_BUTTONS[opt]() }), {});

  return (
    <ConfigProvider locale={esEs}>
      <RangePickerStyles>
        <Label {...{ textLabel }} />
        <DatePickerFns.RangePicker
          {...props}
          value={dates}
          format={format}
          ranges={options && options.length ? getButtons(options) : []}
          disabledDate={
            disabledDate ||
            ((date) => {
              const dateDefaultMax = limitDefaultMaxDate();
              if (maxDate && minDate) {
                return date < minDate || date > maxDate;
              }
              if (minDate) {
                return date < minDate || date > dateDefaultMax;
              }
              if (maxDate) {
                return date > maxDate;
              }
              return date > dateDefaultMax;
            })
          }
          onChange={(dts) => {
            if (dts && dts.length) {
              const [startDate, endDate] = dts;
              setDatesAction({ startDate, endDate });
            } else {
              setDatesAction({ startDate: undefined, endDate: undefined });
            }
            return dts;
          }}
        />
      </RangePickerStyles>
    </ConfigProvider>
  );
};

RangePicker.propTypes = {
  textLabel: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  dates: PropTypes.arrayOf(PropTypes.any),
  setDates: PropTypes.arrayOf(PropTypes.func).isRequired,
  setDatesMethod: PropTypes.arrayOf(PropTypes.func),
  disabledDate: PropTypes.arrayOf(PropTypes.func),
  errors: PropTypes.arrayOf(PropTypes.any).isRequired,
  format: PropTypes.string,
  locale: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
};

RangePicker.defaultProps = {
  options: [],
  setDatesMethod: undefined,
  disabledDate: undefined,
  locale: 'es',
  dates: [subDays(new Date(), 7), new Date()],
  format: 'dd/MM/yyyy',
  maxDate: '',
  minDate: '',
};
export default RangePicker;
