import { css } from 'styled-components';

export default {
  alignActionsBar: {
    between: css`
      justify-content: space-between;
    `,
    end: css`
      justify-content: flex-end;
    `,

    start: css`
      justify-content: flex-start;
    `,

    center: css`
      justify-content: center;
    `,
  },
};
