import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select, RowFlex, Text, RadioButton } from 'ui';
import { RowSelection, Section } from '../styles';
import { withSearchEntities } from '../../Selectors';

const SelectMeasure = withSearchEntities({ type: 'measuresSearch' });

const SelectsUmLines = ({
  errors,
  control,
  getValues,
  entitySelected,
  optionsLines,
  linesSelecteds,
  setValue,
  alertType,
  operationsRef,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Section>
        <RowFlex align="center">
          <Text strong>{t('components:alerts:section-select-text')}</Text>
          <Controller
            as={
              <RadioButton
                variant="colored"
                name="entitySelected"
                id="mu"
                textLabel={t('components:alerts:entitySelected-label')}
                checked={entitySelected === 'mu'}
              />
            }
            control={control}
            onChange={() => {
              if (entitySelected !== 'mu') {
                // eslint-disable-next-line no-param-reassign
                operationsRef.current = [];
                setValue('selectVariable', null);
              }
              return 'mu';
            }}
            name="entitySelected"
          />
          <Controller
            as={
              <RadioButton
                variant="colored"
                id="line"
                name="entitySelected"
                textLabel={t('components:alerts:lines-label')}
                disabled={Boolean(alertType && alertType.value === 'delta_pcs')}
                checked={entitySelected === 'line'}
              />
            }
            control={control}
            onChange={() => {
              if (entitySelected !== 'line') {
                // eslint-disable-next-line no-param-reassign
                operationsRef.current = [];
                setValue('selectVariable', null);
              }
              return 'line';
            }}
            name="entitySelected"
          />
        </RowFlex>
      </Section>
      <RowSelection>
        <Text color="primary" size="small">
          {entitySelected === 'line' ? t('components:alerts:line-title') : ''}
        </Text>
        <RowFlex justify="space-between" align="flex-start">
          <Controller
            as={
              <SelectMeasure
                textLabel={t('components:alerts:measurementUnits-select-label')}
                placeholder={t('components:alerts:measurementUnits-select-placeholder')}
                width={48}
                name="selectMeasureunits"
                isMulti
                outSideSelecteds
                errors={errors}
                fillParams={{ is_brick: true, ...(entitySelected !== 'line' && { croma: true }) }}
              />
            }
            control={control}
            rules={{
              validate: (value) => {
                return (
                  Boolean(value && value.length) ||
                  t('forms:validations:required', { field: t('forms:fields:measures') })
                );
              },
            }}
            onChange={([selected]) => {
              return selected;
            }}
            name="selectMeasureunits"
          />

          {entitySelected === 'line' && (
            <Controller
              as={
                <Select
                  textLabel={t('components:alerts:lines-select-label')}
                  placeholder={t('components:alerts:lines-select-placeholder')}
                  isMulti
                  outSideSelecteds
                  isSearchable
                  width={48}
                  options={optionsLines}
                  name="selectLines"
                  hadleOnRemove={(id) => {
                    const filterdLines = linesSelecteds.filter(({ value }) => value !== id);
                    setValue('selectLines', filterdLines);
                  }}
                  errors={errors}
                />
              }
              control={control}
              rules={{
                validate: (value) => {
                  const currentValues = getValues();
                  if (Boolean(currentValues.entitySelected === 'mu')) {
                    return true;
                  }
                  return (
                    Boolean(value && value.length) || t('forms:validations:required', { field: t('forms:fields:line') })
                  );
                },
              }}
              onChange={([selected]) => {
                return selected;
              }}
              name="selectLines"
            />
          )}
        </RowFlex>
      </RowSelection>
    </>
  );
};

SelectsUmLines.propTypes = {
  alertType: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  getValues: PropTypes.func.isRequired,
  entitySelected: PropTypes.string.isRequired,
  optionsLines: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  linesSelecteds: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  setValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  operationsRef: PropTypes.object.isRequired,
};

SelectsUmLines.defaultProps = {
  alertType: undefined,
  linesSelecteds: undefined,
};

export default SelectsUmLines;
