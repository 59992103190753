import { useCallback } from 'react';

const useSelect = (value, onChangeSelect = () => {}) => {
  const handleOnChange = useCallback(
    (itemSelected, actionInfo = {}) => {
      if (actionInfo.action === 'select-option') {
        onChangeSelect(itemSelected);
      }
      if (actionInfo.action === 'remove-value') {
        onChangeSelect(itemSelected);
      }
      if (actionInfo.action === 'clear') {
        onChangeSelect(null);
      }
    },
    [onChangeSelect]
  );
  return [value, handleOnChange];
};

export default useSelect;
