import React from 'react';

import { useTranslation } from 'react-i18next';
import Tag from './Tag';
import { WrapperTag } from './styles';

interface ContainerTagOption {
  value: any;
  label: string;
}

interface ContainerProps {
  tagsOptions: ContainerTagOption[];
  onClose?: (v: any, t: ContainerTagOption[]) => any;
  name: string;
}

const ContainerTags: React.FC<ContainerProps> = ({ tagsOptions = [], onClose, name }) => {
  const { t } = useTranslation();
  return (
    <WrapperTag>
      {tagsOptions.map((s) => (
        <Tag key={`${s.value}-${name}`} closable onClose={() => onClose && onClose(s.value, tagsOptions)}>
          {t(s.label)}
        </Tag>
      ))}
    </WrapperTag>
  );
};

export type { ContainerProps, ContainerTagOption };

export default ContainerTags;
