import { generateEntityQueries } from 'libs/request-utils';

const { createEntity: uploadLinearSchema } = generateEntityQueries({
  nameForLists: 'linearSchema',
});

const uploadLinearSchemaRequest = (body) => {
  const formData = new FormData();

  formData.append('entityId', body.entityId);
  formData.append('entityType', body.entityType);

  if (body.files.length) {
    formData.append('length', body.files.length);

    Array.from(body.files).forEach((file) => {
      formData.append('file', file);
      formData.append('filename', file.name);
    });
  }

  return uploadLinearSchema({ body: formData, headers: { 'content-type': 'multipart/form-data' } });
};

export { uploadLinearSchemaRequest };
