import { operations as notificationOperations } from 'modules/notifications';
import { actions as actionsDashboard } from '../dashboard';
import actions from './actions';
import { getConfigUser, addGraphUser, deleteGraphUser } from './api';

const getConfigUserRequest = () => {
  return (dispatch) => {
    dispatch(actions.showConfigUserRequested());

    getConfigUser()
      .then(({ data }) => {
        dispatch(actions.showConfigUserSucceeded(data));
      })
      .catch((error) => {
        dispatch(actions.showConfigUserFailed({ error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: 'messages:request-interrupted',
          })
        );
      });
  };
};

const addGraphConfigUserRequest = (data) => {
  return (dispatch) => {
    addGraphUser(data)
      .then(() => {
        dispatch(getConfigUserRequest());
      })
      .catch(() => {
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: 'messages:request-interrupted',
          })
        );
      });
  };
};
const deleteGraphConfigUserRequest = (data) => {
  return (dispatch) => {
    deleteGraphUser(data)
      .then(() => {
        dispatch(getConfigUserRequest());
        dispatch(actionsDashboard.cleanGraphsDashboard());
      })
      .catch(() => {
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: 'messages:request-interrupted',
          })
        );
      });
  };
};

export default { addGraphConfigUserRequest, getConfigUserRequest, deleteGraphConfigUserRequest };
