import styled, { css } from 'styled-components';
import { Typography } from 'antd';

const { Text, Title, Paragraph } = Typography;

const TitleStyles = styled(Title)<{
  align?: 'left' | 'center' | 'right';
  color?: 'primary' | 'success' | 'danger' | 'ghost' | 'white' | 'warning' | 'light' | 'secondary';
}>`
  font-family: 'Lato';
  ${({ align, theme }) => (align ? theme.tipography.align[align] : '')};
  ${({ color, theme }) => (color ? theme.tipography.color[color] : '')};

  color: ${(props) => props.theme.colors.primary};

  h2.ant-typography,
  .ant-typography h2 {
    color: ${(props) => props.theme.colors.primary}!important;
  }
`;

const ParagraphStyles = styled(Paragraph)<{
  align: 'left' | 'center' | 'right';
}>`
  font-family: 'Lato';
  ${({ align, theme }) => theme.tipography.align[align]};
  font-size: 14px;
  line-height: 19px;
`;

const TextStyles = styled(Text)<{
  align: 'left' | 'center' | 'right';
  color?: 'primary' | 'success' | 'danger' | 'ghost' | 'white' | 'warning' | 'light' | 'secondary';
  textTransform: string;
  inline?: boolean;
  size: 'xsmall' | 'small' | 'medium' | 'large';
}>`
  font-family: 'Lato';
  line-height: 1.6em;
  font-size: 0.9em;
  color: #444;
  display: ${({ inline }) => (inline ? 'inline-block' : 'flex')};
  align-items: center;
  margin-right: ${({ inline }) => (inline ? '4px' : '0')};
  margin-bottom: 0px;

  ${({ size, theme }) => theme.tipography.size[size]};
  ${({ color, theme }) => (color ? theme.tipography.color[color] : '')};
  ${({ align, theme }) => theme.tipography.align[align]};

  a {
    margin-left: 4px;
  }

  ${({ textTransform }) =>
    textTransform
      ? css`
          text-transform: ${textTransform};
        `
      : ''};

  mark {
    color: white;
    background-color: ${(props) => props.theme.colors.primary};
    padding: 0 3px;
    border-radius: 2px;
    margin: 0;
  }
`;

export { TitleStyles, TextStyles, ParagraphStyles };
