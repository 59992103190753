import React from 'react';
import PropTypes from 'prop-types';

import { LabelStyles } from './styles';

const Label = ({ textLabel, dataHelp, htmlFor }) =>
  textLabel ? (
    <LabelStyles data-help={dataHelp} htmlFor={htmlFor}>
      {textLabel}
    </LabelStyles>
  ) : null;

Label.propTypes = {
  textLabel: PropTypes.string,
  dataHelp: PropTypes.string,
  htmlFor: PropTypes.string,
};

Label.defaultProps = {
  textLabel: '',
  dataHelp: '',
  htmlFor: '',
};

export default Label;
