import React from 'react';
import PropTypes from 'prop-types';

import { Spinner as SpinnerStyles } from './styles';

const Spinner = ({ size }) => (
  <SpinnerStyles>
    <div />
    <div />
  </SpinnerStyles>
);

Spinner.propTypes = {
  size: PropTypes.number,
};

export default Spinner;
