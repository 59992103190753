import React from 'react';

import { ItemStyles } from './styles';

const Summary = ({ number, title, state }) => (
  <ItemStyles {...{ state }}>
    <h3>{number}</h3>
    <p>{title}</p>
  </ItemStyles>
);

export default Summary;
