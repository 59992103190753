import { createTypesCrud } from 'libs/generate-redux-utils/generate-types';

const CHANGE_PAGINATE = '@MONITORING/CHANGE_PAGINATE';
const CHANGE_SELECTED_MONITORING = '@MONITORING/CHANGE_SELECTED_MONITORING';
const ADD_SUMMARY = '@MONITORING/ADD_SUMMARY';

export default {
  ...createTypesCrud('monitoring'),
  ADD_SUMMARY,
  CHANGE_PAGINATE,
  CHANGE_SELECTED_MONITORING,
};
