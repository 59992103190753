import React, { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TableWithActions } from 'ui';
import { operations, selectors } from 'modules/alerts';
import { useHandleActionsTable, useDeepCompareMemoize } from 'components/hooks';
import { createAlertsSaveColumns } from 'config/table/alerts';
import { useFiltersTable } from './hooks';

const PAGE_SIZE = Math.floor((window.innerHeight - 220 - 20 - 43) / 43);

const AlertsSavedTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { infoSelected, FiltersComponent } = useFiltersTable({ type: 'alert' });

  const { savedAlerts } = useSelector(
    (state) => ({
      savedAlerts: selectors.getAlertsSaved(state),
    }),
    shallowEqual
  );

  const requestSavedAlerts = useCallback((query) => {
    dispatch(
      operations.listSavedAlertsRequest({
        ...query,
        ...(Boolean(infoSelected.type) && { type: infoSelected.type }),
        ...(Boolean(infoSelected.measurementUnits) && { measurementUnits: infoSelected.measurementUnits }),
      })
    );
  }, useDeepCompareMemoize([dispatch, infoSelected]));

  const requestDeleteAlerts = useCallback(
    (ids, query) => {
      dispatch(operations.deleteAlertRequest(ids, query));
    },
    [dispatch]
  );

  const { handleOnChange, handleRemove, handleSearch } = useHandleActionsTable({
    listRequest: requestSavedAlerts,
    deleteRequest: requestDeleteAlerts,
    initialPageSize: PAGE_SIZE,
  });

  return (
    <TableWithActions
      actionsComponents={TableWithActions.factoryActionsCreator({
        handleSearch,
        handleDelete: handleRemove,
        handleOnCreate: () => history.push('/alerts/saved/new'),
        createText: t('actions:NewEntityF', { entity: t('entitiesNames:Alert') }),
        children: FiltersComponent,
      })}
      height="calc(100vh - 280px)"
      filterBarWithFrame
      isLoading={savedAlerts.status === 'loading'}
      total={savedAlerts.total}
      pageSize={PAGE_SIZE}
      data={savedAlerts.entities}
      columns={TableWithActions.factoryActionsCreator.ROWS.generateActionRows(
        createAlertsSaveColumns(t),
        TableWithActions.factoryActionsCreator.ROWS.defaultGeneratorActionRow({
          onDelete: handleRemove,
          urlDetails: '/alerts/saved',
          t,
        })
      )}
      onChange={handleOnChange}
    />
  );
};

export default AlertsSavedTable;
