import { css } from 'styled-components';

export default {
  position: {
    left: css`
      right: 0;
    `,
    right: css`
      left: 0;
    `,
  },
};
