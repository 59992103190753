import { createTypesCrud } from 'libs/generate-redux-utils/generate-types';

const ALERTS_SAVED = createTypesCrud('alerts', 'alerts_saved');
const ALERTS_GENERATED = createTypesCrud('alerts', 'alerts_generated');
const ALERTS_TEMPLATES = createTypesCrud('alerts', 'alerts_templates');

const actionTypes = {
  ALERTS_SAVED,
  ALERTS_GENERATED,
  ALERTS_TEMPLATES,
};

export default actionTypes;
