/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/display-name */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table';
import { TableStyles, TheadStyles, SCPagination } from './styles';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.any,
};

IndeterminateCheckbox.defaultProps = {
  indeterminate: null,
};

const Table = ({ columns, data, sortBy, pagination, paginationInfo }) => {
  const extraInfoTable = [sortBy ? useSortBy : null, pagination ? usePagination : null].filter((f) => f);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    pageCount,
    state: { pageIndex },
  } = useTable(
    {
      columns: React.useMemo(() => columns, [columns]),
      data: React.useMemo(() => data, [data]),
      manualPagination: true,
      state: { pageIndex: 0, pageSize: 10 },
      pageCount: paginationInfo.total === 0 ? 0 : Math.round(paginationInfo.total / paginationInfo.pageSize),
    },
    useRowSelect,
    (hooks) => {
      hooks.columns.push((col) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />,
          Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
        },
        ...col,
      ]);
    },
    ...extraInfoTable
  );

  const handleOnNextPage = useCallback(() => {
    nextPage();
  }, [nextPage]);

  const renderRows = pagination ? page : rows;

  return (
    <>
      <TableStyles {...getTableProps()}>
        <TheadStyles>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(sortBy ? column.getSortByToggleProps() : [])}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </TheadStyles>

        <tbody {...getTableBodyProps()}>
          {renderRows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </TableStyles>

      <SCPagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={handleOnNextPage}
        pageIndex={pageIndex}
        pageCount={pageCount}
        pageOptions={pageOptions}
      />
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accesor: PropTypes.string,
      Cell: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  sortBy: PropTypes.bool,
  pagination: PropTypes.bool,
  paginationInfo: PropTypes.shape({
    init: PropTypes.number,
    end: PropTypes.number,
    total: PropTypes.number,
  }),
  actions: PropTypes.shape({
    previousPage: PropTypes.func,
    nextPage: PropTypes.func,
    selectRows: PropTypes.func,
  }),
};

Table.defaultProps = {
  sortBy: false,
  pagination: false,
  paginationInfo: {},
  actions: {},
};

export { Table, TableStyles };
