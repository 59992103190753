import { createTypesCrud } from 'libs/generate-redux-utils/generate-types';

const SET_DATE_RANGE = '@measures/SET_DATE_RANGE';
const SET_REQUEST_CALL = '@measures/SET_REQUEST_CALL';
const RESET_STORE = '@measures/RESET_STORE';
const SHOW_SUCCEEDED_AVERAGES = '@measures/SHOW_SUCCEEDED_AVERAGES';
const CLEAN_AVERAGES = '@measures/CLEAN_AVERAGES';
export default {
  ...createTypesCrud('measures'),
  SHOW_SUCCEEDED_AVERAGES,
  SET_DATE_RANGE,
  SET_REQUEST_CALL,
  RESET_STORE,
  CLEAN_AVERAGES,
};
