import React from 'react';
import PropTypes from 'prop-types';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import Icon from '../icons';
import Label from '../form/label';

import { DataPickerStyles, ContainerStyles } from './styles';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es', es);
setDefaultLocale('es');

const DatePicker = ({ width, noIcon, textLabel, noSeparation, ...props }) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <ContainerStyles {...{ width, noIcon, noSeparation }}>
      {textLabel ? <Label {...{ textLabel }} /> : null}
      <Icon iconName="icon-calendar" size={34} />
      <DataPickerStyles {...props} />
    </ContainerStyles>
  );
};

DatePicker.propTypes = {
  locale: PropTypes.string,
  dateFormat: PropTypes.string,
  noIcon: PropTypes.bool,
  noSeparation: PropTypes.bool,
};

DatePicker.defaultProps = {
  locale: 'es',
  dateFormat: 'dd/MM/yyyy',
  noIcon: false,
  noSeparation: false,
};

export default DatePicker;
