import { STATUS } from './status-reducers';

const actionCreateRequestedCreator = (type) => (params) => {
  return {
    type,
    payload: { status: STATUS.LOADING },
    error: false,
    meta: {
      timestamp: new Date(),
      params,
    },
  };
};

const actionCreateSuccessCreator = (type) => ({ entity, ...rest }) => {
  return {
    type,
    payload: { entity, status: STATUS.READY, ...rest },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const actionCreateFailedCreator = (type) => (params, error) => {
  return {
    type,
    payload: { status: STATUS.ERROR },
    error: true,
    meta: {
      timestamp: new Date(),
      params,
      error,
    },
  };
};

const actionUpdateRequestCreator = (type) => (params) => {
  return {
    type,
    payload: { status: STATUS.LOADING, params },
    error: false,
    meta: {
      timestamp: new Date(),
      params,
    },
  };
};

const actionUpdateSucceededCreator = (type) => ({ entity, ...rest }) => {
  return {
    type,
    payload: { entity, status: STATUS.READY, ...rest },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const actionUpdateFailedCreator = (type) => (params, error) => {
  return {
    type,
    payload: { status: STATUS.ERROR },
    error: true,
    meta: {
      timestamp: new Date(),
      params,
      error,
    },
  };
};

const actionListRequestedCreator = (type) => (params) => {
  return {
    type,
    payload: { oldParamsList: params, status: STATUS.LOADING },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const actionListSucceededCreator = (type) => ({ entities, ...rest }) => {
  return {
    type,
    payload: { entities, status: STATUS.READY, ...rest },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const actionListFailedCreator = (type) => (params, error) => {
  return {
    type,
    payload: { status: STATUS.ERROR },
    error: true,
    meta: {
      timestamp: new Date(),
      params,
      error,
    },
  };
};

const actionDeleteRequestedCreator = (type) => (ids) => {
  return {
    type,
    payload: { ids, status: STATUS.LOADING },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const actionDeleteSucceededCreator = (type) => (ids) => {
  return {
    type,
    payload: { ids, status: STATUS.READY },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const actionDeleteFailedCreator = (type) => (ids, error) => {
  return {
    type,
    payload: { ids, status: STATUS.ERROR },
    error: true,
    meta: {
      timestamp: new Date(),
      error,
      ids,
    },
  };
};

const actionGetRequestedCreator = (type) => (id) => {
  return {
    type,
    payload: { id, status: STATUS.LOADING },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const actiongGetSucceededCreator = (type) => (entity) => {
  return {
    type,
    payload: { entity, status: STATUS.READY },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const actionGetFailedCreator = (type) => (id, error) => {
  return {
    type,
    payload: { id, error, status: STATUS.ERROR },
    error,
    meta: {
      timestamp: new Date(),
      id,
      error,
    },
  };
};

const MAP_ACTIONS_CREATOR = {
  CREATE_REQUESTED: { functionName: 'createRequested', action: actionCreateRequestedCreator },
  CREATE_SUCCEEDED: { functionName: 'createSucceeded', action: actionCreateSuccessCreator },
  CREATE_FAILED: { functionName: 'createFailed', action: actionCreateFailedCreator },

  UPDATE_REQUESTED: { functionName: 'updateRequested', action: actionUpdateRequestCreator },
  UPDATE_SUCCEEDED: { functionName: 'updateSucceeded', action: actionUpdateSucceededCreator },
  UPDATE_FAILED: { functionName: 'updateFailed', action: actionUpdateFailedCreator },

  DELETE_REQUESTED: { functionName: 'deleteRequested', action: actionDeleteRequestedCreator },
  DELETE_SUCCEEDED: { functionName: 'deleteSucceeded', action: actionDeleteSucceededCreator },
  DELETE_FAILED: { functionName: 'deleteFailed', action: actionDeleteFailedCreator },

  LIST_REQUESTED: { functionName: 'listRequested', action: actionListRequestedCreator },
  LIST_SUCCEEDED: { functionName: 'listSucceeded', action: actionListSucceededCreator },
  LIST_FAILED: { functionName: 'listFailed', action: actionListFailedCreator },

  GET_REQUESTED: { functionName: 'getRequested', action: actionGetRequestedCreator },
  GET_SUCCEEDED: { functionName: 'getSucceeded', action: actiongGetSucceededCreator },
  GET_FAILED: { functionName: 'getFailed', action: actionGetFailedCreator },
};

const generateActions = (types = {}) => {
  return Object.entries(types).reduce((actions, [typeKey, typeValue]) => {
    const actionCreator = MAP_ACTIONS_CREATOR[typeKey];
    if (!actionCreator) {
      return actions;
    }
    return {
      ...actions,
      [actionCreator.functionName]: actionCreator.action(typeValue),
    };
  }, {});
};

export { generateActions };
