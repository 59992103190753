import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Select from 'ui/form/select';
import useAsyncOptionsOnType from './useAsyncOptionsOnType';

const SelectorAsync = ({
  useSearchEntities,
  onChange: onChangeSelect,
  isMulti,
  name,
  outSideSelecteds,
  cacheOptions,
  value,
  noSeparation,
  fillParams,
  ...props
}) => {
  const [onChange, handleOptions] = useAsyncOptionsOnType({
    onChangeSelect,
    useSearchEntities,
    initialValue: value,
    isMulti,
    fillParams,
  });

  const hadleOnRemove = useCallback(
    (v) => {
      const removed = (value || []).filter((selected) => v !== selected.value);
      onChangeSelect(removed);
    },
    [value, onChangeSelect]
  );

  return (
    <Select
      defaultOptions
      cacheOptions={cacheOptions}
      noSeparation={noSeparation}
      isAsync
      isMulti={isMulti}
      isSearchable
      onChange={onChange}
      loadOptions={handleOptions}
      name={name}
      outSideSelecteds={outSideSelecteds}
      hadleOnRemove={hadleOnRemove}
      value={value}
      {...props}
    />
  );
};

export const propTypes = {
  onChange: PropTypes.func,
  useSearchEntities: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  name: PropTypes.string.isRequired,
  outSideSelecteds: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  fillParams: PropTypes.object,
};

export const defaultProps = {
  outSideSelecteds: false,
  cacheOptions: false,
  isMulti: false,
  onChange: () => {},
  fillParams: {},
};

SelectorAsync.propTypes = propTypes;

SelectorAsync.defaultProps = defaultProps;

export default SelectorAsync;
