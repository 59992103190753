import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { notification as antdNotification, message as antdMessage } from 'antd';

import { useTranslation } from 'react-i18next';
import { selectors } from 'modules/notifications';
import LogoNeptuno from 'assets/images/icon-logo.svg';

const useNotifications = () => {
  const { t } = useTranslation();
  const [apiNotifications, contextHolder] = antdNotification.useNotification();

  const { message, notification } = useSelector((state) => ({
    message: selectors.getMessage(state),
    notification: selectors.getNotification(state),
  }));

  const dispatchNotification = (config) => {
    const MAP = {
      success: {
        action: apiNotifications.success,
        iconComponent: <img src={LogoNeptuno} />,
      },
      error: {
        action: apiNotifications.error,
        iconComponent: <img src={LogoNeptuno} />,
      },
      info: {
        action: apiNotifications.info,
        iconComponent: <img src={LogoNeptuno} />,
      },
      warning: {
        action: apiNotifications.warning,
        iconComponent: <img src={LogoNeptuno} />,
      },
    };
    const { action = () => {}, style = {}, iconComponent = null } = MAP[config.type];

    const { id: key, icon = null, onRetry, message: description, title, ...rest } = config;
    action({
      key,
      style,
      description: t(description),
      message: t(title),
      ...(onRetry && { btn: <button onClick={onRetry}>{t('commonText:retry')}</button> }),
      ...rest,
    });
  };

  const dispatchMessage = (config) => {
    const MAP = {
      success: {
        action: antdMessage.success,
        iconComponent: <img src={LogoNeptuno} />,
      },
      error: {
        action: antdMessage.error,
        iconComponent: <img src={LogoNeptuno} />,
      },
      info: {
        action: antdMessage.info,
        iconComponent: <img src={LogoNeptuno} />,
      },
      warning: {
        action: antdMessage.warning,
        iconComponent: <img src={LogoNeptuno} />,
      },
    };
    const { action = () => {}, style = {}, iconComponent = null } = MAP[config.type];

    const { id: key, icon = null, onRetry, message: content, title, ...rest } = config;

    action({
      key,
      icon: icon || iconComponent,
      style,
      content: t(content),
      ...rest,
    });
  };

  useEffect(() => {
    if (message) {
      dispatchMessage(message);
    }
  }, [message]);
  useEffect(() => {
    if (notification) {
      dispatchNotification(notification);
    }
  }, [notification]);

  return contextHolder;
};

export default useNotifications;
