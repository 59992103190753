import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from '../icons';
import Text from '../tipography/text';
import Tooltip from '../tooltip';

import { DrawerStyles } from './styles';

const Drawer = ({ children, noPaddding, ...props }) => {
  const { t } = useTranslation();
  const closeIcon = (
    <Tooltip
      placement="left"
      title={
        <Text style={{ margin: '0', whiteSpace: 'nowrap' }} color="white" size="small" align="center">
          {t('actions:Close_esc')}
        </Text>
      }
    >
      <div>
        <Icon color="ghost" iconName="icon-close" />
      </div>
    </Tooltip>
  );
  return <DrawerStyles {...{ closeIcon, noPaddding, ...props }}>{children}</DrawerStyles>;
};

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
};

Drawer.defaultProps = {
  width: 450,
};
export default Drawer;
