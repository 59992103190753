import styled, { css } from 'styled-components';

const buttonAction = css`
  .hideButton {
    display: 'none';
  }

  .showButton {
    display: 'block';
  }
`;

const RowActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-items: center;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 16px 0 40px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 17%,
    rgba(255, 255, 255, 1) 40%
  );

  :hover {
    opacity: 1;
  }

  button {
    align-items: center;
    justify-content: center;
    width: 24px;
    margin: 0 6px;
    box-shadow: none;
    display: flex;
    span {
      color: #7171a6;
    }
    :hover {
      background: #fff;
      span {
        opacity: 0.9;
      }
    }
  }

  .button-delete {
    span {
      color: #ec7a83;
    }
    :hover span {
      color: red;
    }
  }
`;

const SCPagination = styled.div`
  position: absolute;
  top: 2px;
  left: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9999;
  padding: 0px 3px;
  align-items: center;
  height: 56px;
  color: ${(props) => props.theme.colors.primary};

  span {
    font-size: 11px;
    font-weight: bold;
  }
`;

const PaginationTable = styled.div`
  height: 56px;
  align-items: center;
  display: flex;
  justify-content: flex-end;

  input {
    margin-right: 2px;
    text-align: center;
  }

  button,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    pointer-events: auto;
  }

  button {
    width: 0px;
  }
`;

const Search = styled.div`
  width: 270px;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  > div {
    margin-right: 5px;
  }
`;

const FilterBar = styled.div`
  width: 100%;
  display: flex;
  ${({ alignActionsBar, theme }) => theme.table.alignActionsBar[alignActionsBar || 'space-between']};
  margin: ${({ noSeparation }) => (noSeparation ? '0px' : '10px 0')};
  align-items: center;

  ${(props) =>
    props.filterBarWithFrame &&
    css`
      padding: 0 15px;
      background: #ffffff;
      box-shadow: 0px 0px 5px rgba(51, 52, 79, 0.07);
      border-radius: 3px;
      margin-bottom: 10px;
      position: sticky;
      justify-content: space-between;
      top: 0;
      z-index: 9;
    `}

  input {
    max-width: 270px;
  }
  button {
    margin-left: 10px;
  }
`;

const WrapperTableWithActions = styled.div`
  margin: 5px 20px 20px;
  position: relative;
`;

const TableStyles = styled.div`
  border-radius: 7px;
  width: 100%;
  overflow-y: overlay;
  max-height: ${(props) => props.height || 'calc(100vh - 185px)'};

  ::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 15px;
    border-radius: 10px;
    background: #d3d3d3;
    border: 3px solid #f7f8f9;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: transparent;
  }

  .table {
    position: relative;
    width: max-content;
    min-width: calc(100% - 4px);
    border: 1px solid #e6e6ff;

    .thead {
      background: ${({ styles, theme }) => (styles.coloredTable ? theme.colors.secondary : '#f7f7fc')};
      position: sticky;
      top: 0;
      z-index: 4;

      .columnSelects {
        padding: 0 10px;
      }
      .tr {
        display: flex;
        position: relative;
        background: ${({ styles, theme }) => (styles.coloredTable ? theme.colors.secondary : '#f7f7fc')};
        color: ${({ styles, theme }) => (styles.coloredTable ? theme.colors.white : '#7171a6')};
      }

      .tr:nth-child(2) {
        border-top: ${({ styles }) => (styles.coloredTable ? '1px solid' : '0px solid')};
      }

      .th {
        font-size: 11px;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        padding: 0.5rem 1.125rem;
        cursor: default;
        position: relative;
        display: flex;
        flex: 1;
        align-items: center;
        border-right: ${({ styles }) => (styles.coloredTable ? '1px solid' : '0px solid')};
      }
      [data-sticky-td] {
        z-index: 4;
        border-bottom: ${({ styles }) => (styles.coloredTable ? '0px ' : '1px solid #e6e6ff')};
        font-weight: 700;
      }
    }
    .tbody {
      background: ${({ styles, theme }) => (styles.lightTable ? 'transparent' : theme.colors.white)};
      position: relative;

      .th,
      .td {
        font-size: 12px;
        color: #33334f;
        min-height: 38px;
        margin: 0;
        padding: 0.4rem 1.125rem;
        overflow: overlay;
        display: flex;
        align-items: center;
        flex: 1;

        :last-child {
          border-right: 0;
        }
      }
    }

    [data-sticky-td] {
      position: sticky !important;
      background-color: #f7f7fc;
      border-right: 1px solid #e6e6ff;
      font-weight: 700;
    }

    .footer {
      position: sticky;
      bottom: 0;
      .td {
        font-size: 12px;
        line-height: 1.2;
        background-color: #7171a6;
        flex: 1;
      }
    }

    .th {
      color: ${({ styles, theme }) => (styles.coloredTable ? theme.colors.white : '#7171a6')};
      background: ${({ styles, theme }) => (styles.coloredTable ? theme.colors.secondary : '#f7f7fc')};
    }
  }
`;

const TheadStyles = styled.div`
  background: ${({ styles, theme }) => (styles.coloredTable ? theme.colors.secondary : '#f7f7fc')};
  overflow-y: auto;
  overflow-x: hidden;

  .columnSelects {
    padding: 0 10px;
  }
  .tr {
    display: flex;
    align-items: center;
    border-top: none;
    position: relative;
  }

  .th {
    font-size: 13px;
    font-weight: bold;
    color: ${({ styles, theme }) => (styles.coloredTable ? theme.colors.white : '#7171a6')};
    text-align: left;
    line-height: 20px;
    border-right: 1px solid ${({ styles, theme }) => (styles.coloredTable ? theme.colors.white : 'transparent')};
    padding: 10px;
    flex: 1;
  }
`;

const TrBody = styled.tr`
  ${({ backGround }) =>
    backGround &&
    css`
      background: ${backGround};
    `}
`;

const GoPage = styled.div`
  display: flex;
  input {
    width: 50px;
    margin-right: 10px;
    height: 25px;
  }
`;

const TrStyles = styled.div`
  display: flex;
  border-bottom: ${({ styles }) => (styles.lightTable ? '0px' : '1px solid rgb(230, 230, 255);')};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  position: relative;
  cursor: ${({ cursor }) => cursor || 'pointer'};
  :last-child {
    border-bottom: 0px solid transparent;
  }

  .ant-tag {
    padding: 3px 8px;
    margin: 0;
    margin-right: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  ${({ isHover }) =>
    isHover
      ? `
	  background: #6078ff1a;
		box-shadow: ${({ styles }) => (styles.coloredTable ? 'none' : '0 2px 5px 0 rgba(51,51,79,0.07)')};

		${RowActions} {
      opacity: 1;
      background: transparent;
		}

		.link-button {
			text-decoration: none;
		}

		a {
			text-decoration: underline;
		}
	`
      : ''}
  a {
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
  }
  :hover {
    background: #6078ff1a;
    box-shadow: ${({ styles }) => (styles.coloredTable ? 'none' : '0 2px 5px 0 rgba(51,51,79,0.07)')};

    ${RowActions} {
      opacity: 1;
    }

    .link-button {
      text-decoration: none;
    }

    a {
      text-decoration: underline;
    }
  }
`;

const MessageEmpty = styled.div`
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 160px;
    margin-top: 50px;
  }

  h3 {
    max-width: 400px;
    color: #7f8a9d;
  }

  svg {
    width: 100px;
    height: 100px;
    margin: 20px;
    display: inline-block;
  }

  div {
    padding: 20px;
    border-radius: 4px;
    text-align: center;

    h2 {
      margin: 0;
    }
  }
`;

export {
  TableStyles,
  TheadStyles,
  FilterBar,
  Filters,
  SCPagination,
  RowActions,
  PaginationTable,
  WrapperTableWithActions,
  TrBody,
  Search,
  GoPage,
  TrStyles,
  MessageEmpty,
};
