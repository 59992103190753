import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CROMA, FC, COUNTER, COUNTER_ERROR } from 'config/variables';
import { useEffectDeepCompare, useFormulaDispatch, useCallbackDeepCompare } from 'components/hooks';
import { evaluate } from 'libs/math';
import { RowFlex, Tag } from 'ui';
import Text from '../tipography/text';
import { useHandleKeyDown } from './hook';

import Calculator from './Calculator';
import SelectVariables from './SelectVariables';
import Input from './Input';

import { InputContainer, ContentForm, HelpColumn } from './styles';
import * as CONSTANTS from './constants';

const INPUT_ACCESS = 'formulaFocusInput';

const DEFAULT_VARIABLES = [
  ...CROMA.map((v) => ({ ...v, type: 'measurementUnit' })),
  ...[...FC, ...COUNTER, ...COUNTER_ERROR].map((v) => ({ ...v, type: 'line' })),
];

const Formula = ({ initialOperations, operatorsConfig, showHelpText, variables, concreteEntities }) => {
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const [operations, setOperation] = useState([]);
  const [vars, setVars] = useState([]);
  const [cursorPosition, setCursorPosition] = useState(-1);
  const [isFocus, setFocus] = useState(false);
  const dispatchFormulaContext = useFormulaDispatch();

  const getOperations = useCallbackDeepCompare(() => {
    return operations;
  }, [operations]);

  useEffect(() => {
    const handle = (event) => {
      if (event.target.dataset.focusFormula === INPUT_ACCESS) {
        setFocus(true);
      } else {
        setFocus(false);
      }
    };
    document.addEventListener('click', handle);
    return () => {
      document.removeEventListener('click', handle, false);
    };
  }, [setFocus]);

  useEffectDeepCompare(() => {
    setOperation(initialOperations);
    setCursorPosition(initialOperations.length - 1);
  }, [setOperation, initialOperations]);

  const { handleDigit, handleMathOperator, addNewOperator, canNavigate, replaceOperator } = useHandleKeyDown({
    input,
    setInput,
    operations,
    setOperation,
    setVars,
    cursorPosition,
    setCursorPosition,
    isFocus,
    variables,
    concreteEntities,
  });

  const handleRemove = useCallback(() => {
    setInput('');
    setOperation([]);
    setCursorPosition(-1);
  }, [setInput, setOperation]);

  const handleSetCursor = useCallback(
    (nextPosition) => {
      if (canNavigate(operations)) {
        setCursorPosition(nextPosition);
      }
    },
    [canNavigate, setCursorPosition, operations]
  );
  const isValid =
    operations.some((o) => o.type !== 'math') &&
    operations.every((o) => o.type === 'math' || !!o.entity) &&
    evaluate(
      operations.reduce(
        (acc, operation) => `${acc}${operation.type === 'math' ? operation.value : String(Math.random())}`,
        ''
      )
    );

  useEffectDeepCompare(() => {
    dispatchFormulaContext({ isValid });
  }, [isValid]);

  useEffectDeepCompare(() => {
    dispatchFormulaContext({ getOperations });
  }, [getOperations]);
  useEffectDeepCompare(() => {
    dispatchFormulaContext({ operations: { current: operations } });
  }, [operations]);

  const lastOperation = operations[cursorPosition];

  const handleCustomOperation = (func, { value, label, event }) => {
    func({
      isValid,
      operations,
      cursorPosition,
      setCursorPosition,
      setOperation,
      replaceOperator,
      value,
      label,
      event,
    });
  };

  return (
    <ContentForm>
      <InputContainer>
        <Text size="small">{t('CustomVarPage:WriteYourFormula')}</Text>
        <Input
          inputAccess={INPUT_ACCESS}
          operations={operations}
          input={input}
          cursorPosition={cursorPosition}
          setCursorPosition={handleSetCursor}
          isFocus={isFocus}
          placeholder={t('CustomVarPage:WriteNameFormula')}
        />
        <SelectVariables
          variables={vars}
          inputAccess={INPUT_ACCESS}
          onClick={({ value, type }) => {
            addNewOperator({
              type: type === 'line' ? 'varLine' : 'varMeasurementUnit',
              entity: null,
              value,
              key: Math.random(0, 1),
            });
          }}
        />
      </InputContainer>
      <RowFlex justify="space-between">
        {showHelpText && (
          <HelpColumn>
            {!lastOperation || lastOperation.type === 'math' || !!lastOperation.entity ? (
              <>
                <Text strong color="primary" size="large">
                  {t('CustomVarPage:Help-AddVariables')}
                </Text>
                <Text>{t('CustomVarPage:Help-searchVariables')}</Text>
              </>
            ) : null}
            {lastOperation && lastOperation.type === 'varLine' && !lastOperation.entity ? (
              <>
                <Text strong color="primary" size="large">
                  {t('CustomVarPage:Help-refNumberTitle')}
                </Text>

                <p>{t('CustomVarPage:Help-refNumberText')}</p>
                <p>{t('CustomVarPage:Help-refNumberText-2')}</p>

                <RowFlex>
                  <p>
                    <strong>
                      {t('CustomVarPage:Help-example')}: Vn<Tag>#{t('CustomVarPage:Help-line')}1</Tag> * PCS
                      <Tag>#UM1</Tag>
                    </strong>
                  </p>
                </RowFlex>
              </>
            ) : null}
            {lastOperation && lastOperation.type === 'varMeasurementUnit' && !lastOperation.entity ? (
              <>
                <Text strong color="primary" size="large">
                  {t('CustomVarPage:Help-refNumberTitleUm')}
                </Text>

                <p>{t('CustomVarPage:Help-refNumberUmText')}</p>
                <p> {t('CustomVarPage:Help-refNumberTextUm-2')}</p>

                <RowFlex>
                  <p>
                    <strong>
                      {t('CustomVarPage:Help-example')}: Vn<Tag>#{t('CustomVarPage:Help-line')}1</Tag> * PCS
                      <Tag>#UM1</Tag>
                    </strong>
                  </p>
                </RowFlex>
              </>
            ) : null}
          </HelpColumn>
        )}
        <Calculator
          inputAccess={INPUT_ACCESS}
          handleDigit={handleDigit}
          handleMathOperator={handleMathOperator}
          handleRemove={handleRemove}
          handleCustomOperation={handleCustomOperation}
          operatorsConfig={operatorsConfig}
        />
      </RowFlex>
    </ContentForm>
  );
};

Formula.propTypes = {
  initialOperations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['math', 'varLine', 'varMeasurementUnit']),
      entity: PropTypes.string,
      value: PropTypes.string,
      key: PropTypes.number,
    })
  ),
  operatorsConfig: Calculator.propTypes.operatorsConfig,
  showHelpText: PropTypes.bool,
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.oneOf(['measurementUnit', 'line']),
    })
  ),
  concreteEntities: PropTypes.bool,
};

Formula.defaultProps = {
  initialOperations: [],
  operatorsConfig: Calculator.defaultProps.operatorsConfig,
  showHelpText: true,
  variables: DEFAULT_VARIABLES,
  concreteEntities: false,
};

Formula.CONSTANTS = CONSTANTS;

export default Formula;
