import React from 'react';
import PropTypes from 'prop-types';

import { ParagraphStyles } from './styles';

const Paragraph = ({ align, children, ...props }) => (
  <ParagraphStyles {...{ align, ...props }}>{children}</ParagraphStyles>
);

Paragraph.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  type: PropTypes.oneOf(['secondary', 'warning', 'danger']),
};

Paragraph.defaultProps = {
  size: 'medium',
  align: 'left',
};

export default Paragraph;
