import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Title, Drawer, RowFlex, CardDescription, Button, List } from 'ui';
import { useEffectDeepCompare } from 'components/hooks';
import { InitialState } from './constants';
import improvesConfig from './ImprovesConfig';

const ChangeLogSidebar = ({ isOpen, toggleModal }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const keys = Object.keys(improvesConfig(history, toggleModal));
  const [state, setState] = useState(InitialState);
  const handleOnLoad = () => {
    setState({ ...state, loading: true });
    if (state.keys.length === keys.length) {
      setState({
        ...state,
        loading: false,
      });
      return;
    }
    setState({
      ...state,
      data: state.data.concat(improvesConfig(history, toggleModal)[keys[state.keys.length]]),
      keys: state.keys.concat(keys[state.keys.length]),
      loading: false,
    });
  };
  const { data, loading, initLoading } = state;

  useEffectDeepCompare(() => {
    if (initLoading)
      setState({
        ...state,
        data: state.data.concat(improvesConfig(history, toggleModal)[keys[state.keys.length]]),
        keys: state.keys.concat(keys[state.keys.length]),
        loading: false,
        initLoading: false,
      });
  }, [initLoading]);

  return (
    <Drawer
      visible={isOpen}
      onClose={() => {
        toggleModal(false);
        setState(InitialState);
      }}
      position="right"
      width={540}
      title={<Title level={3}>{t('TopBar:AccountInfo:Changelog')}</Title>}
    >
      <RowFlex direction="column">
        <List
          loading={loading}
          dataSource={data}
          itemLayout="horizontal"
          onLoadMore={handleOnLoad}
          loadMore={
            keys.length !== state.keys.length && (
              <Button variant="primary" loading={loading} onClick={handleOnLoad}>
                Cargar novedades versión anterior
              </Button>
            )
          }
          renderItem={(item) => (
            <CardDescription colorType={item.colorType} type={item.type} date={item.date} title={item.title}>
              {item.content}
            </CardDescription>
          )}
        />
      </RowFlex>
    </Drawer>
  );
};

ChangeLogSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default ChangeLogSidebar;
