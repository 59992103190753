import styled from 'styled-components';

const CardStyles = styled.div`
  border-radius: 3px;
  padding: 16px;
  max-width: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 0 1px 2px rgb(0 0 0 / 14%);
  margin-bottom: 14px;

  h2 {
    display: flex;
    align-items: flex-end;
    margin: 10px 0;
    color: #2f2e48;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;

    span {
      margin-left: 6px;
      margin-bottom: 4px;
    }
  }
  span {
    margin: 0;
  }
  .ant-tag {
    padding: 2px 6px;
    font-size: 10px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 10px;
    margin-right: 8px;
    overflow: hidden;
    border: none;
    margin-bottom: 0;
  }
`;

const Description = styled.div`
  img {
    max-width: 100%;
  }

  a {
    display: flex;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;

    span {
      margin: 10px 0;
    }
  }

  ul {
    padding-left: 30px;

    li {
      span {
        display: block;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      img {
        margin: 10px 0 30px;
      }
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }
`;

export { CardStyles, Description };
