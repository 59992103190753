import React from 'react';
import { Table as AntdTable, TableProps } from 'antd';
import styled from 'styled-components';

import { Search, Delete, Create, FilterBar } from './HeadersActions';
import { RowActions, WrapperTableWithActions } from './styles';

interface PropsStyles {
  margin?: string;
}

const TableStyles = styled(AntdTable)<PropsStyles>`
  margin: ${({ margin }) => margin};
  border: 1px solid rgb(230, 230, 255);
  .ant-table-tbody > tr > td {
    font-size: 12px;
    color: rgb(51, 51, 79);
    min-height: 38px;
    margin: 0px;
    padding: 0.4rem 0.8rem;
    background: transparent;
  }

  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none;
  }
  .ant-table-thead > tr > th {
    font-size: 11px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.8px;
    color: #7171a6;
    background: #f7f7fc;
    text-transform: uppercase;
    padding: 0.4rem 0.8rem;
    cursor: default;
    position: relative;
    border-right: 0px solid;
  }

  .ant-table-tbody > tr {
    :hover {
      ${RowActions} {
        opacity: 1;
      }
    }
  }
` as typeof AntdTable;

// eslint-disable-next-line @typescript-eslint/ban-types
function Table<RecordType extends object = any>(props: TableProps<RecordType> & PropsStyles): JSX.Element {
  return <TableStyles<RecordType> {...props} />;
}

Table.FilterBar = Object.assign(FilterBar, { Search, Delete, Create });
Table.Container = WrapperTableWithActions;

Table.defaultProps = {
  margin: '0px',
};

export default Table;
