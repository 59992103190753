import React from 'react';
import { EmptyProps } from 'antd';
import EmptyImage from 'assets/images/empty-image.svg';
import { EmptyStyles, Container } from './styles';

const Empty: React.FC<EmptyProps> = ({ image = EmptyImage, ...props }) => (
  <Container>
    <EmptyStyles {...{ image, ...props }} />
  </Container>
);

export default Empty;
