import { css } from 'styled-components';

export default {
  type: {
    active: css`
      color: #00d998;
    `,
    desactive: css`
      color: #fe6a66;
    `,
    draft: css`
      color: #b9b9b9;
    `,
  },
};
