import { set, addHours, format, differenceInCalendarDays } from 'libs/date';
import { EFFORT_REQUEST_ADVANCED } from '../constants';

const generateOptionsGasHours = () => {
  const DAY_HOURS = 24;
  const START_GAS_HOUR = set(new Date(), { hours: 6, minutes: 0 });

  return new Array(DAY_HOURS).fill(null).map((_, index) => ({
    label: format(addHours(START_GAS_HOUR, index), 'HH:mm'),
    value: format(addHours(START_GAS_HOUR, index), 'HH:mm'),
  }));
};

const getEffortRequest = ({ shiftType, shiftValue, mapVariablesEntities, endDate, startDate }) => {
  const valuesEffort = (() => {
    if (shiftType === 'hours') {
      return 1440 / (60 * Number(shiftValue));
    }
    if (shiftType === 'days') {
      return 1440 / (1440 * Number(shiftValue));
    }
    return 1440 / Number(shiftValue);
  })();

  const lines = mapVariablesEntities.reduce((acc, { lineId, measureId }) => {
    if (lineId && !acc.includes(lineId)) return [...acc, lineId];
    if (!lineId && measureId && !acc.includes(measureId)) return [...acc, lineId];
    return acc;
  }, []);

  const variables = mapVariablesEntities.reduce((acc, { variable }) => {
    if (variable && !acc.includes(variable)) return [...acc, variable];
    return acc;
  }, []);

  const daysDiference = differenceInCalendarDays(endDate, startDate);
  const value = valuesEffort * daysDiference * (mapVariablesEntities.length * 1.5);
  return { value, valid: value <= EFFORT_REQUEST_ADVANCED };
};

export { generateOptionsGasHours, getEffortRequest };
