import styled, { css } from 'styled-components';
import { Spin } from 'antd';

const height = ({ height }: { height: string }) => css`
  height: ${height};
`;

const SpinnerStyles = styled(Spin)`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.theme.colors.primary};

  .ant-spin-dot-item {
    background-color: ${(props) => props.theme.colors.primary};
  }
  .ant-spin-text {
    font-size: 14px;
    margin-top: 14px;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  ${height};

  > div {
    font-size: 16px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    border-radius: 3px;
  }
`;

export { SpinnerStyles, Wrapper };
