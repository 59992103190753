/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Link } from 'react-router-dom';
import { can } from 'libs/authorization';

import { getDateFormated, getDateInTimezone, addMonths } from 'libs/date';
import { Dropdown, Button, Tag, Popover, Text, RowFlex } from 'ui';

import i18n from 'config/i18n';

const ITEMS_PER_PAGE = 25;
const EMPTY_FILTERS = (equipmentType) => ({
  flowComputer: { measurementUnit: null },
  patternEquipment: { brand: null, model: null, area: null },
  equipmentType,
});

const EQUIPMENTS_OPTIONS = (t) => [
  { label: t('entitiesNames:chromatograph'), value: 'chromatograph', goBack: false },
  { label: t('entitiesNames:counter'), value: 'counter', goBack: true },
  { label: t('entitiesNames:flowComputer'), value: 'flowComputer', goBack: true },
  { label: t('entitiesNames:pattern'), value: 'patternEquipment', goBack: false },
  { label: t('entitiesNames:bottle'), value: 'calibrationBottle', goBack: false },
];

const COUNTER_TYPES = (t) => [
  { label: t('EquipmentPages:Turbine'), value: 'turbine' },
  { label: t('EquipmentPages:Ultrasonic'), value: 'ultrasonic' },
  { label: t('EquipmentPages:Rotative'), value: 'rotative' },
  { label: t('EquipmentPages:Membrane'), value: 'membrane' },
  { label: t('EquipmentPages:Coriolis'), value: 'coriolis' },
];

const PARSER_TYPES = [
  { label: 'abb8206-completo', value: 'abb8206-completo' },
  { label: 'daniel500-completo', value: 'daniel500-completo' },
  { label: 'daniel500-abb8206-completo', value: 'daniel500-abb8206-completo' },
];

const CLIENT_TYPES = [
  { label: 'ACTIVE', value: 'active' },
  { label: 'I782', value: 'i782' },
  { label: 'PASSIVE', value: 'passive' },
  { label: 'SERIAL', value: 'serial' },
  { label: 'TCP', value: 'tcp' },
];
const FLOW_COMPUTER_TYPES = [
  { label: 'P', value: 'P' },
  { label: 'PT', value: 'PT' },
  { label: 'PTZ', value: 'PTZ' },
];
const VARIABLES_FOOTPRINT = ['N2', 'CO2', 'C1', 'C2', 'C3', 'iC4', 'nC4', 'iC5', 'nC5', 'C6plus'];
const PATTERN_AREAS = (t) => [
  { label: t('PatternPage:areas:Pressure'), value: 'pressure' },
  { label: t('PatternPage:areas:Temperature'), value: 'temperature' },
];

const FOOTPRINT_HEADERS_HISTORY = (t) => [
  {
    Header: t('Headers:Date'),
    accessor: 'date',
    width: '120px',
    maxWidth: '120px',
    sticky: 'left',
    Cell: ({ row: { original } }) => {
      return <span>{getDateFormated(getDateInTimezone(new Date(original.date)), 'dd/MM/yyyy HH:mm')}</span>;
    },
  },
  { Header: 'stream', accessor: 'stream', width: '80px', maxWidth: '80px' },
  ...VARIABLES_FOOTPRINT.map((variable) => ({
    Header: variable,
    width: '100px',
    maxWidth: '100px',
    accessor: `CR_${variable}`,
    Cell: ({ row: { original } }) => {
      return <span>{original.data.variables.variables_PV[`CR_${variable}`]}</span>;
    },
  })),
  {
    Header: '',
    accessor: 'actions',
    width: '10%',
    Cell: function Actions({ row: { original } }) {
      return (
        <Popover
          placement="bottomLeft"
          content={
            <div>
              {' '}
              <div>
                <div style={{ color: 'grey' }}> {t('Headers:responsable')}:</div>
                <div>
                  <b> {original.user_creator} </b>
                </div>
              </div>
              <div>
                <div style={{ color: 'grey' }}> {t('components:footprintPage:set-date')}:</div>
                <div>
                  <b>
                    {original.creation_date
                      ? getDateFormated(getDateInTimezone(new Date(original.creation_date)), 'dd/MM/yyyy HH:mm')
                      : ''}
                  </b>
                </div>
              </div>
            </div>
          }
        >
          <Button variant="plain" color="ghost" customIcon="icon-more-info" size="small" />
        </Popover>
      );
    },
  },
];

const FOOTPRINT_HEADERS = (t) => (selectFunc, selected) => {
  return [
    {
      Header: t('Headers:Date'),
      accessor: 'date',
      width: '140px',
      maxWidth: '140px',
      sticky: 'left',

      Cell: ({ row: { original } }) => {
        return (
          <RowFlex direction="column" justify="center">
            {selected.date !== original.date ? (
              <Button
                variant="primary"
                onClick={() => {
                  selectFunc(original);
                }}
              >
                {t('actions:Select')}
              </Button>
            ) : (
              ''
            )}

            <span>{getDateFormated(getDateInTimezone(new Date(original.date)), 'dd/MM/yyyy HH:mm')}</span>
          </RowFlex>
        );
      },
    },
    { Header: 'stream', accessor: 'stream', width: '100px', maxWidth: '100px' },
    ...VARIABLES_FOOTPRINT.map((variable) => ({
      Header: variable,
      width: '100px',
      maxWidth: '100px',
      accessor: `CR_${variable}`,
      Cell: ({ row: { original } }) => {
        return <span>{original.variables.variables_PV[`CR_${variable}`]}</span>;
      },
    })),
  ];
};

const FOOTPRINT_HEADERS_DATA = (t) => (selectFunc, footprints) => [
  {
    Header: t('Headers:Date'),
    accessor: 'date',
    sticky: 'left',
    width: '120px',
    maxWidth: '120px',
    Cell: ({ row: { original } }) => {
      return <span>{getDateFormated(getDateInTimezone(new Date(original.date)), 'dd/MM/yyyy HH:mm')}</span>;
    },
  },
  { Header: 'stream', accessor: 'stream', width: '80px', maxWidth: '80px' },
  ...VARIABLES_FOOTPRINT.map((variable) => ({
    Header: variable,
    width: '100px',
    maxWidth: '100px',
    accessor: `CR_${variable}`,
    Cell: ({ row: { original } }) => <span>{original.variables.variables_PV[`CR_${variable}`]}</span>,
  })),
  {
    Header: '',
    accessor: 'actions',
    width: '10%',
    Cell: function Actions({ row: { original } }) {
      const options = [
        can('select', original) && {
          label: t('actions:Select'),
          value: `show-${original.id}`,
          onClick: () => selectFunc(original),
        },
      ];

      return footprints && footprints.find((e) => e.date === original.date) ? (
        <Tag color="#B3B0F0">{t('components:footprintPage:footprint')}</Tag>
      ) : (
        <Dropdown variant="link" color="ghost" customIcon="icon-more-info" options={options} />
      );
    },
  },
];

const DEFAULT_VALUE = {
  defaultValue: true,
  items: [],
  total: 0,
  headers: [
    { Header: i18n.t('entitiesNames:chromatograph'), accesor: 'name' },
    { Header: i18n.t('modules:equipments-table-company-header'), accesor: 'company_label' },
    { Header: i18n.t('Headers:SerialNumber'), accesor: 'serial_number' },
    { Header: i18n.t('modules:equipments-table-totalUsed-header'), accesor: 'total_used' },
    { Header: i18n.t('Headers:CommonLastModified'), accesor: 'modification_date' },
  ],
};

const CONFIG_STATUS_CERTIFICATE = (t) => ({
  lapsed: { color: 'red', colorIcon: 'danger', icon: 'icon-warning', text: t('PatternPage:certificate-expired') },
  next: {
    color: 'orange',
    colorIcon: 'warning',
    icon: 'icon-warning',
    text: t('PatternPage:certificate-expires-soon'),
  },
  valid: { color: 'green', colorIcon: '', icon: '', text: '' },
});

const HEADERS_LIST_EQUIPMENTS = (t) => ({
  counter: [
    {
      Header: t('Headers:CommonType'),
      accessor: 'type',
      width: '30%',
      maxWidth: '150px',
      Cell: ({ row: { original } }) => (
        <Link to={`/infrastructure/equipments?id=${original.id}&equipmentType=counter`}>{original.type}</Link>
      ),
    },
    { Header: t('Headers:Model'), accessor: 'model', width: '30%', maxWidth: '250px' },
    {
      Header: t('Headers:CommonLastModified'),
      accessor: 'modificationDate',
      width: '30%',
      maxWidth: '250px',
      Cell: ({
        row: {
          original: { modificationDate },
        },
      }) => getDateFormated(modificationDate, 'dd-MM-yyyy HH:mm'),
    },
    { Header: t('entitiesNames:Company'), accessor: 'companyName', width: '20%', maxWidth: '250px' },
  ],
  chromatograph: [
    {
      Header: t('entitiesNames:chromatograph'),
      accessor: 'name',
      width: '30%',
      maxWidth: '250px',
      Cell: ({ row: { original } }) => (
        <Link to={`/infrastructure/equipments/chromatograph/${original.id}`}>{original.name}</Link>
      ),
    },
    { Header: t('Headers:SerialNumber'), accessor: 'serialNumber', width: '30%', maxWidth: '250px' },
    {
      Header: t('Headers:CommonLastModified'),
      accessor: 'modificationDate',
      width: '30%',
      maxWidth: '250px',
      Cell: ({
        row: {
          original: { modificationDate },
        },
      }) => getDateFormated(modificationDate, 'dd-MM-yyyy HH:mm'),
    },
    { Header: t('entitiesNames:Company'), accessor: 'companyName', width: '20%' },
  ],
  flowComputer: [
    {
      Header: t('Headers:SerialNumber'),
      accessor: 'serialNumber',
      width: '15%',
      maxWidth: '150px',
      Cell: ({
        row: {
          original: { id, serialNumber },
        },
      }) => <Link to={`/infrastructure/equipments?id=${id}&equipmentType=flowComputer`}>{serialNumber}</Link>,
    },
    { Header: t('Headers:ConversionType'), accessor: 'conversionType', width: '15%', maxWidth: '150px' },
    { Header: t('Headers:Brand'), accessor: 'brand', width: '20%', maxWidth: '150px' },
    { Header: t('Headers:Model'), accessor: 'model', width: '20%', maxWidth: '150px' },
    {
      Header: t('Headers:Lines'),
      accessor: 'lines',
      width: '60%',
      maxWidth: '500px',
      Cell: ({
        row: {
          original: { lines },
        },
      }) =>
        lines.map((line) => (
          <Tag color="blue" style={{ marginRight: '8px' }} key={line}>
            {line}
          </Tag>
        )),
    },
    { Header: t('entitiesNames:Company'), accessor: 'companyName', width: '15%', maxWidth: '100px' },
  ],
  patternEquipment: [
    {
      Header: t('Headers:SerialNumber'),
      accessor: 'serialNumber',
      width: '15%',
      Cell: ({
        row: {
          original: { id, serialNumber },
        },
      }) => <Link to={`/infrastructure/equipments/patternEquipment/${id}`}>{serialNumber}</Link>,
    },
    { Header: t('Headers:Brand'), accessor: 'brand', width: '15%' },
    { Header: t('Headers:Model'), accessor: 'model', width: '15%' },

    {
      Header: t('Headers:area'),
      accessor: 'areaName',
      width: '20%',
      Cell: ({
        row: {
          original: { areaName },
        },
      }) => (areaName ? PATTERN_AREAS(t).find(({ value }) => value === areaName).label : ''),
    },
    { Header: t('Headers:organizational_unit_abr'), accessor: 'organizationalUnitName', width: '30%' },
    { Header: t('Headers:certificateCode'), accessor: 'certificateCode', width: '20%' },
    {
      Header: t('Headers:validDate'),
      accessor: 'expirationDate',
      width: '20%',
      Cell: ({
        row: {
          original: { expirationDate, status },
        },
      }) =>
        expirationDate && (
          <Tag color={CONFIG_STATUS_CERTIFICATE(t)[status].color}>
            {getDateFormated(new Date(expirationDate), 'dd/MM/yyyy')}
          </Tag>
        ),
    },
  ],
  calibrationBottle: [
    {
      Header: t('Headers:SerialNumber'),
      accessor: 'serialNumber',
      width: '10%',
      Cell: ({
        row: {
          original: { id, serialNumber },
        },
      }) => <Link to={`/infrastructure/equipments/calibrationBottle/${id}`}>{serialNumber}</Link>,
    },
    { Header: t('Headers:Brand'), accessor: 'brand', width: '15%' },
    { Header: t('Headers:Model'), accessor: 'model', width: '15%' },
    {
      Header: t('Headers:AssociatedChroma'),
      accessor: 'chromaName',
      width: '15%',
      maxWidth: '100%',
      Cell: ({
        row: {
          original: { chromaName },
        },
      }) => (
        <Text size="small" strong>
          {chromaName}
        </Text>
      ),
    },
    {
      Header: t('entitiesNames:MeasurementUnitAbr'),
      accessor: 'chromaMUs',
      width: '10%',
      Cell: ({
        row: {
          original: { chromaMUs },
        },
      }) => (
        <Text size="small" strong>
          {chromaMUs}
        </Text>
      ),
    },
    {
      Header: t('Headers:instalationDate'),
      accessor: 'instalationDate',
      width: '15%',
      Cell: ({
        row: {
          original: { instalationDate },
        },
      }) => instalationDate && getDateFormated(new Date(instalationDate), 'dd/MM/yyyy'),
    },
    { Header: t('Headers:certificateCode'), accessor: 'certificateCode', width: '15%' },
    {
      Header: t('Headers:validDate'),
      accessor: 'expirationDate',
      width: '10%',
      Cell: ({
        row: {
          original: { expirationDate, status },
        },
      }) =>
        expirationDate && (
          <Tag color={CONFIG_STATUS_CERTIFICATE(t)[status].color}>
            {getDateFormated(new Date(expirationDate), 'dd/MM/yyyy')}
          </Tag>
        ),
    },
  ],
});

const BRANDS_EQUIPMENTS = {
  chromatograph: [
    { label: 'ABB', value: 'ABB' },
    { label: 'AGILENT', value: 'AGILENT' },
    { label: 'CHROMPACK', value: 'CHROMPACK' },
    { label: 'CHROMPACK-THT', value: 'CHROMPACK-THT' },
    { label: 'DANIEL', value: 'DANIEL' },
    { label: 'ELSTER/INSTROMET', value: 'ELSTER/INSTROMET' },
    { label: 'VARIAN', value: 'VARIAN' },
  ],
  ultrasonic: [
    { label: 'ELSTER/INSTROMET', value: 'Instromet' },
    { label: 'KROHNE', value: 'KROHNE' },
    { label: 'SICK', value: 'SICK' },
  ],
  turbine: [
    { label: 'ACTARIS', value: 'ACTARIS' },
    { label: 'ELSTER', value: 'ELSTER' },
    { label: 'INSTROMET', value: 'INSTROMET' },
    { label: 'ITRON', value: 'ITRON' },
    { label: 'RMG', value: 'RMG' },
    { label: 'SCHLUMBERGER', value: 'SCHLUMBERGER' },
  ],
  flowComputer: [
    { label: 'ELSTER', value: 'ELSTER' },
    { label: 'EMERSON', value: 'EMERSON' },
    { label: 'EX-I FLOW', value: 'EX-I FLOW' },
    { label: 'INSTROMET', value: 'Instromet' },
    { label: 'KROHNE', value: 'KROHNE' },
    { label: 'MECI', value: 'MECI' },
    { label: 'TECLAB (cliente)', value: 'TECLAB (cliente)' },
  ],
  patternEquipment: [
    { label: 'ADDITEL', value: 'ADDITEL' },
    { label: 'AMETEK', value: 'AMETEK' },
    { label: 'BEAMEX', value: 'BEAMEX' },
    { label: 'DRUCK', value: 'DRUCK' },
    { label: 'FLUKE', value: 'FLUKE' },
    { label: 'FLUKE HARTSCIENTIFIC', value: 'FLUKE HARTSCIENTIFIC' },
    { label: 'GOMETRICS', value: 'GOMETRICS' },
    { label: 'HART SCIENTIFIC', value: 'HART SCIENTIFIC' },
    { label: 'ISOTECH', value: 'ISOTECH' },
  ],
  calibrationBottle: [
    { label: 'ABELLO LINDE S.A.', value: 'ABELLO LINDE S.A.' },
    { label: 'AIR LIQUIDE', value: 'AIR LIQUIDE' },
    { label: 'Carburos Metálicos', value: 'Carburos Metálicos' },
    { label: 'Enagás LCE', value: 'Enagás LCE' },
  ],
};

const DIAMETERS_EQUIPMENTS = [
  { label: 'DN25 (1")', value: 'DN25 (1")' },
  { label: 'DN40 (1.6")', value: 'DN40 (1.6")' },
  { label: 'DN50 (2")', value: 'DN50 (2")' },
  { label: 'DN80 (3")', value: 'DN80 (3")' },
  { label: 'DN100 (4")', value: 'DN100 (4")' },
  { label: 'DN150 (6")', value: 'DN150 (6")' },
  { label: 'DN200 (8")', value: 'DN200 (8")' },
  { label: 'DN250 (10")', value: 'DN250 (10")' },
  { label: 'DN300 (12")', value: 'DN300 (12")' },
  { label: 'DN400 (16")', value: 'DN400 (16")' },
  { label: 'DN500 (20")', value: 'DN500 (20")' },
  { label: 'DN600 (24")', value: 'DN600 (24")' },
  { label: 'DN750 (30")', value: 'DN750 (30")' },
];
const CONFIG_DIAMETERS_EQUIPMENTS = {
  DN25: { inchDN: 1 },
  DN40: { inchDN: 1.6 },
  DN50: { inchDN: 2 },
  DN80: { inchDN: 3 },
  DN100: { inchDN: 4 },
  DN150: { inchDN: 6 },
  DN200: { inchDN: 8 },
  DN250: { inchDN: 10 },
  DN300: { inchDN: 12 },
  DN400: { inchDN: 16 },
  DN500: { inchDN: 20 },
  DN600: { inchDN: 24 },
  DN750: { inchDN: 30 },
};
const PRESSURE_EQUIPMENTS = [
  { label: 'ANSI 125', value: 'ANSI 125' },
  { label: 'ANSI 150', value: 'ANSI 150' },
  { label: 'ANSI 300', value: 'ANSI 300' },
  { label: 'ANSI 600', value: 'ANSI 600' },
];

// COUNTERS
const MODELS_COUNTERS = {
  ultrasonic: {
    Instromet: [
      { label: 'Q.SONIC-3', value: 'Q.SONIC-3' },
      { label: 'Q.SONIC-4', value: 'Q.SONIC-4' },
      { label: 'Q.SONIC-5', value: 'Q.SONIC-5' },
      { label: 'Q.SONIC-PLUS', value: 'Q.SONIC-PLUS' },
    ],
    KROHNE: [{ label: 'ALTOSONIC V12', value: 'ALTOSONIC V12' }],
    SICK: [{ label: 'FLOWSIC600', value: 'FLOWSIC600' }],
  },
  turbine: {
    ACTARIS: [
      { label: 'FLUXI 2050/TZ', value: 'FLUXI 2050/TZ' },
      { label: 'FLUXI 2080/TZ', value: 'FLUXI 2080/TZ' },
      { label: 'FLUXI 2100/TZ', value: 'FLUXI 2100/TZ' },
      { label: 'FLUXI 2150/TZ', value: 'FLUXI 2150/TZ' },
      { label: 'FLUXI 2200/TZ', value: 'FLUXI 2200/TZ' },
      { label: 'FLUXI 2250/TZ', value: 'FLUXI 2250/TZ' },
      { label: 'FLUXI 2300/TZ', value: 'FLUXI 2300/TZ' },
      { label: 'FLUXI 2400/TZ', value: 'FLUXI 2400/TZ' },
    ],
    ELSTER: [
      { label: 'TRZ', value: 'TRZ' },
      { label: 'TRZ2', value: 'TRZ2' },
      { label: 'TRZ-IFS', value: 'TRZ-IFS' },
    ],
    INSTROMET: [
      { label: 'SM-RI-D', value: 'SM-RI-D' },
      { label: 'SM-RI-F', value: 'SM-RI-F' },
      { label: 'SM-RI-G', value: 'SM-RI-G' },
      { label: 'SM-RI-K', value: 'SM-RI-K' },
      { label: 'SM-RI-L', value: 'SM-RI-L' },
      { label: 'SM-RI-X', value: 'SM-RI-X' },
      { label: 'SM-RI-X-F', value: 'SM-RI-X-F' },
      { label: 'SM-RI-X-K', value: 'SM-RI-X-K' },
      { label: 'SM-RI-X-L', value: 'SM-RI-X-L' },
    ],
    ITRON: [
      { label: 'FLUXI 2080/TZ', value: 'FLUXI 2080/TZ' },
      { label: 'FLUXI 2200/TZ', value: 'FLUXI 2200/TZ' },
      { label: 'FLUXI 2250/TZ', value: 'FLUXI 2250/TZ' },
      { label: 'FLUXI 2300/TZ', value: 'FLUXI 2300/TZ' },
      { label: 'FLUXI 2400/TZ', value: 'FLUXI 2400/TZ' },
    ],
    RMG: [{ label: 'TRZ 03', value: 'TRZ 03' }],
    SCHLUMBERGER: [
      { label: 'FLUXI 2050', value: 'FLUXI 2050' },
      { label: 'FLUXI 2080', value: 'FLUXI 2080' },
      { label: 'FLUXI 2100', value: 'FLUXI 2100' },
      { label: 'FLUXI 2150', value: 'FLUXI 2150' },
      { label: 'FLUXI 2200', value: 'FLUXI 2200' },
      { label: 'FLUXI 2250', value: 'FLUXI 2250' },
      { label: 'FLUXI 2300', value: 'FLUXI 2300' },
    ],
  },
};
const SIZE_COUNTERS = [
  { label: 'G-10', value: 'G-10' },
  { label: 'G-16', value: 'G-16' },
  { label: 'G-25', value: 'G-25' },
  { label: 'G-40', value: 'G-40' },
  { label: 'G-65', value: 'G-65' },
  { label: 'G-100', value: 'G-100' },
  { label: 'G-160', value: 'G-160' },
  { label: 'G-250', value: 'G-250' },
  { label: 'G-400', value: 'G-400' },
  { label: 'G-650', value: 'G-650' },
  { label: 'G-1000', value: 'G-1000' },
  { label: 'G-1600', value: 'G-1600' },
  { label: 'G-2500', value: 'G-2500' },
  { label: 'G-4000', value: 'G-4000' },
  { label: 'G-6500', value: 'G-6500' },
  { label: 'G-10000', value: 'G-10000' },
  { label: 'G-16000', value: 'G-16000' },
];
const CONFIG_SIZE_COUNTERS = {
  'G-10': { qMax: 16, qMin: 1.6 },
  'G-16': { qMax: 25, qMin: 1.25 },
  'G-25': { qMax: 40, qMin: 2 },
  'G-40': { qMax: 65, qMin: 3 },
  'G-65': { qMax: 100, qMin: 5 },
  'G-100': { qMax: 160, qMin: 8 },
  'G-160': { qMax: 250, qMin: 13 },
  'G-250': { qMax: 400, qMin: 20 },
  'G-400': { qMax: 650, qMin: 32 },
  'G-650': { qMax: 1000, qMin: 50 },
  'G-1000': { qMax: 1600, qMin: 80 },
  'G-1600': { qMax: 2500, qMin: 130 },
  'G-2500': { qMax: 4000, qMin: 200 },
  'G-4000': { qMax: 6500, qMin: 320 },
  'G-6500': { qMax: 10000, qMin: 500 },
  'G-10000': { qMax: 16000, qMin: 500 },
  'G-16000': { qMax: 25000, qMin: 500 },
};

// ULTRASONICS
const HARDWARE_MUS = {
  'Q.SONIC-3': [
    { label: 'SERIE_III', value: 'SERIE_III' },
    { label: 'SERIE_IV_ALTA_RES', value: 'SERIE_IV_ALTA_RES' },
    { label: 'SERIE_IV_BAJA_RES', value: 'SERIE_IV_BAJA_RES' },
  ],
  'Q.SONIC-4': [
    { label: 'SERIE_III', value: 'SERIE_III' },
    { label: 'SERIE_IV_ALTA_RES', value: 'SERIE_IV_ALTA_RES' },
    { label: 'SERIE_IV_BAJA_RES', value: 'SERIE_IV_BAJA_RES' },
  ],
  'Q.SONIC-5': [
    { label: 'SERIE_III', value: 'SERIE_III' },
    { label: 'SERIE_IV_ALTA_RES', value: 'SERIE_IV_ALTA_RES' },
    { label: 'SERIE_IV_BAJA_RES', value: 'SERIE_IV_BAJA_RES' },
  ],
  'Q.SONIC-PLUS': [
    { label: 'SERIE_IV_ALTA_RES', value: 'SERIE_IV_ALTA_RES' },
    { label: 'SERIE_IV_BAJA_RES', value: 'SERIE_IV_BAJA_RES' },
  ],
};
// CHROMATOGRAPHS
const MODELS_CHROMATOGRAPHS = {
  ABB: [
    { label: 'NCG 8206', value: 'NCG 8206' },
    { label: 'TOTALFLOW 8100', value: 'TOTALFLOW 8100' },
  ],
  AGILENT: [{ label: '490', value: '490' }],
  CHROMPACK: [{ label: 'MICROGC CP2002', value: 'MICROGC CP2002' }],
  'CHROMPACK-THT': [{ label: 'CP2002', value: 'CP2002' }],
  DANIEL: [
    { label: '500/2350A', value: '500/2350A' },
    { label: '700', value: '700' },
  ],
  Instromet: [{ label: 'ENCAL3000', value: 'ENCAL3000' }],
  VARIAN: [{ label: 'CP-4900', value: 'CP-4900' }],
};

// FLOW_COMPUTERS
const FLOW_COMPUTER_MODELS = {
  ELSTER: [{ label: 'ENCORE FC1', value: 'ENCORE FC1' }],
  EMERSON: [{ label: 'FLOBOSS S600+', value: 'FLOBOSS S600+' }],
  'EX-I FLOW': [{ label: 'SFC3000', value: 'SFC3000' }],
  Instromet: [
    { label: '782-7', value: '782-7' },
    { label: 'FC2000', value: 'FC2000' },
  ],
  KROHNE: [{ label: 'SUMMIT 8800', value: 'SUMMIT 8800' }],
  MECI: [{ label: 'CDV15', value: 'CDV15' }],
  'TECLAB (cliente)': [{ label: 'GENIUS 3', value: 'GENIUS 3' }],
};
// PATTERNS
const PATTERN_MODELS = {
  ADDITEL: [{ label: 'ADT875PC-155', value: 'ADT875PC-155' }],
  AMETEK: [
    { label: '250SE', value: '250SE' },
    { label: 'ATC-125A', value: 'ATC-125A' },
  ],
  BEAMEX: [
    { label: 'FB150', value: 'FB150' },
    { label: 'FB150R', value: 'FB150R' },
    { label: 'MC2', value: 'MC2' },
    { label: 'MC5', value: 'MC5' },
    { label: 'MC5-IS', value: 'MC5-IS' },
    { label: 'MC6', value: 'MC6' },
  ],
  DRUCK: [
    { label: 'DPI 620 IS', value: 'DPI 620 IS' },
    { label: 'DPI605', value: 'DPI605' },
    { label: 'MCX-II', value: 'MCX-II' },
  ],
  FLUKE: [{ label: '9142', value: '9142' }],
  'FLUKE HARTSCIENTIFIC': [{ label: '1523', value: '1523' }],
  GOMETRICS: [{ label: 'TGT-2070', value: 'TGT-2070' }],
  'HART SCIENTIFIC': [
    { label: '1521', value: '1521' },
    { label: '1522', value: '1522' },
    { label: '717', value: '717' },
    { label: '9103', value: '9103' },
  ],
  ISOTECH: [{ label: 'VENUS-2140B', value: 'VENUS-2140B' }],
};

const INITIAL_AREA_PATTERN = {
  key: 0,
  index: 0,
  areaName: '',
  areaDescription: '',
  certificateCode: null,
  instalationDate: undefined,
  issueDate: new Date(),
  expirationDate: addMonths(new Date(), 12),
  statusCertificate: 'valid',
  validityTime: 12,
};

const BOTTLE_MODELS = {
  'ABELLO LINDE S.A.': [{ label: 'ABELLO LINDE S.A.', value: 'ABELLO LINDE S.A.' }],
  'AIR LIQUIDE': [{ label: 'AIR LIQUIDE', value: 'AIR LIQUIDE' }],
  'Carburos Metálicos': [{ label: 'Carburos Metálicos', value: 'Carburos Metálicos' }],
  'Enagás LCE': [{ label: 'Enagás LCE', value: 'Enagás LCE' }],
};

const BOTTLE_TYPES = (t) => ({
  'ABELLO LINDE S.A.': [
    { label: t('BottlePage:types:sulfur'), value: 'sulfur' },
    { label: 'THT', value: 'THT' },
    { label: t('BottlePage:types:naturalGas'), value: 'naturalGas' },
  ],
  'AIR LIQUIDE': [{ label: t('BottlePage:types:naturalGas'), value: 'naturalGas' }],
  'Carburos Metálicos': [{ label: t('BottlePage:types:naturalGas'), value: 'naturalGas' }],
  'Enagás LCE': [{ label: t('BottlePage:types:naturalGas'), value: 'naturalGas' }],
});

export {
  ITEMS_PER_PAGE,
  EMPTY_FILTERS,
  DEFAULT_VALUE,
  HEADERS_LIST_EQUIPMENTS,
  EQUIPMENTS_OPTIONS,
  COUNTER_TYPES,
  FLOW_COMPUTER_TYPES,
  PARSER_TYPES,
  CLIENT_TYPES,
  VARIABLES_FOOTPRINT,
  FOOTPRINT_HEADERS,
  FOOTPRINT_HEADERS_HISTORY,
  FOOTPRINT_HEADERS_DATA,
  BRANDS_EQUIPMENTS,
  DIAMETERS_EQUIPMENTS,
  CONFIG_DIAMETERS_EQUIPMENTS,
  PRESSURE_EQUIPMENTS,
  HARDWARE_MUS,
  SIZE_COUNTERS,
  CONFIG_SIZE_COUNTERS,
  MODELS_CHROMATOGRAPHS,
  MODELS_COUNTERS,
  FLOW_COMPUTER_MODELS,
  PATTERN_MODELS,
  INITIAL_AREA_PATTERN,
  PATTERN_AREAS,
  CONFIG_STATUS_CERTIFICATE,
  BOTTLE_MODELS,
  BOTTLE_TYPES,
};
