import { createPortal } from 'react-dom';
import React, { useEffect, memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ModalStyles, ModalContent, ModalHeader, ModalFooter } from './styles';

const Modal = ({ children, styles, zIndex }) => {
  const HTMLBody = document.body;
  const modalContainer = useRef(document.createElement('div'));
  const [dynamic] = useState(!modalContainer.current.parentElement);

  useEffect(() => {
    if (dynamic) {
      HTMLBody.appendChild(modalContainer.current);
    }

    return () => {
      if (dynamic && modalContainer.current.parentElement) {
        modalContainer.current.parentElement.removeChild(modalContainer.current);
      }
    };
  }, [HTMLBody, dynamic]);

  return createPortal(<ModalStyles {...{ styles, zIndex }}>{children}</ModalStyles>, modalContainer.current);
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  zIndex: PropTypes.string,
  alignSelf: PropTypes.oneOf(['center', 'flex-end', 'flex-start']),
  styles: PropTypes.shape({
    fullScreen: PropTypes.bool,
  }),
};

Modal.defaultProps = {
  styles: {
    fullScreen: false,
  },
  alignSelf: 'center',
};

export default memo(Modal);
export { ModalStyles, ModalHeader, ModalContent, ModalFooter };
