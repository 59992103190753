import { css } from 'styled-components';

export default {
  type: {
    info: css`
      background-color: #e6f7ff;
      border: 1px solid #91d5ff;
    `,
    error: css`
      background-color: #fff1f0;
      border: 1px solid #ffa39e;
    `,
    success: css`
      background-color: #f6ffed;
      border: 1px solid #b7eb8f;
    `,
    warning: css`
      background-color: #fffbe6;
      border: 1px solid #ffe58f;
    `,
  },
};
