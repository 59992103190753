import {
  format,
  subDays,
  differenceInCalendarDays,
  setHours,
  addMinutes,
  addHours,
  addDays,
  subHours,
  subMinutes,
  setMinutes,
  addMonths,
  subMonths,
  setDate,
  differenceInDays,
  set,
  differenceInMonths,
} from 'date-fns';

const transformerFunctions = {
  minutes: addMinutes,
  hours: addHours,
  days: addDays,
};

const getNextDate = (date, unit, quantity = 1) => {
  return transformerFunctions[unit](new Date(date), quantity);
};

const getDateInTimezone = (date) => {
  const localDate = new Date(`${date} UTC`);
  return new Date(localDate.toString());
};

const getDateISOString = (date) => new Date(date).toISOString();

const getDateTimeFormated = (date) => `${date.getHours()}:${String(date.getSeconds()).padStart(2, '0')} h`;

const getDateFormated = (date, formatDate = 'dd/MM/yyyy') => format(date, formatDate);

const getFullDateFormat = (date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getSeconds()}`;
};

const areSameDate = (date, dateToCompare) => getDateFormated(date) === getDateFormated(dateToCompare);

const getGasDayRegardingDate = (date = new Date()) => (date.getHours() < 6 ? subDays(date, 1) : date);

const getOffsetTimezone = (date = new Date()) => date.getTimezoneOffset() / 60;

const isSameDate = (date, dateToCompare) => getDateFormated(date) === getDateFormated(dateToCompare);

const differenceIn = (earlierDate, laterDate, { diff = 'days' } = {}) => {
  if (diff === 'days') {
    return differenceInDays(laterDate, earlierDate);
  }
  if (diff === 'months') {
    return differenceInMonths(laterDate, earlierDate);
  }
  return 0;
};
const getNextWorkingDay = (date, afterHour = 0) => {
  if (date.getHours() < afterHour) return date;
  let nextDay = addDays(date, 1);
  nextDay = [5, 6].includes(date.getDay()) ? addDays(date, 8 - date.getDay()) : nextDay;
  return setMinutes(setHours(nextDay, afterHour), 0);
};

const getNextMonday = (date, afterHour = 0) => {
  if (date.getDay() === 1 && date.getHours() < afterHour) return date;
  const nextDay = date.setDate(date.getDate() + ((1 + 7 - date.getDay()) % 7));
  return setMinutes(setHours(nextDay, afterHour), 0);
};

const getNextFirstDayMonth = (date, afterHour = 0) => {
  if (date.getDate() === 1 && date.getHours() < afterHour) return date;
  const nextDayMonth = setDate(date, 1);
  return setMinutes(setHours(nextDayMonth, afterHour), 0);
};

const getStringDateLanguage = ({
  date,
  options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  language,
}) => date.toLocaleDateString(language, options);

// CONSTANTS
const getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const gasStartEndDayTime = { hours: 6, minutes: 0, seconds: 0 };

const transformDateToUTC = (date) => {
  const dateUTC = typeof date === 'string' ? new Date(date) : date;
  dateUTC.setFullYear(dateUTC.getUTCFullYear());
  dateUTC.setMonth(dateUTC.getUTCMonth());
  dateUTC.setDate(dateUTC.getUTCDate());
  dateUTC.setHours(dateUTC.getUTCHours());
  dateUTC.setMinutes(dateUTC.getUTCMinutes());
  dateUTC.setSeconds(dateUTC.getUTCSeconds());
  return dateUTC;
};

export default {
  areSameDate,
  getDateFormated,
  getDateTimeFormated,
  getFullDateFormat,
  getGasDayRegardingDate,
  getOffsetTimezone,
  isSameDate,
  differenceInCalendarDays,
  setHours,
  setMinutes,
  getNextDate,
  addHours,
  addMinutes,
  addDays,
  addMonths,
  subHours,
  subMinutes,
  subDays,
  subMonths,
  setDate,
  set,
  differenceIn,
  getNextWorkingDay,
  getNextMonday,
  getNextFirstDayMonth,
  getStringDateLanguage,
  getTimeZone,
  getDateInTimezone,
  gasStartEndDayTime,
  transformDateToUTC,
};

export {
  areSameDate,
  getDateFormated,
  getDateTimeFormated,
  getFullDateFormat,
  getGasDayRegardingDate,
  addDays,
  subDays,
  getOffsetTimezone,
  isSameDate,
  differenceInCalendarDays,
  setHours,
  addMinutes,
  addHours,
  subHours,
  subMinutes,
  subMonths,
  setDate,
  addMonths,
  differenceIn,
  set,
  format,
  getNextWorkingDay,
  getNextMonday,
  getNextFirstDayMonth,
  getStringDateLanguage,
  getNextDate,
  getTimeZone,
  getDateInTimezone,
  getDateISOString,
  gasStartEndDayTime,
  transformDateToUTC,
};
