import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MainLayout, Spinner } from 'ui';

import { selectors } from 'modules/auth';
import useAuth from './useAuth';

function PrivateRoute({ children, ...rest }) {
  const { isLoading, isAuth } = useAuth();
  const { auth } = useSelector((state) => ({
    auth: selectors.getUserAuth(state),
  }));

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isLoading) {
          return <Spinner />;
        }

        return isAuth ? (
          <MainLayout auth={auth}>{children}</MainLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

export default PrivateRoute;
