import React, { useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';

const SelectCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </div>
  );
});

SelectCheckbox.displayName = 'SelectCheckbox';

SelectCheckbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  indeterminate: PropTypes.any,
};

SelectCheckbox.defaultProps = {
  indeterminate: null,
};

const SelectCheckBoxRow = ({ row }) => <SelectCheckbox {...row.getToggleRowSelectedProps()} />;

SelectCheckBoxRow.propTypes = {
  row: PropTypes.shape({
    getToggleRowSelectedProps: PropTypes.func.isRequired,
  }).isRequired,
};

const SelectCheckBoxAllRows = ({ getToggleAllRowsSelectedProps }) => (
  <SelectCheckbox {...getToggleAllRowsSelectedProps()} />
);

SelectCheckBoxAllRows.propTypes = {
  getToggleAllRowsSelectedProps: PropTypes.func.isRequired,
};

export { SelectCheckBoxRow, SelectCheckBoxAllRows };

export default SelectCheckbox;
