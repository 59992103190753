import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import Input from '../form/input';
import Button from '../button';
import Popconfirm from '../popConfirm';
import { Search as SearchInput, Filters } from './styles';

interface Props {
  onSearch: React.ChangeEventHandler<HTMLInputElement>;
  children?: React.ReactNode;
}

const FilterBar = styled.div<{
  justify?: 'between' | 'end' | 'start' | 'center';
  noSeparation?: boolean;
  filterBarWithFrame?: boolean;
}>`
  width: 100%;
  display: flex;
  ${({ justify = 'space-between', theme }) =>
    theme.table.alignActionsBar[justify as keyof typeof theme.table.alignActionsBar]};
  margin: ${({ noSeparation }) => (noSeparation ? '0px' : '10px 0')};
  align-items: center;

  ${(props) =>
    props.filterBarWithFrame &&
    css`
      padding: 0 15px;
      background: #ffffff;
      box-shadow: 0px 0px 5px rgba(51, 52, 79, 0.07);
      border-radius: 3px;
      margin-bottom: 10px;
      position: sticky;
      justify-content: space-between;
      top: 0;
      z-index: 9;
    `}

  input {
    max-width: 270px;
  }
  button {
    margin-left: 10px;
  }
`;

const Search = ({ onSearch, children }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Filters>
      <SearchInput>
        <Input onChange={onSearch} data-cy="searchFilter" placeholder={t('commonTexts:introduceSearch')} />
      </SearchInput>
      {children || null}
    </Filters>
  );
};

Search.defaultProps = {
  children: null,
};

const Delete = ({
  onDelete,
}: {
  onDelete: (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Popconfirm
      placement="left"
      title={t('commonTexts:confirmRemove')}
      onConfirm={onDelete}
      okText={t('commonTexts:yes')}
      cancelText={t('commonTexts:no')}
    >
      <Button variant="link" data-cy="del" color="danger">
        {t('actions:DeleteEntity')}
      </Button>
    </Popconfirm>
  );
};

const Create = ({
  onCreate,
  children,
}: {
  onCreate: (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
  children?: React.ReactNode;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button color="white" variant="primary" data-cy="add" onClick={onCreate}>
      {children || t('actions:NewEntity')}
    </Button>
  );
};

Create.defaultProps = {
  children: null,
};

export { Search, Delete, Create, FilterBar };
