import React from 'react';
import { SpinProps } from 'antd';
import { SpinnerStyles, Wrapper } from './styles';

interface Props extends Partial<SpinProps> {
  tip?: string;
  height?: string;
  children?: React.ReactNode;
}

const Spinner: React.FC<Props> = ({ tip = '', size = 'large', children = null, height = '82vh' }) => (
  <Wrapper {...{ height }}>
    <SpinnerStyles {...{ tip, size }}>{children}</SpinnerStyles>
  </Wrapper>
);

export default Spinner;
