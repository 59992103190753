import React from 'react';

import Text from '../tipography/text';
import { TooltipStyles } from './styles';

const Tooltip = ({ children, title, ...props }) => (
  <TooltipStyles
    title={
      <Text color="white" size="samll">
        {title}
      </Text>
    }
    {...props}
  >
    {children}
  </TooltipStyles>
);

export default Tooltip;
