import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Tooltip from '../tooltip';
import Icon from '../icons/index';
import {
  NavStyles,
  ItemMenuStyles,
  FixedContainerStyles,
  HeaderImgStyles,
  ItemsDropdownStyles,
  MenuStyles,
  ContainerStyles,
} from './styles';

const Menu = ({ config }) => {
  const printItems = (items) => {
    return items.map(({ to, text, iconName, iconSize, children, id, className, exact }) => {
      if (!children) {
        return (
          <ItemMenuStyles key={id} className={className}>
            <Tooltip title={text} color="#5f78ffdb" placement="right" key={`${id}-tooltip-item`}>
              <NavLink
                key={`${id}-nav-link-item`}
                to={to}
                activeStyle={{
                  opacity: '1',
                  background: '#5f78ff',
                }}
                exact={exact}
              >
                <Icon size={iconSize} iconName={iconName} color="white" />
              </NavLink>
            </Tooltip>
          </ItemMenuStyles>
        );
      }

      const childrenComponent = children.map(({ to: childrenTo, text: textChild, id: idChild }) => {
        return (
          <ItemMenuStyles key={`${idChild}`}>
            <NavLink to={childrenTo}>{textChild}</NavLink>
          </ItemMenuStyles>
        );
      });

      return (
        <Fragment key={id}>
          <ItemMenuStyles key={`${id}-item`}>
            <NavLink
              key={`${id}-nav-link-item`}
              to={to}
              activeStyle={{
                opacity: '1',
                background: '#5292bdcc',
              }}
              exact={exact}
            >
              <Icon size={iconSize} iconName={iconName} color="white" />
              {text}
            </NavLink>
          </ItemMenuStyles>
          <ItemsDropdownStyles key={`${id}-submenu`}>{childrenComponent}</ItemsDropdownStyles>
        </Fragment>
      );
    });
  };

  const { HeaderComponent, body, bottom } = config;

  return (
    <ContainerStyles>
      <FixedContainerStyles>
        <NavStyles>
          <HeaderComponent />
          <MenuStyles>
            <div>{printItems(body || [])}</div>
            <div>{printItems(bottom || [])}</div>
          </MenuStyles>
        </NavStyles>
      </FixedContainerStyles>
    </ContainerStyles>
  );
};

Menu.propTypes = {
  config: PropTypes.shape({
    HeaderComponent: PropTypes.node,
    body: PropTypes.node,
    bottom: PropTypes.node,
  }).isRequired,
};

export { HeaderImgStyles };

export default Menu;
