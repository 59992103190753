import styled from 'styled-components';

const InputStyles = styled.input<{
  hasError?: boolean;
}>`
  font-size: 0.8rem;
  padding-left: 10px;
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  height: 37px;
  background-color: #fafbfc;

  :focus {
    outline: 0;
    border-color: ${(props) => props.theme.colors.focusField};
  }

  :disabled {
    cursor: no-drop;
    opacity: 0.6;
  }

  :hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  }
  ${({ hasError }) => (hasError ? 'border: 2px solid red;' : null)} :required {
    border: 1px solid ${(props) => props.theme.colors.danger};
  }

  ::placeholder,
  textarea::placeholder {
    color: #7f8a9d;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #dfdfdf;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export { InputStyles };
