import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input, Select, RowFlex, Icon, Tooltip, Text } from 'ui';
import { GRADIENT_PERIODS_OPTIONS } from '../constants';

const InputsValues = ({ alertType }) => {
  const { t } = useTranslation();
  const { register, control, errors, getValues } = useFormContext();
  return (
    <>
      <hr />
      <Text strong color="primary">
        {t('components:alerts:inputsValues-alertValue-text')}
      </Text>
      {alertType && alertType.value === 'gradient' && (
        <RowFlex justify="space-between" align="center">
          <Controller
            as={
              <Select
                textLabel={t('components:alerts:inputsValue-select-period-label')}
                noSeparation
                isSearchable
                width={50}
                menuPlacement="top"
                options={GRADIENT_PERIODS_OPTIONS}
                name="gradientPeriod"
                errors={errors}
              />
            }
            control={control}
            rules={{ required: t('forms:validations:required') }}
            onChange={([selected]) => {
              return selected;
            }}
            name="gradientPeriod"
          />

          <Input
            type="number"
            width={48}
            textLabel={t('components:alerts:inputsValues-input-value-label')}
            placeholder={t('components:alerts:inputsValues-input-value-placeholder')}
            ref={register({ required: t('forms:validations:required') })}
            name="gradientValue"
            step="any"
            errors={errors}
          />
        </RowFlex>
      )}

      {alertType && alertType.value === 'threshold' && (
        <>
          <RowFlex justify="space-between">
            <Input
              type="number"
              width={48}
              textLabel={t('components:alerts:inputsValues-input-thresholdMin-label')}
              ref={register({
                validate: (value) => {
                  if (!value) {
                    return t('forms:validations:required');
                  }
                  const currentValues = getValues();
                  if (Number(currentValues.thresholdMax) <= Number(value)) {
                    return t('forms:validations:greaterThan', { field: 'thresholdMax' });
                  }
                  return true;
                },
              })}
              name="thresholdMin"
              step="any"
              errors={errors}
            />
            <Input
              type="number"
              width={48}
              textLabel={t('components:alerts:inputsValues-input-thresholdMax-label')}
              ref={register({ required: t('forms:validations:required') })}
              name="thresholdMax"
              step="any"
              errors={errors}
            />
          </RowFlex>
        </>
      )}
      {alertType && alertType.value === 'delta_pcs' && (
        <RowFlex align="center">
          <Input
            width={48}
            type="number"
            textLabel={t('components:alerts:inputsValues-input-pcsValue-label')}
            ref={register({ required: t('forms:validations:required') })}
            name="pcsValue"
            step="any"
            errors={errors}
          />
          <Tooltip title={t('components:alerts:inputsValues-tooltip-pcsValue-title')} placement="bottomLeft">
            <div>
              <Icon pointer iconName="icon-info" color="primary" />
            </div>
          </Tooltip>
        </RowFlex>
      )}
      <hr />
    </>
  );
};

InputsValues.propTypes = {
  alertType: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
};

InputsValues.defaultProps = {
  alertType: undefined,
};

export default InputsValues;
