const translateTypes = (type) => {
  const MAP = {
    delta: 'delta',
    threshold: 'threshold',
    delta_pcs: 'delta_pcs',
    gradient: 'gradient',
    Footprint_chroma: 'footprint_chroma',
  };

  return MAP[type] || type;
};

const COLORS_STATUS = {
  open: '#ff6985',
  closed: '#1deac5',
};

export { translateTypes, COLORS_STATUS };
