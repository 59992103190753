import { clone, mergeDeepRight } from 'libs/toolkits';
import { getDateFormated } from 'libs/date';
import { formatNumber } from 'libs/math';
import {
  VARIABLES_BARS,
  CONFIG_VARIABLES,
  DEFAULT_CONFIG_VARIABLE,
  CONFIG_VARIABLES_EXTRA,
  getConfigNameVariables,
} from 'config/variables';
import { OPTION, SERIE, ITEM_STYLE_BAR } from './constants';

const generateSeries = ({ variableName, variableData, configVariable, configNameVariables, t, selectedWindow }) => {
  return Object.entries(variableData).map(([line, { data, gasDay }], idx) => {
    const name = configNameVariables[line];
    const color = CONFIG_VARIABLES_EXTRA[name] ? CONFIG_VARIABLES_EXTRA[name].colors : configVariable.colors.shift();
    return {
      ...clone(SERIE(t)),
      name,
      data,
      variableName,
      ...(!selectedWindow
        ? {
            markLine: {
              symbol: 'circle',
              data: gasDay.map((v) => ({
                xAxis: v,
                itemStyle: {
                  normal: {
                    color: 'green',
                  },
                },
                label: {
                  position: 'start',
                  show: false,
                },
              })),
            },
          }
        : {}),
      ...(line === 'total' || !VARIABLES_BARS.includes(variableName) || line.includes('pred')
        ? {
            type: 'line',
            id: idx,
            itemStyle: {
              normal: {
                color,
              },
            },
          }
        : {
            type: 'bar',
            stack: variableName,
            id: idx,
            barMaxWidth: 35,
            itemStyle: {
              ...clone(ITEM_STYLE_BAR),
              normal: {
                color,
                opacity: '0.9',
              },
            },
          }),
    };
  });
};

const getConfigGraph = ({ data, dates, t, measurementUnitName, variableLabel }) => {
  const [[variableName, variableData]] = Object.entries(data);
  const configVariable = Object.keys(CONFIG_VARIABLES).includes(variableName)
    ? clone(CONFIG_VARIABLES[variableName])
    : clone(DEFAULT_CONFIG_VARIABLE);

  const variableNameTranslate = t(`variables:${CONFIG_VARIABLES[variableName].name}`);
  const variableNameTranslateAbv = variableNameTranslate.split(' ')[0];
  const deltaLabel = VARIABLES_BARS.includes(variableName) && variableName !== 'qb' ? 'Δ ' : '';
  const configNameVariables = getConfigNameVariables({ variableData, measurementUnitName, variableName });

  const yAxis = mergeDeepRight(OPTION(t).yAxis, {
    name: `${deltaLabel}${variableNameTranslateAbv} ${configVariable.unit}`,
  });

  const xAxis =
    dates && dates.startDate && dates.startDate
      ? mergeDeepRight(OPTION(t).xAxis, {
          max: `${dates.endDate} 06:00`,
          min: `${dates.startDate} 06:00`,
        })
      : clone(OPTION(t).xAxis);
  const legend = mergeDeepRight(OPTION(t).legend, {
    data: Object.entries(configNameVariables).map(([, name]) => name),
  });
  const selectedWindow = dates.selectedWindow ? t('BalancePages:Daily') : t('BalancePages:Hourly');

  const title = mergeDeepRight(OPTION(t).title, {
    text: '',
    subtext: dates.selectedWindow ? t('MeasuresPage:DailyRepresentation') : t('MeasuresPage:HourlyRepresentation'),
  });

  const formatterTooltip = (params) => {
    const tooltip = params.reduce((acc, value) => {
      const { unit = '[-]', decimals = 0 } = configVariable;
      return ` ${acc} <br/> <span>${value.marker}</span><span>
				 ${value.seriesName}:</span> <span>${
        value.value[1]
          ? formatNumber(
              value.value[1],
              undefined,
              decimals ? { maximumFractionDigits: decimals, minimumFractionDigits: decimals } : {}
            )
          : t('ReportPages:NoValue')
      } </span> <span>${unit}<span>`;
    }, `${params && params[0] ? getDateFormated(new Date(params[0].axisValue), 'dd/MM/yyyy HH:mm:ss') : ''}`);

    return tooltip;
  };

  const tooltip = mergeDeepRight(OPTION(t).tooltip, {
    text: variableNameTranslate,
    formatter: formatterTooltip,
  });
  const dateNow = getDateFormated(new Date(), 'yyyy-MM-dd');
  const graphName = `${t('entitiesNames:Dashboard')}_${dateNow}_${selectedWindow}`;
  const option = mergeDeepRight(OPTION(t, graphName), {
    series: generateSeries({
      variableName,
      variableData,
      configVariable,
      configNameVariables,
      t,
      selectedWindow: dates.selectedWindow,
    }),
    legend,
    xAxis,
    yAxis,
    title,
    tooltip,
  });

  return option;
};

export default getConfigGraph;
