import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { List } from 'antd';
import InfiniteScroller from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';

import { MessageEmpty } from '../table/styles';
import Spinner from '../spinner';
import ImageNoResult from '../../assets/images/table_no-result.svg';
import { ListContainer } from './styles';

const InfiniteScroll = (props) => {
  const {
    isLoading,
    onLoadMore,
    hasMore,
    total,
    height,
    id,
    RenderItem,
    RenderHeader,
    RenderFooter,
    data,
    noResultText,
  } = props;
  const scrollRef = React.createRef(id);
  const [selectedRow, setSelectedRow] = useState();
  const [hoveredRow, setHoveredRow] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.pageLoaded = 0;
    }
  }, [scrollRef, total]);
  const handleOnSelect = (key) => {
    setSelectedRow(key);
  };

  const handleMouseEnter = (key) => {
    setHoveredRow(key);
  };

  if (!isLoading && total === 0)
    return (
      <MessageEmpty>
        <div>
          <img src={ImageNoResult} alt="" />
          <h3>{t(noResultText)}</h3>
        </div>
      </MessageEmpty>
    );

  return (
    <ListContainer style={{ height }} ref={scrollRef}>
      <InfiniteScroller pageStart={0} loadMore={onLoadMore} hasMore={!isLoading && hasMore} useWindow={false}>
        <List
          header={RenderHeader}
          footer={RenderFooter}
          dataSource={data}
          renderItem={(item) => (
            <RenderItem
              {...item}
              isSelected={item.id === selectedRow}
              isHovered={item.id === hoveredRow}
              onClick={() => {
                handleOnSelect(item.id);
              }}
              onMouseEnter={() => handleMouseEnter(item.id)}
            />
          )}
        >
          {isLoading && <Spinner height="200px" />}
        </List>
      </InfiniteScroller>
    </ListContainer>
  );
};
InfiniteScroll.propTypes = {
  children: PropTypes.node.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  height: PropTypes.string,
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    length: PropTypes.number,
  }).isRequired,
  RenderItem: PropTypes.node.isRequired,
  RenderHeader: PropTypes.node,
  RenderFooter: PropTypes.node,
  noResultText: PropTypes.string,
};

InfiniteScroll.defaultProps = {
  height: 'calc(100vh - 261px)',
  RenderHeader: null,
  RenderFooter: null,
  noResultText: 'commonTexts:NoResultsSearch',
};

export default InfiniteScroll;
