import React from 'react';

import SearchDeleteCreate from './SearchDeleteCreate';
import ExportTable from './ExportTable';
import DownloadRows from './DownloadRows';
import { defaultGeneratorActionRow, generateActionRows } from './generateActionRows';

const TYPES = {
  SEARCH_DELETE_CREATE: 'search-delete-create',
  EXPORT: 'export',
  DOWNLOAD: 'download-rows',
};

const factoryActionsCreator = ({ type = TYPES.SEARCH_DELETE_CREATE, ...extraProps }) => {
  switch (type) {
    case TYPES.EXPORT:
      return function factoryActions(props) {
        return <ExportTable {...props} {...extraProps} />;
      };
    case TYPES.DOWNLOAD:
      return function factoryActions(props) {
        return <DownloadRows {...props} {...extraProps} />;
      };
    case TYPES.SEARCH_DELETE_CREATE:
      return function factoryActions(props) {
        return <SearchDeleteCreate {...props} {...extraProps} />;
      };
    default:
      return function factoryActions(props) {
        return <SearchDeleteCreate {...props} {...extraProps} />;
      };
  }
};

factoryActionsCreator.TYPES = TYPES;
factoryActionsCreator.ROWS = {
  defaultGeneratorActionRow,
  generateActionRows,
};

export default factoryActionsCreator;
