import PropTypes from 'prop-types';

import { TabStyles as Tabs, TabPaneStyles as TabPane } from './styles';

TabPane.propTypes = {
  noPadding: PropTypes.bool,
};

TabPane.defaultProps = {
  noPadding: false,
};

export { Tabs, TabPane };
