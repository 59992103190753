import React from 'react';

import { BoxStyles } from './styles';

interface Props extends Partial<Omit<HTMLDivElement, 'children'>> {
  children: React.ReactNode;
  fullHeight?: boolean;
  onClick?: () => void;
  shadow?: boolean;
  noPadding?: boolean;
  overflowHidden?: boolean;
  overflowAuto?: boolean;
  transparent?: boolean;
  flexAuto?: boolean;
  size?: 'small';
}

const Box: React.FC<Props> = ({
  children = null,
  fullHeight,
  onClick,
  shadow,
  noPadding,
  overflowHidden,
  overflowAuto,
  transparent,
  flexAuto,
  size,
}) => (
  <BoxStyles {...{ fullHeight, shadow, onClick, noPadding, transparent, overflowHidden, overflowAuto, flexAuto, size }}>
    {children}
  </BoxStyles>
);

export default Box;
