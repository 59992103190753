import i18n from 'config/i18n';

const MESSAGES_NOTIFICATIONS = (
  action: string,
  entity: string,
  field?: string,
  name?: string
): {
  [key: number]: string;
} => ({
  400: i18n.t('messages:bad-request'),
  401: i18n.t('messages:not-access'),
  403: i18n.t('messages:not-permission-action'),
  409: i18n.t('messages:error-exists', { entity: i18n.t(entity), field: field && i18n.t(field), name }),
  410: i18n.t('messages:entity-config-exists', { entity: i18n.t(entity), field: field && i18n.t(field) }),
  500: i18n.t('messages:server-error', { action: i18n.t(action), entity: i18n.t(entity) }),
  503: i18n.t('messages:kubernetes-error'),
  200: i18n.t('messages:entity-action-successfully', { action: i18n.t(action), entity: i18n.t(entity) }),
});

export { MESSAGES_NOTIFICATIONS };

export default MESSAGES_NOTIFICATIONS;
