import styled from 'styled-components';

const ContainerGraphs = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ContainerGraph = styled.div`
  display: inline-block;
  box-shadow: 0 0px 6px 0 #e4e4e4;
  background: white;
  padding: 12px;
  border-radius: 5px;
  margin: 5px;
  position: relative;
  height: 490px;
  padding-top: 30px;
  width: ${({ fullWidth }) => (fullWidth ? 'calc(100% - 8px)' : 'calc(50% - 11px)')};
`;

const TitleGraph = styled.div`
  h3 {
    display: flex;
    margin-bottom: 0px;
    position: absolute;
    z-index: 9;
    align-items: center;
    font-size: 19px;
    top: 16px;
    left: 16px;

    span {
      margin-left: 10px;
      color: #7171a6;
    }
  }
`;

export { ContainerGraphs, ContainerGraph, TitleGraph };
