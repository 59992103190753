import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { can } from 'libs/authorization';
import Button from '../../button';
import Tooltip from '../../tooltip';
import Text from '../../tipography/text';
import { RowActions } from '../styles';
import Popconfirm from '../../popConfirm';

const ActionButton = ({ title, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={
        <Text style={{ margin: '0' }} color="white" size="small">
          {t(title)}
        </Text>
      }
      placement="bottom"
    >
      <Button {...rest} variant="plain" color="light" />
    </Tooltip>
  );
};

ActionButton.propTypes = {
  title: PropTypes.string,
};
ActionButton.defaultProps = {
  title: null,
};
const ActionButtonConfirm = ({ title, titleConfirm, onClick, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Popconfirm
      placement="left"
      title={t(titleConfirm)}
      onConfirm={onClick}
      okText={t('commonTexts:yes')}
      cancelText={t('commonTexts:no')}
    >
      <Tooltip
        title={
          <Text style={{ margin: '0' }} color="white" size="small">
            {t(title)}
          </Text>
        }
        placement="bottom"
      >
        <Button {...rest} variant="plain" data-cy={`btn-${t(title).trim()}`} color="light" />
      </Tooltip>
    </Popconfirm>
  );
};

ActionButtonConfirm.propTypes = {
  title: PropTypes.string,
  titleConfirm: PropTypes.string,
  onClick: PropTypes.func,
};
ActionButtonConfirm.defaultProps = {
  title: null,
  titleConfirm: null,
  onClick: () => {},
};
const getComponent = (component) => {
  if (component) {
    return component;
  }
  return ActionButton;
};

/*
  configActions: [{
    action: String!
    propsGenerator: ({ tableState, row }) => Object, function generator props for component
    componentType: button | ... default button
    component React Component
  }]
*/
const generateActionRows = (columns, configActions) => {
  if (!configActions || !configActions.length) {
    return columns;
  }
  return [
    ...columns,
    {
      Header: '',
      accessor: 'actions',
      fixed: 'right',
      minWidth: 0,
      maxWidth: 0,
      width: 0,
      render: (text, record) => {
        const actionsComponents = record.actions.map((actionName, index) => {
          const config = configActions.find((conf) => conf.action === actionName);
          if (!config) {
            return null;
          }
          const { action, propsGenerator, component } = config;
          const Component = getComponent(component);

          return can(action, record) ? (
            <Component key={`${record.id || index}-${action}`} {...propsGenerator({ tableState: {}, row: record })} />
          ) : null;
        });
        return <RowActions role="cell">{actionsComponents}</RowActions>;
      },
      Cell: ({ row: { original }, cell: { value: actions }, state }) => {
        const actionsComponents = actions.map((actionName, index) => {
          const config = configActions.find((conf) => conf.action === actionName);
          if (!config) {
            return null;
          }
          const { action, propsGenerator, component } = config;
          const Component = getComponent(component);

          return can(action, original) ? (
            <Component
              key={`${original.id || index}-${action}`}
              {...propsGenerator({ tableState: state, row: original })}
            />
          ) : null;
        });
        return <RowActions role="cell">{actionsComponents}</RowActions>;
      },
    },
  ];
};

const defaultGeneratorActionRow = ({
  onDelete = () => {},
  removeText = 'actions:DeleteEntity',
  titleConfirm = 'commonTexts:confirmRemove',
  urlDetails = '',
  onShow,
  onSettings = () => {},
  t = () => {},
  goToBalance = () => {},
  goToMeasures = () => {},
  goToMap = () => {},
  onDownload = () => {},
  onSwitch = () => {},
  onOpen = () => {},
  onShowProps = {},
}) => [
  {
    action: 'delete',
    propsGenerator: ({ tableState, row }) => {
      return {
        title: removeText,
        customIcon: 'icon-delete',
        className: 'button-delete',
        color: 'danger',
        titleConfirm,
        onClick: () => onDelete([row.id], { row, tableState }),
      };
    },
    component: ActionButtonConfirm,
  },
  {
    action: 'show',
    propsGenerator: ({ row }) => {
      return {
        title: 'actions:ViewDetails',
        onClick: onShow ? () => onShow(row.id, row) : () => {},
        to: `${urlDetails}/${row.id}`,
        customIcon: 'icon-open',
        color: 'light',
        ...onShowProps,
      };
    },
    component: onShow
      ? null
      : ({ to, title, ...rest }) => (
          <Link className="link-button" to={to}>
            <Tooltip
              title={
                <Text style={{ margin: '0' }} color="white" size="small">
                  {t('actions:ViewDetails')}
                </Text>
              }
              placement="bottom"
            >
              <Button variant="plain" color="light" customIcon="icon-open" {...rest} />
            </Tooltip>
          </Link>
        ),
  },
  {
    action: 'download',
    propsGenerator: ({ tableState, row }) => {
      return {
        title: 'actions:Download',
        customIcon: 'icon-download',
        onClick: () => onDownload(row.id, row, tableState),
      };
    },
  },
  {
    action: 'balance',
    propsGenerator: ({ row }) => {
      return {
        title: 'entitiesNames:Balance',
        customIcon: 'icon-balance',
        onClick: () => goToBalance({ type: row.type, id: row.id, name: row.name }),
      };
    },
  },
  {
    action: 'settings',
    propsGenerator: ({ row }) => {
      return {
        title: '',
        customIcon: 'icon-config',
        onClick: () => onSettings(row.id),
      };
    },
  },
  {
    action: 'goToMeasures',
    propsGenerator: ({ row }) => {
      return {
        title: 'entitiesNames:Measures',
        customIcon: 'icon-graph_bar',
        onClick: () => goToMeasures(row.id),
      };
    },
  },
  {
    action: 'switch',
    propsGenerator: ({ row }) => {
      return {
        title: '',
        customIcon: 'icon-reload',
        onClick: () => onSwitch(row.id, row),
      };
    },
  },
  {
    action: 'map',
    propsGenerator: ({ row }) => {
      return {
        title: 'actions:GoToMap',
        customIcon: 'icon-explore',
        onClick: () => goToMap({ id: row.id, name: row.name, latitude: row.latitude, longitude: row.longitude }),
      };
    },
  },
  {
    action: 'open',
    propsGenerator: ({ row }) => {
      return {
        title: 'actions:ViewDetails',
        customIcon: 'icon-open',
        onClick: () => onOpen(row),
      };
    },
  },
];

export { generateActionRows, defaultGeneratorActionRow };
