import styled from 'styled-components';
import { Button } from 'ui';

const AlertSummary = styled.div`
  background-color: #f7f8f9;
  padding: 20px;
  width: 100%;
  position: relative;
  top: -20px;
  left: -20px;
  min-width: calc(100% + 40px);

  span {
    display: inline-block;
    margin: 2px 0;
  }
`;

const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e2e2e2;
  max-width: 100%;
  h4 {
    margin: 0px;
    color: #6078ff;
  }

  button {
    max-width: 100%;
  }
`;

const Section = styled.div`
  margin: 20px 0 0;
  position: relative;
  width: 100%;
  p {
    margin: 20px 0;
  }
`;

const AvailableVar = styled.div`
  position: absolute;
  right: 0;
  top: -10px;
  z-index: 99999;
`;
const AsideSidebar = styled.div`
  position: fixed;
  width: 280px;
  right: 700px;
  background: #ececec;
  top: -1px;
  padding: 20px;
  height: 103%;
  overflow: hidden;
  overflow-y: auto;
  span {
    display: block;
    white-space: break-spaces;
    text-transform: inherit;
  }
`;

const ColorSection = styled.div`
  background: #f7f7fc;
  padding: 20px;
  margin-bottom: 12px;
`;

const RowSelection = styled.div`
  padding: 12px;
  background: #f7f7fc;
  margin-top: 10px;
`;

const ButtonAnimation = styled(Button)`
  padding: 0;
  max-width: inherit;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const ButtonAnalyze = styled(Button)`
  top: 12px;
  z-index: 99;
  position: fixed;
  right: 54px;
`;

const AlertFilters = styled.div`
  display: flex;
  > div {
    min-width: 200px;
    width: max-content;
    margin-right: 6px;
  }
`;

export {
  AlertSummary,
  AlertFilters,
  SectionTitle,
  ButtonAnimation,
  ButtonAnalyze,
  Section,
  AsideSidebar,
  AvailableVar,
  RowSelection,
  ColorSection,
};
