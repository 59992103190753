import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from '../../icons';
import RowFlex from '../../common';
import Button from '../../button';
import { WrapperStyles, ContentStyles, FooterStyles, RowActions, Arrow } from './styles';

const ReportDetails = ({ children, title, isOpen, footer, onClickIcon, onClick, textSave, layoutReport }) => {
  const { t } = useTranslation();
  return (
    <WrapperStyles isOpen={isOpen}>
      <RowActions>
        <div justify="space-between">
          <RowFlex align="center">
            <p>{t(title)}</p>
            <Arrow isOpen={isOpen}>
              <Icon iconName="icon-arrow-up" size={20} isOpen={isOpen} onClick={onClickIcon} />
            </Arrow>
          </RowFlex>
          {onClick && (
            <Button variant="primary" color="white" size="small" onClick={onClick}>
              {t(textSave)}
            </Button>
          )}
        </div>
      </RowActions>

      <ContentStyles layoutReport={layoutReport}>{children}</ContentStyles>
      <FooterStyles>{footer}</FooterStyles>
    </WrapperStyles>
  );
};

ReportDetails.propTypes = {
  title: PropTypes.string.isRequired,
  textSave: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  onClickIcon: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  layoutReport: PropTypes.oneOf(['default', 'advanced']),
};

ReportDetails.defaultProps = {
  layoutReport: 'default',
  onClick: null,
  textSave: 'ReportPages:ReportsSaved',
};

export default ReportDetails;
