import React, { ReactNode } from 'react';

import { TextProps } from 'antd/lib/typography/Text';
import { TextStyles } from './styles';

interface Props extends Partial<TextProps> {
  align?: 'left' | 'center' | 'right';
  children?: ReactNode;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'danger' | 'success' | 'ghost' | 'warning' | 'white' | 'light';
  inline?: boolean;
  type?: 'secondary' | 'warning' | 'danger';
  textTransform?: string;
}

const Text: React.FC<Props> = ({
  align = 'left',
  children,
  size = 'medium',
  color,
  type,
  textTransform = 'none',
  inline,
  ...props
}) => (
  <TextStyles
    align={align}
    size={size}
    color={color}
    inline={inline}
    {...(type && { type })}
    textTransform={textTransform}
    {...props}
  >
    {children}
  </TextStyles>
);

export default Text;
