import styled from 'styled-components';

const TopBarStyles = styled.aside`
  display: block;
  position: sticky;
  top: 0;
  background: transparent;
  z-index: 8;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin: 0 22px;

  h3 {
    font-family: Lato;
    font-weight: 500;
    font-size: 15px;
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const Options = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item-topBar {
    margin-left: 4px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export { TopBarStyles, FlexContainer, Container, Options };
