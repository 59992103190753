import React from 'react';
import PropTypes from 'prop-types';

import { IconStyles } from './styles';

const Icon = ({ color, iconName, size, pointer, onClick }) => (
  <IconStyles color={color} icon={iconName} size={size} pointer={pointer} onClick={onClick} />
);

Icon.propTypes = {
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'dark',
    'white',
    'ghost',
    'light',
    'danger',
    'success',
    'warning',
  ]),
  iconName: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pointer: PropTypes.bool,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  size: 38,
  pointer: false,
  onClick: () => {},
};

export default Icon;
