import styled from 'styled-components';

const LabelStyles = styled.label`
  font-size: 0.9em;
  font-weight: bold;
  color: #565682;
  display: block;
  margin-left: 6px;
  margin-bottom: 2px;
`;

export { LabelStyles };
