import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';

import HomePages from 'pages/home';
import InfrastructurePages from 'pages/infrastructure';
import ReportPages from 'pages/reports';
import BalancesPages from 'pages/balances';
import ConfigPages from 'pages/config';
import MonitoringPages from 'pages/monitoring';
import MeasuresPages from 'pages/measures';
import AlertsPages from 'pages/alerts';
import ExplorePages from 'pages/explore';
import PredictionPages from 'pages/prediction';

import { PrivateRoute, CompanyRoute } from 'components/auth';

import LoginPages from 'pages/login';

import { useNotifications } from 'components/hooks';

function NoMatch() {
  const location = useLocation();
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default function AppRoutes() {
  const contextHolder = useNotifications();
  return (
    <Router>
      {contextHolder}
      <Switch>
        <PrivateRoute exact path="/">
          <HomePages />
        </PrivateRoute>
        <PrivateRoute path="/infrastructure">
          <InfrastructurePages />
        </PrivateRoute>
        <PrivateRoute path="/explore">
          <ExplorePages />
        </PrivateRoute>
        <PrivateRoute path="/reports">
          <ReportPages />
        </PrivateRoute>
        <PrivateRoute path="/balances">
          <BalancesPages />
        </PrivateRoute>
        <PrivateRoute path="/config">
          <ConfigPages />
        </PrivateRoute>
        <PrivateRoute path="/monitoring">
          <MonitoringPages />
        </PrivateRoute>
        <PrivateRoute path="/measures">
          <MeasuresPages />
        </PrivateRoute>
        <PrivateRoute path="/alerts">
          <AlertsPages />
        </PrivateRoute>
        <CompanyRoute path="/coga/prediction" company="coga">
          <PredictionPages />
        </CompanyRoute>
        <Route path="/login">
          <LoginPages />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Router>
  );
}
