import { STATUS } from 'libs/generate-redux-utils';
import types from './types';

const generateReportRequest = (params) => {
  return {
    type: types.GET_REQUESTED,
    payload: { status: STATUS.LOADING },
    error: false,
    meta: {
      timestamp: new Date(),
      params,
    },
  };
};

const generateReportSucceeded = (report) => {
  return {
    type: types.GET_SUCCEEDED,
    payload: { report, status: STATUS.READY },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const generateReportFailed = (params, error) => {
  return {
    type: types.GET_FAILED,
    payload: { status: STATUS.ERROR, statusCode: params.statusCode },
    error: true,
    meta: {
      timestamp: new Date(),
      params,
      error,
    },
  };
};

const getMeasureRequested = (id) => {
  return {
    type: types.MEASURE.SHOW_REQUESTED,
    payload: { status: STATUS.LOADING },
    error: false,
    meta: {
      timestamp: new Date(),
      id,
    },
  };
};

const getMeasureSucceeded = (entity, isCompareMeasure = false) => {
  return {
    type: types.MEASURE.SHOW_SUCCEEDED,
    payload: { entity, status: STATUS.READY, isCompareMeasure },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setMeasure = ({ id, name, ...rest }, isCompareMeasure = false) => {
  return {
    type: types.MEASURE.SET_MEASURE,
    payload: { entity: { id, name, ...rest }, isCompareMeasure },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const getMeasureFailed = ({ id, error }) => {
  return {
    type: types.MEASURE.SHOW_FAILED,
    payload: { status: STATUS.ERROR },
    error: true,
    meta: {
      timestamp: new Date(),
      error,
      id,
    },
  };
};

const setReport = (report) => {
  return {
    type: types.SET_REPORT,
    payload: { report },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};
const cleanReport = () => {
  return {
    type: types.CLEAN_REPORT,
    payload: {},
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setReferenceValue = (referenceValue) => {
  return {
    type: types.SET_REFERENCES_VALUES,
    payload: { ...referenceValue },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const restoreReport = () => {
  return {
    type: types.RESTORE_REPORTS,
    payload: {},
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const restoreAdvancedFormReport = () => {
  return {
    type: types.RESTORE_ADVANCED_FORM,
    payload: {},
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setDateInfo = (dateInfo) => {
  return {
    type: types.SET_DATE_INFO,
    payload: { dateInfo },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setDateRange = (dateRange) => {
  return {
    type: types.SET_DATE_RANGE,
    payload: { dateRange },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setType = (type) => {
  return {
    type: types.SET_TYPE,
    payload: { type },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setIsCorrelation = (correlation) => {
  return {
    type: types.SET_CORRELATION,
    payload: correlation,
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setLastMeasure = (lastMeasure) => {
  return {
    type: types.SET_LAST_MEASURE,
    payload: lastMeasure,
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setRepresentation = (representation) => {
  return {
    type: types.SET_REPRESENTATION,
    payload: representation,
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setLastRepresentation = (lastRepresentation) => {
  return {
    type: types.SET_LAST_REPRESENTATION,
    payload: lastRepresentation,
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setOnlyRepresentationTable = (representation) => {
  return {
    type: types.SET_ONLY_REPRESENTATION_TABLE,
    payload: representation,
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setTypeWindow = (typeWindow) => {
  return {
    type: types.SET_TYPE_WINDOw,
    payload: { typeWindow },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setMeasurementUnit = (measurementUnit) => {
  return {
    type: types.SET_MEASUREMENT_UNIT,
    payload: { measurementUnit },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const createReportRequest = (params) => {
  return {
    type: types.CREATE_REQUESTED,
    payload: {},
    error: false,
    meta: {
      timestamp: new Date(),
      params,
    },
  };
};

const createReportSucceeded = (report) => {
  return {
    type: types.CREATE_SUCCEEDED,
    payload: { report },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const createReportFailed = (params, error) => {
  return {
    type: types.CREATE_FAILED,
    payload: { status: STATUS.ERROR },
    error: true,
    meta: {
      timestamp: new Date(),
      params,
      error,
    },
  };
};

const createReferenceValueRequest = (params) => {
  return {
    type: types.CREATE_REQUESTED,
    payload: { status: STATUS.LOADING },
    error: false,
    meta: {
      timestamp: new Date(),
      params,
    },
  };
};

const createReferenceValueSucceeded = (report) => {
  return {
    type: types.CREATE_SUCCEEDED,
    payload: { report, status: STATUS.READY },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const createReferenceValueFailed = (params, error) => {
  return {
    type: types.CREATE_FAILED,
    payload: { status: STATUS.ERROR },
    error: true,
    meta: {
      timestamp: new Date(),
      params,
      error,
    },
  };
};

const listRequested = (params) => {
  return {
    type: types.LIST_REQUESTED,
    payload: { params, status: STATUS.LOADING },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const listSucceeded = (entities, total) => {
  return {
    type: types.LIST_SUCCEEDED,
    payload: { entities, total, status: STATUS.READY },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const listFailed = ({ query, error }) => {
  return {
    type: types.LIST_FAILED,
    payload: { query, status: STATUS.ERROR },
    error: true,
    meta: {
      timestamp: new Date(),
      error,
    },
  };
};

const listReferenceValuesRequested = (params) => {
  return {
    type: types.LIST_REFERENCE_VALUES_REQUESTED,
    payload: { params, status: STATUS.LOADING },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const listReferenceValuesSucceeded = (entities, total) => {
  return {
    type: types.LIST_REFERENCE_VALUES_SUCCEEDED,
    payload: { entities, total, status: STATUS.READY },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const listReferenceValuesFailed = ({ query, error }) => {
  return {
    type: types.LIST_REFERENCE_VALUES_FAILED,
    payload: { query, status: STATUS.ERROR },
    error: true,
    meta: {
      timestamp: new Date(),
      error,
    },
  };
};

const listScheduleReportsRequested = (params) => {
  return {
    type: types.LIST_SCHEDULE_REPORTS_REQUESTED,
    payload: { params, status: STATUS.LOADING },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const listScheduleReportsSucceeded = (entities, total) => {
  return {
    type: types.LIST_SCHEDULE_REPORTS_SUCCEEDED,
    payload: { entities, total, status: STATUS.READY },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const listScheduleReportsFailed = ({ query, error }) => {
  return {
    type: types.LIST_SCHEDULE_REPORTS_FAILED,
    payload: { query, status: STATUS.ERROR },
    error: true,
    meta: {
      timestamp: new Date(),
      error,
    },
  };
};

const listScheduleReportsFilesRequested = (params) => {
  return {
    type: types.LIST_SCHEDULE_REPORTS_FILES_REQUESTED,
    payload: { params, status: STATUS.LOADING },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const listScheduleReportsFilesSucceeded = (entities, total) => {
  return {
    type: types.LIST_SCHEDULE_REPORTS_FILES_SUCCEEDED,
    payload: { entities, total, status: STATUS.READY },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const listScheduleReportsFilesFailed = ({ query, error }) => {
  return {
    type: types.LIST_SCHEDULE_REPORTS_FILES_FAILED,
    payload: { query, status: STATUS.ERROR },
    error: true,
    meta: {
      timestamp: new Date(),
      error,
    },
  };
};

const deleteSavedReportsRequested = (ids) => {
  return {
    type: types.DELETE_REQUESTED,
    payload: { ids, loading: true },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const deleteSavedReportsSucceeded = (ids) => {
  return {
    type: types.DELETE_SUCCEEDED,
    payload: { ids, loading: false },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const deleteSavedReportsFailed = ({ ids, error }) => {
  return {
    type: types.DELETE_FAILED,
    payload: { ids, error },
    error: true,
    meta: {
      timestamp: new Date(),
      error,
    },
  };
};

const deleteReferenceValueRequested = (ids) => {
  return {
    type: types.DELETE_REQUESTED,
    payload: { ids, loading: true },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};
const deleteReferenceValueSucceeded = (ids) => {
  return {
    type: types.DELETE_SUCCEEDED,
    payload: { ids, loading: false },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};
const deleteReferenceValueFailed = ({ ids, error }) => {
  return {
    type: types.DELETE_FAILED,
    payload: { ids, error },
    error: true,
    meta: {
      timestamp: new Date(),
      error,
    },
  };
};

const showRequested = (id) => {
  return {
    type: types.SHOW_REQUESTED,
    payload: { id, loading: true },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const showSucceeded = (entity) => {
  return {
    type: types.SHOW_SUCCEEDED,
    payload: { entity, loading: false },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const showFailed = ({ id, error }) => {
  return {
    type: types.SHOW_FAILED,
    payload: { id, error },
    error,
    meta: {
      timestamp: new Date(),
      error,
    },
  };
};

const setVariables = (variables) => {
  return {
    type: types.SET_VARIABLES,
    payload: { variables },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const getSignatureAuditRequested = (id) => {
  return {
    type: types.SIGNATURE_AUDIT.SHOW_REQUESTED,
    payload: { status: STATUS.LOADING, entity: {} },
    error: false,
    meta: {
      timestamp: new Date(),
      id,
    },
  };
};

const getSignatureAuditSucceeded = (entity) => {
  return {
    type: types.SIGNATURE_AUDIT.SHOW_SUCCEEDED,
    payload: { entity, status: STATUS.READY },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const getSignatureAuditFailed = ({ id, error }) => {
  return {
    type: types.SIGNATURE_AUDIT.SHOW_FAILED,
    payload: { status: STATUS.ERROR, entity: {} },
    error: true,
    meta: {
      timestamp: new Date(),
      error,
      id,
    },
  };
};

const setMapVariablesEntities = (mapVariablesEntities) => {
  return {
    type: types.ADVANCED.SET_MAP_VARIABLES_ENTITIES,
    payload: { mapVariablesEntities },
    error: true,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setAdvancedPeriod = (period) => {
  return {
    type: types.ADVANCED.SET_ADVANCED_PERIOD,
    payload: { ...period },
    error: true,
    meta: {
      timestamp: new Date(),
    },
  };
};

const restoreVariables = () => {
  return {
    type: types.RESTORE_VARIABLES,
    payload: {},
    error: true,
    meta: {
      timestamp: new Date(),
    },
  };
};

const setReportLayoutStatusOpen = (isOpen) => {
  return {
    type: types.SET_REPORT_LAYOUT_STATUS_OPEN,
    payload: { isOpen },
    error: true,
    meta: {
      timestamp: new Date(),
    },
  };
};

const mergeStore = (store) => {
  return {
    type: types.MERGE_STORE,
    payload: store,
    error: true,
    meta: {
      timestamp: new Date(),
    },
  };
};

const getEvolutionRequested = () => ({
  type: types.GET_EVOLUTION_REQUESTED,
  payload: { status: STATUS.LOADING },
});

const getEvolutionFailed = ({ error }) => ({
  type: types.GET_EVOLUTION_FAILED,
  payload: { status: STATUS.ERROR },
  error,
});

const getEvolutionSucceeded = ({ report }) => {
  return { type: types.GET_EVOLUTION_SUCCEEDED, payload: { report, status: STATUS.READY } };
};

const listAssignmentRequested = (params) => {
  return {
    type: types.LIST_ASSIGNMENT_REQUESTED,
    payload: { params, loading: true },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const listAssignmentSucceeded = (entities, total) => {
  return {
    type: types.LIST_ASSIGNMENT_SUCCEEDED,
    payload: { entities, total, loading: false },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const listAssignmentFailed = ({ query, error }) => {
  return {
    type: types.LIST_ASSIGNMENT_FAILED,
    payload: { query, error },
    error: true,
    meta: {
      timestamp: new Date(),
      error,
    },
  };
};
const resetStatus = (status) => ({
  type: types.RESET_STATUS,
  payload: status,
});

const setStatusCode = (statusCode) => {
  return { type: types.SET_STATUS_CODE, payload: statusCode };
};
const setReportInfo = ({ name, frequencies, receiveBool }) => {
  return { type: types.SET_REPORT_INFO, payload: { name, frequencies, receiveBool } };
};

export default {
  createReportRequest,
  createReportSucceeded,
  createReportFailed,
  createReferenceValueRequest,
  createReferenceValueSucceeded,
  createReferenceValueFailed,
  generateReportRequest,
  generateReportSucceeded,
  generateReportFailed,
  getMeasureRequested,
  getMeasureSucceeded,
  getMeasureFailed,
  setMeasure,
  setReport,
  cleanReport,
  setReferenceValue,
  restoreReport,
  restoreVariables,
  restoreAdvancedFormReport,
  setDateInfo,
  setType,
  setIsCorrelation,
  setRepresentation,
  setLastRepresentation,
  setOnlyRepresentationTable,
  setTypeWindow,
  setMeasurementUnit,
  listRequested,
  listSucceeded,
  listFailed,
  listReferenceValuesRequested,
  listReferenceValuesSucceeded,
  listReferenceValuesFailed,
  listScheduleReportsRequested,
  listScheduleReportsSucceeded,
  listScheduleReportsFailed,
  listScheduleReportsFilesRequested,
  listScheduleReportsFilesSucceeded,
  listScheduleReportsFilesFailed,
  deleteSavedReportsRequested,
  deleteSavedReportsSucceeded,
  deleteSavedReportsFailed,
  deleteReferenceValueRequested,
  deleteReferenceValueSucceeded,
  deleteReferenceValueFailed,
  showRequested,
  showSucceeded,
  showFailed,
  setVariables,
  setDateRange,
  getSignatureAuditRequested,
  getSignatureAuditSucceeded,
  getSignatureAuditFailed,
  setMapVariablesEntities,
  setAdvancedPeriod,
  setReportLayoutStatusOpen,
  mergeStore,
  setLastMeasure,
  getEvolutionRequested,
  getEvolutionFailed,
  getEvolutionSucceeded,
  listAssignmentRequested,
  listAssignmentSucceeded,
  listAssignmentFailed,
  resetStatus,
  setStatusCode,
  setReportInfo,
};
