import { createTypesCrud } from 'libs/generate-redux-utils/generate-types';

const actionTypes = {
  ...createTypesCrud('measurementUnit'),
  IS_LOADING_MEASUREMENT_UNITS: '@measurementUnit/IS_LOADING_MEASUREMENT_UNITS',
  SET_ENTITY: '@measurementUnit/SET_ENTITY',
  SET_SELECTED_MEASUREMENTUNITS: '@branch/SET_SELECTED_MEASUREMENTUNITS',
  UPLOAD_LINEAR_SCHEMA_REQUESTED: '@measurementUnit/UPLOAD_LINEAR_SCHEMA_REQUESTED',
  UPLOAD_LINEAR_SCHEMA_SUCCEEDED: '@measurementUnit/UPLOAD_LINEAR_SCHEMA_SUCCEEDED ',
  UPLOAD_LINEAR_SCHEMA_FAILED: '@measurementUnit/UPLOAD_LINEAR_SCHEMA_FAILED',
  GET_EQUIPMENT_LINE_REQUESTED: '@measurementUnit/GET_EQUIPMENT_LINE_REQUESTED',
  GET_EQUIPMENT_LINE_SUCCEEDED: '@measurementUnit/GET_EQUIPMENT_LINE_SUCCEEDED',
  GET_EQUIPMENT_LINE_FAILED: '@measurementUnit/GET_EQUIPMENT_LINE_FAILED',
};

export default actionTypes;
