import React from 'react';
import PropTypes from 'prop-types';
import { DetailsStyles } from './styles';

const Details = ({ children }) => <DetailsStyles>{children}</DetailsStyles>;

Details.propTypes = {
  children: PropTypes.node,
};

Details.defaultProps = {
  children: null,
};

export default Details;
