import { useState, useEffect, useCallback } from 'react';

import { debounce } from 'libs/toolkits';

const useHandleListTable = ({ listRequest = () => {}, deleteRequest = (ids, query) => {}, initialPageSize }) => {
  const [termsSearch, setTermsSearch] = useState('');
  const requestEntities = useCallback(
    (query = {}) => {
      listRequest({ ...query, ...(termsSearch ? { q: termsSearch } : {}), limit: query.pageSize || initialPageSize });
    },
    [listRequest, termsSearch, initialPageSize]
  );

  useEffect(() => {
    requestEntities();
  }, [requestEntities]);

  const handleOnChange = useCallback(
    ({ pageIndex, pageSize, ...rest }) => {
      requestEntities({ offset: pageIndex * pageSize, pageSize, ...rest });
    },
    [requestEntities]
  );

  const deabounceSearch = useCallback(
    debounce((value) => {
      setTermsSearch(value);
    }, 300),
    []
  );

  const handleSearch = ({ target: { value } }) => {
    deabounceSearch(value);
  };

  const handleRemove = useCallback(
    (ids, { tableState }) => {
      deleteRequest(ids, {
        ...(termsSearch && { q: termsSearch }),
        offset: tableState ? tableState.pageIndex * tableState.pageSize : 0,
        limit: tableState ? tableState.pageSize : initialPageSize,
      });
    },
    [deleteRequest, termsSearch, initialPageSize]
  );

  return {
    handleOnChange,
    handleSearch,
    handleRemove,
    termsSearch,
    setTermsSearch,
  };
};

export default useHandleListTable;
