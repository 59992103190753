import styled from 'styled-components';

const ListContainer = styled.div`
  overflow: auto;
  border-radius: 4px;

  .loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
  }

  .ant-list-item {
    padding: 0px 8px;
    justify-content: flex-start;
    min-height: 42px;

    :hover {
      background-color: #151b260a;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
    }
  }

  .ant-list-item-action > li {
    padding-right: 0;
    display: flex;
    align-items: center;

    span {
      margin: 0;
    }
  }

  .ant-list-split .ant-list-item:last-child {
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-list-item-action {
    margin-left: auto;
    margin-right: 10px;
    position: absolute;
    right: 0;
  }

  [data-theme='dark'] .infinite-container {
    border: 1px solid #303030;
  }
`;
export { ListContainer };
