import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text, Avatar } from 'ui';
import { actions as actionsAuth } from 'modules/auth';

import Preference from 'assets/images/icon-config-flat.svg';
import Support from 'assets/images/icon-support-flat.svg';
import Logout from 'assets/images/icon-key-flat.svg';

import { useToggle } from 'components/hooks';
import ChangelogModal from './ChangelogModal';
import SupportModal from './SupportModal';
import PreferencesModal from './PreferencesModal';

const AvatarInfo = ({ auth }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpenModalPreferences, toggleModalPreferences] = useToggle(false);
  const [isOpenModalSupport, toggleModalSupport] = useToggle(false);
  const [isOpenModalChangelog, toggleModalChangelog] = useToggle(false);
  const closeModalPreferences = useCallback(() => {
    toggleModalPreferences(false);
  }, [toggleModalPreferences]);

  const closeModalSupport = useCallback(() => {
    toggleModalSupport(false);
  }, [toggleModalSupport]);

  const closeModalChangelog = useCallback(() => {
    toggleModalChangelog(false);
  }, [toggleModalChangelog]);

  const handleLogOut = () => {
    dispatch(actionsAuth.setUserInfo(auth));
    history.push('/login');
  };

  const options = [
    <li key="preferences" onClick={toggleModalPreferences}>
      <img src={Preference} alt="" />
      {t('TopBar:AccountInfo:Preferences')}
    </li>,
    <li key="support" onClick={toggleModalSupport}>
      <img src={Support} alt="" />
      {t('TopBar:AccountInfo:Support')}
    </li>,
    <li key="logout" onClick={handleLogOut}>
      <img src={Logout} alt="" />
      <Text>{t('TopBar:AccountInfo:LogOut')}</Text>
    </li>,
  ];
  return (
    <>
      {isOpenModalPreferences ? (
        <PreferencesModal isOpen={isOpenModalPreferences} onClose={closeModalPreferences} />
      ) : null}
      {isOpenModalSupport ? <SupportModal isOpen={isOpenModalSupport} onClose={closeModalSupport} /> : null}
      {isOpenModalChangelog ? <ChangelogModal isOpen={isOpenModalChangelog} onClose={closeModalChangelog} /> : null}
      <Avatar name={auth.name || t('TopBar:AccountInfo:UserName')} photo={auth.photoURL} options={options} />
    </>
  );
};

AvatarInfo.propTypes = {
  auth: PropTypes.shape({
    name: PropTypes.string,
    photoURL: PropTypes.string,
  }),
};

AvatarInfo.defaultProps = {
  auth: { name: '', photoURL: '' },
};
export default AvatarInfo;
