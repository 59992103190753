import styled from 'styled-components';

const RadioButtonStyles = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    :disabled,
    :disabled + label {
      cursor: no-drop;
      opacity: 0.6;
    }

    :focus {
      outline: 0;
    }

    :required {
      border: 1px solid ${(props) => props.theme.colors.danger};
    }

    :required + label {
      color: ${(props) => props.theme.colors.danger};
    }

    :checked + label {
      color: #000;
    }
  }

  label {
    cursor: pointer;
    margin: 0;
    font-size: 0.9rem;
  }
  ${({ variant, theme }) => theme.radioButton.variant[variant]};
`;

export { RadioButtonStyles };
