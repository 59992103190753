import types from './types';

const changeLanguageSelected = (language) => ({
  type: types.CHANGE_LANGUAGE,
  payload: language,
});

const setUserInfo = (user) => ({
  type: types.SET_USER_INFO,
  payload: { user },
});

const changeChangelogStatus = (isShow) => ({
  type: types.SHOW_CHANGELOG,
  payload: isShow,
});

export { setUserInfo, changeLanguageSelected, changeChangelogStatus };
