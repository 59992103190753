import styled, { ThemeProps } from 'styled-components';

import { justifyContent, alignItems, flexDirection, flexGrow } from '../../utils';

const RowFlexStyles = styled.div<{
  justify?: string;
  align?: string;
  direction?: string;
  flex?: string;
  margin?: number;
  fullHeight?: boolean;
  noWrap?: boolean;
}>`
  display: flex;
  max-width: 100%;
  margin: ${(props) => props.margin || '0'};

  ${({ justify }) => (justify ? justifyContent({ justify }) : '')};
  ${({ align }) => (align ? alignItems({ align }) : '')};
  ${({ direction }) => (direction ? flexDirection({ direction }) : '')}
  ${({ flex }) => (flex ? flexGrow({ flex }) : '')}

  height: ${(props) => (props.fullHeight ? '100%' : 'auto')};
  min-height: auto;
  flex-wrap: ${(props) => (props.noWrap ? 'nowrap' : 'wrap')};

  h3 {
    margin: 0px 0 7px;
  }

  h5 {
    margin: 0 0 15px;
    text-transform: uppercase;
    color: #67758d;
    letter-spacing: 1px;
    font-size: 15px;
  }

  p {
    margin: 5px 0;
  }
`;

export { RowFlexStyles };
