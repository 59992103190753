import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';

import MonitoringReducer from 'modules/monitoring/reducer';
import equipmentsReducer from 'modules/equipments/reducers';
import measurementUnitsReducer from 'modules/measurementUnits/reducers';
import reportsReducer from 'modules/reports/reducer';
import formulasReducer from 'modules/formulas/reducer';
import balancesReducer from 'modules/balances/reducers';
import companyReducer from 'modules/companies/reducers';
import measuresReducer from 'modules/measures/reducer';
import alertsReducer from 'modules/alerts/reducer';
import mapReducer from 'modules/explore/reducer';
import authReducer from 'modules/auth/reducer';
import notificationReducer from 'modules/notifications/reducer';
import topbarseachReducer from 'modules/topbarSearch/reducers';
import messageReducer from 'modules/messages/reducer';
import preferencesReducer from 'modules/preferences/reducer';
import dashboardReducer from 'modules/dashboard/reducer';

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
  compose;

const makeConfiguredStore = (reducer, initialState) =>
  createStore(reducer, initialState, composeEnhancers(applyMiddleware(thunk)));

let store;

const makeStore = (initialState) => {
  if (store) {
    return store;
  }

  const reducer = combineReducers({
    monitoring: MonitoringReducer,
    equipments: equipmentsReducer,
    measurementUnits: measurementUnitsReducer,
    reports: reportsReducer,
    formulas: formulasReducer,
    balances: balancesReducer,
    company: companyReducer,
    measures: measuresReducer,
    alerts: alertsReducer,
    map: mapReducer,
    auth: authReducer,
    notifications: notificationReducer,
    topbarSearch: topbarseachReducer,
    messages: messageReducer,
    preferences: preferencesReducer,
    dashboard: dashboardReducer,
  });

  const newStore = makeConfiguredStore(reducer, initialState);

  store = newStore;

  return newStore;
};

export { makeStore };
