import { css } from 'styled-components';

export default {
  size: {
    small: css`
      max-width: 124px;
      height: 74px;
      margin-right: 20px;
      text-align: center;
      line-height: 15px;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 5px rgba(51, 52, 79, 0.07);
      border-radius: 3px;
      color: #7171a6;
      flex: auto;
      cursor: pointer;
    `,
  },
};
