import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalContent, ModalHeader, Icon, Title, Text, Tabs, TabPane, Input, Button, RowFlex, Select } from 'ui';
import { selectors, operations } from 'modules/auth';
import { LANGUAGE_OPTIONS, DEFAULT_LANGUAGE } from 'modules/auth/constants';

const PreferencesModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => ({
    auth: selectors.getUserAuth(state),
    language: selectors.getLanguage(state),
  }));
  const [languagePreselect, setLanguagePreselect] = useState(auth.language || DEFAULT_LANGUAGE);

  const findObject = (array, value) => array.find((arrayItem) => arrayItem.value === value.toLowerCase());

  const requestSaveChanges = useCallback(() => {
    dispatch(operations.saveLanguageRequest(languagePreselect));
  }, [dispatch, languagePreselect]);
  if (!isOpen) {
    return null;
  }

  return (
    <Modal>
      <ModalContent>
        <ModalHeader>
          <RowFlex justify="space-between" align="flex-strat" flex={1}>
            <RowFlex direction="column">
              <Title color="primary" style={{ marginBottom: '0px' }} level={3}>
                {t('TopBar:AccountInfo:Preferences')}
              </Title>
              <Text color="ghost">{t('TopBar:AccountInfo:PreferencesInfo')}</Text>
            </RowFlex>
            <Icon iconName="icon-close" pointer onClick={onClose} />
          </RowFlex>
        </ModalHeader>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t('TopBar:AccountInfo:PersonalDetails')} key="1" style={{ padding: '0 10px 10px' }}>
            <Input textLabel={t('TopBar:AccountInfo:Name')} width={60} disabled value={auth.name} />
            <Input textLabel={t('TopBar:AccountInfo:Email')} width={60} value={auth.email} disabled />
          </TabPane>

          <TabPane
            tab={t('TopBar:AccountInfo:Language')}
            key="2"
            style={{ padding: '0 10px 10px', minHeight: '172px' }}
          >
            <RowFlex direction="column" justify="space-between" fullHeight>
              <Select
                width={100}
                textLabel={t('entitiesNames:Language')}
                htmlFor="type"
                options={LANGUAGE_OPTIONS(t)}
                onChange={(selected) => setLanguagePreselect(selected.value)}
                name="language"
                value={findObject(LANGUAGE_OPTIONS(t), languagePreselect)}
              />

              <Button
                variant="primary"
                size="full"
                onClick={() => {
                  requestSaveChanges();
                  onClose();
                }}
              >
                {t('actions:SaveEntity', { entity: t('entitiesNames:Change_plural') })}
              </Button>
            </RowFlex>
          </TabPane>
        </Tabs>
      </ModalContent>
    </Modal>
  );
};

PreferencesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

PreferencesModal.defaultValues = {
  isOpen: false,
};
export default PreferencesModal;
