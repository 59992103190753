import styled from 'styled-components';

import { Tabs } from 'antd';

const { TabPane } = Tabs;

const TabStyles = styled(Tabs)`
  box-sizing: border-box;
  max-width: inherit;
  flex: 1;
  position: relative;

  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  .ant-tabs-nav-scroll {
    padding: 0 20px;
  }

  .ant-tabs-nav-wrap {
    padding-left: 20px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-family: Lato;
    font-size: 13px;
    color: #bfbfbf;
    margin: 0 18px 0 0;
    padding: 8px 0;

    :hover {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6078ff;
    font-weight: bold;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    :hover {
      color: #6078ff;
    }
  }

  &.ant-tabs-top .ant-tabs-ink-bar-animated {
    background-color: #6078ff;
    height: 2px;
  }
  .ant-tabs-content {
    min-height: 100%;
  }

  .ant-tabs-content-holder {
    overflow-y: overlay;
  }
`;

const TabPaneStyles = styled(TabPane)`
  padding: ${(props) => (props.noPadding ? '0px' : '20px')};
  box-sizing: border-box;

  h4 {
    display: block;
    width: 100%;
    margin-top: 20px;
  }
`;

export { TabPaneStyles, TabStyles };
