import React from 'react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { WithTheme, themes } from 'ui/theme';
import { makeStore } from 'utils/store';
import Router from './routes';

const store = makeStore(undefined);

const WithThemeWrapper = WithTheme(themes.themeDefault);
ReactGA.initialize(`${process.env.REACT_APP_ANALYTICS_ID}`);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      retry(failureCount: number, error: any) {
        if (error.status === 404) {
          return false;
        }

        if (failureCount < 2) {
          return true;
        }

        return false;
      },
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <WithThemeWrapper>
        <Provider store={store}>
          <Router />
        </Provider>
      </WithThemeWrapper>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
