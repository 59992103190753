import { useCallback } from 'react';

import { generateEntityQueries } from 'libs/request-utils';
import { transformFillResponseToSelectOptions } from './utils';

const { fillEntities } = generateEntityQueries({
  nameForLists: 'microSection/provinces',
});

const useFillProvinces = () => {
  return fillEntities({})
    .then(({ data: { provinces } = { provinces: [] } }) => {
      return transformFillResponseToSelectOptions(provinces);
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export { useFillProvinces };
