import { STATUS } from 'libs/generate-redux-utils';
import types from './types';

const initialState = {
  entities: [],
  total: 0,
  status: STATUS.READY,
  entity: null,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GET_REQUESTED:
    case types.GET_SUCCEEDED:
    case types.GET_FAILED:
    case types.CREATE_REQUESTED:
    case types.CREATE_SUCCEEDED:
    case types.CREATE_FAILED:
    case types.LIST_REQUESTED:
    case types.LIST_SUCCEEDED:
    case types.LIST_FAILED:
    case types.SHOW_REQUESTED:
    case types.SHOW_SUCCEEDED:
    case types.SHOW_FAILED:
    case types.UPDATE_REQUESTED:
    case types.UPDATE_SUCCEEDED:
    case types.UPDATE_FAILED:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default reducer;
