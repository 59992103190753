import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LoadingOutlined } from '@ant-design/icons';
import { Divider, Spin } from 'antd';
import globalSearch from 'modules/topbarSearch/operations';
import { retrieveGlobalSearch } from 'modules/topbarSearch/selectors';
import { debounce } from 'libs/toolkits';
import { Column, Text, RowFlex, MetaSearch } from 'ui';

import EmptyImage from 'assets/images/empty-image.svg';
import { List, ContainerSpinner } from './styles';
import { URL_MAP, translateDict } from './constants';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const SearchBox = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [hover, setHover] = useState(false);

  const {
    globalSearch: { isLoading, items, noResults },
  } = useSelector((state) => ({
    globalSearch: retrieveGlobalSearch(state),
  }));

  const requestSearch = useCallback(
    debounce((terms) => {
      dispatch(globalSearch(terms));
    }, 300),
    []
  );

  const renderItem = (item) => (
    <List key={item.link}>
      <p
        onClick={() => {
          history.push(`${URL_MAP[item.type]}${item.link}`);
          dispatch(globalSearch());
          setInputValue('');
          setHover(false);
        }}
      >
        <Text strong color="primary" inline>
          {item.name}
        </Text>
        <Text size="small" italic>
          ({t(`entitiesNames:${translateDict[item.type]}`)})
        </Text>
      </p>
    </List>
  );

  const onChangeInput = useCallback(
    (value) => {
      requestSearch(value);
      setInputValue(value);
      setHover(true);
      if (value === '') setHover(false);
    },
    [requestSearch, setInputValue, setHover]
  );

  return (
    <>
      <MetaSearch
        onChange={onChangeInput}
        showSearch={hover}
        showResults={inputValue !== '' && (isLoading || items?.length || noResults) && hover}
        inputValue={inputValue}
        onClickOutSide={() => {
          onChangeInput('');
        }}
        content={
          <>
            {isLoading ? (
              <ContainerSpinner>
                <Spin indicator={antIcon} />
              </ContainerSpinner>
            ) : (
              ''
            )}
            {!isLoading && inputValue !== '' && noResults ? (
              <Column fullHeight>
                <RowFlex direction="column" align="center" justify="center">
                  <img src={EmptyImage} />
                  <Text size="small" align="center" className="no-result">
                    {t('commonTexts:no-results')}
                  </Text>
                </RowFlex>
              </Column>
            ) : (
              ''
            )}
            {inputValue !== '' && items?.length && !isLoading
              ? (items || []).map((searchItem) => (
                  <>
                    {renderItem(searchItem)} {searchItem?.mus ? searchItem?.mus.map((mu) => renderItem(mu)) : ''}
                    <Divider className="divider-search" />
                  </>
                ))
              : ' '}
          </>
        }
      />
    </>
  );
};

SearchBox.propTypes = {};
SearchBox.defaultProps = {};

export default SearchBox;
