import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Badge, Icon, Tooltip } from 'ui';
import { operations } from 'modules/auth';

const ChangeLogInfo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('TopBar:AccountInfo:Changelog')}>
        <Badge dot color="#5f78ff">
          <Icon iconName="news" onClick={() => dispatch(operations.saveChangelogStatus(true))} />
        </Badge>
      </Tooltip>
    </>
  );
};

ChangeLogInfo.propTypes = {};

ChangeLogInfo.defaultProps = {};

export default ChangeLogInfo;
