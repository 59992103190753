import { getGasDayRegardingDate, areSameDate } from 'libs/date';
import i18n from 'config/i18n';
import types from './types';

const initialState = {
  isOnError: false,
  isLoading: false,
  error: null,
  monitorings: [],
  filters: {
    measures: [],
    source: { label: i18n.t('modules:monitoring-select-brick-label'), value: 'brick' },
    type: { label: i18n.t('modules:monitoring-select-converter-label'), value: 'FC' },
  },
  limit: 11,
  offset: 0,
  page: 1,
  selected: { column: null, row: null },
  dateRequested: getGasDayRegardingDate(new Date()),
  summary: {
    ok: 0,
    notCompleted: 0,
    fail: 0,
    total: 0,
  },
  lastRequest: {},
};

const refreshMonitorings = (state, payload) => {
  return (
    state.lastRequest.source !== payload.params.source ||
    state.lastRequest.equipmentType !== payload.params.equipmentType ||
    !areSameDate(payload.params.date, state.dateRequested) ||
    payload.params.ids
  );
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case types.LIST_SUCCEEDED:
      return {
        ...state,
        monitorings: [...state.monitorings, ...payload.entities],
        isLoading: false,
        isOnError: error,
        selected: { column: null, row: null },
      };
    case types.LIST_FAILED:
      return {
        ...state,
        error: payload.error,
        isLoading: false,
        isOnError: error,
        selected: { column: null, row: null },
      };
    case types.LIST_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isOnError: error,
        lastRequest: payload.params,
        monitorings: refreshMonitorings(state, payload) ? [] : state.monitorings,
        dateRequested: payload.params.date,
        selected: { column: null, row: null },
      };
    case types.CHANGE_PAGINATE:
      return {
        ...state,
        selected: { column: null, row: null },
      };
    case types.CHANGE_SELECTED_MONITORING:
      return {
        ...state,
        selected: { column: payload.column, row: payload.row },
      };
    case types.ADD_SUMMARY:
      return {
        ...state,
        summary: payload.summary,
      };
    default:
      return state;
  }
};

export default reducer;
