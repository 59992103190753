import styled, { css } from 'styled-components';

import { Tag } from 'antd';

const TagStyles = styled(Tag)<{
  rounded?: boolean;
}>`
  font-size: 12px;
  margin: 0px 4px 5px;
  padding: 2px 10px;
  align-items: center;

  :hover {
    opacity: initial;
  }

  ${({ rounded }) =>
    rounded
      ? css`
          margin-bottom: 0px;
          border-radius: 40px;
        `
      : ''}
`;

const WrapperTag = styled.div`
  width: 100%;
  min-height: 135px;
  border: 3px dashed #7171a636;
  padding: 6px;
  height: auto;
  max-height: calc(100vh - 300px);
  margin-top: 5px;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  .ant-tag {
    font-weight: bold;
    border-color: #5f78ff;
    color: #5f78ff;
    background: #ebefff;
    display: flex;
    padding-right: 7px;
    margin: 4px;

    .anticon-close {
      margin-left: auto;
      color: #5f78ff;
      font-size: 15px;
    }
  }
`;
export { TagStyles, WrapperTag };
