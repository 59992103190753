import React, { MouseEvent, ReactNode } from 'react';

import { ButtonProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import Icon from 'ui/icons';
import { StyledButton } from './styles';

interface Props extends Partial<Omit<ButtonProps, 'size'>> {
  children?: ReactNode;
  customIcon?: string;
  ghost?: boolean;
  reverse?: boolean;
  size?: SizeType | 'default';
  iconSize?: number;
  variant?: 'primary' | 'default' | 'ghost' | 'success' | 'link' | 'plain' | 'danger';
  color?: 'primary' | 'secondary' | 'ghost' | 'success' | 'light' | 'white' | 'danger' | 'success';
  disabled?: boolean;
  dropdown?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => any;
}

const Button: React.FC<Props> = ({
  onClick = () => {},
  children,
  customIcon,
  size = 'default',
  iconSize,
  type,
  color = 'white',
  shape,
  variant = 'default',
  reverse,
  disabled,
  dropdown,
  className,
  ...props
}) => {
  const buttonProps = {
    ...props,
    onClick,
    ...(size !== 'default' && { size }),
    variant,
    color,
    disabled,
    type,
    shape,
    className,
  };
  return (
    <StyledButton
      {...buttonProps}
      styled={{
        size,
        variant,
        color,
      }}
    >
      {customIcon ? <Icon iconName={customIcon} size={iconSize} /> : null}
      {children}
    </StyledButton>
  );
};

export default Button;

export type { Props };
