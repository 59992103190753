import { getDateInTimezone } from 'libs/date';
import { CONFIG_VARIABLES_EXTRA as EXT_VARIABLES_ASSOC } from 'config/variables';

const transformDataValue = (data) =>
  data.reduce(
    (acc, { window_key: windowKey, value, gas_day: gasDay, imputation }) => {
      const windowKeyTz = getDateInTimezone(windowKey);
      return {
        ...acc,
        data: [...acc.data, [windowKeyTz, value]],
        gasDay: gasDay ? [...acc.gasDay, windowKey] : acc.gasDay,
        valuesImputed: imputation ? [...acc.valuesImputed, windowKeyTz] : acc.valuesImputed,
      };
    },
    { data: [], gasDay: [], valuesImputed: [] }
  );

const transformData = (data) =>
  data.reduce(
    (acc, value) => ({
      ...acc,
      ...(Object.keys(acc).includes(value.var)
        ? { [value.var]: { ...acc[value.var], [value.name]: { ...transformDataValue(value.value.data) } } }
        : { [value.var]: { [value.name]: { ...transformDataValue(value.value.data) } } }),
    }),
    {}
  );

const transformResult = ({ data, variables }) => {
  const dataMeasures = transformData(data.filter((e) => !Object.keys(EXT_VARIABLES_ASSOC).includes(e.var)));

  const extraVariables = transformData(data.filter((e) => Object.keys(EXT_VARIABLES_ASSOC).includes(e.var)));

  if (Object.keys(extraVariables).length) {
    Object.entries(extraVariables).forEach(([variable, dataOb]) => {
      const [[nameVariable, dataExtVariable]] = Object.entries(dataOb);
      if (!dataMeasures[EXT_VARIABLES_ASSOC[variable]]) {
        dataMeasures[EXT_VARIABLES_ASSOC[variable]] = {};
      }
      dataMeasures[EXT_VARIABLES_ASSOC[variable]][nameVariable] = dataExtVariable;
    });
  }
  return {
    ...dataMeasures,
    ...variables.filter((v) => !Object.keys(dataMeasures).includes(v)).reduce((acc, v2) => ({ ...acc, [v2]: {} }), {}),
  };
};

export default transformResult;
