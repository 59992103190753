import styled, { css } from 'styled-components';

const WrapperStyles = styled.div`
  background: #fff;
  box-shadow: 0px 0px 5px rgba(51, 52, 79, 0.07);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: height 1s ease-in-out;
  margin-top: -10px;
  ${({ isOpen }) => {
    if (!isOpen) {
      return css`
        height: 44px;
      `;
    }
    return css`
      min-height: calc(100vh - 82px);
    `;
  }}
`;

const ContentStyles = styled.div`
  display: flex;
  flex: 2;
  ${({ layoutReport, theme }) => theme.layouts.layoutReport[layoutReport]};

  .ant-tabs {
    width: 100%;
    max-width: 100%;
  }

  .ant-tabs-bar {
    margin: 0;
  }

  .ant-tabs-tabpane {
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    position: relative;
  }

  h4 {
    font-size: 15px;
    font-weight: 500;
    display: block;
    width: 100%;
  }

  p {
    font-size: 10px;
    color: #808080;
  }
`;

const FooterStyles = styled.div`
  margin-top: auto;
  padding: 15px 20px;
  border-top: 1px solid #e6e6ff;
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 15px;
  }
`;

const RowActions = styled.div`
  display: flex;
  justify-content: flex-end;
  background: #fff;
  width: 100%;
  border-bottom: 1px solid #e6e6ff;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px 0 20px;
    height: 44px;

    p {
      font-weight: bold;
    }
  }
`;

const Arrow = styled.div`
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  ${({ isOpen }) => {
    if (!isOpen) {
      return css`
        transform: rotate(180deg);
      `;
    }
    return css`
      transform: rotate(0deg);
    `;
  }}
`;

export { WrapperStyles, ContentStyles, FooterStyles, RowActions, Arrow };
