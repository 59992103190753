// Menu Option
import { getUserCompanyName } from 'config/companies';
import CogaOptions from './coga';
import EnagasOptions from './enagas';

const menuOptions = {
  enagas: EnagasOptions,
  coga: CogaOptions,
};

const getSuperAdminOptions = (t) => Object.keys(menuOptions).flatMap((company) => menuOptions[company](t));

export const getGroupMenuOptions = (company, role, t) => {
  if (role === 'superadmin') {
    return getSuperAdminOptions(t);
  }
  const companyName = getUserCompanyName(company);
  return menuOptions[companyName] ? menuOptions[companyName](t) : [];
};
