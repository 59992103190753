import { generateEntityQueries, toFormData, getStatusCode } from 'libs/request-utils';
import { operations as notificationOperations } from 'modules/notifications';
import { MESSAGES_NOTIFICATIONS } from 'modules/notifications/constants';
import { changeLanguageSelected, changeChangelogStatus } from './actions';

const { createEntity: saveLanguage } = generateEntityQueries({
  nameForLists: 'language',
});

const saveLanguageRequest = (language) => {
  const params = toFormData({ language });
  return (dispatch) => {
    saveLanguage({
      body: params,
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
      .then(({ status }) => {
        dispatch(changeLanguageSelected(language));

        dispatch(
          notificationOperations.successNotification({
            title: '',
            message: MESSAGES_NOTIFICATIONS('messages:participles:updated', 'entitiesNames:Language')[status],
          })
        );
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:UpdateEntity', 'entitiesNames:Language')[statusCode],
          })
        );
      });
  };
};

const saveChangelogStatus = (isOpen) => {
  return (dispatch) => dispatch(changeChangelogStatus(isOpen));
};
export { saveLanguageRequest, saveChangelogStatus };
