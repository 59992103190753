import { useTranslation } from 'react-i18next';

import { useEffectDeepCompare } from '../../hooks';
import { ALERT_TYPES_OPTIONS } from '../constants';

const useFillAlertSaved = ({
  alertSaved,
  reset,
  openCheckToogle,
  operationsRef,
  setAlertTypeView,
  setOptionsLines,
}) => {
  const { t } = useTranslation();

  useEffectDeepCompare(() => {
    if (!alertSaved) {
      return;
    }

    const formula = alertSaved.formula
      ? alertSaved.formula.map(({ entity, ...rest }) => ({
          ...rest,
          entity: { ...entity, toString: () => entity.label },
        }))
      : [];

    if (formula.length) {
      setAlertTypeView('formula');
      // eslint-disable-next-line no-param-reassign
      operationsRef.current = formula;
    }
    if (alertSaved.receiver) {
      openCheckToogle();
    }
    setOptionsLines(alertSaved.lines || []);
    reset({
      alertName: alertSaved.name,
      alertType: ALERT_TYPES_OPTIONS(t).find((a) => a.value === alertSaved.alertType),
      entitySelected: alertSaved.entitySelected || 'mu',
      selectMeasureunits: alertSaved.measurementUnits || undefined,
      selectLines: alertSaved.lines || undefined,
      selectVariable: alertSaved.variables || undefined,
      gradientPeriod: alertSaved.gradientPeriod || { label: '15 min', value: 15 },
      gradientValue: alertSaved.gradientValue || undefined,
      thresholdMax: alertSaved.thresholdMax || undefined,
      thresholdMin: alertSaved.thresholdMin || undefined,
      pcsValue: alertSaved.pcsValue || undefined,
      receiver: alertSaved.receiver || '',
      consolidationTime: alertSaved.consolidationTime || 5,
      active: alertSaved.active,
    });
  }, [alertSaved, reset, openCheckToogle]);
};

export default useFillAlertSaved;
