import { STATUS } from 'libs/generate-redux-utils';
import { mergeDeepRight } from 'libs/toolkits';
import actionType from './types';

const DEFAULT_EQUIPMENT_CHROMATOGRAPH_ENTITY = {
  id: null,
  name: null,
  brand: null,
  model: null,
  serialNumber: null,
  parserName: null,
  clientType: null,
  company: null,
  enabled: false,
};

const DEFAULT_EQUIPMENT_COUNTER_ENTITY = {
  id: null,
  enabled: false,
  type: null,
  model: null,
  qMax: null,
  qmin: null,
  qt: null,
  company: null,
};

const DEFAULT_EQUIPMENT_FLOW_COMPUTER_ENTITY = {
  id: null,
  conversionType: null,
  brand: null,
  model: null,
  serialNumber: null,
  company: null,
};

const DEFAULT_EQUIPMENT_PATTERN_ENTITY = {
  id: null,
  brand: null,
  model: null,
  serialNumber: null,
  organizationUnit: null,
  company: null,
};
const DEFAULT_EQUIPMENT_BOTTLE_ENTITY = {
  id: null,
  brand: null,
  model: null,
  serialNumber: null,
  type: null,
  pressure: '',
  chroma: null,
  company: null,
};

const DEFAULT_MODAL_STATE = {
  step: 1,
  isOpen: false,
  action: 'create',
  equipmentType: 'chromatograph',
};

const initialState = {
  modalState: DEFAULT_MODAL_STATE,
  error: null,
  counter: {
    entities: [],
    entity: DEFAULT_EQUIPMENT_COUNTER_ENTITY,
    total: 0,
    status: STATUS.READY,
  },
  chromatograph: {
    entities: [],
    entity: DEFAULT_EQUIPMENT_CHROMATOGRAPH_ENTITY,
    total: 0,
    status: STATUS.LOADING,
  },
  flowComputer: {
    entities: [],
    entity: DEFAULT_EQUIPMENT_FLOW_COMPUTER_ENTITY,
    total: 0,
    status: STATUS.READY,
  },
  patternEquipment: {
    entities: [],
    entity: DEFAULT_EQUIPMENT_PATTERN_ENTITY,
    total: 0,
    status: STATUS.READY,
  },
  calibrationBottle: {
    entities: [],
    entity: DEFAULT_EQUIPMENT_BOTTLE_ENTITY,
    total: 0,
    status: STATUS.READY,
  },
  footprintChroma: {
    chromatograph: {},
    entities: [],
    footprint: {},
    footprints: [],
    total: 0,
    status: STATUS.LOADING,
  },
  evolutionChroma: {
    chromatograph: {},
    report: {},
    status: STATUS.LOADING,
  },
  analysisChroma: {
    chromatograph: {},
    entities: [],
    analysis: {},
    total: 0,
    status: STATUS.LOADING,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionType.LIST_FAILED:
    case actionType.LIST_SUCCEEDED:
    case actionType.SHOW_REQUESTED:
    case actionType.SHOW_SUCCEEDED:
    case actionType.SHOW_FAILED:
    case actionType.UPDATE_FAILED:
    case actionType.DELETE_FAILED:
    case actionType.CREATE_FAILED:
    case actionType.LIST_REQUESTED: {
      const { equipmentType, ...rest } = payload;
      return { ...state, [equipmentType]: mergeDeepRight(state[equipmentType], rest) };
    }
    case actionType.CREATE_SUCCEEDED:
    case actionType.UPDATE_SUCCEEDED: {
      const { equipmentType, ...rest } = payload;
      return {
        ...state,
        [equipmentType]: mergeDeepRight(state[equipmentType], {
          ...rest,
          ...(equipmentType === 'chromatograph' && { entity: DEFAULT_EQUIPMENT_CHROMATOGRAPH_ENTITY }),
          ...(equipmentType === 'counter' && { entity: DEFAULT_EQUIPMENT_COUNTER_ENTITY }),
          ...(equipmentType === 'flowComputer' && { entity: DEFAULT_EQUIPMENT_FLOW_COMPUTER_ENTITY }),
          ...(equipmentType === 'patternEquipment' && { entity: DEFAULT_EQUIPMENT_PATTERN_ENTITY }),
          ...(equipmentType === 'calibrationBottle' && { entity: DEFAULT_EQUIPMENT_BOTTLE_ENTITY }),
        }),
      };
    }
    case actionType.SET_MODAL_INFO:
      return mergeDeepRight(state, { modalState: payload });

    case actionType.SET_CHROMA_FOOTPRINT:
      return {
        ...state,
        footprintChroma: { ...state.footprintChroma, chromatograph: payload },
        analysisChroma: { ...state.analysisChroma, chromatograph: payload },
      };

    case actionType.GET_DATA_FAILED:
    case actionType.GET_DATA_SUCCEEDED:
    case actionType.GET_DATA_REQUESTED:
      return { ...state, analysisChroma: { ...state.analysisChroma, ...payload } };

    case actionType.SET_DATA_ANALYSIS:
      return { ...state, analysisChroma: { ...state.analysisChroma, analysis: payload } };

    case actionType.GET_FOOTPRINT_FAILED:
    case actionType.GET_FOOTPRINT_SUCCEEDED:
    case actionType.GET_FOOTPRINT_REQUESTED:
      return { ...state, footprintChroma: { ...state.footprintChroma, ...payload } };

    case actionType.GET_EVOLUTION_REQUESTED:
    case actionType.GET_EVOLUTION_SUCCEEDED:
    case actionType.GET_EVOLUTION_FAILED:
      return { ...state, evolutionChroma: { ...state.evolutionChroma, ...payload } };

    case actionType.GET_ANALYSIS_REQUESTED:
    case actionType.GET_ANALYSIS_SUCCEEDED:
    case actionType.GET_ANALYSIS_FAILED:
      return {
        ...state,
        footprintChroma: { status: payload.status, ...state.footprintChroma, footprint: payload?.footprint },
        analysisChroma: { status: payload.status, ...state.analysisChroma, analysis: payload?.analysis },
      };

    default:
      return state;
  }
};

export {
  DEFAULT_EQUIPMENT_CHROMATOGRAPH_ENTITY,
  DEFAULT_EQUIPMENT_COUNTER_ENTITY,
  DEFAULT_EQUIPMENT_FLOW_COMPUTER_ENTITY,
  DEFAULT_EQUIPMENT_PATTERN_ENTITY,
  DEFAULT_EQUIPMENT_BOTTLE_ENTITY,
  DEFAULT_MODAL_STATE,
};

export default reducer;
