import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useToggle } from 'components/hooks';
import { Select, RowFlex, Formula, Checkbox, Text } from 'ui';
import { Section, AvailableVar } from '../styles';
import InputsValues from './InputsValues';
import VariablesInfo from './VariablesInfo';

const VariablesSelects = ({
  alertTypeView,
  variablesPermited,
  handleSelect,
  operationsRef,
  operatorsConfig,
  alertType,
}) => {
  const { t } = useTranslation();
  const [isOpenVariablesPermited, toggleVariablesPermited] = useToggle(false);
  const { control, errors } = useFormContext();

  return (
    <>
      <Section>
        <RowFlex align="end" direction="column">
          <Text strong color="primary">
            {t('components:alerts:section-variable-text')}
          </Text>
          {alertTypeView === 'simple' && (
            <Controller
              as={
                <Select
                  isSearchable
                  width={50}
                  placeholder={t('components:alerts:select-selectVariable-placeholder')}
                  options={variablesPermited.map(({ label, ...rest }) => ({ label: t(label), ...rest }))}
                  name="selectVariable"
                  errors={errors}
                  menuPlacement="top"
                />
              }
              control={control}
              rules={{
                required: t('forms:validations:required'),
              }}
              onChange={([selected]) => {
                return selected;
              }}
              name="selectVariable"
            />
          )}

          <Checkbox
            name="selectec-alert-formula"
            textLabel={t('components:alerts:section-checkbox-createVariable-label')}
            id="selectec-alert-formula"
            isSelected={alertTypeView === 'formula'}
            onChange={() => handleSelect('formula')}
          />

          {alertTypeView === 'formula' && (
            <Section>
              <AvailableVar>
                <Checkbox
                  name="variables-permited"
                  textLabel={t('components:alerts:section-checkbox-showVariable-label')}
                  id="variables-permited"
                  checked={isOpenVariablesPermited}
                  onChange={toggleVariablesPermited}
                />
              </AvailableVar>
              {isOpenVariablesPermited && <VariablesInfo variables={variablesPermited} />}
              <Formula
                initialOperations={operationsRef?.current ? operationsRef.current : []}
                showHelpText={false}
                operatorsConfig={operatorsConfig}
                variables={variablesPermited}
                concreteEntities
              />
              <InputsValues alertType={alertType} />
            </Section>
          )}
        </RowFlex>
      </Section>
      {alertTypeView === 'simple' && <InputsValues alertType={alertType} />}
    </>
  );
};

VariablesSelects.propTypes = {
  alertTypeView: PropTypes.string.isRequired,
  variablesPermited: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleSelect: PropTypes.func.isRequired,
  alertType: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  operatorsConfig: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  operationsRef: PropTypes.object.isRequired,
};

VariablesSelects.defaultProps = {
  alertType: undefined,
};
export default VariablesSelects;
