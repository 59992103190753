import styled from 'styled-components';

const AvatarStyles = styled.img`
  width: 35px;
  height: 35px;
  margin-left: 10px;
  border-radius: 50%;
  border: 3px solid #fff;
  cursor: pointer;
`;

const MenuAccount = styled.div`
  img {
    width: 80px;
    height: auto;
    border-radius: 50%;
    margin-bottom: 8px;
  }
`;

const Options = styled.ul`
  width: 230px;
  border-top: 1px solid #e9e9e9;
  padding: 0px;
  margin-top: 20px;
  padding-top: 20px;
  list-style: none;

  li {
    height: 40px;
    padding: 0 10px;
    color: #000;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;

    :hover {
      background: #f0f0f0;
    }

    img {
      margin: 0px;
      margin-right: 10px;
      max-width: 20px;
      border-radius: 0px;
    }
  }
`;

export { AvatarStyles, MenuAccount, Options };
