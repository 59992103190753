import React from 'react';
import { Provider } from 'react-redux';

import { makeStore } from './makeStore';

const store = makeStore();

const withRedux = (Component) => (props) => {
  return (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );
};

export { withRedux };
