import { getDateInTimezone } from 'libs/date';

const actionsTranslate = {
  load: 'show',
  delete: 'delete',
  goToDashboard: 'goToMeasures',
};

const transformMeasurementUnitLine = (line, measurementUnitId) => ({
  id: String(line.id),
  nameLine: line.name,
  diameterLine: line.diameter,
  sizeLine: line.size,
  typeCounter: line.m_type,
  modelCounter: line.m_model,
  brandCounter: line.m_model,
  modelCounterName: line.m_model_name,
  brandCounterName: line.m_brand_name,
  numberSerialCounter: line.m_number,
  orderLine: line.order,
  sizeCounter: line.size_counter,
  qMin: line.q_min_counter,
  qMax: line.q_max_counter,
  qT: line.q_t_counter,
  modifySizeCounter: line.modify_size_counter,
  modifyqMin: line.modify_q_min,
  modifyqMax: line.modify_q_max,
  modifyqT: line.modify_q_t,
  nPathsModify: line.modify_n_paths,
  nPaths: line.n_paths,
  hardware: line.hardware,
  counterDiameter: line.counter_diameter,
  pressureMax: line.pressure_max,
  instalationDateCounter: line.instalation_date_counter,
  lastCalibrationDateCounter: line.calibration_date_counter,
  calibrationValidationDateCounter: line.calibration_validation_date_counter,
  certificateCode: line.certificate_code,
  measurementUnitId,
  actions: ['open', 'delete'],
});

const transformMeasurementUnitLineBody = (line, measurementUnitId) => ({
  ...((line.idLine || line.id) && { id: String(line.idLine || line.id) }),
  name: line.nameLine,
  diameter: line.diameterLine,
  size: line.sizeLine,
  m_type: String(line.typeCounter),
  m_model: String(line.modelCounter),
  m_number: line.numberSerialCounter,
  size_counter: line.sizeCounter,
  q_min_counter: line.qMin,
  q_max_counter: line.qMax,
  q_t_counter: line.qT,
  modify_size_counter: line.modifySizeCounter,
  modify_q_min: line.modifyqMin,
  modify_q_max: line.modifyqMax,
  modify_q_t: line.modifyqT,
  instalation_date_counter: line.instalationDateCounter,
  calibration_date_counter: line.lastCalibrationDateCounter,
  calibration_validation_date_counter: line.calibrationValidationDateCounter,
  modify_n_paths: line.nPathsModify,
  n_paths: line.nPaths,
  hardware: line.hardware,
  counter_diameter: line.counterDiameter,
  pressure_max: line.pressureMax,
  certificate_code: line.certificateCode,
  measurementUnitId,
  order: line.orderLine,
});

const transformMeasurementUnit = (measurementUnit) => ({
  id: String(measurementUnit.id),
  name: measurementUnit.name,
  code: measurementUnit.code,
  type: measurementUnit.type,
  companyId: measurementUnit.company,
  companyName: measurementUnit.company_name,
  position: String(measurementUnit.position),
  positionName: measurementUnit.position_name,
  modificationDate: getDateInTimezone(measurementUnit.modification_date),
  creationDate: getDateInTimezone(measurementUnit.creation_date),
  lines: (measurementUnit.lines || []).map((e) => transformMeasurementUnitLine(e, measurementUnit.id)) || [],
  flowComputers: measurementUnit.flow_computers || [],
  active: measurementUnit.active,
  creator: measurementUnit.creator,
  numberLines: measurementUnit.total_lines,
  selfConsumption: measurementUnit.self_consumption,
  selfConsumptionBranch: String(measurementUnit.self_consumption_branch),
  selfConsumptionBranchName: measurementUnit.self_consumption_branch_name,
  inputBranchId: String(measurementUnit.in_branch),
  inputBranchName: measurementUnit.in_branch_name,
  outputBranchId: String(measurementUnit.out_branch),
  outputBranchName: measurementUnit.out_branch_name,
  bidirectional: measurementUnit.bidirectional,
  bidirectionalUnit: String(measurementUnit.bidirectional_unit),
  bidirectionalUnitName: measurementUnit.bidirectional_unit_name,
  beBackUp: measurementUnit.be_backup,
  backUpUnit: String(measurementUnit.backup),
  backUpUnitName: measurementUnit.backup_name,

  chroma: measurementUnit.croma,
  chromatographMeasurementUnit: measurementUnit.cromatograph,
  chromatographMeasurementUnitName: measurementUnit.croma_name,
  chromatographMeasurementUnitR: measurementUnit.cromatograph_r,
  chromatographMeasurementUnitRName: measurementUnit.croma_name_r,
  linearSchema: measurementUnit.linear_schema,

  chromaActive: measurementUnit.chroma_active,
  chromaName: measurementUnit.chroma_active_name,
  defaultChromaName: measurementUnit.default_chroma_name,
  chromas: measurementUnit.chromas_config,

  actions: (measurementUnit.actions || []).map(({ func }) => actionsTranslate[func]).filter((item) => item),
});

const transformToQuery = (queryToTransform) => {
  const query = {
    q: queryToTransform.q,
    pageSize: queryToTransform.pageSize,
    limit: queryToTransform.limit,
    offset: queryToTransform.offset,
    type: queryToTransform.type,
    croma: Boolean(queryToTransform.isActiveChroma),
    branch: queryToTransform.branch,
    line_type: queryToTransform.counterType,
    line_model: queryToTransform.counterModel,
    line_ammount: queryToTransform.linesNumber,
  };
  return query;
};
export { transformMeasurementUnitLine, transformMeasurementUnitLineBody, transformMeasurementUnit, transformToQuery };
