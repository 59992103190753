import { useCallback } from 'react';
import { generateEntityQueries } from 'libs/request-utils';

import { searchMeasurementUnits } from 'modules/measurementUnits/api';
import { transformFillResponseToSelectOptions } from './utils';

const { fillEntities, searchEntities } = generateEntityQueries({
  nameForLists: 'measurementUnit',
});

const useSearchMeasures = (extraParams) => {
  return useCallback(
    (text) => {
      return searchMeasurementUnits({
        params: { query: text, ...extraParams },
      }).then((data) => {
        const response = { items: data.data.measurementunits };
        return { ...data, data: response };
      });
    },
    [extraParams]
  );
};

const useSearchMeasuresList = (extraParams) => {
  return useCallback(
    (text) => {
      return searchEntities({
        params: { q: text, ...extraParams },
      }).then((data) => {
        return data;
      });
    },
    [extraParams]
  );
};

const useFillMeasures = ({ type = 'all', isGycam = null, allOption, t }) => {
  return fillEntities({
    params: {},
  })
    .then(
      ({
        data: {
          measurementunits,
          measurementunits_cr: measurementunitsCr,
          measurementunits_ultra: measurementunitsUltra,
        } = {
          measurementunits: [],
          measurementunits_cr: [],
          measurementunits_ultra: [],
        },
      }) => {
        const chromas = transformFillResponseToSelectOptions(
          measurementunitsCr,
          {
            hasChroma: true,
            hasUltrasonic: false,
          },
          ({ gycam }) => ({ isGycam: gycam })
        );

        const ultrasonic = transformFillResponseToSelectOptions(
          measurementunitsUltra,
          {
            hasChroma: false,
            hasUltrasonic: true,
          },
          ({ gycam }) => ({ isGycam: gycam })
        );

        const all = transformFillResponseToSelectOptions(
          measurementunits,
          {
            hasChroma: false,
            hasUltrasonic: false,
          },
          ({ gycam }) => ({ isGycam: gycam })
        );

        const chromaIds = chromas.map(({ value }) => value);
        const ultrasonicIds = ultrasonic.map(({ value }) => value);

        let measureUnits = all.map(({ value, ...rest }) => ({
          ...rest,
          value,
          hasChroma: chromaIds.includes(value),
          hasUltrasonic: ultrasonicIds.includes(value),
        }));

        if (isGycam !== null) {
          measureUnits = measureUnits.filter(({ isGycam: g }) => g === isGycam);
        }
        if (type === 'chroma') {
          return measureUnits.filter(({ hasChroma }) => hasChroma);
        }

        if (type === 'ultrasonic') {
          return measureUnits.filter(({ hasUltrasonic }) => hasUltrasonic);
        }

        if (allOption)
          return [
            { label: t('components:footprintPage:assignment-report:all-measurement-units'), value: 'all' },
            ...measureUnits,
          ];

        return measureUnits;
      }
    )
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export { useSearchMeasures, useFillMeasures, useSearchMeasuresList };
