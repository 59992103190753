import { css } from 'styled-components';

export default {
  variant: {
    primary: css`
      background: ${(props) => props.theme.colors.primary};
      color: white;
      border: 1px solid ${(props) => props.theme.colors.primary};

      &.ant-btn-clicked:after {
        content: '';
        display: none;
      }
      :hover {
        background: ${(props) => props.theme.colors.secondary};
        color: #fff;
        border-color: ${(props) => props.theme.colors.secondary};
      }
      :focus {
        background: ${(props) => props.theme.colors.secondary};
        border: 1px solid ${(props) => props.theme.colors.primary};
        color: white;
      }

      span {
        margin: 0;
      }
    `,
    default: css`
      background: rgba(9, 30, 66, 0.08);
      color: ${(props) => props.theme.colors.textColor};

      &:hover {
        background: rgba(9, 30, 66, 0.1);
        border: 1px solid transparent;
        color: ${(props) => props.theme.colors.textColor};
      }

      :focus {
        outline: transparent;
      }
    `,
    success: css`
      background: ${(props) => props.theme.colors.success};
      border-color: ${(props) => props.theme.colors.success};

      :hover {
        color: white;
        background: ${(props) => props.theme.colors.success};
        border-color: ${(props) => props.theme.colors.success};
        opacity: 0.8;
      }

      :focus {
        background: ${(props) => props.theme.colors.success};
        border-color: ${(props) => props.theme.colors.success};
        color: white;
      }
    `,

    danger: css`
      background: ${(props) => props.theme.colors.danger};
      border-color: ${(props) => props.theme.colors.danger};

      :hover {
        color: white;
        background: ${(props) => props.theme.colors.danger};
        border-color: ${(props) => props.theme.colors.danger};
        opacity: 0.8;
      }

      :focus {
        background: ${(props) => props.theme.colors.danger};
        border-color: ${(props) => props.theme.colors.danger};
        color: white;
      }
      span {
        margin: 0;
      }
    `,
    link: css`
      border: none;
      padding: 0px;
      background-color: transparent;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: none;
        color: inherit;
      }
    `,

    ghost: css`
      border-color: #6078ff;
      background: transparent;

      span {
        color: #6078ff;
      }
    `,
    plain: css`
      background: transparent;
      color: #adadad;
      border: none;
      padding: 0px;
      margin: 0 10px;
      box-shadow: none;

      &:hover,
      &:active {
        color: inherit;
        background: transparent;
      }

      &:active {
        background: transparent;
        color: #4a7e84;
      }

      &.ant-btn-clicked:after {
        content: '';
        display: none;
      }
      :disabled {
        background: transparent;

        :hover {
          background: transparent;
        }
      }
    `,
  },

  shape: {
    quadrate: css`
      border-radius: 10px;
      width: 40px;
      height: 40px;
      font-size: 18px;
    `,

    rounded: css`
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      border-radius: 50%;
      justify-content: center;
      margin-right: 10px;
    `,
  },

  color: {
    default: css`
      color: ${(props) => props.theme.colors.default};
    `,
    primary: css`
      color: ${(props) => props.theme.colors.primary};
    `,
    secondary: css`
      color: ${(props) => props.theme.colors.secondary};
    `,
    white: css`
      color: #fff;
    `,
    ghost: css`
      color: ${(props) => props.theme.colors.darkGrey};
    `,
    light: css`
      color: ${(props) => props.theme.colors.lightIcon};
    `,
    danger: css`
      color: ${(props) => props.theme.colors.danger};
    `,
    success: css`
      color: ${(props) => props.theme.colors.success};
    `,
    dark: css`
      color: ${(props) => props.theme.colors.dark};
    `,
    warning: css`
      color: orange;
    `,
  },

  size: {
    small: css`
      font-size: 0.8rem;
      height: 27px;
      padding: 0 10px;
    `,

    medium: css`
      font-size: 0.8rem;
      height: 35px;
      padding: 0 20px;
    `,

    large: css`
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    `,

    full: css`
      font-size: 1rem;
      width: 100%;
      height: 40px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
  },
};
