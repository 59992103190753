import styled from 'styled-components';

import { Space } from 'antd';

const SpaceStyles = styled(Space)`
  width: ${({ width }) => width || 'auto'};
  .ant-space-item {
    flex: 1;
  }
`;
export { SpaceStyles };
