import React, { useCallback } from 'react';

import { CROMA, FC, ULTRASONIC_PERMITTED } from 'config/variables';
import { Formula } from 'ui';
import { useEffectDeepCompare } from '../../hooks';

const DEFAULT_OPERATORS_CONFIG = {
  operators: [
    Formula.CONSTANTS.OPERATOR_SUM,
    Formula.CONSTANTS.OPERATOR_SUBTRACT,
    Formula.CONSTANTS.OPERATOR_DIVIDE,
    Formula.CONSTANTS.OPERATOR_MULTIPLY,
    Formula.CONSTANTS.OPERATOR_OPEN_PARENTHESIS,
    Formula.CONSTANTS.OPERATOR_CLOSE_PARENTHESIS,
    Formula.CONSTANTS.OPERATOR_REMOVE,
  ],
  perRow: 7,
};

const CHROMA = CROMA.map((v) => ({ ...v, type: 'measurementUnit' }));
const FLOW_COMPUTER = FC.map((v) => ({ ...v, type: 'line' }));

const useFormulaLogic = ({ measureLines, linesIdsSelecteds, measures, entitySelected }) => {
  const handleOnClick = useCallback(
    ({ replaceOperator, operations, cursorPosition, value, label, setCursorPosition }) => {
      const lastOperator = operations[cursorPosition] || {};
      if (lastOperator.type !== 'math' && !lastOperator.entity) {
        const entityValue = {
          value,
          label,
          toString: () => label,
        };
        setCursorPosition(cursorPosition);
        replaceOperator({
          entity: entityValue,
          entityId: value,
          key: Math.random(0, 1),
        });
      }
    },
    []
  );

  const [operatorsConfig, setOperatorsConfig] = React.useState({});

  useEffectDeepCompare(() => {
    const operationsLines = measureLines.reduce(
      (accLines, { label, value }) =>
        linesIdsSelecteds.includes(value)
          ? [...accLines, { value, label, handler: handleOnClick, extraProps: { variant: 'primary', color: 'white' } }]
          : accLines,
      []
    );

    const operationsMeasures = (measures || []).map(
      ({ value, label }) => ({
        value,
        label,
        handler: handleOnClick,
        extraProps: { variant: 'primary', color: 'white' },
      }),
      []
    );

    const extraOperators = entitySelected === 'mu' ? operationsMeasures : operationsLines;

    setOperatorsConfig({
      ...DEFAULT_OPERATORS_CONFIG,
      operators: [...DEFAULT_OPERATORS_CONFIG.operators, ...extraOperators],
    });
  }, [setOperatorsConfig, entitySelected, handleOnClick, linesIdsSelecteds, measureLines, measures]);

  return {
    operatorsConfig,
    variablesPermited: entitySelected === 'mu' ? CHROMA : [...FLOW_COMPUTER, ...ULTRASONIC_PERMITTED],
  };
};

export default useFormulaLogic;
