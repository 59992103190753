import Axios, { Canceler, AxiosError, AxiosResponse } from 'axios';

import { getDateFormated } from '../date';

type RequestError = AxiosError;

const { CancelToken } = Axios;

interface RequestParamsDefault<
  T = {
    [key: string]: any;
  }
> {
  id?: string;
  params?: T;
  headers?: Record<string, unknown>;
}

interface RequestBodyDefault<
  T = {
    [key: string]: any;
  }
> {
  body: T;
  headers?: Record<string, unknown>;
  setCall?: (c: Canceler) => void;
  cancelCall?: () => void;
}

type GetFunction = <Params = Record<string, unknown>, Res = Record<string, unknown>>(
  r: RequestParamsDefault<Params>
) => Promise<AxiosResponse<Res>>;
type PostFunction = <Body = Record<string, unknown>, Res = Record<string, unknown>>(
  r: RequestBodyDefault<Body>
) => Promise<AxiosResponse<Res>>;

interface Kk {
  getEntities: GetFunction;
  getEntity: GetFunction;
  searchEntities: GetFunction;
  fillEntities: GetFunction;
  createEntity: PostFunction;
  saveEntity: PostFunction;
  updateEntity: PostFunction;
  deleteEntity: <Res = Record<string, unknown>>(r: {
    id: string;
    headers?: Record<string, unknown>;
  }) => Promise<AxiosResponse<Res>>;
  downloadFile: <Params = Record<string, unknown>>(req: RequestParamsDefault<Params>) => Promise<void>;
  allEntities: <Params = Record<string, unknown>, Res = Record<string, unknown>>(
    req: RequestParamsDefault<Params>
  ) => Promise<AxiosResponse<Res>>;
  getEntitiesMultipleCalls: <Params = Record<string, unknown>, Res = Record<string, unknown>>(req: {
    paramsList: {
      params: Params;
      id: string;
    }[];
    headers: Record<string, unknown>;
  }) => Promise<AxiosResponse<Res>[]>;
}

const getUserToken = (): string | null => {
  return localStorage.getItem('sea-token');
};

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Access-Control-Allow-Origin': '*', crossDomain: true },
});

axiosInstance.interceptors.request.use((config) => {
  const token = getUserToken();
  return { ...config, headers: { Authorization: `Bearer ${token}`, ...config.headers } };
});

const getEntities = (url: string) => <Params = Record<string, unknown>, Res = Record<string, unknown>>({
  params,
  headers = {},
  ...rest
}: RequestParamsDefault<Params>) => {
  return axiosInstance.get<Res>(url, {
    params: { ...(params || {}), i: 1 },
    headers,
    ...rest,
  });
};

const searchEntities = (url: string) => <Params = Record<string, unknown>, Res = Record<string, unknown>>({
  params,
  headers = {},
  ...rest
}: RequestParamsDefault<Params>) => {
  return axiosInstance.get<Res>(`${url}/list`, {
    params: { ...(params || {}), i: 1 },
    headers,
    ...rest,
  });
};

const fillEntities = (url: string) => <Params = Record<string, unknown>, Res = Record<string, unknown>>({
  params,
  headers = {},
  ...rest
}: RequestParamsDefault<Params>) => {
  return axiosInstance.get<Res>(`${url}/fill`, {
    params: params || {},
    headers,
    ...rest,
  });
};

const allEntities = (url: string) => <Params = Record<string, unknown>, Res = Record<string, unknown>>({
  params,
  headers = {},
  ...rest
}: RequestParamsDefault<Params>) => {
  return axiosInstance.get<Res>(`${url}/all`, {
    params: params || {},
    headers,
    ...rest,
  });
};

const createEntity = (url: string) => <Body = Record<string, unknown>, Res = Record<string, unknown>>({
  body,
  headers = {},
  setCall,
  cancelCall,
  ...rest
}: RequestBodyDefault<Body>) => {
  if (cancelCall) cancelCall();
  return axiosInstance.post<Res>(`${url}`, body || {}, {
    headers,
    ...rest,
    ...(setCall
      ? {
          cancelToken: new CancelToken(function executor(c) {
            setCall(c);
          }),
        }
      : {}),
  });
};

const saveEntity = (url: string) => <Body = Record<string, unknown>, Res = Record<string, unknown>>({
  body,
  headers = {},
  ...rest
}: RequestBodyDefault<Body>) => {
  return axiosInstance.post<Res>(`${url}/save`, body || {}, {
    headers,
    ...rest,
  });
};

const updateEntity = (url: string) => <Body = Record<string, unknown>, Res = Record<string, unknown>>({
  body,
  headers = {},
  ...rest
}: RequestBodyDefault<Body>) => {
  return axiosInstance.post<Res>(`${url}`, body || {}, {
    headers,
    ...rest,
  });
};

const deleteEntity = (url: string) => <Res = Record<string, unknown>>({
  id,
  headers = {},
  ...rest
}: {
  id: string;
  headers?: Record<string, unknown>;
}) => {
  return axiosInstance.delete<Res>(`${url}/${id}`, {
    headers,
    ...rest,
  });
};

const getEntity = (url: string) => <Params = Record<string, unknown>, Res = Record<string, unknown>>({
  id,
  params,
  headers = {},
  ...rest
}: RequestParamsDefault<Params>) => {
  return axiosInstance.get<Res>(`${url}${id ? `/${id}` : ''}`, {
    params: params || {},
    headers,
    ...rest,
  });
};

const getEntitiesMultipleCalls = (url: string) => <Params = Record<string, unknown>, Res = Record<string, unknown>>({
  paramsList = [],
  headers = {},
  ...rest
}: {
  paramsList: { params: Params; id: string }[];
  headers: Record<string, unknown>;
}): Promise<AxiosResponse<Res>[]> => {
  const requests = paramsList.map(({ params, id }) =>
    axiosInstance.get<Res>(`${url}${id ? `/${id}` : ''}`, {
      params: { ...params },
      headers,
      ...rest,
    })
  );
  return Axios.all(requests).then(
    Axios.spread((...responses) => {
      return responses;
    })
  );
};

const downloadFile = (url: string) => <Params = Record<string, unknown>>({
  params,
  headers = {},
  ...rest
}: RequestParamsDefault<Params>) => {
  return axiosInstance
    .get(`${url}`, {
      params: params || {},
      headers: { ...headers, Accept: 'application/json, text/plain, */*, Content-Disposition,Content-Type' },
      responseType: 'blob',
      ...rest,
    })
    .then(({ data, headers: headersResponse }) => {
      const headerDisposition = headersResponse['content-disposition'];
      const headerType = headersResponse['content-type'];
      const filename = headerDisposition
        ? headerDisposition.match(/.+filename="(.+)"/, '')[1]
        : `SEA-DOWNLOAD-${getDateFormated(new Date(), 'dd-MM-yyyy HH:mm')}`;
      const href = window.URL.createObjectURL(new Blob([data], { type: headerType }));
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename);
      link.click();
      setTimeout(() => {
        link.remove();
      }, 1000);
    });
};

const generateEntityQueries = ({
  apiUrl,
  nameForLists,
  nameForEntity,
}: {
  apiUrl?: string;
  nameForLists?: string;
  nameForEntity?: string;
}): Kk => {
  const url = !apiUrl ? process.env.REACT_APP_API_URL || '' : apiUrl;
  const urlForLists = nameForLists || nameForEntity ? `${url}/${nameForLists || nameForEntity}` : url;
  return Object.freeze({
    getEntities: getEntities(urlForLists),
    getEntity: getEntity(urlForLists),
    searchEntities: searchEntities(urlForLists),
    fillEntities: fillEntities(urlForLists),
    createEntity: createEntity(urlForLists),
    saveEntity: saveEntity(urlForLists),
    updateEntity: updateEntity(urlForLists),
    deleteEntity: deleteEntity(urlForLists),
    downloadFile: downloadFile(urlForLists),
    allEntities: allEntities(urlForLists),
    getEntitiesMultipleCalls: getEntitiesMultipleCalls(urlForLists),
  });
};

const toFormData = (body: { [key: string]: any }): FormData => {
  const params = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    params.append(key, typeof value === 'object' ? JSON.stringify(value) : value);
  });

  return params;
};

const extractorActionsResponse = <P>(translator: { [key: string]: P }) => (actions: { func: string }[]) =>
  (actions || []).map(({ func }) => translator[func]).filter((item) => item);

const camelToSnake = (variableName: string) =>
  variableName && variableName.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase();

const transformQuery = (queryToTransform: Record<string, unknown>) => {
  const a = Object.entries(queryToTransform).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(value
        ? {
            [camelToSnake(key)]: value instanceof Date ? getDateFormated(new Date(value), 'yyyy-MM-dd') : value,
          }
        : {}),
    }),
    {}
  );

  return a;
};

const getStatusCode = (error: RequestError) => (error.response ? error.response.status : 500);

export {
  generateEntityQueries,
  getUserToken,
  toFormData,
  extractorActionsResponse,
  camelToSnake,
  transformQuery,
  getStatusCode,
};

export type { RequestError };

export default axiosInstance;
