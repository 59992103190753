import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'modules/alerts';
import { useEffectDeepCompare } from 'components/hooks';

const useHandleUpdateFormAlert = ({ linesSelecteds, setValue, reset, entitySelected, alertType, optionsLines }) => {
  const dispatch = useDispatch();
  useEffectDeepCompare(() => {
    const validLinesIds = optionsLines.map(({ value }) => value);
    const fiterValidLines = (linesSelecteds || []).filter(({ value }) => validLinesIds.includes(value));
    if (fiterValidLines.length < (linesSelecteds || []).length) {
      setValue('selectLines', fiterValidLines);
    }
  }, [optionsLines, linesSelecteds, setValue]);

  useEffect(() => {
    return () => {
      dispatch(actions.ACTIONS_ALERTS_SAVED.getSucceeded(null));
      reset({});
    };
  }, [dispatch, reset]);

  useEffectDeepCompare(() => {
    const isDelta = Boolean(alertType && alertType.value.includes('delta_pcs'));
    if (isDelta && entitySelected !== 'mu') {
      setValue('entitySelected', 'mu');
    }
  }, [alertType, entitySelected]);
};

export default useHandleUpdateFormAlert;
