import types from './types';

const showRequested = (data) => {
  return {
    type: types.SHOW_REQUESTED,
    payload: { ...data, loading: true },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const showSucceeded = (data) => {
  return {
    type: types.SHOW_SUCCEEDED,
    payload: { ...data, loading: false },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};

const showFailed = ({ measurementUnit, variable, selectedWindow, error }) => {
  return {
    type: types.SHOW_FAILED,
    payload: { measurementUnit, variable, selectedWindow, error },
    error,
    meta: {
      timestamp: new Date(),
      error,
    },
  };
};

const cleanGraphsDashboard = () => ({
  type: types.CLEAN_GRAPHS,
  payload: { loading: false },
  meta: {
    timestamp: new Date(),
  },
});

export default {
  showRequested,
  showSucceeded,
  showFailed,
  cleanGraphsDashboard,
};
