import { generateEntityQueries, toFormData } from 'libs/request-utils';
import { getOffsetTimezone } from 'libs/date';

const { saveEntity, searchEntities, deleteEntity, getEntity } = generateEntityQueries({
  nameForLists: 'customVariable',
});

const updateApi = ({ id, name, formula, formulaSplited = [] }) => {
  const body = {
    id,
    name,
    formula,
    formula_splited: formulaSplited,
  };

  const params = toFormData(body);

  return saveEntity({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const createApi = ({ name, formula, formulaSplited = [] }) => {
  const body = {
    name,
    formula,
    formula_splited: formulaSplited,
  };

  const params = toFormData(body);

  return saveEntity({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const deleteApi = ({ id }) => {
  return deleteEntity({
    id: 'save',
    params: {
      id,
    },
  });
};

const getApi = ({ id }) => {
  return getEntity({ id: '', params: { id } });
};

const listApi = ({ q = '', order = 'ASC', sort = 'None', i = 1, limit = 20, offset = 0 }) => {
  return searchEntities({
    params: {
      q,
      order,
      sort,
      i,
      limit,
      offset,
      ho: getOffsetTimezone(),
    },
  });
};

export { updateApi, createApi, deleteApi, getApi, listApi };
