import React, { useState } from 'react';

import FiltersTable from '../FiltersTable';

const useFiltersTable = ({ type }) => {
  const [infoSelected, setInfoSelects] = useState({
    type: '',
    status: 'open',
    measurementUnits: null,
  });

  return {
    FiltersComponent: <FiltersTable type={type} infoSelected={infoSelected} setInfoSelects={setInfoSelects} />,
    infoSelected,
    setInfoSelects,
  };
};

export default useFiltersTable;
