import React, { Suspense } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Spinner } from 'ui';

const Instant = React.lazy(() => import('./Instant'));
const Ultrasonic = React.lazy(() => import('./Ultrasonic'));
const Advanced = React.lazy(() => import('./Advanced'));
const ComparativeConsumption = React.lazy(() => import('./ComparativeConsumption'));
const Control = React.lazy(() => import('./Control'));
const Home = React.lazy(() => import('./Home'));
const SignatureAudit = React.lazy(() => import('./SignatureAudit'));
const Footprint = React.lazy(() => import('./Footprint'));

const ReportRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path={path}>
          <Home />
        </Route>
        <Route path={[`${path}/instant/:id`, `${path}/instant`]}>
          <Instant />
        </Route>
        <Route path={[`${path}/comparative-consumption/:id`, `${path}/comparative-consumption`]}>
          <ComparativeConsumption />
        </Route>
        <Route path={[`${path}/ultrasonic/:id`, `${path}/ultrasonic`]}>
          <Ultrasonic />
        </Route>
        <Route path={[`${path}/control/:id`, `${path}/control`]}>
          <Control />
        </Route>
        <Route path={[`${path}/signature-audit/:id`, `${path}/signature-audit`]}>
          <SignatureAudit />
        </Route>
        <Route path={[`${path}/advanced/:id`, `${path}/advanced`]}>
          <Advanced />
        </Route>
        <Route path={[`${path}/footprint/:id`, `${path}/footprint`]}>
          <Footprint />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default ReportRoutes;
