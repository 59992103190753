import * as React from 'react';
import PropTypes from 'prop-types';

import { useToggle } from 'components/hooks';
import { omit } from 'libs/toolkits';
import Button, { Props as ButtonProps } from '../button';
import { StyleDropdown, ContainerDropdown } from './styles';

interface Props extends Partial<Omit<ButtonProps, 'onClick'>> {
  options: { label: string; value: string; onClick: (event: React.MouseEvent<HTMLLIElement>, value: string) => any }[];
  isInitialOpen?: boolean;
  position: 'left' | 'right';
}

const Dropdown: React.FC<Props> = ({ children, options, isInitialOpen, position = 'left', ...buttonProps }) => {
  const [isOpen, toggle] = useToggle(isInitialOpen);
  return (
    <ContainerDropdown>
      <Button onClick={toggle} {...buttonProps}>
        {children}
      </Button>
      <StyleDropdown {...{ buttonProps, position }} isOpen={isOpen} onMouseLeave={() => toggle(false)}>
        <ul>
          {options.map(({ label, value, onClick }) => {
            return (
              <li key={value} onClick={(event) => onClick(event, value)}>
                {label}
              </li>
            );
          })}
        </ul>
      </StyleDropdown>
    </ContainerDropdown>
  );
};

/*
Dropdown.propTypes = {
	...omit(['onClick'], propTypes),
	options: PropTypes.arrayOf(
	  PropTypes.shape({
		label: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
		onClick: PropTypes.func.isRequired,
	  })
	).isRequired,
	isInitialOpen: PropTypes.bool,
	position: PropTypes.oneOf(['left', 'right']),
  };

  
Dropdown.defaultProps = {
  ...omit(['onClick'], defaultProps),
  isInitialOpen: false,
  children: '',
  position: 'left',
};
*/

export default Dropdown;
