import { createSelector } from 'reselect';

import { getDateFormated, subDays } from 'libs/date';
import { pick, uniqBy } from 'libs/toolkits';
import { getEffortRequest } from './utils/generate';

const getReportsRoot = (state) => state.reports;

const getIsOpen = createSelector([getReportsRoot], (state) => state.isOpen);
const getIsLoading = createSelector([getReportsRoot], (state) => state.isLoading);
const getVariables = createSelector([getReportsRoot], (state) => state.variables);
const getFlowComputerVariables = createSelector([getVariables], (variables) => variables.flowComputer);
const getCromaVariables = createSelector([getVariables], (variables) => variables.croma);
const getFootprintVariables = createSelector([getVariables], (variables) => variables.footprint);
const getCounterVariables = createSelector([getVariables], (variables) => variables.counter);
const getCounterErrorVariables = createSelector([getVariables], (variables) => variables.counterError);

const getLineVariables = createSelector([getVariables], ({ flowComputer, counter, ultrasonic }) => {
  return [...flowComputer, ...counter, ...ultrasonic];
});
const getLineVariableSelecteds = createSelector([getLineVariables], (variables) =>
  variables.filter(({ selected }) => selected)
);
const getMeasurementUnitVariables = createSelector([getVariables], ({ croma }) => {
  return croma;
});
const getMeasurementUnitVariableSelecteds = createSelector([getMeasurementUnitVariables], (variables) =>
  variables.filter(({ selected }) => selected)
);

const getReport = createSelector([getReportsRoot], (state) => state.report);
const getInstantReportVariables = createSelector(
  [getFlowComputerVariables, getCromaVariables],
  (flowComputer, croma) => {
    return {
      croma,
      flowComputer,
    };
  }
);
const getReferenceValues = createSelector([getReportsRoot], (state) => state.referenceValues);
const getReferenceValueFocus = createSelector([getReportsRoot], (state) => state.referenceValue);
const getReferenceValueCurrent = createSelector([getReportsRoot], (state) => state.referenceValueCurrent);
const getReportStatus = createSelector([getReportsRoot], (state) => state.status);
const getReportStatusCode = createSelector([getReportsRoot], (state) => state.statusCode);
const getMeasurementUnitReport = createSelector([getReportsRoot], (state) => state.measurentUnit);
const getMeasurementUnitToCompareReport = createSelector([getReportsRoot], (state) => state.measurentUnitToCompare);
const getComparativeReportVariables = createSelector(
  [getCounterVariables, getCounterErrorVariables],
  (counter, counterError) => {
    return {
      counter: uniqBy(({ value }) => value, [...counter, ...counterError]),
    };
  }
);
const getDateInfo = createSelector([getReportsRoot], (state) => state.dateInfo);
const getDateInfoHour = createSelector([getDateInfo], (state) => state.hour);
const getDateInfoPeriod = createSelector([getDateInfo], (state) => state.period);
const getDateInfoStartHour = createSelector([getDateInfoHour], (state) => state.label.split('-')[0]);
const getDateInfoEndHour = createSelector([getDateInfoHour], (state) => state.label.split('-')[1]);
const getDateInfoStartDateKey = createSelector(
  [getDateInfo, getDateInfoStartHour, getDateInfoEndHour, getDateInfoPeriod],
  (state, startHour, endHour, period) => {
    if (period === 'daily') {
      return getDateFormated(subDays(state.date, 1), `yyyy-MM-dd'T'${endHour}:00`);
    }
    return getDateFormated(subDays(state.date, 1), `yyyy-MM-dd'T'${startHour}:00`);
  }
);
const getDateInfoEndDateKey = createSelector(
  [getDateInfo, getDateInfoStartHour, getDateInfoEndHour, getDateInfoPeriod],
  (state, startHour, endHour, period) => {
    if (period === 'daily') {
      return getDateFormated(state.date, `yyyy-MM-dd'T'${endHour}:00`);
    }
    return getDateFormated(state.date, `yyyy-MM-dd'T'${endHour}:00`);
  }
);
const getDateInfoStartDate = createSelector(
  [getDateInfo, getDateInfoEndHour, getDateInfoPeriod],
  (state, startHour, period) => {
    if (period === 'daily') {
      return getDateFormated(subDays(state.date, 1), `yyyy-MM-dd`);
    }
    return getDateFormated(subDays(state.date, 1), `yyyy-MM-dd ${startHour}`);
  }
);
const getDateInfoEndDate = createSelector(
  [getDateInfo, getDateInfoEndHour, getDateInfoPeriod],
  (state, endHour, period) => {
    if (period === 'daily') {
      return getDateFormated(state.date, `yyyy-MM-dd`);
    }
    return getDateFormated(state.date, `yyyy-MM-dd'T'${endHour}:00`);
  }
);

const getTypeReport = createSelector([getReportsRoot], (state) => state.type);
const getLastMeasure = createSelector([getReportsRoot], (state) => state.lastMeasure);
const getCorrelationReport = createSelector([getReportsRoot], (state) => state.correlation);
const getRepresentationOnlyTable = createSelector([getReportsRoot], (state) => state.onlyRepresentationTable);
const getRepresentationReport = createSelector([getReportsRoot], (state) => state.representation);
const getLastRepresentationReport = createSelector([getReportsRoot], (state) => state.lastRepresentation);
const getTypeWindow = createSelector([getReportsRoot], (state) => state.typeWindow);

const getSavedReportsRoot = createSelector([getReportsRoot], (state) => state.savedReports);
const getSavedReports = createSelector([getSavedReportsRoot], (state) => state.entities);
const getSavedReportsTotal = createSelector([getSavedReportsRoot], (state) => state.total);
const getSavedReportsStatus = createSelector([getSavedReportsRoot], (state) => state.status);

const someSelected = (items) => (items || []).some(({ selected }) => selected);
const isValidMeasureUnit = (measureUnit) => !!measureUnit.id && someSelected(measureUnit.lines);
const getNumberSelecteds = (items) => (items || []).reduce((acc, { selected }) => acc + (selected ? 1 : 0), 0);

const isValidInstantReport = createSelector(
  [getMeasurementUnitReport, getInstantReportVariables],
  (measureUnit, variables) => {
    return isValidMeasureUnit(measureUnit) && (someSelected(variables.croma) || someSelected(variables.flowComputer));
  }
);
const isValidSignatureAudit = createSelector([getMeasurementUnitReport], (measureUnit) => {
  return isValidMeasureUnit(measureUnit);
});

const isValidComparativeReport = createSelector(
  [getMeasurementUnitReport, getMeasurementUnitToCompareReport, getComparativeReportVariables],
  (measureUnit, measurementUnitToCompare, variables) => {
    return (
      getNumberSelecteds(measureUnit.lines) === getNumberSelecteds(measurementUnitToCompare.lines) &&
      isValidMeasureUnit(measureUnit) &&
      isValidMeasureUnit(measurementUnitToCompare) &&
      someSelected(variables.counter)
    );
  }
);

const getDateRange = createSelector([getReportsRoot], (state) => state.dateRange);
const getSignatureAudit = createSelector([getReportsRoot], (state) => state.signatureAudit);

const getAdvancedReportVariables = createSelector(
  [getVariables],
  ({ croma, flowComputer, counter, counterError, ultrasonic }) => {
    return {
      croma,
      flowComputer,
      counter: uniqBy(({ value }) => value, [...counter, ...counterError]),
      ultrasonic,
    };
  }
);

const getFootprintReportVariables = createSelector([getVariables], ({ footprintTR, footprintFR }) => {
  return {
    footprintTR,
    footprintFR,
  };
});

const getMapVariablesEntities = createSelector([getReportsRoot], ({ mapVariablesEntities }) => mapVariablesEntities);
const getAdvancedPeriod = createSelector([getReportsRoot], ({ advancedPeriod }) => advancedPeriod);
const getEffortAdvanced = createSelector([getReportsRoot], ({ advancedPeriod, mapVariablesEntities, dateRange }) => {
  return getEffortRequest({
    shiftType: advancedPeriod?.period?.value?.shiftType,
    shiftValue: advancedPeriod.period.value.shiftValue,
    mapVariablesEntities,
    endDate: dateRange.endDate,
    startDate: dateRange.startDate,
  });
});
const isValidAdvancedReport = createSelector(
  [getMapVariablesEntities, getEffortAdvanced],
  (mapVariablesEntities, { valid }) => {
    return mapVariablesEntities.length;
  }
);
const isValidCorrelationReport = createSelector(
  [getMapVariablesEntities],
  (mapVariablesEntities) => mapVariablesEntities.length === 2
);

const getReportStoreToSave = createSelector(
  [getReportsRoot],
  pick([
    'variables',
    'measurentUnit',
    'measurentUnitToCompare',
    'dateInfo',
    'dateRange',
    'advancedPeriod',
    'mapVariablesEntities',
    'correlation',
    'measurementUnit',
  ])
);

const getScheduleReports = createSelector([getReportsRoot], (state) => state.scheduleReports);
const getScheduleReportsTotal = createSelector([getReportsRoot], (state) => state.total);
const getScheduleReportsFiles = createSelector([getReportsRoot], (state) => state.scheduleReportsFiles);
const getScheduleReportsStatus = createSelector([getScheduleReports], (state) => state.status);

const hasCounterVariables = createSelector(
  [getCounterVariables, getMapVariablesEntities],
  (counter, mapVariablesEntities) => {
    const variablesCounter = counter.map(({ value }) => value);

    return mapVariablesEntities.some(
      ({ type, variable, customVariableVariables = [] }) =>
        (type !== 'math' && variablesCounter.includes(variable)) ||
        customVariableVariables.some((v) => variablesCounter.includes(v))
    );
  }
);

const hasCounterErrorVariables = createSelector(
  [getCounterErrorVariables, getMapVariablesEntities],
  (counterError, mapVariablesEntities) => {
    const variablesCounterError = counterError.map(({ value }) => value);

    return mapVariablesEntities.some(
      ({ type, variable, customVariableVariables = [] }) =>
        (type !== 'math' && variablesCounterError.includes(variable)) ||
        customVariableVariables.some((v) => variablesCounterError.includes(v))
    );
  }
);

const getEvolutionChroma = createSelector([getReportsRoot], (state) => state.evolutionChroma);
const getGasQualityAssignment = createSelector([getReportsRoot], (state) => state.qualityAssignment);
const getReportName = createSelector([getReportsRoot], (state) => state.reportName);
const getReportFrequencies = createSelector([getReportsRoot], (state) => state.frequencies);
const getReportReceiveBool = createSelector([getReportsRoot], (state) => state.receiveBool);

export default {
  getVariables,
  getFlowComputerVariables,
  getCromaVariables,
  getFootprintVariables,
  getFootprintReportVariables,
  getCounterVariables,
  getCounterErrorVariables,
  getReport,
  getInstantReportVariables,
  getReferenceValues,
  getReferenceValueFocus,
  getReferenceValueCurrent,
  getReportStatus,
  getReportStatusCode,
  getMeasurementUnitReport,
  getMeasurementUnitToCompareReport,
  getComparativeReportVariables,
  getDateInfo,
  getDateInfoStartDate,
  getDateInfoEndDate,
  getDateInfoStartDateKey,
  getDateInfoEndDateKey,
  getLastMeasure,
  getTypeReport,
  getCorrelationReport,
  getRepresentationOnlyTable,
  getRepresentationReport,
  getLastRepresentationReport,
  getTypeWindow,
  getSavedReports,
  getSavedReportsTotal,
  getSavedReportsStatus,
  getScheduleReportsTotal,
  getScheduleReportsFiles,
  getScheduleReportsStatus,
  isValidInstantReport,
  isValidComparativeReport,
  getDateRange,
  getSignatureAudit,
  getAdvancedReportVariables,
  getMapVariablesEntities,
  getAdvancedPeriod,
  isValidAdvancedReport,
  isValidCorrelationReport,
  isValidSignatureAudit,
  getEffortAdvanced,
  getIsOpen,
  getReportStoreToSave,
  getLineVariables,
  getMeasurementUnitVariables,
  getLineVariableSelecteds,
  getMeasurementUnitVariableSelecteds,
  getScheduleReports,
  getIsLoading,
  hasCounterVariables,
  hasCounterErrorVariables,
  getEvolutionChroma,
  getGasQualityAssignment,
  getReportName,
  getReportFrequencies,
  getReportReceiveBool,
};
