import React from 'react';
import PropTypes from 'prop-types';

const FormulaStateContext = React.createContext(undefined);
const FormulaDispatchContext = React.createContext(undefined);

FormulaStateContext.displayName = 'FormulaStateContext';

const initialState = () => ({
  isValid: false,
  getOperations: () => [],
  operations: { current: [] },
});

const FormulaProvider = (props) => {
  const { children } = props;

  const [state, dispatch] = React.useReducer((currentState, newState) => {
    return {
      ...currentState,
      ...newState,
    };
  }, initialState);

  return (
    <FormulaStateContext.Provider value={state}>
      <FormulaDispatchContext.Provider value={dispatch}>{children}</FormulaDispatchContext.Provider>
    </FormulaStateContext.Provider>
  );
};

const useFormulaState = () => {
  const context = React.useContext(FormulaStateContext);
  if (context === undefined) {
    throw new Error('useFormulaState must be used within a FormulaProvider');
  }

  return context;
};

const useFormulaDispatch = () => {
  const context = React.useContext(FormulaDispatchContext);
  if (context === undefined) {
    throw new Error('useFormulaDispatch must be used within a FormulaProvider');
  }

  return context;
};

FormulaProvider.propTypes = {
  children: PropTypes.func.isRequired,
};

export { FormulaProvider, useFormulaState, useFormulaDispatch };
