import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Select from 'ui/form/select';
import { equals } from 'libs/toolkits';
import { useEffectDeepCompare } from '../hooks';
import useSelect from './useSelect';

const SelectorSync = ({
  fillOptions,
  onChange: onChangeSelect,
  isMulti,
  name,
  outSideSelecteds,
  value,
  margin,
  fillParams,
  noSeparation,
  ...props
}) => {
  const [selecteds, onChange] = useSelect(value, onChangeSelect);
  const [options, setOptions] = useState([]);
  useEffectDeepCompare(() => {
    fillOptions(fillParams).then((result) => {
      setOptions(result);
    });
  }, [fillOptions, fillParams, setOptions]);

  const hadleOnRemove = useCallback(
    (val) => {
      const removed = selecteds.filter((selected) => val !== selected.value);
      onChangeSelect(removed);
    },
    [selecteds, onChangeSelect]
  );
  useEffectDeepCompare(() => {
    if (Array.isArray(value) && options.length) {
      const formatedValues = value.map(({ label, ...rest }) => ({
        ...(!label ? options.find((e) => e.value === rest.value) : { label, ...rest }),
      }));
      if (!equals(formatedValues, value)) {
        onChangeSelect(formatedValues);
      }
    }
    if (value && !Array.isArray(value) && !value.label && options.length) {
      onChangeSelect({ ...value, ...options.find((e) => e.value === value.value) });
    }
  }, [value, options]);

  return (
    <>
      <Select
        value={selecteds}
        options={options}
        noSeparation={noSeparation}
        isMulti={isMulti}
        isSearchable
        onChange={onChange}
        name={name}
        outSideSelecteds={outSideSelecteds}
        hadleOnRemove={hadleOnRemove}
        {...props}
      />
    </>
  );
};

export const propTypes = {
  onChange: PropTypes.func,
  fillOptions: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  name: PropTypes.string.isRequired,
  outSideSelecteds: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), label: PropTypes.string }),
    PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), label: PropTypes.string })
    ),
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  fillParams: PropTypes.object,
};

export const defaultProps = {
  onChange: () => {},
  outSideSelecteds: false,
  isMulti: false,
  value: null,
  fillParams: {},
};

SelectorSync.propTypes = propTypes;

SelectorSync.defaultProps = defaultProps;

export default SelectorSync;
