import React, { FC } from 'react';
import { RowFlexStyles } from './styles';

interface Props {
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-around' | 'space-between';
  align?: 'stretch' | 'baseline' | 'center' | 'flex-start' | 'flex-end';
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flex?: string;
  fullHeight?: boolean;
  margin?: number;
  noWrap?: boolean;
}

const RowFlex: FC<Props> = ({ justify, align, children, direction, flex, fullHeight, margin, noWrap }) => (
  <RowFlexStyles {...{ justify, align, flex, direction, fullHeight, margin, noWrap }}>{children}</RowFlexStyles>
);

export default RowFlex;
