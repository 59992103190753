import { generateEntityQueries, toFormData } from 'libs/request-utils';
import {
  getDateFormated,
  getOffsetTimezone,
  getDateISOString,
  subDays,
  set,
  gasStartEndDayTime,
  getTimeZone,
  transformDateToUTC,
} from 'libs/date';
import { getMeasurementUnit } from '../measurementUnits/api';
import { getEffortRequest } from './utils/generate';

const { createEntity, saveEntity, searchEntities, deleteEntity, getEntity } = generateEntityQueries({
  nameForLists: 'simos/report',
});
const { createEntity: saveRenameReportRequest } = generateEntityQueries({
  nameForLists: 'simos/report/rename',
});
const { createEntity: saveEntityReferenceValue } = generateEntityQueries({
  nameForLists: 'referenceValues/saveValue',
});

const { createEntity: setEntityReferenceValue } = generateEntityQueries({
  nameForLists: 'referenceValues/setValue',
});

const { deleteEntity: deleteEntityReferenceValue } = generateEntityQueries({
  nameForLists: 'referenceValues/deleteValue',
});
const { createEntity: createEntityUltrasonic } = generateEntityQueries({
  nameForLists: 'ultrasonic/report',
});

const { createEntity: sendEmail } = generateEntityQueries({
  nameForLists: 'simos/report/emailSend',
});

const { createEntity: sendEmailAssignment } = generateEntityQueries({
  nameForLists: 'qualityAssignment/sendReport',
});

const { downloadFile: downloadAssignmentReport } = generateEntityQueries({
  nameForLists: 'qualityAssignment/downloadReport',
});
const { searchEntities: searchSignatureAudits } = generateEntityQueries({
  nameForLists: 'signature/report',
});

const { getEntities: getSignatureAudit } = generateEntityQueries({
  nameForLists: 'signature/getSign',
});
const { getEntities: getReferenceValuesList } = generateEntityQueries({
  nameForLists: 'referenceValues/list',
});

const { downloadFile } = generateEntityQueries({
  nameForLists: 'signature/getSignFiles',
});

const { createEntity: requestAdvancedAnalysis } = generateEntityQueries({
  nameForLists: 'simos/analysis',
});

const { createEntity: requestReportSaturation } = generateEntityQueries({
  nameForLists: 'report/saturation',
});
const { createEntity: requestReportLine } = generateEntityQueries({
  nameForLists: 'report/line',
});
const { createEntity: saveScheduledReportRequest } = generateEntityQueries({
  nameForLists: 'report/saveCrono',
});

const { deleteEntity: deleteScheduledReportRequest } = generateEntityQueries({
  nameForLists: 'report/deleteCrono',
});
const { getEntities: getScheduleReportList } = generateEntityQueries({
  nameForLists: 'report/list',
});
const { getEntities: getScheduleReportFileList } = generateEntityQueries({
  nameForLists: 'report/filesList',
});
const { downloadFile: downloadScheduleReportFile } = generateEntityQueries({
  nameForLists: 'report/downloadReportFile',
});
const { createEntity: ableScheduleReport } = generateEntityQueries({
  nameForLists: 'report/ablecrono',
});

const getMeasurementRequest = getMeasurementUnit;

const sendReportToEmail = ({ filename = 'report', content, type, emails }) => {
  const params = toFormData({
    filename,
    content,
    type,
    emails,
  });

  return sendEmail({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};
const sendAssignmentReportToEmail = ({ filename = 'report', content, type, emails, ...rest }) => {
  const params = toFormData({
    filename,
    content,
    type,
    emails,
    timezone: getTimeZone,
    ...rest,
  });

  return sendEmailAssignment({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};
const downloadAssignmentReportAPI = ({ filename = 'report', type, ...rest }) => {
  return downloadAssignmentReport({
    params: { filename, type, timezone: getTimeZone, ...rest },
    headers: {
      'content-disposition': `attachment; filename="${filename}.${type}"`,
    },
  });
};

const generateInstantReport = ({ measurementUnitId, lines = [], variables = [] }) => {
  const body = {
    start_date: getDateFormated(new Date(), 'yyyy-MM-dd'),
    end_date: getDateFormated(subDays(new Date(), 1), 'yyyy-MM-dd'),
    mu: measurementUnitId,
    datetime_type: 'quarterly',
    lines,
    variables,
    ho: getOffsetTimezone(),
    mu_r: '',
    time: '',
    lines_r: [],
    type: 'instant',
  };

  const params = toFormData(body);

  return createEntity({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const generateComparativeReport = ({
  measurementUnitId,
  measurementUnitIdToCompare,
  lines = [],
  linesToCompare = [],
  variables = [],
  dateType,
  date,
  time,
}) => {
  const { timeUTC, dateUTC } = (() => {
    const dateTime = transformDateToUTC(`${getDateFormated(date, 'yyyy-MM-dd')} ${time}`);
    return { timeUTC: getDateFormated(dateTime, 'HH:mm'), dateUTC: dateTime };
  })();

  const startDateParam =
    dateType === 'daily'
      ? getDateFormated(dateUTC, 'yyyy-MM-dd')
      : getDateFormated(transformDateToUTC(dateUTC), 'yyyy-MM-dd');
  const endDateParam = getDateFormated(subDays(dateUTC, -1), 'yyyy-MM-dd');

  const body = {
    start_date: startDateParam,
    end_date: endDateParam,
    mu: measurementUnitId,
    datetime_type: dateType,
    lines,
    variables,
    ho: 0,
    mu_r: measurementUnitIdToCompare,
    time: timeUTC,
    lines_r: linesToCompare,
    type: 'comparative',
  };
  const params = toFormData(body);
  return createEntity({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const saveReport = (params) => {
  return saveEntity({
    body: toFormData(params),
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const saveScheduledReport = (params) => {
  return saveScheduledReportRequest({
    body: toFormData(params),
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const saveRenameReport = (params) => {
  return saveRenameReportRequest({
    body: toFormData(params),
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const saveReferenceValue = ({ data }) => {
  const body = {
    ...data,
    type: 'ultrasonic',
    subtype: 'all',
  };
  const params = toFormData(body);

  return saveEntityReferenceValue({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const setEntityReferenceValueCall = (body) => {
  const bodyObject = {
    ...body,
  };
  const params = toFormData(bodyObject);

  return setEntityReferenceValue({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};
const listSavedReports = ({
  order = 'ASC',
  sort = undefined,
  q = undefined,
  cursor = undefined,
  ho = '-1',
  limit = 15,
  offset = 0,
  type,
}) => {
  return searchEntities({
    params: {
      order,
      sort,
      q,
      cursor,
      ho,
      limit,
      offset,
      type,
    },
  });
};

const deleteReferenceValue = ({ id }) => {
  return deleteEntityReferenceValue({
    id,
  });
};
const deleteSavedReport = ({ id }) => {
  return deleteEntity({
    id,
  });
};

const getSavedReport = ({ id }) => {
  return getEntity({ id: 'getConfig', params: { id } });
};

const getSignatureAudits = ({ startDate, endDate, type, measureUnitId, lines, limit, offset, cursor }) => {
  return searchSignatureAudits({
    params: {
      order: 'ASC',
      start_date: getDateISOString(startDate),
      end_date: getDateISOString(endDate),
      table: type,
      mu: measureUnitId,
      lines: JSON.stringify(lines),
      limit,
      offset,
      ho: 0,
      cursor,
    },
  });
};

const getAdvancedReport = ({
  startDate,
  endDate,
  shiftValue = 5,
  shiftType = 'minutes',
  variables = [],
  customVariables = [],
  correlation = false,
  representation,
}) => {
  const body = {
    start_date: getDateISOString(new Date(`${startDate}`)),
    end_date: getDateISOString(new Date(`${endDate}`)),
    shift_value: shiftValue,
    shift_type: shiftType,
    variables,
    custom_variables: customVariables,
    ho: 0,
    correlation,
    representation,
  };
  const { valid: validRequest } = getEffortRequest({
    startDate: new Date(`${startDate}`),
    endDate: new Date(`${endDate}`),
    shiftType,
    shiftValue,
    mapVariablesEntities: [...body.variables, ...body.custom_variables],
  });

  if (body.variables.length + body.custom_variables.length >= 20 || !validRequest) {
    body.representation = 'email';
  }

  const params = toFormData(body);
  return requestAdvancedAnalysis({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const getSignatureAuditApi = ({ id }) => {
  return getSignatureAudit({
    params: {
      id,
      ho: getOffsetTimezone(),
    },
  });
};

const downloadSignatuAudits = (ids = []) => {
  return downloadFile({
    params: {
      packages: JSON.stringify(ids),
    },
  });
};

const generateUltrasonicReport = ({ measurementUnitId, lineId, date, lastMeasure }) => {
  const body = {
    ...(!lastMeasure && { date }),
    mu: measurementUnitId,
    line: lineId,
    ho: getOffsetTimezone(),
    type: 'ultrasonic',
  };
  const params = toFormData(body);
  return createEntityUltrasonic({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const generateControlReport = ({ measurementUnitId, startDate, endDate, date, windowType, type }) => {
  const startDateUTC = getDateISOString(set(startDate, gasStartEndDayTime));
  const endDateUTC = getDateISOString(set(endDate, gasStartEndDayTime));
  const body = {
    ...(type === 'minimum' ? { start_date: startDateUTC, end_date: endDateUTC } : { end_date: endDateUTC }),
    ho: 0,
    window_type: windowType || 'h',
    mu: measurementUnitId,
    timezone: getTimeZone,
  };
  const params = toFormData(body);

  if (type === 'minimum') {
    return requestReportLine({
      body: params,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }
  return requestReportSaturation({
    body: params,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const getReferenceValues = ({ subtype, line }) => {
  return getReferenceValuesList({
    params: {
      subtype,
      line,
      ho: getOffsetTimezone(),
    },
  });
};

const getScheduleReports = ({ type, limit, offset = 0 }) => {
  return getScheduleReportList({
    params: {
      type,
      ho: getOffsetTimezone(),
      limit,
      offset,
    },
  });
};
const getScheduleReportFiles = ({ id, startDate, endDate, limit, offset = 0 }) => {
  return getScheduleReportFileList({
    params: {
      id,
      ho: getOffsetTimezone(),
      start_date: startDate ? getDateFormated(startDate, 'yyyy-MM-dd') : '',
      end_date: endDate ? getDateFormated(endDate, 'yyyy-MM-dd') : '',
      limit,
      offset,
    },
  });
};

const downloadReportFile = ({ path, nameFile }) => {
  return downloadScheduleReportFile({
    params: { file_path: path, name_file: nameFile },
  });
};

const ableScheduleReportCall = ({ id, mode }) => {
  return ableScheduleReport({
    params: { id, mode },
  });
};

const deleteScheduledReport = ({ id }) => {
  return deleteScheduledReportRequest({
    id,
  });
};

export {
  generateInstantReport,
  generateComparativeReport,
  generateControlReport,
  generateUltrasonicReport,
  getMeasurementRequest,
  sendReportToEmail,
  sendAssignmentReportToEmail,
  downloadAssignmentReportAPI,
  saveScheduledReport,
  saveRenameReport,
  saveReferenceValue,
  setEntityReferenceValueCall,
  deleteReferenceValue,
  listSavedReports,
  deleteSavedReport,
  deleteScheduledReport,
  getSavedReport,
  getSignatureAudits,
  downloadSignatuAudits,
  getSignatureAuditApi,
  getReferenceValues,
  getAdvancedReport,
  getScheduleReports,
  getScheduleReportFiles,
  downloadReportFile,
  ableScheduleReportCall,
  saveReport,
};
