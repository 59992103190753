const fontFamily = {
  defaultFont: 'Lato, sans-serif',
  FontTitleSection: 'fontTitleSection, Lato, sans-serif',
  iconFont: 'iconFont',
};

const fontSize = {
  fontSizeH1: `30px`,
  fontSizeH2: `25px`,
  fontSizeH3: `18px`,
  fontSizeP: `15px`,
  fontSizeInput: `15px`,
};

const fontHeight = {
  lineHeightH1: `40px`,
  lineHeightH2: `34px`,
  lineHeightH3: `27px`,
  lineHeightP: `24px`,
};

const fontWeight = {
  Bold: 900,
  Regular: 400,
  SemiBold: 600,
  Light: 300,
};

const fonts = {
  ...fontFamily,
  ...fontSize,
  ...fontHeight,
  ...fontWeight,
};

export default fonts;
