import i18n from 'config/i18n';
import { transformFillResponseToSelectOptions } from './utils';

const useFillEntities = () => {
  const entities = [
    { id: 'positions', name: i18n.t('components:sections:select-position-label') },
    { id: 'measurementunits', name: i18n.t('components:sections:select-measurementUnits-label') },
  ];

  return new Promise((resolve) => {
    resolve(transformFillResponseToSelectOptions(entities));
  });
};

export { useFillEntities };
