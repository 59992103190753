import { css } from 'styled-components';

export default {
  position: {
    fixedWithShadow: css`
      ul {
        box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 42px;
        left: 180px;
        width: 100%;
        padding-left: 14px;
      }
    `,
    default: css`
      ul {
        position: sticky;
        z-index: 9999;
        top: 0;
        margin-bottom: 20px;
      }
    `,

    left: css`
      display: flex;
      max-width: 1200px;
      width: 90%;
      margin: 0 auto;
      height: 100%;

      .react-tabs__tab--selected {
        color: #fff;
        background: ${(props) => props.theme.colors.primary};
        border-radius: 4px;
      }

      .react-tabs__tab--selected:hover {
        background: ${(props) => props.theme.colors.primary};
        opacity: 0.8;
        color: #fff;
      }

      ul {
        width: 250px;
        margin-right: 40px;
        flex-direction: column;
      }

      li {
        font-size: 1rem;
        margin: 0 0 4px;
      }

      & .react-tabs__tab-panel--selected {
        width: 100%;
      }
    `,
  },

  size: {
    small: css`
      font-size: 0.8rem;
    `,
    medium: css`
      font-size: 1rem;
    `,
  },
};
