import styled from 'styled-components';
import { Statistic } from 'antd';

const StatisticStyles = styled(Statistic)`
  margin-right: 8px;
  margin-top: 10px;
  padding: 0 14px;
  min-width: 150px;
  display: flex;
  flex-direction: column-reverse;
  width: 38%;

  :first-child {
    border-right: 1px solid #ccc;
  }

  .ant-statistic-title {
    margin-bottom: 0;
    font-size: 10px;
    text-transform: uppercase;
  }
  .ant-statistic-content {
    height: 50px;
  }

  .ant-statistic-content-value-int {
    color: #ff6985;
    font-size: 1.5em;
    font-weight: bold;
  }
`;

export { StatisticStyles };
