import React, { Suspense } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Spinner } from 'ui';

const Explore = React.lazy(() => import('./Explore'));

const ExploreRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route path={`${path}`}>
          <Explore />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default ExploreRoutes;
