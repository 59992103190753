import actionType from './types';

const initialState = {
  isLoading: false,
  isOnError: false,
  measurementUnitsById: {},
  companyOptions: [],
  newMeasurementUnit: null,
  measurementUnit: {},
  equipmentLine: {
    isLoading: false,
    entity: {},
  },
  error: null,
  measurementUnits: [],
  total: 0,
};

const reducer = (state = initialState, action) => {
  const {
    type,
    isLoading,
    error,
    LineMeasuringConfigSelected,
    cromatographSelected,
    newEquipment,
    companyOptions,
    entity,
  } = action;
  switch (type) {
    case actionType.LIST_REQUESTED:
    case actionType.GET_BY_ID_REQUESTED:
    case actionType.CREATE_REQUESTED:
    case actionType.DELETE_REQUESTED:
    case actionType.UPDATE_REQUESTED:
    case actionType.FILL_REQUESTED:
    case actionType.UPLOAD_LINEAR_SCHEMA_REQUESTED:
      return { ...state, isLoading };

    case actionType.SHOW_REQUESTED:
      return { ...state, measurementUnit: {}, isLoading: action.payload.loading };

    case actionType.SET_ENTITY:
      return { ...state, entity };
    case actionType.SHOW_SUCCEEDED:
      return {
        ...state,
        measurementUnit: action.payload.entity,
        isLoading: false,
        isOnError: !!error,
      };

    case actionType.SHOW_FAILED:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isOnError: !!error,
      };
    case actionType.FILL_SUCCEEDED:
      return { ...state, companyOptions };

    case actionType.LIST_SUCCEEDED:
      return { ...state, measurementUnits: action.payload.entities, total: action.payload.total };

    case actionType.GET_BY_ID_SUCCEEDED:
      return { ...state, ...(LineMeasuringConfigSelected || cromatographSelected) };

    case actionType.CREATE_SUCCEEDED:
      return { ...state, newEquipment };

    case actionType.DELETE_SUCCEEDED:
      return { ...state, isLoading };

    case actionType.UPDATE_SUCCEEDED:
      return { ...state, isLoading };
    case actionType.UPLOAD_LINEAR_SCHEMA_SUCCEEDED:
      return { ...state, measurementUnit: { ...state.measurementUnit, linearSchema: action.payload }, isLoading };

    case actionType.LIST_FAILED:
    case actionType.GET_BY_ID_FAILED:
    case actionType.DELETE_FAILED:
    case actionType.CREATE_FAILED:
    case actionType.UPDATE_FAILED:
    case actionType.FILL_FAILED:
    case actionType.UPLOAD_LINEAR_SCHEMA_FAILED:
      return { ...state, error };

    case actionType.IS_LOADING_EQUIPMENTS:
      return { ...state, isLoading };

    case actionType.GET_EQUIPMENT_LINE_REQUESTED:
      return { ...state, equipmentLine: { ...state.equipmentLine, isLoading: true } };
    case actionType.GET_EQUIPMENT_LINE_FAILED:
    case actionType.GET_EQUIPMENT_LINE_SUCCEEDED:
      return {
        ...state,
        equipmentLine: { isLoading: false, entity: { ...state.equipmentLine.entity, ...action.payload.entity } },
      };

    default:
      return state;
  }
};

export default reducer;
