import { createSelector } from 'reselect';

const getResultStatus = (state) => state.preferences;

const getConfigUser = createSelector([getResultStatus], (state) => state.configUser);
const getConfigUserLoading = createSelector([getConfigUser], (state) => state.loading);

export default {
  getConfigUser,
  getConfigUserLoading,
};
