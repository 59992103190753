import actionTypes from './types';

const initialState = {
  Vn: null,
  Vn30: null,
  E: null,
  E30: null,
  balance: { isLoading: false, error: null, data: null },
  isLoading: false,
  isLoadingN30: false,
  params: { branch: '', startDate: null, endDate: null, datetimeType: 'h' },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GET_BY_ID_REQUESTED:
    case actionTypes.GET_BY_ID_SUCCEEDED:
    case actionTypes.GET_BY_ID_FAILED:
      return { ...state, ...payload };
    case actionTypes.RESET_STORE:
      return { ...initialState, params: payload.params };
    default:
      return state;
  }
};

export default reducer;
