import React from 'react';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';

import Dropdown from '../../dropdown';

const ExportTable = ({ fileName, id }) => {
  const exportToCSV = (event, extension) => {
    const fileExtension = `.${extension}`;
    const elt = document.getElementById(id);
    const wb = XLSX.utils.table_to_book(elt, { sheet: 'Sheet JS' });

    return XLSX.writeFile(wb, fileName + fileExtension);
  };
  const options = [
    {
      label: 'xlsx',
      value: 'xlsx',
      onClick: exportToCSV,
    },
    {
      label: 'csv',
      value: 'csv',
      onClick: exportToCSV,
    },
  ];
  return (
    <Dropdown variant="warning" options={options}>
      Export
    </Dropdown>
  );
};

ExportTable.propTypes = {
  id: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default ExportTable;
