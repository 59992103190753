/* eslint-disable react/display-name */
import React, { useState, useEffect, ChangeEvent } from 'react';
import PropTypes from 'prop-types';

import Label from '../label';
import { CheckboxStyles } from './styles';

interface Props extends Partial<HTMLInputElement> {
  textLabel: string;
  isSelected?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any;
  width?: number;
  isChecked?: boolean;
  variant?: 'default' | 'colored';
}

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      textLabel,
      width,
      name = '',
      disabled = false,
      isSelected = false,
      variant = 'default',
      onChange = () => {},
      checked: isChecked = false,
    },
    ref
  ) => {
    const [checkValue, setCheckValue] = useState(isSelected || isChecked);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { checked },
      } = event;
      setCheckValue(checked);
      onChange(event);
    };

    useEffect(() => {
      setCheckValue(isSelected || isChecked);
    }, [setCheckValue, isSelected, isChecked]);

    return (
      <CheckboxStyles width={width} variant={variant}>
        <input
          type="checkbox"
          data-cy={`checkbox-${name}`}
          name={name}
          id={id}
          disabled={disabled}
          onChange={handleChange}
          checked={checkValue}
          {...(ref && { ref })}
        />
        <Label htmlFor={id} textLabel={textLabel} />
      </CheckboxStyles>
    );
  }
);

export default Checkbox;
