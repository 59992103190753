import styled from 'styled-components';

const ModalStyles = styled.div<{
  zIndex?: number | string;
  styles: { fullScreen?: boolean };
}>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex || '999'};
  height: 100vh;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: ${({ styles }) => (styles.fullScreen ? 'start' : 'center')};
  padding: ${({ styles }) => (styles.fullScreen ? '0 20px' : '20px')};
  background: ${({ styles }) => (styles.fullScreen ? 'white' : '#27324099')};
`;

const ModalHeader = styled.div<{
  customJustify?: string;
}>`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: ${({ customJustify }) => customJustify || 'flex-end'};
  position: sticky;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 99;
  max-height: 80px;
  flex-grow: 1;

  span {
    cursor: pointer;
  }
`;

const ModalContent = styled.div<{
  maxWidth?: string | number;
  alignSelf?: string;
  flex?: string;
}>`
  max-width: ${(props) => props.maxWidth || '700px'};
  align-self: ${(props) => props.alignSelf || 'center'};
  padding: 0 20px 20px;
  width: 90%;
  background: #fff;
  border-radius: 4px;
  max-height: 90%;
  overflow-y: auto;
  flex: ${(props) => props.flex || 'inherit'};

  ${ModalHeader} {
    padding: 20px 0;
    max-height: initial;
  }

  .content-sticky {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 99;
    padding-bottom: 10px;
  }

  form button {
    margin: 20px 5px 0;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  position: fixed;
  bottom: 0;
  background: #fff;
  width: calc(100% - 48px);
  padding: 0 20px 20px;
  border-top: 1px solid #e4e2e7;

  button {
    margin: 0 24px;
  }
`;

export { ModalStyles, ModalContent, ModalHeader, ModalFooter };
