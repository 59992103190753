import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useEffectDeepCompare } from 'components/hooks';

const useHandleKeyDown = ({
  input,
  setInput,
  operations,
  setOperation,
  setVars,
  cursorPosition,
  setCursorPosition,
  isFocus,
  variables,
  concreteEntities,
}) => {
  const { t } = useTranslation();

  const canNavigate = useCallback((ops) => {
    return !ops.some(({ entity, type }) => type !== 'math' && !entity);
  }, []);

  const addNewOperator = useCallback(
    (newOperator) => {
      setOperation([
        ...operations.slice(0, cursorPosition + 1),
        newOperator,
        ...operations.slice(cursorPosition + 1, operations.length),
      ]);
      setCursorPosition(cursorPosition + 1);
      setInput('');
    },
    [operations, cursorPosition, setOperation, setCursorPosition, setInput]
  );

  const replaceOperator = useCallback(
    (newOperator) => {
      const newOperations = operations.reduce((acc, operator, index) => {
        if (index === cursorPosition) {
          return [...acc, { ...operator, ...newOperator }];
        }
        return [...acc, operator];
      }, []);
      setOperation(newOperations);
      setInput('');
    },
    [operations, cursorPosition, setOperation, setInput]
  );

  const removeOperator = useCallback(() => {
    const newOperations = operations.filter((_, index) => index !== cursorPosition);
    setOperation(newOperations);
    setCursorPosition(cursorPosition - 1 < -1 ? -1 : cursorPosition - 1);
    setInput('');
  }, [operations, setOperation, setCursorPosition, cursorPosition, setInput]);

  const handleBackspace = useCallback(() => {
    const lastOperation = operations[cursorPosition];
    if (input) {
      setInput(input.slice(0, input.length - 1));
      return;
    }
    if (!lastOperation || !lastOperation.entity) {
      removeOperator();
      return;
    }
    replaceOperator({
      entity: null,
      key: Math.random(0, 1),
    });
  }, [operations, cursorPosition, input, replaceOperator, setInput, removeOperator]);

  const handleDigit = useCallback(
    (eventValue) => {
      const lastOperation = operations[cursorPosition];
      if (input.length) {
        return;
      }
      if (lastOperation && lastOperation.type !== 'math' && lastOperation.entity !== null) {
        return;
      }
      if (concreteEntities && lastOperation && lastOperation.type !== 'math') {
        return;
      }
      if (lastOperation && lastOperation.type !== 'math') {
        const maxId = operations.reduce((acc, operation) => {
          return Number(operation.entity) >= acc ? Number(operation.entity) + 1 : acc;
        }, 1);

        if (Number(eventValue) > maxId) {
          return;
        }
        replaceOperator({
          entity: eventValue,
          key: Math.random(0, 1),
        });
      } else {
        addNewOperator({
          type: 'math',
          entity: null,
          value: eventValue,
          key: Math.random(0, 1),
        });
      }
    },
    [operations, input.length, cursorPosition, replaceOperator, addNewOperator, concreteEntities]
  );

  const handleMathOperator = useCallback(
    (eventValue) => {
      const lastOperation = operations[cursorPosition];
      const lastOperationIsVar = lastOperation && lastOperation.type !== 'math';
      if (input.length || (lastOperationIsVar && lastOperation.entity === null)) {
        return;
      }
      addNewOperator({
        type: 'math',
        entity: null,
        value: eventValue,
        key: Math.random(0, 1),
      });
    },
    [input, operations, cursorPosition, addNewOperator]
  );

  const handleEventKeyDown = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.key === 'ArrowRight' && canNavigate(operations)) {
        setCursorPosition(cursorPosition >= operations.length - 1 ? cursorPosition : cursorPosition + 1);
      }
      if (event.key === 'ArrowLeft' && canNavigate(operations)) {
        setCursorPosition(cursorPosition - 1 < -1 ? -1 : cursorPosition - 1);
      }
      if (event.key === 'End' && canNavigate(operations)) {
        setCursorPosition(operations.length - 1);
      }
      if (event.key === 'Home' && canNavigate(operations)) {
        setCursorPosition(-1);
      }
      if (event.key === 'Backspace') {
        handleBackspace();
        return;
      }
      if (/\d/.test(event.key)) {
        handleDigit(event.key);
        return;
      }
      if (/\(|\)|-|\+|\.|\/|\*/.test(event.key)) {
        handleMathOperator(event.key);
        return;
      }
      if (/^[a-zA-z]$/.test(event.key)) {
        const lastOperation = operations[operations.length - 1];
        if (lastOperation && lastOperation.type !== 'math') {
          return;
        }
        const newValueInput = input + event.key;
        setInput(newValueInput);
      }
    },
    [
      handleBackspace,
      handleDigit,
      handleMathOperator,
      cursorPosition,
      canNavigate,
      setInput,
      input,
      operations,
      setCursorPosition,
    ]
  );
  useEffectDeepCompare(() => {
    const VARIABLES = variables.map((v) => ({ ...v, label: t(v.label) }));
    const regex = new RegExp(input, 'i');
    const variablesSearched = input ? VARIABLES.filter(({ label }) => regex.test(label)) : [];
    setVars(variablesSearched);
  }, [input, setVars, t, variables]);

  useEffect(() => {
    if (isFocus) {
      document.addEventListener('keydown', handleEventKeyDown);
    } else {
      document.removeEventListener('keydown', handleEventKeyDown, false);
    }
    return () => {
      document.removeEventListener('keydown', handleEventKeyDown, false);
    };
  }, [isFocus, handleEventKeyDown]);

  return {
    handleDigit,
    handleMathOperator,
    addNewOperator,
    canNavigate,
    replaceOperator,
  };
};

export { useHandleKeyDown };
