import { useEffect, useRef } from 'react';

const useOnUpdate = (func, funcFinish = null) => {
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (!isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      func();
    }
    if (funcFinish) {
      return funcFinish;
    }
  }, [func, funcFinish]);
};

export default useOnUpdate;
