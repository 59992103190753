import styled from 'styled-components';

import { Alert } from 'antd';

const AlertStyles = styled(Alert)`
  &.ant-alert {
    margin: 20px 0;
  }

  .ant-alert-message,
  .ant-alert-description {
    max-width: 80%;
    display: inherit;
  }
`;

export { AlertStyles };
