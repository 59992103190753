import types from './types';

const initialState = {
  isOnError: false,
  isLoading: false,
  error: null,
  data: [],
  variables: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case types.SHOW_REQUESTED:
      return {
        ...state,
        variables: {
          ...state.variables,
          [`${payload.measurementUnit.id}${payload.variable}`]: {
            ...payload,
            isLoading: true,
            isOnError: !!error,
          },
        },
      };

    case types.SHOW_SUCCEEDED:
      return {
        ...state,
        variables: {
          ...state.variables,
          [`${payload.measurementUnit.id}${payload.variable}`]: {
            ...payload,
            isLoading: false,
            isOnError: !!error,
          },
        },
      };

    case types.SHOW_FAILED:
      return {
        ...state,
        variables: {
          ...state.variables,
          [`${payload.measurementUnit.id}${payload.variable}`]: {
            ...payload,
            isLoading: false,
            isOnError: !!error,
          },
        },
      };

    case types.MEASURES_REQUESTED:
    case types.MEASURES_SUCCEED:
    case types.MEASURES_FAILED:
      return {
        ...state,
        configUser: { ...payload, error: payload.error, isOnError: !!error },
      };

    case types.CLEAN_GRAPHS:
      return {
        ...state,
        variables: {},
      };
    default:
      return state;
  }
};

export default reducer;
