/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/icons';
import { BoxStyles } from './styles';

const Box = ({ title, onClick, ...props }) => {
  return (
    <BoxStyles onClick={onClick}>
      <Icon {...props} />
      <h4>{title}</h4>
    </BoxStyles>
  );
};

Box.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

Box.defaultProps = {
  title: '',
  onClick: () => {},
};

export default Box;
