import styled from 'styled-components';

const StyleDropdown = styled.div<{
  isOpen: boolean;
  position: 'left' | 'right';
}>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  left: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: absolute;
  margin-top: 5px;
  ${({ position, theme }) => theme.dropdown.position[position]};
  z-index: 99;

  :focus {
    outline: transparent;
  }
  ul {
    padding: 10px 0;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15), 0 5px 20px 0 rgba(21, 27, 38, 0.08);
  }
  li {
    display: flex;
    white-space: nowrap;
    padding: 8px 45px 8px 20px;

    :hover {
      background: #ecebed;
      color: ${(props) => props.theme.colors.secondary};
      cursor: pointer;
    }
  }
`;

const ContainerDropdown = styled.div`
  width: auto;
  position: relative;
`;

export { StyleDropdown, ContainerDropdown };
