const DEFAULT_TYPES = {
  FILL_REQUESTED: '@$context$/FILL_$CONTEXT$_REQUESTED',
  FILL_SUCCEEDED: '@$context$/FILL_$CONTEXT$_SUCCEEDED',
  FILL_FAILED: '@$context$/FILL_$CONTEXT$_FAILED',

  CREATE_REQUESTED: '@$context$/CREATE_$CONTEXT$_REQUESTED',
  CREATE_SUCCEEDED: '@$context$/CREATE_$CONTEXT$_SUCCEEDED',
  CREATE_FAILED: '@$context$/CREATE_$CONTEXT$_FAILED',

  UPDATE_REQUESTED: '@$context$/UPDATE_$CONTEXT$_REQUESTED',
  UPDATE_SUCCEEDED: '@$context$/UPDATE_$CONTEXT$_SUCCEEDED',
  UPDATE_FAILED: '@$context$/UPDATE_$CONTEXT$_FAILED',

  DELETE_REQUESTED: '@$context$/DELETE_$CONTEXT$_REQUESTED',
  DELETE_SUCCEEDED: '@$context$/DELETE_$CONTEXT$_SUCCEEDED',
  DELETE_FAILED: '@$context$/DELETE_$CONTEXT$_FAILED',

  LIST_REQUESTED: '@$context$/LIST_$CONTEXT$_REQUESTED',
  LIST_SUCCEEDED: '@$context$/LIST_$CONTEXT$_SUCCEEDED',
  LIST_FAILED: '@$context$/LIST_$CONTEXT$_FAILED',

  LIST_SUCCEEDED_ITEMS: '@$context$/LIST_$CONTEXT$_SUCCEEDED_ITEMS',

  GET_BY_ID_REQUESTED: '@$context$/GET_$CONTEXT$_BY_ID_REQUESTED',
  GET_BY_ID_SUCCEEDED: '@$context$/GET_$CONTEXT$_BY_ID_SUCCEEDED',
  GET_BY_ID_FAILED: '@$context$/GET_$CONTEXT$_BY_ID_FAILED',

  GET_REQUESTED: '@$context$/GET_$CONTEXT$_REQUESTED',
  GET_SUCCEEDED: '@$context$/GET_$CONTEXT$_SUCCEEDED',
  GET_FAILED: '@$context$/GET_$CONTEXT$_FAILED',

  SET_ENTITY_ID: '@$context$/SET_$CONTEXT$_ENTITY',

  SHOW_REQUESTED: '@$context$/SHOW_$CONTEXT$_REQUESTED',
  SHOW_SUCCEEDED: '@$context$/SHOW_$CONTEXT$_SUCCEEDED',
  SHOW_FAILED: '@$context$/SHOW_$CONTEXT$_FAILED',
};

const createTypesCrud = (context, actionContext = null) => {
  const defaultTypesFormatted = JSON.stringify(DEFAULT_TYPES);
  const types = defaultTypesFormatted
    .replace(/\$CONTEXT\$/g, (actionContext || context).toUpperCase())
    .replace(/\$context\$/g, context.toLowerCase());
  return JSON.parse(types);
};

export { createTypesCrud, DEFAULT_TYPES };
