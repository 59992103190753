import React from 'react';
import PropTypes from 'prop-types';

import { IframeStyles } from './styles';

const Iframe = ({ width, minHeight, height, src }) => <IframeStyles {...{ width, minHeight, height, src }} />;

Iframe.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  minHeight: PropTypes.string,
  height: PropTypes.string,
};

Iframe.defaultProps = {
  width: '100%',
};
export default Iframe;
