import { css } from 'styled-components';

export default {
  variant: {
    default: css`
      margin: 10px 19px 10px 0px;
    `,
    colored: css`
      padding: 8px;
      margin: 10px 10px 1px 0px;
      border-radius: 3px;
      background: #f7f7f7;
      box-shadow: 0px 0px 5px rgba(51, 52, 79, 0.07);
      font-size: 13px;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: unset;
      input {
        min-width: 18px;
        + label:before {
          top: 15px;
          left: initial;
          right: 17px;
        }
      }
    `,
  },
};
