import React from 'react';

import { Tag } from '../../tag';
import Text from '../../tipography/text';
import RowFlex from '../../common';

import { CardStyles, Description } from './styles';

const CardDescription = ({ children, type, title, date, colorType }) => (
  <CardStyles>
    <RowFlex align="center">
      <Tag color={colorType}>{type}</Tag>
      <Text size="small" color="ghost">
        {date}
      </Text>
    </RowFlex>
    <h2>{title}</h2>
    <Description>{children}</Description>
  </CardStyles>
);

export default CardDescription;
