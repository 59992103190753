import React from 'react';
import { TagProps } from 'antd';

import { TagStyles } from './styles';

const Tag: React.FC<{ rounded?: boolean } & TagProps> = ({ rounded = false, children, ...props }) => (
  <TagStyles rounded={rounded} {...props}>
    {children}
  </TagStyles>
);

export default Tag;
