import styled from 'styled-components';

const ContainerResult = styled.div`
  overflow-y: scroll;
  width: 100%;
  padding: 20px;
  height: 100%;
`;

const FilterBarFavorite = styled.div`
  position: sticky;
  top: -20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  z-index: 97;
  padding: 5px;
  border-bottom: 1px solid #e6e6ff;
  margin-bottom: 1.5rem;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  > div {
    width: 180px;
  }
  button {
    margin-left: 0.75rem;
    height: 39px;
  }
`;
const Box = styled.div`
  background: #ffffff;
  border-radius: 6px;
  padding: 0 20px 20px;
  height: auto;
  width: 100%;
`;

export { ContainerResult, FilterBarFavorite, Filters, Box };
