import React from 'react';
import PropTypes from 'prop-types';

import { AvatarInfo, MessagesInfo, SearchBox, ChangeLogInfo } from 'components/topbar';
import { TopBarStyles, Container, Options } from './styles';

const TopBar = ({ title, auth }) => {
  return (
    <>
      <TopBarStyles>
        <Container>
          <div>{title}</div>
          <Options>
            <div className="item-topBar">
              <SearchBox />
            </div>
            <div className="item-topBar">
              <ChangeLogInfo />
            </div>
            <div className="item-topBar">
              <MessagesInfo />
            </div>
            <div className="item-topBar">
              <AvatarInfo auth={auth} />
            </div>
          </Options>
        </Container>
      </TopBarStyles>
    </>
  );
};
TopBar.propTypes = {
  title: PropTypes.string,
  auth: PropTypes.shape({
    name: PropTypes.string,
    photoURL: PropTypes.string,
  }),
};

TopBar.defaultProps = {
  title: '',
  auth: { name: '', photoURL: '' },
};
export default TopBar;
