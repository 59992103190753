import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import { validator } from 'libs/toolkits';
import { Input, RowFlex, Text, Checkbox } from 'ui';
import { ColorSection } from '../styles';
import { DEFAULT_VALUES } from '../constants';

const Options = ({ checkEmail, toggleCheckEmail, register, errors, control, watch }) => {
  const { t } = useTranslation();

  const emailChecked = watch('checkEmail');
  return (
    <>
      <ColorSection>
        <RowFlex align="center">
          <Text strong inline>
            {t('components:alerts:colorSection-alertState-text')}
          </Text>
          <Controller
            as={<Checkbox id="active" name="active" textLabel={t('AlertPage:active')} />}
            control={control}
            onChange={([
              {
                target: { checked },
              },
            ]) => {
              return checked;
            }}
            name="active"
          />
        </RowFlex>
      </ColorSection>
      <ColorSection>
        <Text color="primary" strong size="large">
          {t('components:alerts:colorSection-consolidateTime-text')}
        </Text>

        <Text>
          {t('components:alerts:colorSection-createIncidence-text')}{' '}
          <Input
            name="consolidationTime"
            id="consolidationTime"
            defaultValue={DEFAULT_VALUES.consolidationTime}
            width={10}
            noSeparation
            ref={register({
              validate: (value) => {
                return value < 1 ? t('forms:validations:greaterThan', { field: 1 }) : true;
              },
            })}
            type="number"
          />{' '}
          {'  '}
          {t('components:alerts:colorSection-minutes-text')}
        </Text>
      </ColorSection>
      <ColorSection>
        <Text color="primary" strong size="large">
          {t('components:alerts:colorSection-notifications-text')}
        </Text>
        <RowFlex>
          <Checkbox
            name="checkEmail"
            isSelected={checkEmail}
            textLabel={t('AlertPage:SendEmail')}
            id="checkEmail"
            onChange={toggleCheckEmail}
          />
          <Input
            hidden={!checkEmail}
            textLabel={t('components:alerts:email-label')}
            placeholder={t('components:alerts:email-placeholder')}
            dataHelp={t('components:alerts:email-data-help')}
            ref={register({
              validate: (value) => {
                if (!emailChecked) return true;
                const emailInput = value || '';
                const isValidEmail = emailInput.split(';').every((str) => validator.string().email().isValidSync(str));
                const isValidEndEmail = emailInput.length && emailInput[emailInput.length - 1] !== ';';
                return (isValidEmail && isValidEndEmail) || t('forms:validations:emails');
              },
            })}
            errors={errors}
            name="receiver"
            id="receiver"
          />
        </RowFlex>
      </ColorSection>
    </>
  );
};

Options.propTypes = {
  checkEmail: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
  toggleCheckEmail: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
};

export default Options;
