import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { subHours } from 'libs/date';
import { Input, Select, RowFlex, TabPane, Tabs } from 'ui';
import { getMultipleMeasurementUnits } from 'modules/measurementUnits/api';
import { useToggle, useEffectDeepCompare, useFormulaState } from '../hooks';
import { ButtonAnalyze } from './styles';

import SelectsUmLines from './form/SelectsUmLines';
import VariablesSelects from './form/VariablesSelects';
import Options from './form/Options';
import {
  useFillAlertSaved,
  useFormWatchParams,
  useFormulaLogic,
  useHandleUpdateFormAlert,
  useAnalyzeIncidence,
} from './hooks';
import { ALERT_TYPES_OPTIONS } from './constants';

const FormAlert = ({ alertSaved, operationsRef }) => {
  const { t } = useTranslation();
  const [alertTypeView, setAlertTypeView] = useState('simple');
  const { getOperations } = useFormulaState();
  useEffect(() => {
    if (getOperations) getOperations({ isValid: alertTypeView === 'simple' });
  }, [alertTypeView, getOperations]);

  const [measuresSelected, setMeasuresSelected] = useState([]);
  const [optionsLines, setOptionsLines] = useState([]);
  const [checkEmail, toggleCheckEmail, openCheckToogle] = useToggle(false);

  const handleSelect = React.useCallback(
    (selected) => {
      setAlertTypeView(alertTypeView === selected ? 'simple' : selected);
    },
    [setAlertTypeView, alertTypeView]
  );

  const { register, control, errors, setValue, getValues, watch, reset, formState } = useFormContext();

  const { handleAnalize } = useAnalyzeIncidence({
    creationDate: subHours(Date.now(), 1),
    ...(alertSaved
      ? {
          ...alertSaved,
          variables: [alertSaved.variables],
          measurementUnits: (alertSaved.measurementUnits || []).map(({ item }) => item),
          lines: (alertSaved.lines || []).map(({ value }) => ({ id: value })),
        }
      : {}),
  });

  useFillAlertSaved({ alertSaved, reset, openCheckToogle, operationsRef, setAlertTypeView, setOptionsLines });
  const { linesIdsSelecteds, entitySelected, alertType, linesSelecteds, measures, measureLines } = useFormWatchParams({
    getValues,
    watch,
  });

  useEffectDeepCompare(() => {
    if (measures) setMeasuresSelected(measures.map(({ value }) => value));
  }, [measures]);

  useEffectDeepCompare(() => {
    if (measuresSelected) {
      getMultipleMeasurementUnits(measuresSelected.map((id) => ({ id, params: {} }))).then((response) => {
        if (response) {
          setOptionsLines(
            response.reduce(
              (acc, { data }) => [
                ...acc,
                ...data.lines.reduce(
                  (accLines, { name, id, ...rest }) => [...accLines, { value: id, label: name, ...rest }],
                  []
                ),
              ],
              []
            )
          );
        }
      });
    } else {
      setOptionsLines([]);
    }
  }, [measuresSelected]);

  const { operatorsConfig, variablesPermited } = useFormulaLogic({
    measureLines: optionsLines,
    linesIdsSelecteds,
    measures,
    entitySelected,
  });

  useHandleUpdateFormAlert({
    measureLines,
    linesSelecteds,
    setValue,
    reset,
    entitySelected,
    alertType,
    optionsLines,
  });

  const { isValid, touched, isSubmitted } = formState;

  const showVariables =
    entitySelected &&
    ((entitySelected === 'mu' && (measures || []).length) ||
      (entitySelected !== 'mu' && (linesIdsSelecteds || []).length));
  const showSelectUmLines = Boolean(alertType);
  return (
    <>
      {Boolean(alertSaved) && (
        <ButtonAnalyze variant="primary" size="small" onClick={handleAnalize}>
          {t('components:alerts:analyze-text')}
        </ButtonAnalyze>
      )}
      <Tabs defaultActiveKey="config" noPadding>
        <TabPane forceRender tab={t('AlertPage:config')} key="config" style={{ minHeight: '77vh' }}>
          <RowFlex align="center" justify="space-between">
            <Input
              width={48}
              name="alertName"
              placeholder={t('components:alerts:input-alertName-text')}
              ref={register({
                required: t('forms:validations:required', { field: t('forms:fields:name') }),
              })}
              textLabel={t('components:alerts:input-name-label')}
              errors={errors}
            />
            <Controller
              as={
                <Select
                  textLabel={t('components:alerts:select-alertType-label')}
                  placeholder={t('components:alerts:select-alertType-placeholder')}
                  options={ALERT_TYPES_OPTIONS(t)}
                  name="alertType"
                  errors={errors}
                />
              }
              width={48}
              control={control}
              rules={{
                required: t('forms:validations:required', { field: t('forms:fields:type') }),
              }}
              onChange={([selected]) => {
                return selected;
              }}
              name="alertType"
            />
          </RowFlex>
          {showSelectUmLines ? (
            <SelectsUmLines
              errors={errors}
              control={control}
              getValues={getValues}
              entitySelected={entitySelected}
              optionsLines={optionsLines}
              linesSelecteds={linesSelecteds}
              setValue={setValue}
              alertType={alertType}
              operationsRef={operationsRef}
            />
          ) : (
            ''
          )}
          <hr />

          {showVariables ? (
            <VariablesSelects
              alertTypeView={alertTypeView}
              variablesPermited={variablesPermited}
              errors={errors}
              control={control}
              handleSelect={handleSelect}
              operationsRef={operationsRef}
              operatorsConfig={operatorsConfig}
              alertType={alertType}
              register={register}
              getValues={getValues}
              isValid={isValid}
              touched={touched}
              isNew={!Boolean(alertSaved)}
              isSubmitted={isSubmitted}
            />
          ) : (
            ''
          )}
        </TabPane>
        <TabPane
          forceRender
          tab={t('AlertPage:options')}
          key="options"
          style={{ paddingTop: '10px', minHeight: '77vh' }}
        >
          <Options
            checkEmail={checkEmail}
            toggleCheckEmail={toggleCheckEmail}
            register={register}
            errors={errors}
            control={control}
            watch={watch}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

FormAlert.propTypes = {
  alertSaved: PropTypes.shape({
    name: PropTypes.string,
    alertType: PropTypes.string,
    entitySelected: PropTypes.oneOf(['mu', 'line']),
    formula: PropTypes.arrayOf(PropTypes.shape({})),
    measurementUnits: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    lines: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.string,
      })
    ),
    variables: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    gradientPeriod: PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
    gradientValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    thresholdMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    thresholdMin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pcsValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    deltaValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    receiver: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  operationsRef: PropTypes.object.isRequired,
};

FormAlert.defaultProps = {
  alertSaved: null,
};

export default FormAlert;
