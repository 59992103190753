const OPERATOR_0 = {
  value: 0,
  label: '0',
  handler: 'digit',
  extraProps: {},
};

const OPERATOR_1 = {
  value: 1,
  label: '1',
  handler: 'digit',
  extraProps: {},
};

const OPERATOR_2 = {
  value: 2,
  label: '2',
  handler: 'digit',
  extraProps: {},
};

const OPERATOR_3 = {
  value: 3,
  label: '3',
  handler: 'digit',
  extraProps: {},
};

const OPERATOR_4 = {
  value: 4,
  label: '4',
  handler: 'digit',
  extraProps: {},
};

const OPERATOR_5 = {
  value: 5,
  label: '5',
  handler: 'digit',
  extraProps: {},
};

const OPERATOR_6 = {
  value: 6,
  label: '6',
  handler: 'digit',
  extraProps: {},
};

const OPERATOR_7 = {
  value: 7,
  label: '7',
  handler: 'digit',
  extraProps: {},
};

const OPERATOR_8 = {
  value: 8,
  label: '8',
  handler: 'digit',
  extraProps: {},
};

const OPERATOR_9 = {
  value: 9,
  label: '9',
  handler: 'digit',
  extraProps: {},
};

const OPERATOR_DIVIDE = {
  value: '/',
  label: '/',
  handler: 'math',
  extraProps: {},
};

const OPERATOR_MULTIPLY = {
  value: '*',
  label: 'X',
  handler: 'math',
  extraProps: {},
};

const OPERATOR_SUBTRACT = {
  value: '-',
  label: '-',
  handler: 'math',
  extraProps: {},
};

const OPERATOR_SUM = {
  value: '+',
  label: '+',
  handler: 'math',
  extraProps: {},
};

const OPERATOR_POINT = {
  value: '.',
  label: '.',
  handler: 'math',
  extraProps: {},
};

const OPERATOR_REMOVE = {
  value: '',
  label: 'AC',
  handler: 'remove',
  extraProps: {},
};

const OPERATOR_OPEN_PARENTHESIS = {
  value: '(',
  label: '(',
  handler: 'math',
  extraProps: {},
};

const OPERATOR_CLOSE_PARENTHESIS = {
  value: ')',
  label: ')',
  handler: 'math',
  extraProps: {},
};

const DEFAULT_OPERATORS = [
  OPERATOR_1,
  OPERATOR_2,
  OPERATOR_3,
  OPERATOR_DIVIDE,
  OPERATOR_MULTIPLY,
  OPERATOR_4,
  OPERATOR_5,
  OPERATOR_6,
  OPERATOR_SUBTRACT,
  OPERATOR_SUM,
  OPERATOR_7,
  OPERATOR_8,
  OPERATOR_9,
  OPERATOR_OPEN_PARENTHESIS,
  OPERATOR_CLOSE_PARENTHESIS,
  OPERATOR_0,
  OPERATOR_POINT,
  OPERATOR_REMOVE,
];

export {
  DEFAULT_OPERATORS,
  OPERATOR_1,
  OPERATOR_2,
  OPERATOR_3,
  OPERATOR_4,
  OPERATOR_5,
  OPERATOR_6,
  OPERATOR_7,
  OPERATOR_8,
  OPERATOR_9,
  OPERATOR_0,
  OPERATOR_SUBTRACT,
  OPERATOR_SUM,
  OPERATOR_DIVIDE,
  OPERATOR_MULTIPLY,
  OPERATOR_OPEN_PARENTHESIS,
  OPERATOR_CLOSE_PARENTHESIS,
  OPERATOR_POINT,
  OPERATOR_REMOVE,
};
