import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Text, RowFlex, Title, Button, Tooltip, Column, Tag, List } from 'ui';
import { MessageEmpty } from 'ui/table/styles';
import { concatEntities, prop, pipe } from 'libs/toolkits';
import { getDateFormated } from 'libs/date';
import { useCopyToClipboard, useToggle } from 'components/hooks';
import { AlertSummary, SectionTitle, ButtonAnimation } from './styles';
import { useAnalyzeIncidence } from './hooks';
import ImageNoResult from '../../assets/images/table_no-result.svg';
import { COLORS_STATUS } from '../../config/table/alerts/common';

const Incidence = ({ incidence }) => {
  const { t } = useTranslation();
  const [showHistory, toogleShowHistory, , closeShowHistory] = useToggle(false);

  useEffect(() => closeShowHistory, [closeShowHistory]);

  const {
    id,
    alert,
    history,
    alertType,
    closeDate,
    creationDate,
    formula,
    variables,
    measurementUnits,
    equipments,
    lines,
    triggerValue,
    status,
    creator,
    magnitude,
  } = incidence || {};
  const { handleAnalize } = useAnalyzeIncidence({
    formula,
    measurementUnits,
    lines,
    variables,
    creationDate,
    alertType,
    equipmentId: alert?.equipmentId,
  });

  const copyToClipboard = useCopyToClipboard(`${window.location.host}/alerts/incidences/${id}`);
  const checksumTypes = ['checksum_flowcomputer', 'checksum_chroma'];
  if (!incidence) {
    return '';
  }

  return (
    <RowFlex direction="column">
      <AlertSummary>
        <Column flex={1} noPadding>
          <Text type="secondary">
            {t('AlertPage:type')}: <Text strong>{t(`AlertPage:types:${alertType}`)}</Text>
          </Text>
        </Column>

        <Column flex={1} noPadding>
          <Text type="secondary">
            {`${t('AlertPage:table:saved:um/lines')}`}:{' '}
            <Text strong>
              {`${lines.length ? concatEntities(lines, prop('name')) : concatEntities(measurementUnits, prop('name'))}`}
            </Text>
          </Text>
        </Column>

        {equipments.length ? (
          <Column flex={1} noPadding>
            <Text type="secondary">
              {`${t('MenuItems:Equipments')}`}:<Text strong>{concatEntities(equipments, prop('name'))}</Text>
            </Text>
          </Column>
        ) : (
          ''
        )}

        {!checksumTypes.includes(alert.alertType) && (
          <Column noPadding flex={3}>
            <Text type="secondary">
              {`${t('AlertPage:table:saved:variables')}`}:{' '}
              <Text strong>{`${concatEntities(variables, pipe(prop('value'), t))}`}</Text>
            </Text>
          </Column>
        )}

        {alert.alertType === 'threshold' ? (
          <RowFlex>
            <Column noPadding flex={1}>
              <Text type="secondary">
                {`${t('AlertPage:MaxValue')}`}:<Text strong> {`${alert.thresholdMax}`}</Text>
              </Text>
            </Column>
            <Column noPadding flex={1}>
              <Text type="secondary">
                {`${t('AlertPage:MinValue')}`}:<Text strong> {`${alert.thresholdMin}`}</Text>
              </Text>
            </Column>
          </RowFlex>
        ) : null}
        {alert.alertType === 'gradient' ? (
          <Text type="secondary">
            {`${t('AlertPage:value')}`}:<Text strong> {`${alert.gradientValue}`}</Text>
          </Text>
        ) : null}
        {alert.alertType === 'delta_pcs' ? (
          <Text type="secondary">
            {`${t('AlertPage:value')}`}:<Text strong> {`${alert.pcsValue}`}</Text>
          </Text>
        ) : null}
      </AlertSummary>

      <SectionTitle>
        <Title level={4}>
          {showHistory ? t('components:alerts:section-history-title') : t('components:alerts:section-incidence-title')}
        </Title>
        <RowFlex align="center">
          <Tooltip placement="bottomRight" title={t('components:alerts:section-tooltip-copy-title')}>
            <Button customIcon="icon-copy" variant="link" color="default" onClick={copyToClipboard} />
          </Tooltip>
          <Tooltip
            placement="bottomRight"
            title={
              showHistory
                ? t('components:alerts:section-tooltip-goBack-title')
                : t('components:alerts:section-tooltip-history-title')
            }
          >
            <Button
              customIcon={showHistory ? 'icon-left' : 'icon-history'}
              variant={showHistory ? 'plain' : 'link'}
              color="default"
              onClick={toogleShowHistory}
            />
          </Tooltip>
        </RowFlex>
      </SectionTitle>

      {showHistory ? (
        <Column noPadding>
          <Text>{t('components:alerts:history-incidence-list-text')}</Text>
          <br />
          {history.length ? (
            <List
              header={<div>{t('Headers:CommonCreationDate')}</div>}
              size="large"
              bordered
              loading={false}
              dataSource={history}
              renderItem={(item) => (
                <List.Item>
                  <Text>{getDateFormated(item.creationDate, 'dd-MM-yyyy HH:mm')}</Text>
                  <span align="right">
                    <Tag rounded color={COLORS_STATUS[item.status]}>
                      {t(`AlertPage:status:${item.status}`)}
                    </Tag>
                  </span>
                </List.Item>
              )}
            />
          ) : (
            <MessageEmpty>
              <div>
                <img src={ImageNoResult} alt="" />
                <h3>{t('components:alerts:empty-history-text')}</h3>
              </div>
            </MessageEmpty>
          )}
        </Column>
      ) : (
        <>
          <RowFlex>
            <Column noPadding flex={1}>
              <p>
                <Text type="secondary">
                  {t('components:alerts:history-dateAndHour-text')}
                  <Text strong>{`${getDateFormated(creationDate, 'dd-MM-yyyy HH:mm')}`}</Text>
                </Text>
              </p>
              <p>
                <Text type="secondary">
                  {`${t('AlertPage:triggerValue')}`}:
                  <Text strong>
                    {!checksumTypes.includes(alert.alertType)
                      ? `${triggerValue} ${magnitude}`
                      : t('AlertPage:checksumChanged')}
                  </Text>
                </Text>
              </p>
              <p>
                <Text type="secondary">
                  {t('components:alerts:history-responsable-text')} <Text strong> {`${creator}`}</Text>
                </Text>
              </p>
            </Column>
            <Column noPadding flex={1}>
              <p>
                <Text type="secondary">
                  {t('components:alerts:history-closeAt-text')}{' '}
                  <Text strong> {`${closeDate ? getDateFormated(closeDate, 'dd-MM-yyyy HH:mm') : '-'}`}</Text>
                </Text>
              </p>
              <p>
                <Text type="secondary">
                  {t('components:alerts:history-state-text')}
                  <Text strong>
                    <Tag rounded color={COLORS_STATUS[status]}>
                      {t(`AlertPage:status:${status}`)}{' '}
                    </Tag>
                  </Text>
                </Text>
              </p>
            </Column>
          </RowFlex>
          {!checksumTypes.includes(alert.alertType) && (
            <ButtonAnimation variant="primary" size="large" onClick={handleAnalize}>
              {t('components:alerts:history-analyzeIncidence-text')}
            </ButtonAnimation>
          )}
        </>
      )}
    </RowFlex>
  );
};

const PropTypeIndicence = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  alertType: PropTypes.oneOf(['threshold', 'gradient', 'delta_pcs']).isRequired,
  creationDate: PropTypes.instanceOf(Date).isRequired,
  closeDate: PropTypes.instanceOf(Date),
  formula: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
      }),
      key: PropTypes.number,
      type: PropTypes.string,
      value: PropTypes.string,
      entityId: PropTypes.number,
    })
  ),
  magnitude: PropTypes.string,
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  measurementUnits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  triggerValue: PropTypes.number.isRequired,
  status: PropTypes.oneOf(['closed', 'open']).isRequired,
  creator: PropTypes.string.isRequired,
  alertId: PropTypes.number.isRequired,
};

Incidence.propTypes = {
  incidence: PropTypes.shape({
    ...PropTypeIndicence,
    history: PropTypes.arrayOf(PropTypes.shape(PropTypeIndicence)),
  }),
};

Incidence.defaultProps = {
  incidence: null,
};

export default Incidence;
