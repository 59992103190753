import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { omit } from 'libs/toolkits';
import { useSearchCompanies } from './useCompanies';
import { useSearchMeasures, useSearchMeasuresList } from './useMeasures';
import { useSearchSections } from './useSections';
import { useSearchBranches } from './useBranches';
import { useSearchChromatographs } from './useChromatographs';
import { useSearchCustomVariables } from './useCustomVariables';
import SelectorAsync, { defaultProps, propTypes } from './SelectorAsync';

const searchEntities = {
  measuresSearch: useSearchMeasures,
  measures: useSearchMeasuresList,
  companies: useSearchCompanies,
  sections: useSearchSections,
  branches: useSearchBranches,
  chromatographs: useSearchChromatographs,
  customVariables: useSearchCustomVariables,
};

const withSearchEntities = ({ type }) => {
  const WithSearchEntitiesWrapped = (props) => {
    const useSearchEntities = useCallback((params) => {
      return searchEntities[type](params);
    }, []);
    const onChange = useCallback(() => {}, []);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <SelectorAsync {...{ onChange, ...props }} useSearchEntities={useSearchEntities} />;
  };
  WithSearchEntitiesWrapped.displayName = `withSearchEntities(SelectorAsync)`;
  WithSearchEntitiesWrapped.propTypes = omit(['useSearchEntities'], propTypes);
  WithSearchEntitiesWrapped.defaultProps = defaultProps;

  return WithSearchEntitiesWrapped;
};

withSearchEntities.propTypes = {
  type: PropTypes.oneOf(['measures', 'companies', 'sections', 'chromatographs', 'branches']).isRequired,
};

export default withSearchEntities;
