import { css } from 'styled-components';

export default {
  position: {
    left: css`
      left: ${({ isOpen }) => (isOpen ? '0' : '-700px')};
    `,
    right: css`
      right: ${({ isOpen }) => (isOpen ? '0' : '-700px')};
    `,
  },
};
