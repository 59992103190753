import React from 'react';

import { Tag } from 'ui';
import { getDateFormated } from 'libs/date';
import { COLORS_STATUS } from './common';

const createColumnsConfig = (t) => [
  {
    Header: t('Headers:responsable'),
    accessor: 'creator',
    width: '25%',
    maxWidth: 150,
  },

  {
    Header: t('Headers:CommonCreationDate'),
    accessor: 'creationDate',
    width: '60%',
    maxWidth: 200,
    Cell: ({
      row: {
        original: { creationDate },
      },
    }) => getDateFormated(creationDate, 'dd-MM-yyyy HH:mm'),
  },
  {
    Header: t('AlertPage:table:saved:status'),
    accessor: 'status',
    width: '20%',
    maxWidth: 200,
    Cell: ({
      row: {
        original: { status },
      },
    }) => (
      <Tag rounded color={COLORS_STATUS[status]}>
        {t(`AlertPage:status:${status}`)}{' '}
      </Tag>
    ),
  },
];
export { createColumnsConfig };
