import { createTypesCrud } from 'libs/generate-redux-utils/generate-types';

const actionTypes = {
  ...createTypesCrud('equipments'),
  SET_MODAL_INFO: '@equipments/SET_MODAL_INFO',
  SET_CHROMA_FOOTPRINT: '@equipments/SET_CHROMA_FOOTPRINT',
  GET_DATA_FAILED: '@equipments/GET_DATA_FAILED',
  GET_DATA_SUCCEEDED: '@equipments/GET_DATA_SUCCEEDED',
  GET_DATA_REQUESTED: '@equipments/GET_DATA_REQUESTED',
  SET_DATA_ANALYSIS: '@equipments/SET_DATA_ANALYSIS',
  GET_FOOTPRINT_SUCCEEDED: '@equipments/GET_FOOTPRINT_SUCCEEDED',
  GET_FOOTPRINT_REQUESTED: '@equipments/GET_FOOTPRINT_REQUESTED',
  SET_FOOTPRINT_ANALYSIS: '@equipments/SET_FOOTPRINT_ANALYSIS',
  GET_EVOLUTION_REQUESTED: '@equipments/GET_EVOLUTION_REQUESTED',
  GET_EVOLUTION_SUCCEEDED: '@equipments/GET_EVOLUTION_SUCCEEDED',
  GET_EVOLUTION_FAILED: '@equipments/GET_EVOLUTION_FAILED',
  GET_ANALYSIS_REQUESTED: '@equipments/GET_ANALYSIS_REQUESTED',
  GET_ANALYSIS_SUCCEEDED: '@equipments/GET_ANALYSIS_SUCCEEDED',
  GET_ANALYSIS_FAILED: '@equipments/GET_ANALYSIS_FAILED',
};

export default actionTypes;
