import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { subDays } from 'libs/date';

import { operations as operationsUser, selectors as selectorsUser } from 'modules/preferences';
import { operations as operationsDashboard, selectors as selectorsDashboard } from 'modules/dashboard';
import { RowFlex, Title, Select, Button, Text } from 'ui';
import ResultGraphs from 'components/dashboard/ResultGraphs';
import { ContainerResult, FilterBarFavorite, Filters, Box } from './styles';

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [rangeDates, setRangeDates] = useState('hours');
  const [startDate, setStartDate] = useState(subDays(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [order, setOrder] = useState('');

  const { configGraphsUserFav, configGraphsUserFavLoading, variables } = useSelector(
    (state) => ({
      configGraphsUserFav: selectorsUser.getConfigUser(state).data,
      configGraphsUserFavLoading: selectorsUser.getConfigUserLoading(state).data,
      variables: selectorsDashboard.getResultStatus(state),
    }),
    shallowEqual
  );

  const getConfigGraphFavorite = useCallback(() => {
    dispatch(operationsUser.getConfigUserRequest());
  }, [dispatch]);

  const getDataGraphs = useCallback(
    (params) => {
      dispatch(operationsDashboard.getVariableData(params));
    },
    [dispatch]
  );

  const orderData = (data, type) => {
    if (type === 'mu')
      return data.sort((a, b) =>
        a.measurementUnit && b.measurementUnit ? a.measurementUnit.id - b.measurementUnit.id : 1
      );
    return data.sort((a, b) => (a.variable && b.variable ? a.variable - b.variable : 1));
  };

  useEffect(() => {
    if (rangeDates === 'days') {
      setStartDate(subDays(new Date(), 7));
      setEndDate(new Date());
    } else {
      setStartDate(subDays(new Date(), 1));
      setEndDate(new Date());
    }
  }, [rangeDates]);

  useEffect(() => {
    if (!configGraphsUserFav && !configGraphsUserFavLoading) {
      getConfigGraphFavorite();
    } else {
      configGraphsUserFav.forEach((graph) => {
        getDataGraphs({
          measurementUnit: { id: graph.id_mu, name: graph.name_mu },
          startDate,
          endDate,
          variable: graph.variable,
          selectedWindow: rangeDates === 'hours' ? '' : 'd',
        });
      });
    }
  }, [
    configGraphsUserFav,
    getConfigGraphFavorite,
    configGraphsUserFavLoading,
    getDataGraphs,
    startDate,
    endDate,
    rangeDates,
  ]);

  const data = Object.entries(variables).map(([, data]) => data);

  return (
    <ContainerResult>
      <Box>
        <Title level={2} style={{ position: 'fixed', top: '18px', fontSize: '22px' }}>
          {t('entitiesNames:Dashboard')}
        </Title>

        <RowFlex direction="column">
          <FilterBarFavorite>
            <Text color="primary" size="large" strong>
              {t('DashboardPage:Favorite')}
            </Text>
            <Filters>
              <Select
                width={10}
                options={[
                  { label: t('entitiesNames:MeasurementUnit'), value: 'mu' },
                  { label: t('entitiesNames:Variable'), value: 'variable' },
                ]}
                placeholder={t('DashboardPage:Order')}
                onChange={({ value }) => {
                  setOrder(value);
                }}
                defaultValue=""
              />
              <Button
                {...{ ...(rangeDates === 'days' ? { variant: 'primary' } : { color: 'ghost' }) }}
                onClick={() => {
                  setRangeDates('days');
                }}
              >
                {t('commonTexts:LastDays', { days: '7' })}
              </Button>
              <Button
                {...{ ...(rangeDates === 'hours' ? { variant: 'primary' } : { color: 'ghost' }) }}
                onClick={() => {
                  setRangeDates('hours');
                }}
              >
                {t('commonTexts:LastHours', { hours: '24' })}
              </Button>
            </Filters>
          </FilterBarFavorite>

          <ResultGraphs
            variables={order ? orderData(data, order) : data}
            dates={{ startDate, endDate, selectedWindow: rangeDates === 'hours' ? '' : 'd' }}
          />
        </RowFlex>
      </Box>
    </ContainerResult>
  );
};

export default Home;
