import localeES from 'antd/lib/locale-provider/es_ES';
import localeEN from 'antd/lib/locale-provider/en_GB';
import { subDays, getDateFormated } from 'libs/date';

const getGasDay = (date) => new Date(getDateFormated(date, 'yyyy-MM-dd 06:00:00'));

const CONFIG_BUTTONS = {
  Last30days: () => [getGasDay(subDays(new Date(), 30)), getGasDay(new Date())],
  LastWeek: () => [getGasDay(subDays(new Date(), 7)), getGasDay(new Date())],
};

const CONFIG_LANGUAGE = {
  ES: localeES,
  EN: localeEN,
};

export { CONFIG_BUTTONS, CONFIG_LANGUAGE };
