import i18n from 'config/i18n';
import { pipe } from '../toolkits';
import { sum, div, mul, sub } from './operations';

const mapOperators = {
  '+': sum,
  '-': sub,
  '*': mul,
  '/': div,
};

const mapValuesOperators = {
  '^': 4,
  '*': 3,
  '/': 3,
  '+': 2,
  '-': 2,
};

const splitFormula = (formula) => {
  return formula
    .replace(/\//g, ',/,')
    .replace(/\*/g, ',*,')
    .replace(/\+/g, ',+,')
    .replace(/-/g, ',-,')
    .replace(/\)/g, ',),')
    .replace(/\(/g, ',(,')
    .split(',')
    .filter((i) => i.length);
};

const toReversePolishNotation = (splittedFormula) => {
  const { outPut: resultOutPut, operatorStack: resultOperatorStack } = splittedFormula.reduce(
    ({ outPut, operatorStack }, operator) => {
      if (/\d+/.test(operator)) {
        return { operatorStack, outPut: [...outPut, operator] };
      }
      if (!operatorStack.length) {
        return { outPut, operatorStack: [operator] };
      }
      const lastOperator = operatorStack[0];
      const firstParenthesis = operatorStack.indexOf('(');
      if (/\(/.test(operator)) {
        return { outPut, operatorStack: [operator, ...operatorStack] };
      }
      if (/\)/.test(operator)) {
        if (firstParenthesis === -1) {
          throw new Error(i18n.t('libs:math-invalid-text'));
        }
        return {
          outPut: [...outPut, ...operatorStack.slice(0, firstParenthesis)],
          operatorStack: operatorStack.slice(firstParenthesis + 1),
        };
      }
      if (mapValuesOperators[lastOperator] > mapValuesOperators[operator]) {
        return {
          outPut: [...outPut, lastOperator],
          operatorStack: [operator, ...operatorStack.slice(1)],
        };
      }
      return {
        outPut,
        operatorStack: [operator, ...operatorStack],
      };
    },
    { outPut: [], operatorStack: [] }
  );
  if (resultOperatorStack.includes(/\(|\)/)) {
    throw new Error(i18n.t('libs:math-parentesis-error-text'));
  }

  return [...resultOutPut, ...resultOperatorStack];
};

const evaluateRpn = (rpnFormula) => {
  const { formula: result } = rpnFormula.reduce(
    ({ formula, index }) => {
      const actual = formula[index];
      const next = formula[index + 1];
      const next2 = formula[index + 2];
      const func = mapOperators[next2];

      if (/\d+/.test(next2) || !func) {
        return { formula, index: index + 1 };
      }

      return {
        formula: [...formula.slice(0, index), func(actual, next), ...formula.slice(3 + index)],
        index: index - 2 < 0 ? 0 : index - 2,
      };
    },
    { index: 0, formula: rpnFormula }
  );

  return result.length === 1 && !Number.isNaN(result[0]) && result[0] !== Infinity;
};

const evaluate = (formula) => {
  try {
    return pipe(splitFormula, toReversePolishNotation, evaluateRpn)(formula);
  } catch (error) {
    return false;
  }
};

export default evaluate;
