import React from 'react';
import PropTypes from 'prop-types';

import Text from 'ui/tipography/text';
import Icon from 'ui/icons';
import { getDateFormated, getDateTimeFormated } from 'libs/date';
import { DatesStyles } from './styles';

const TwoLines = ({ date = new Date() }) => {
  const dateYear = getDateFormated(date);
  const hours = getDateTimeFormated(date);

  return (
    <DatesStyles>
      <Text size="small">
        <Icon iconName="icon-calendar" />
        {dateYear}
      </Text>
      <Text size="small">
        <Icon iconName="icon-clock" />
        {hours}
      </Text>
    </DatesStyles>
  );
};

TwoLines.propTypes = {
  date: PropTypes.instanceOf(Date),
};

TwoLines.defaultProps = {
  date: new Date(),
};

export default TwoLines;
