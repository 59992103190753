import { transformFillResponseToSelectOptions } from './utils';

const useFillTypeMu = () => {
  const items = [
    { id: 'EM', name: 'EM' },
    { id: 'ERM', name: 'ERM' },
  ];
  return new Promise((resolve) => {
    resolve(transformFillResponseToSelectOptions(items));
  });
};
export { useFillTypeMu };
