import { notification as antdNotification } from 'antd';
import actions from './actions';
import selectors from './selectors';

const closeNotification = (key) => {
  antdNotification.close(key);
};

const generateOperation = (action) => (params) => (dispatch, getState) => {
  const notificationsStore = selectors.getNotifications(getState());
  const { key, closeKeys } = params;
  if (closeKeys && closeKeys.length) {
    closeKeys.forEach((keyNotif) => closeNotification(keyNotif));
  }
  if (notificationsStore[key]) {
    closeNotification(key);
    const config = {
      ...notificationsStore[key],
      ...(params.title ? { message: params.title } : {}),
      ...(params.message ? { description: params.message } : {}),
    };

    setTimeout(() => {
      antdNotification.open(config);
    }, 50);
    return key;
  }
  return dispatch(action(params));
};

const cleanAllNotifications = () => {
  return (dispatch, getState) => {
    const notificationsStore = selectors.getNotifications(getState());
    Object.keys(notificationsStore).forEach((key) => closeNotification(key));
  };
};

const successNotification = generateOperation(actions.successNotification);
const errorNotification = generateOperation(actions.errorNotification);
const warningNotification = generateOperation(actions.warningNotification);
const infoNotification = generateOperation(actions.infoNotification);

const successMessage = generateOperation(actions.successMessage);
const errorMessage = generateOperation(actions.errorMessage);
const warningMessage = generateOperation(actions.warningMessage);
const infoMessage = generateOperation(actions.infoMessage);

export default {
  successNotification,
  errorNotification,
  warningNotification,
  infoNotification,
  successMessage,
  errorMessage,
  warningMessage,
  infoMessage,
  closeNotification,
  cleanAllNotifications,
};
