import styled, { css } from 'styled-components';
import { Drawer } from 'antd';

const DrawerStyles = styled(Drawer)`
  .ant-drawer-title {
    width: 98%;

    h4 {
      margin: 0;
    }
  }

  .ant-drawer-header {
    min-height: 53px;
    padding: 10px 24px;
    display: flex;
    align-items: center;

    h3 {
      margin-bottom: 0px;
      display: flex;
      align-items: center;
    }
  }

  .ant-drawer-close {
    height: 53px;
    padding: 0px 6px;
  }

  .ant-drawer-body {
    ${({ noPadding }) =>
      noPadding &&
      css`
        padding: 0px;
      `};
    h4 {
      font-size: 16px;
    }
  }

  .ant-drawer-footer {
    button {
      margin-left: 10px;
    }
  }
`;
export { DrawerStyles };
