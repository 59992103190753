import React from 'react';
import PropTypes from 'prop-types';

import Text from '../tipography/text';

import { SelectVar } from './styles';

const SelectVariables = ({ variables, onClick, inputAccess }) => {
  if (!variables.length) {
    return '';
  }
  return (
    <SelectVar data-focus-formula={inputAccess}>
      {variables.map(({ label, value, type }) => (
        <li data-focus-formula={inputAccess} key={value} onClick={(event) => onClick({ value, type })}>
          <Text style={{ marginBottom: '0px' }} data-focus-formula={inputAccess}>
            {label}
          </Text>
        </li>
      ))}
    </SelectVar>
  );
};

SelectVariables.propTypes = {
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  inputAccess: PropTypes.string.isRequired,
};

export default SelectVariables;
