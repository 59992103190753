import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Tag } from 'ui';
import { AsideSidebar } from '../styles';

const COLOR_LINE = '#D699B4';
const COLOR_MEASUREUNIT = '#7171a6';

const VariablesInfo = ({ variables }) => {
  const { t } = useTranslation();
  const isLineVariable = (type) => type === 'line';

  return (
    <AsideSidebar>
      {variables.map(({ label, type, value }) => {
        return (
          <Tag key={value} color={isLineVariable(type) ? COLOR_LINE : COLOR_MEASUREUNIT}>
            {t(label)}
          </Tag>
        );
      })}
    </AsideSidebar>
  );
};

VariablesInfo.propTypes = {
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default VariablesInfo;
