import { css } from 'styled-components';

const fontSize = (size: string) => css`
  font-size: ${`${size}px`};
`;

const isPlain = ({ plain }: { plain: boolean }) => css`
  box-shadow: ${plain ? null : '0 2px 9px 0 rgba(0, 0, 0, 0.15)'};
  background: ${plain ? 'transparent' : '#17C6D5'};
  color: ${plain ? '#fff' : '#17C6D5'};
`;

const direction = ({ reverse }: { reverse: boolean }) => css`
  flex-direction: ${reverse ? 'row-reverse' : 'row'};
`;

const justifyContent = ({ justify }: { justify: string }) => css`
  justify-content: ${justify};
`;

const alignItems = ({ align }: { align: string }) => css`
  align-items: ${align};
`;

const flexDirection = ({ direction: d }: { direction: string }) => css`
  flex-direction: ${d};
`;

const flexGrow = ({ flex }: { flex: string }) => css`
  flex: ${flex};
`;

const height = ({ fullHeight }: { fullHeight: boolean }) => css`
  height: ${fullHeight ? '100%' : 'auto'};
`;

const width = ({ width: w }: { width: string }) => css`
  width: ${w};
`;

export { fontSize, isPlain, direction, justifyContent, alignItems, flexDirection, flexGrow, height, width };
