import styled from 'styled-components';

const List = styled.div`
  width: 100%;
  background: white;

  span {
    margin-top: -4px;
    margin-bottom: -6px;
  }

  p {
    cursor: pointer;
    margin: 0;
    display: flex;
    align-items: end;
    padding: 6px 16px;

    :hover {
      background: #f5f5f5;
    }
  }
`;

export { List };
