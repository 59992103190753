import { generateEntityQueries } from 'libs/request-utils';
import { transformFillResponseToSelectOptions } from './utils';

const { fillEntities } = generateEntityQueries({
  nameForLists: 'LineMeasuringConfig',
});

const useFillCounterModels = ({ type, brand }) => {
  return fillEntities({
    params: { brand },
  })
    .then(({ data }) => {
      return Object.entries(data).reduce((acc, [typeKey, dataByType]) => {
        return typeKey !== type ? acc : [...acc, ...transformFillResponseToSelectOptions(dataByType)];
      }, []);
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};
export { useFillCounterModels };
