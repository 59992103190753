const TRANSLATION_ULTRASONIC = (t) => ({
  efficiency: t('ReportUltrasonicPage:Efficiency'),
  Footprint: t('ReportUltrasonicPage:Footprint'),
  vel_prof: t('ReportUltrasonicPage:VelocityProfile'),
  gain: t('ReportUltrasonicPage:Gain'),
  snr: t('ReportUltrasonicPage:SNR'),
});
const CONTROL_TYPES = (t) => [
  {
    label: t('ReportControlPage:saturation'),
    value: 'saturation',
  },
  {
    label: t('ReportControlPage:minimum'),
    value: 'minimum',
  },
];
const EFFORT_REQUEST_ADVANCED = 30000;
const EFFORT_GRAPH_LIMIT = 9000;

const TYPES_VARIABLES_ULTRASONIC_REPORT = ['efficiency', 'Footprint', 'vel_prof', 'gain', 'snr'];
export {
  CONTROL_TYPES,
  TRANSLATION_ULTRASONIC,
  TYPES_VARIABLES_ULTRASONIC_REPORT,
  EFFORT_REQUEST_ADVANCED,
  EFFORT_GRAPH_LIMIT,
};
