import { generateEntityQueries } from 'libs/request-utils';
import { transformFillResponseToSelectOptions } from './utils';

const { fillEntities } = generateEntityQueries({
  nameForLists: 'organization',
});

const useFillOrganizations = ({ type: companyId }) => {
  return fillEntities({
    params: { company: companyId },
  })
    .then(({ data: { organizations } = { organizations: [] } }) => {
      return transformFillResponseToSelectOptions(organizations);
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

export { useFillOrganizations };
