import actionTypes from './types';

const listMessagesRequested = (params) => {
  return {
    type: actionTypes.LIST_MESSAGES_REQUESTED,
    payload: { params, loading: true },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};
const listMessagesSucceeded = (reports, incidences) => {
  return {
    type: actionTypes.LIST_MESSAGES_SUCCEEDED,
    payload: {
      reports,
      incidences,

      loading: false,
    },
    error: false,
    meta: {
      timestamp: new Date(),
    },
  };
};
const listMessagesFailed = ({ query, error }) => {
  return {
    type: actionTypes.LIST_MESSAGES_FAILED,
    payload: { query, error },
    error: true,
    meta: {
      timestamp: new Date(),
      error,
    },
  };
};

export { listMessagesRequested, listMessagesSucceeded, listMessagesFailed };
