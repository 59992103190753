const STATUS = {
  READY: 'ready',
  LOADING: 'loading',
  ERROR: 'error',
};

const isLoading = (status) => status === STATUS.LOADING;
const isOnError = (status) => status === STATUS.ERROR;
const isReady = (status) => status === STATUS.READY;

export { STATUS, isLoading, isOnError, isReady };
