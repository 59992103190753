import { generateEntityQueries } from 'libs/request-utils';
import { transformFillResponseToSelectOptions } from './utils';

const { fillEntities } = generateEntityQueries({
  nameForLists: 'flowComputer',
});

const useFillFlowComputers = ({ serialNumbers }) => {
  return fillEntities({
    params: {},
  })
    .then(
      ({
        data: { flow_computers: flowComputers } = {
          flowComputers: [],
        },
      }) => {
        return transformFillResponseToSelectOptions(flowComputers.filter((flow) => !serialNumbers.includes(flow.id)));
      }
    )
    .catch((error) => {
      console.error(error);
      return [];
    });
};
export { useFillFlowComputers };
