import styled, { css } from 'styled-components';

const WidthContainer = (width?: number) => css`
  width: ${`${width}%`};
`;

const CheckboxStyles = styled.div<{
  width?: number;
  variant: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  ${({ width }) => WidthContainer(width)};

  input {
    width: 18px;
    height: 18px;
    margin: 0;
    margin-right: 5px;
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    display: inline-block;
    padding: 0;
    border: 2px solid #ccc;
    border-radius: 2px;
    cursor: pointer;

    :focus {
      outline: 0;
    }

    + label:before {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      top: 6px;
      left: 5px;
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    :disabled,
    :disabled + label {
      cursor: no-drop;
      opacity: 0.6;
    }

    :checked + label:before {
      opacity: 1;
      transition: all 0.2s ease-in-out;
      width: 9px;
      height: 4px;
    }

    :checked {
      border: 2px solid ${(props) => props.theme.colors.primary};
      background: ${(props) => props.theme.colors.primary};
      transition: all 0.2s ease-in-out;
    }

    :checked + label {
      color: #000;
    }
  }

  label {
    cursor: pointer;
    font-size: 0.9em;
    margin: 0;
  }
  ${({ variant, theme }) => theme.checkbox.variant[variant as keyof typeof theme.checkbox.variant] || ''};
`;

export { CheckboxStyles };
