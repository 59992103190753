import { getStatusCode } from 'libs/request-utils';
import { MESSAGES_NOTIFICATIONS } from 'modules/notifications/constants';
import { operations as notificationOperations } from 'modules/notifications';
import actions from './actions';
import { listAlerts, deleteAlert, createAlert, getAlert, listHistoryAlerts, getHistoryAlert } from './api';
import {
  transformSavedAlert,
  formatSaveAlert,
  transformAlert,
  transformAlertFromViewToRedux,
  transformAlertHistory,
} from './utils';
import { getAlertsSavedListParams } from './selectors';

const listAlertTypesRequest = (requestFunction, transformFunction, actionTypes, paramsExtractor = () => ({})) => (
  query
) => {
  return (dispatch, getState) => {
    const state = getState();
    const params = { ...query, ...paramsExtractor(state, query) };
    dispatch(actionTypes.listRequested(params));
    requestFunction({ params })
      .then(({ data }) => {
        const { items = [], total = 0 } = data;
        dispatch(
          actionTypes.listSucceeded({
            entities: items.map(transformFunction),
            total,
          })
        );
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:List', 'entitiesNames:Alert')[statusCode],
          })
        );
        dispatch(actionTypes.listFailed({ query: params, error }));
      });
  };
};

const listSavedAlertsRequest = listAlertTypesRequest(listAlerts, transformSavedAlert, actions.ACTIONS_ALERTS_SAVED);

const listHistoryAlertsRequest = listAlertTypesRequest(
  listHistoryAlerts,
  transformAlertHistory,
  actions.ACTIONS_ALERTS_GENERATED
);

const deleteAlertRequest = (ids, query) => {
  return (dispatch, getState) => {
    dispatch(actions.ACTIONS_ALERTS_SAVED.deleteRequested(ids));
    Promise.all(ids.map((id) => deleteAlert({ id })))
      .then(([data]) => {
        const { status } = data;
        dispatch(actions.ACTIONS_ALERTS_SAVED.deleteSucceeded(ids));
        listSavedAlertsRequest(query)(dispatch, getState);
        dispatch(
          notificationOperations.successNotification({
            title: '',
            message: MESSAGES_NOTIFICATIONS('messages:participles:removedF', 'entitiesNames:Alert')[status],
          })
        );
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.ACTIONS_ALERTS_SAVED.deleteFailed({ id: ids, error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:DeleteEntity', 'entitiesNames:Alert')[statusCode],
          })
        );
      });
  };
};

const getAlertRequest = ({ id }) => {
  return (dispatch) => {
    dispatch(actions.ACTIONS_ALERTS_SAVED.getRequested(id));
    getAlert({ id })
      .then(({ data }) => {
        dispatch(actions.ACTIONS_ALERTS_SAVED.getSucceeded(transformAlert(data)));
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.ACTIONS_ALERTS_SAVED.getFailed({ id, error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:ViewEntity', 'entitiesNames:Alert')[statusCode],
          })
        );
      });
  };
};

const getHistoryAlertRequest = ({ id }) => {
  return (dispatch) => {
    getHistoryAlert({ id })
      .then(({ data }) => {
        dispatch(actions.ACTIONS_ALERTS_GENERATED.getSucceeded(transformAlertHistory(data)));
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.ACTIONS_ALERTS_GENERATED.getFailed({ id, error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:ViewEntity', 'entitiesNames:Incidence')[statusCode],
          })
        );
      });
  };
};

const createAlertRequest = (params) => {
  return (dispatch, getState) => {
    dispatch(actions.ACTIONS_ALERTS_SAVED.createRequested(params));
    const state = getState();
    const oldParams = getAlertsSavedListParams(state);
    createAlert(formatSaveAlert(params))
      .then(({ data, status }) => {
        dispatch(actions.ACTIONS_ALERTS_SAVED.createSucceeded({ entity: transformAlertFromViewToRedux(params, data) }));
        listSavedAlertsRequest(oldParams)(dispatch, getState);
        params.setLoadingButton(false);
        dispatch(
          notificationOperations.successNotification({
            title: '',
            message: MESSAGES_NOTIFICATIONS('messages:participles:createdF', 'entitiesNames:Alert')[status],
          })
        );
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        params.setLoadingButton(false);
        dispatch(actions.ACTIONS_ALERTS_SAVED.createFailed({ params, error }));
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS(
              'actions:Create',
              'entitiesNamesWithArticles:alert',
              statusCode === 409 ? 'forms:fields:name' : 'forms:fields:configuration',
              params.alertName
            )[statusCode],
          })
        );
      });
  };
};

const updateAlertRequest = (params) => {
  return (dispatch, getState) => {
    dispatch(actions.ACTIONS_ALERTS_SAVED.updateRequested(params));
    const state = getState();
    const oldParams = getAlertsSavedListParams(state);
    createAlert(formatSaveAlert(params))
      .then(({ data, status }) => {
        dispatch(actions.ACTIONS_ALERTS_SAVED.updateSucceeded({ entity: transformAlertFromViewToRedux(params, data) }));
        listSavedAlertsRequest(oldParams)(dispatch, getState);
        params.setLoadingButton(false);
        dispatch(
          notificationOperations.successNotification({
            title: '',
            message: MESSAGES_NOTIFICATIONS('messages:participles:updated', 'entitiesNames:Alert')[status],
          })
        );
      })
      .catch((error) => {
        const statusCode = getStatusCode(error);
        dispatch(actions.ACTIONS_ALERTS_SAVED.updateFailed({ params, error }));
        params.setLoadingButton(false);
        dispatch(
          notificationOperations.errorNotification({
            title: 'actions:Error',
            message: MESSAGES_NOTIFICATIONS('actions:UpdateEntity', 'entitiesNames:Alert', params.alertName)[
              statusCode
            ],
          })
        );
      });
  };
};

export default {
  listSavedAlertsRequest,
  deleteAlertRequest,
  createAlertRequest,
  getAlertRequest,
  updateAlertRequest,
  listHistoryAlertsRequest,
  getHistoryAlertRequest,
};
