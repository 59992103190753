import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Select } from 'ui';
import { prop } from 'libs/toolkits';
import { withSearchEntities } from '../Selectors';
import { AlertFilters } from './styles';
import { ALERTS_TYPES_OPTIONS_TABLE, INCIDENCES_OPTIONS_TABLE } from './constants';

const SelectMeasure = withSearchEntities({ type: 'measures' });

const FiltersTable = ({ type, setInfoSelects, infoSelected }) => {
  const { t } = useTranslation();
  const findObject = (array, value) => array.find((arrayItem) => String(arrayItem.value) === String(value));

  if (type === 'alert') {
    return (
      <AlertFilters>
        <Select
          menuPosition="fixed"
          options={ALERTS_TYPES_OPTIONS_TABLE(t)}
          value={findObject(ALERTS_TYPES_OPTIONS_TABLE(t), infoSelected.type)}
          onChange={({ value }) => {
            setInfoSelects({ ...infoSelected, type: value });
          }}
        />
        <SelectMeasure
          menuPosition="fixed"
          width={40}
          placeholder={t('entitiesNames:MeasurementUnit')}
          onChange={(value) =>
            setInfoSelects({ ...infoSelected, measurementUnits: value ? value.map(prop('value')) : null })
          }
          isMulti
        />
      </AlertFilters>
    );
  }
  if (type === 'incidence') {
    return (
      <AlertFilters>
        <Select
          menuPosition="fixed"
          options={ALERTS_TYPES_OPTIONS_TABLE(t, type)}
          value={findObject(ALERTS_TYPES_OPTIONS_TABLE(t, type), infoSelected.type)}
          onChange={({ value }) => {
            setInfoSelects({ ...infoSelected, type: value });
          }}
        />
        <Select
          menuPosition="fixed"
          options={INCIDENCES_OPTIONS_TABLE(t)}
          value={findObject(INCIDENCES_OPTIONS_TABLE(t), infoSelected.status)}
          onChange={({ value }) => {
            setInfoSelects({ ...infoSelected, status: value });
          }}
        />
      </AlertFilters>
    );
  }

  return null;
};

FiltersTable.propTypes = {
  type: PropTypes.string.isRequired,
  setInfoSelects: PropTypes.string.isRequired,
  infoSelected: PropTypes.shape({
    type: PropTypes.string,
    measurementUnits: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
  }).isRequired,
};

export default FiltersTable;
