import { getDateFormated } from 'libs/date';

const OPTION = (t, name) => ({
  toolbox: {
    show: true,
    top: -1,
    right: 0,
    feature: {
      orient: 'vertical',
      saveAsImage: {
        title: t('actions:DownloadEntity'),
        show: true,
        name,
        pixelRatio: 2,
      },
    },
  },
  grid: {
    width: '93%',
    height: '63%',
    bottom: '10%',
    left: '3%',
    right: '3%',
    containLabel: true,
  },
  legend: {
    data: [],
    x: 'center',
    type: 'scroll',
    left: '0',
    top: '50',
    orient: 'horizontal',
    itemGap: 20,
    itemWidth: 14,
    itemHeight: 10,
  },
  tooltip: {
    trigger: 'axis',
    borderRadius: 8,
    borderWidth: 2,

    axisPointer: {
      type: 'cross',
    },
    backgroundColor: 'rgba(255,255,255,1)',
    textStyle: {
      color: '#7171A6',
      fontWeight: 'bold',
      height: '30px',
    },
    extraCssText:
      'z-index:22; padding: 20px;  box-shadow: 0px 0px 5px rgba(51, 52, 79, 0.07);border-radius: 3px;min-width: 250px;border:1px solid #DADAF3;line-height: 28px;',
  },
  axisPointer: {
    link: [
      {
        xAxisIndex: 'all',
      },
    ],
  },
  xAxis: {
    name: '',
    type: 'category',
    scale: true,

    axisLabel: {
      margin: 15,
      formatter: (value) => {
        const date = getDateFormated(new Date(value), 'dd/MM/yyyy hh:mm');
        return date.split(' ').join('\n');
      },
    },

    axisPointer: {
      snap: true,
      lineStyle: {
        color: '#2f2e48',
        width: 2,
      },
      label: {
        show: true,
        formatter: (params) => {
          return getDateFormated(
            typeof params.value === 'string' ? new Date(params.value) : params.value,
            'dd-MM-yyyy HH:mm'
          );
        },
        backgroundColor: '#2f2e48',
      },
    },
  },
  yAxis: {
    type: 'value',
    name: '',
    scale: false,
    axisLine: {
      onZero: false,
      show: true,
      lineStyle: {
        type: 'dashed',
        color: '#7171A6',
        width: 0.5,
      },
    },
  },
  series: [],
});
const SERIE = (t) => ({
  name: '',
  type: 'line',
  symbolSize: 6,
  smooth: false,
  sampling: 'average',
  lineStyle: {
    width: 2,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    shadowBlur: 8,
  },
  itemStyle: {
    normal: {
      color: '',
    },
  },
  data: [],
});

const ITEM_STYLE_BAR = {
  emphasis: {
    itemStyle: {
      barBorderWidth: 1,
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowColor: 'rgba(0,0,0,0.5)',
    },
  },
};

export { OPTION, SERIE, ITEM_STYLE_BAR };
