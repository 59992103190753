import React, { useCallback, useMemo, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTable, usePagination, useRowSelect, useFlexLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { useTranslation } from 'react-i18next';
import Space from '../space';
import Button from '../button';
import Spinner from '../spinner';
import Text from '../tipography/text';
import { Input } from '../form';
import ImageNoResult from '../../assets/images/table_no-result.svg';

import { TableStyles, GoPage, PaginationTable, FilterBar, TrStyles, MessageEmpty } from './styles';
import { SelectCheckBoxAllRows, SelectCheckBoxRow } from './SelectCheckbox';
import factoryActionsCreator from './actions/factoryActionsCreator';

const getStyles = (props, { align = 'left', background, color, ...rest }) => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'center',
      display: 'flex',
      backgroundColor: background,
      color,
      ...rest,
    },
  },
];

const headerProps = (props, { ...style }) => getStyles(props, { ...style });

const getCellProps = (props = {}, { cell, ...rest } = { cell: null }) =>
  getStyles(props, { align: cell && cell.column.align, background: null, ...rest });

const TableWithActions = ({
  actionsComponents,
  total,
  pageSize,
  data,
  columns,
  onChange,
  rowSelect,
  manualPagination,
  goPageAllow,
  pagination,
  showFooter,
  id,
  alignActionsBar,
  styles,
  isLoading,
  height,
  filterBarWithFrame,
  getCustomRowProps,
  flexLayoutOn,
  noResultText,
  stickyOn,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 150, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const { t } = useTranslation();
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    getTableProps,
    nextPage,
    previousPage,
    selectedFlatRows,
    state: { pageIndex },
    toggleAllRowsSelected,
    toggleRowSelected,
    gotoPage,
    rows,
  } = useTable(
    {
      ...(manualPagination ? { manualPagination, pageCount: total === 0 ? 0 : Math.ceil(total / pageSize) } : {}),
      columns: useMemo(() => columns, [columns]),
      data: useMemo(() => data, [data]),
      initialState: { pageIndex: 0, pageSize },
      ...(flexLayoutOn && defaultColumn),
    },
    flexLayoutOn ? useFlexLayout : () => {},
    stickyOn ? useSticky : () => {},
    pagination ? usePagination : () => {},
    rowSelect ? useRowSelect : () => {},
    (hooks) => {
      if (rowSelect) {
        hooks.columns.push((col) => [
          {
            id: 'selection',
            Header: SelectCheckBoxAllRows,
            Cell: SelectCheckBoxRow,
            minWidth: 10,
            width: 10,
            maxWidth: 10,
            ...(stickyOn && { sticky: 'left' }),
          },
          ...col,
        ]);
      }
      if (getCustomRowProps) {
        getCustomRowProps.forEach((rowProps) => {
          hooks.getRowProps.push(rowProps);
        });
      }
    }
  );
  const handlePaginate = useCallback(
    (next = true) => {
      onChange({
        pageIndex: next ? pageIndex + 1 : pageIndex - 1,
        pageSize,
      });
      if (next) {
        nextPage();
      } else {
        previousPage();
      }
    },
    [onChange, pageSize, pageIndex, nextPage, previousPage]
  );
  // Workaround as react-table footerGroups doesn't provide the same internal data than headerGroups
  const footerGroups = headerGroups.map((header) => header).reverse();

  useEffect(() => {
    if (gotoPage) gotoPage(0);
  }, [gotoPage, total]);

  return (
    <>
      {actionsComponents && (
        <FilterBar alignActionsBar={alignActionsBar} filterBarWithFrame={filterBarWithFrame}>
          {actionsComponents({
            selecteds: selectedFlatRows || [],
            pageSize,
            pageIndex,
            toggleAllRowsSelected,
            toggleRowSelected,
          })}
        </FilterBar>
      )}

      {isLoading && <Spinner tip={t('actions:LoadingData')} />}
      {!isLoading && total === 0 && noResultText && (
        <MessageEmpty>
          <div>
            <img src={ImageNoResult} alt="" />
            <h3>{t(noResultText)}</h3>
          </div>
        </MessageEmpty>
      )}
      {!isLoading && total !== 0 && (
        <>
          <TableStyles id={id} styles={styles} height={height}>
            <div
              {...getTableProps()}
              className={`table ${stickyOn ? 'sticky' : ''}`}
              {...(flexLayoutOn && { style: { minWidth: '100%' } })}
            >
              <div className="thead">
                {headerGroups.map((headerGroup) => (
                  <div {...headerGroup.getHeaderGroupProps({})} className="tr">
                    {headerGroup.headers.map((column) => {
                      if (column.Header === '') return '';
                      return (
                        <div
                          {...column.getHeaderProps()}
                          {...(flexLayoutOn
                            ? column.getHeaderProps((props) =>
                                headerProps(props, {
                                  width: column.width,
                                  maxWidth: column.maxWidth,
                                  minWidth: column.minWidth,
                                  left: column.left || '0px',
                                })
                              )
                            : {
                                style: {
                                  width: column.width,
                                  maxWidth: column.maxWidth,
                                  minWidth: column.minWidth,
                                  left: column.left || '0px',
                                },
                              })}
                          className="th"
                        >
                          {column.render('Header')}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>

              <div {...getTableBodyProps()} className="tbody" height={height}>
                {(page || rows).map((row) => {
                  prepareRow(row);

                  const rowProps = row.getRowProps();

                  return (
                    <TrStyles {...{ ...rowProps, cursor: rowProps.onClick ? 'pointer' : 'default' }} styles={styles}>
                      {row.cells.map((cell, indexColumn) => {
                        const { cells } = row;
                        const {
                          column: { cellOnClick },
                        } = cells[indexColumn];
                        const cellProps = cell.getCellProps();
                        return cell.column.id === 'actions' ? (
                          <Fragment key={cellProps.key}>{cell.render('Cell')}</Fragment>
                        ) : (
                          <div
                            {...(cellOnClick && {
                              onClick: (event) => cellOnClick(event, cell),
                              style: { cursor: 'pointer' },
                            })}
                            {...cell.getCellProps()}
                            {...(flexLayoutOn
                              ? cell.getCellProps(getCellProps).styles
                              : {
                                  style: {
                                    width: cell.column.width,
                                    maxWidth: cell.column.maxWidth,
                                    minWidth: cell.column.minWidth,
                                    left: cell.column.left || '0px',
                                  },
                                })}
                            {...(stickyOn && {
                              style: {
                                width: cell.column.width,
                                maxWidth: cell.column.maxWidth,
                                minWidth: cell.column.minWidth,
                                left: cell.column.left || '0px',
                              },
                            })}
                            className="td"
                          >
                            {cell.render('Cell')}
                          </div>
                        );
                      })}
                    </TrStyles>
                  );
                })}
              </div>

              {showFooter && (
                <div className="footer">
                  <TrStyles {...{ cursor: 'default' }} styles={styles}>
                    {footerGroups.map((footerGroup) =>
                      footerGroup.headers.map((column) => (
                        <div
                          key={column.getHeaderProps().key}
                          {...column.getHeaderProps((props) =>
                            headerProps(props, {
                              width: column.width,
                              maxWidth: column.maxWidth,
                              minWidth: column.minWidth,
                              background: column.footerBackground,
                              color: column.footerColor,
                              left: column.left || '0px',
                              ...(column.padding && { padding: column.padding }),
                            })
                          )}
                          className="td"
                        >
                          {column.render('Footer')}
                        </div>
                      ))
                    )}
                  </TrStyles>
                </div>
              )}
            </div>
          </TableStyles>

          {pagination && (
            <PaginationTable>
              <GoPage>
                <Input
                  type="number"
                  min="1"
                  step="1"
                  width={100}
                  max={pageOptions.length || 1}
                  defaultValue={1}
                  value={pageIndex >= pageOptions.length ? 1 : pageIndex + 1}
                  pattern="[0-9]"
                  onkeypress={(e) => !(e.charCode === 46)}
                  onChange={({ target: { value } }) => {
                    if (!value) return;
                    const pageToChange =
                      Number(value) - 1 >= pageOptions.length ? pageOptions.length - 1 : Number(value) - 1;
                    gotoPage(pageToChange);
                    onChange({ pageSize, pageIndex: pageToChange });
                  }}
                  disabled={!goPageAllow}
                />
              </GoPage>
              <Space>
                <Text>de {pageOptions.length || 1}</Text>
                <Button
                  color="secondary"
                  variant="plain"
                  customIcon="icon-arrow-left"
                  iconSize={28}
                  onClick={() => handlePaginate(false)}
                  disabled={!canPreviousPage}
                />

                <Button
                  color="secondary"
                  customIcon="icon-arrow-right"
                  iconSize={28}
                  variant="plain"
                  onClick={() => handlePaginate(true)}
                  disabled={!canNextPage}
                />
              </Space>
            </PaginationTable>
          )}
        </>
      )}
    </>
  );
};

TableWithActions.propTypes = {
  actionsComponents: PropTypes.elementType,
  total: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  rowSelect: PropTypes.bool,
  manualPagination: PropTypes.bool,
  goPageAllow: PropTypes.bool,
  pagination: PropTypes.bool,
  showFooter: PropTypes.bool,
  stickyOn: PropTypes.bool,
  height: PropTypes.string,
  getCustomRowProps: PropTypes.arrayOf(PropTypes.func),
  filterBarWithFrame: PropTypes.bool,
  styles: PropTypes.shape({
    coloredTable: PropTypes.bool,
    lightTable: PropTypes.bool,
    gradientScroll: PropTypes.bool,
  }),
  alignActionsBar: PropTypes.oneOf(['end', 'start', 'between', 'center', 'space-between']),
  flexLayoutOn: PropTypes.bool,
  noResultText: PropTypes.string,
};

TableWithActions.defaultProps = {
  onChange: () => {},
  getCustomRowProps: null,
  total: 0,
  actionsComponents: null,
  rowSelect: true,
  filterBarWithFrame: false,
  manualPagination: true,
  goPageAllow: true,
  isLoading: false,
  pagination: true,
  showFooter: false,
  stickyOn: false,
  alignActionsBar: 'space-between',
  styles: {
    coloredTable: false,
    lightTable: false,
    gradientScroll: false,
  },
  id: undefined,
  flexLayoutOn: false,
  noResultText: 'commonTexts:NoResultsSearch',
};

TableWithActions.factoryActionsCreator = factoryActionsCreator;

export default TableWithActions;
