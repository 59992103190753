import React from 'react';
import { TitleProps } from 'antd/lib/typography/Title';

import { TitleStyles } from './styles';

interface Props extends Partial<TitleProps> {
  align?: 'left' | 'center' | 'right';
  color?: 'primary' | 'danger' | 'success' | 'ghost' | 'warning' | 'white' | 'light';
}

const Title: React.FC<Props> = ({ align, children, color, ...props }) => (
  <TitleStyles {...{ align, color, ...props }}>{children}</TitleStyles>
);

export default Title;
