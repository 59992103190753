import React from 'react';

// 2021-02-21
import { GasQuality, ConfigQGraph } from 'assets/video/changelog/20210220';
import { GasQualityImage, ScheduleReport, RenameReport } from 'assets/images/changelog/20210220';

// 2021-03-15
import { FixedColumn, RunMetrics, GraphMeasurePanel } from 'assets/video/changelog/20210315';
import { BreadcrumbNav, Frequency, DailyAverages } from 'assets/images/changelog/20210315';

// 2021-04-06
import { TooltipGraphs, VariableZn, NoResultsGraphs, ComparativeTable } from 'assets/images/changelog/20210406';

import LinkReferenceValue from 'assets/video/changelog/20210406';

// 2021-04-16
import { VelocityProfile } from 'assets/images/changelog/20210416';

import { Button, Text } from 'ui';

const improvesConfig = (history, toggleModal) => ({
  '16 Abril 2021': [
    {
      date: '16 Abril 2021',
      colorType: '#61bd4f',
      type: 'Mejora',
      title: 'Perfil de velocidades',
      content: (
        <>
          <p>
            Se ha actualizado la unidad asociada a la representación de los valores de perfil de velocidades en el
            informe de ultrasónicos.
          </p>
          <a target="_blank" rel="noreferrer" href={VelocityProfile}>
            <img src={VelocityProfile} alt="velocity profile" />
          </a>
        </>
      ),
    },
    {
      date: '16 Abril 2021',
      colorType: '#61bd4f',
      type: 'Mejora',
      title: 'Aumentamos la resolución de los gráficos',
      content: (
        <>
          <p>
            A partir de ahora podrás descargar las capturas de tus gráficos con mayor calidad. ¡Di adiós a las imágenes
            pixeladas!
          </p>
        </>
      ),
    },
  ],
  '6 Abril 2021': [
    {
      date: '6 Abril 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Análisis avanzado',
      content: (
        <ul>
          <li className="title">
            Incorporación de la variable Zn
            <span>
              Ahora podrás realizar informes para la variable Zn (Factor de compresibilidad en condiciones normales)
            </span>
            <a target="_blank" rel="noreferrer" href={VariableZn}>
              <img src={VariableZn} alt="variable Zn" />
            </a>
          </li>

          <li className="title">
            Configuración de variables
            <span>
              Se ha corregido la configuración de las variables (nombres, abreviaturas, descripciones, decimales y
              magnitudes)
            </span>
          </li>
        </ul>
      ),
    },
    {
      date: '6 Abril 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Panel de medidas',
      content: (
        <ul>
          <li className="title">Quitamos las variables del tipo contador que no son &#x394;</li>
          <li className="title">
            No resultados en gráficas
            <span>
              La visualización del panel de medidas se ha mejorado para que aparezcan los gráficos incluso de aquellas
              variables / unidades de medida de las que no hemos obtenido ningún resultado.
            </span>
            <a target="_blank" rel="noreferrer" href={NoResultsGraphs}>
              <img src={NoResultsGraphs} alt="no results graphs" />
            </a>
          </li>
          <li className="title">
            Mantener tab seleccionado
            <span>
              Si estamos consultando las medias diarias de calidad de gas y modificamos algún parámetro de la consulta,
              el tab se mantiene seleccionado, actualizando la información mostrada.
            </span>
          </li>
        </ul>
      ),
    },
    {
      date: '6 Abril 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Actualización diseño',
      content: (
        <ul>
          <li className="title">
            Informe comparativo de consumos
            <span>
              La visualización del informe comparativo de consumos se ha mejorado para que aparezca completo incluso al
              no obtener datos de alguna unidad de medida.
            </span>
            <a target="_blank" rel="noreferrer" href={ComparativeTable}>
              <img src={ComparativeTable} alt="comparative table" />
            </a>
          </li>
          <li className="title">
            Valores de referencia
            <span>
              Se ha incluido un “atajo“ en el listado de valores de referencia para que sea más rápido consultar dicha
              configuración gracias a un click en el nombre del valor de referencia.
            </span>
            <video width="100%" controls>
              <source src={LinkReferenceValue} type="video/mp4" />
            </video>
          </li>
          <li className="title">
            Mensajes de notificaciones de tablas, errores,...
            <span>
              Se han mejorado las respuestas mostradas por pantalla de la aplicación al realizar consultas, se han
              corregido mensajes incoherentes que se mostraban en tablas y notificaciones emergentes,
            </span>
          </li>
          <li className="title">
            Ajuste de tooltips informativos
            <span>
              Se han ajustado la posición de los tooltips informativos de las gráficas para que no se corten por el
              tamaño de los mismos.
            </span>
            <a target="_blank" rel="noreferrer" href={TooltipGraphs}>
              <img src={TooltipGraphs} alt="tooltip graphs" />
            </a>
          </li>
          <li className="title">
            Logotipo
            <span>
              En esta versión hemos sustituido el logotipo de la aplicación que se venía utilizando por uno que se
              estaba comenzando a utilizar en comunicaciones internas.
            </span>
          </li>
        </ul>
      ),
    },
  ],
  '15 Marzo 2021': [
    {
      date: '15 Marzo 2021',
      colorType: '#61bd4f',
      type: 'Nuevo',
      title: 'Gráfico de áreas a gran escala',
      content: (
        <>
          <p>
            Con el fin de mejorar la representación y visualización de los datos en periodos de tiempo largos, hemos
            añadido a las gráficas del panel de medida un área por donde podrás desplazarte y personalizar la sección
            del periodo a representar.
          </p>
          <video width="100%" controls>
            <source src={GraphMeasurePanel} type="video/mp4" />
          </video>
        </>
      ),
    },
    {
      date: '15 Marzo 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Tablas con columnas y cabeceras fijas',
      content: (
        <>
          <p>
            Para que no pierdas la referencia de los datos en tablas con scroll horizontal, se fijarán automáticamente
            las columnas y cabeceras que contengan títulos.
          </p>
          <video width="100%" controls>
            <source src={FixedColumn} type="video/mp4" />
          </video>
        </>
      ),
    },
    {
      date: '15 Marzo 2021',
      colorType: '#61bd4f',
      type: 'Nuevo',
      title: 'Se añade desplegable en las rutas',
      content: (
        <>
          <p>Navega por las diferentes secciones de un módulo desde la ruta superior.</p>
          <a target="_blank" rel="noreferrer" href={BreadcrumbNav}>
            <img src={BreadcrumbNav} alt="breadcrumb nav" />
          </a>
        </>
      ),
    },
    {
      date: '15 Marzo 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Frecuencia de los informes programados',
      content: (
        <>
          <p>
            Consulta y gestiona con qué frecuencia está programado cualquiera de tus informes guardados desde el mismo
            diálogo de configuración.
          </p>
          <a target="_blank" rel="noreferrer" href={Frequency}>
            <img src={Frequency} alt="frequency scheduled" />
          </a>
        </>
      ),
    },
    {
      date: '15 Marzo 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Consultas panel de medidas',
      content: (
        <ul>
          <li className="title">
            Se modifica la forma de realizar las consultas
            <span>
              Para evitar problemas de rendimiento y mejorar la experiencia de usuarios hemos añadido un botón de
              <strong> “Aceptar“</strong> que deberás pulsar una vez hayas terminado de seleccionar las variables que
              quieras ver representadas.
            </span>
          </li>
          <video width="100%" controls>
            <source src={RunMetrics} type="video/mp4" />
          </video>
          <li className="title">
            Las medias diarias siempre visibles
            <span>
              Las medias diarias de calidad de gas aparecerán siempre que la unidad de medida seleccionada disponga de
              esta información. Ya no será necesario seleccionar esta opción desde el panel de configuración.
            </span>
            <a target="_blank" rel="noreferrer" href={DailyAverages}>
              <img src={DailyAverages} alt="daily averages" />
            </a>
          </li>
        </ul>
      ),
    },
    {
      date: '15 Marzo 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Configuración de tramos',
      content: (
        <p>
          Configura tus tramos de una formas más fácil e intuitiva.
          <Button
            variant="link"
            color="primary"
            onClick={() => {
              history.push('/infrastructure/sections/new');
              toggleModal(false);
            }}
          >
            Crear nuevo tramo
          </Button>
        </p>
      ),
    },
    {
      date: '15 Marzo 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Actualización diseño',
      content: (
        <p>
          El diseño es un aspecto importante de la experiencia de usuario. En esta nueva versión hemos mejorado la
          apariencia de los iconos animados de carga, la librería de iconos, el módulos de &ldquo;Mis informes
          programados&rdquo; y la visualización de las tablas.
        </p>
      ),
    },
    {
      date: '15 Marzo 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Atajos de teclado',
      content: (
        <>
          <p>
            Sabemos que los atajos de teclado facilitan el trabajo diario. Nos estrenamos con un atajo simple pero muy
            útil.
          </p>
          <p>
            Pulsa <code>ESC</code> para cerrar los diálogos laterales.
          </p>
        </>
      ),
    },
    {
      date: '15 Marzo 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Programa, envía y descarga tus informes en menos pasos',
      content: (
        <>
          <p>Hemos mejorado la usabilidad de las acciones generales de informes.</p>
          <ul>
            <li>Nuevas validaciones</li>
            <li>
              Visualiza rápidamente que informes guardados están programados
              <a target="_blank" rel="noreferrer" href={ScheduleReport}>
                <img src={ScheduleReport} alt="scheduled report" />
              </a>
            </li>
            <li>
              Posibilidad de renombrar informes guardados
              <a target="_blank" rel="noreferrer" href={RenameReport}>
                <img src={RenameReport} alt="rename report" />
              </a>
            </li>
            <li>
              Al editar un informe guardado podrás elegir entre guardar los cambios o guardarlo como un informe nuevo.
            </li>
          </ul>
        </>
      ),
    },
  ],
  '20 Febrero 2021': [
    {
      date: '20 Febrero 2021',
      colorType: '#61bd4f',
      type: 'Nuevo',
      title: (
        <>
          Calidad de gas
          <Text color="danger" size="small">
            (Versión BETA)
          </Text>
        </>
      ),
      content: (
        <ul>
          <li className="title">
            Informe asignación diaria de cromatografía
            <span>
              Consulta la asignación diaria de cromatografía para cada unidad de medida y comprueba si existe
              diferencias con el cromatógrafo establecido por defecto.
              <Text strong size="small">
                <em> * Datos obtenidos de SLM. Fecha límite de consulta 31/01/2021</em>
              </Text>
            </span>
            <video width="100%" controls>
              <source src={GasQuality} type="video/mp4" />
            </video>
            <Button
              variant="link"
              color="primary"
              onClick={() => {
                history.push('/reports/footprint?type=assignment');
                toggleModal(false);
              }}
            >
              Probar informe
            </Button>
          </li>

          <li className="title">
            Visualiza el cromatógrafo por defecto de cada unidad de medida
            <span>
              Se ha añadido la columna “Cromatógrafo por defecto” en el listado de unidades de medida.
              <Text strong size="small">
                <em> * Datos obtenidos de SLM. Última actualización 31/01/2021</em>
              </Text>
            </span>
            <a target="_blank" rel="noreferrer" href={GasQualityImage}>
              <img src={GasQualityImage} alt="gas quality" />
            </a>
          </li>
        </ul>
      ),
    },
    {
      date: '20 Febrero 2021',
      colorType: '#61bd4f',
      type: 'Nuevo',
      title: 'Configura las acciones de las gráficas de tu panel de medidas',
      content: (
        <>
          <p>
            Se ha añadido al panel de configuración una nueva sección desde la que podrás configurar si las acciones de
            descarga, zoom y captura se aplican de forma global (para todas las gráficas de la pantalla) o individual.
          </p>
          <video width="100%" controls>
            <source src={ConfigQGraph} type="video/mp4" />
          </video>
        </>
      ),
    },
    {
      date: '20 Febrero 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Ajustar escalas',
      content: (
        <p>
          Ajusta las escalas de tus gráficos de análisis avanzado sin perder la configuración al añadir nuevas
          variables.
        </p>
      ),
    },
    {
      date: '20 Febrero 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Variables de ultrasónicos en análisis avanzado',
      content: (
        <ul>
          <li className="title">
            Se ha corregido la representación de las siguientes variables:
            <span>
              <ul>
                <li>
                  <strong>VoS [m/s]</strong> - velocidad media del sonido. Cálculo entregado por el MUS de una velocidad
                  media ponderada de las velocidades del sonido medidas en cada haz.
                </li>
                <li>
                  <strong>VoSMeterDev [m/s]</strong> - desviación de la velocidad del sonido medida (se utiliza la
                  velocidad media calculada por el MUS) respecto a la velocidad del sonido teórica según AGA10. Es una
                  desviación absoluta respecto a la referencia teórica.
                </li>
              </ul>
            </span>
          </li>
          <li className="title">
            <span>
              <strong>Añadida la variable Eficiencia [%] por haz</strong>
            </span>
            <span>Entendida como el número de muestras válidas sobre comunicaciones realizadas.</span>
          </li>
        </ul>
      ),
    },
    {
      date: '20 Febrero 2021',
      colorType: '#ff9800',
      type: 'Mejora',
      title: 'Nuevos selectores para rangos de fecha y hora',
      content: (
        <>
          <p>
            Ahora puedes seleccionar la fecha de inicio y fin en el mismo diálogo o utilizar los rangos predefinidos
            para consultar periodos con un solo click.
          </p>
          <p>¡Mejoramos en rendimiento y usabilidad!</p>
        </>
      ),
    },
  ],
});
export default improvesConfig;
