import { pathOr, debounce } from 'libs/toolkits';

const useHandleSearch = ({ handleTypeInSelect, keyId = 'id', labelKey = 'name', debounceTime = 1000 }) => {
  return debounce((text, callback) => {
    handleTypeInSelect(text)
      .then((result) => {
        return pathOr([], ['data', 'items'], result);
      })
      .then((items) => {
        callback(items ? items.map((item) => ({ value: item[keyId], label: item[labelKey], item })) : []);
      })
      .catch((error) => {
        callback([]);
      });
  }, debounceTime);
};

export default useHandleSearch;
