const FC = [
  {
    value: 'Vb',
    label: 'variables:BruteVolume',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'Vbc',
    label: 'variables:CorrectedBruteVolume',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'Vn',
    label: 'variables:NormalizedVolume',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'E',
    label: 'variables:Energy',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'Veb',
    label: 'variables:BruteErrorVolume',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'Vebc',
    label: 'variables:CorrectedBruteErrorVolume',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'Ven',
    label: 'variables:NormalizedErrorVolume',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'EE',
    label: 'variables:ErrorEnergy',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'Presion',
    label: 'variables:Pressure',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'Temperatura',
    label: 'variables:Temperature',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'Z',
    label: 'variables:ZFactor',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'CFV',
    label: 'variables:CFV',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'Zn',
    label: 'variables:ZFactorN',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'presSens1',
    label: 'variables:PressureSense1',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'presSens2',
    label: 'variables:PressureSense2',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'tempSens1',
    label: 'variables:TemperatureSense1',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'tempSens2',
    label: 'variables:TemperatureSense2',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'qb',
    label: 'variables:BruteFlow',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'qn',
    label: 'variables:NormalizedFlow',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'qe',
    label: 'variables:EnergyFlow',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'qbIo',
    label: 'variables:qbIo',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'qbpk',
    label: 'variables:qbpk',
    title: 'variables:VolumnsTitle',
  },
  {
    value: 'ch_Vb',
    label: 'variables:ch_Vb',
    title: 'variables:accumulatedCurrent',
  },
  {
    value: 'ch_Vbc',
    label: 'variables:ch_Vbc',
    title: 'variables:accumulatedCurrent',
  },
  {
    value: 'ch_Vn',
    label: 'variables:ch_Vn',
    title: 'variables:accumulatedCurrent',
  },
  {
    value: 'ch_E',
    label: 'variables:ch_E',
    title: 'variables:accumulatedCurrent',
  },
  {
    value: 'ch_Veb',
    label: 'variables:ch_Veb',
    title: 'variables:accumulatedCurrent',
  },
  {
    value: 'ch_Vebc',
    label: 'variables:ch_Vebc',
    title: 'variables:accumulatedCurrent',
  },
  {
    value: 'ch_Ven',
    label: 'variables:ch_Ven',
    title: 'variables:accumulatedCurrent',
  },
  {
    value: 'ch_EE',
    label: 'variables:ch_EE',
    title: 'variables:accumulatedCurrent',
  },
  {
    value: 'cd_Vb',
    label: 'variables:cd_Vb',
    title: 'variables:accumulatedDaily',
  },
  {
    value: 'cd_Vbc',
    label: 'variables:cd_Vbc',
    title: 'variables:accumulatedDaily',
  },
  {
    value: 'cd_Vn',
    label: 'variables:cd_Vn',
    title: 'variables:accumulatedDaily',
  },
  {
    value: 'cd_E',
    label: 'variables:cd_E',
    title: 'variables:accumulatedDaily',
  },
  {
    value: 'cd_Veb',
    label: 'variables:cd_Veb',
    title: 'variables:accumulatedDaily',
  },
  {
    value: 'cd_Vebc',
    label: 'variables:cd_Vebc',
    title: 'variables:accumulatedDaily',
  },
  {
    value: 'cd_Ven',
    label: 'variables:cd_Ven',
    title: 'variables:accumulatedDaily',
  },
  {
    value: 'cd_EE',
    label: 'variables:cd_EE',
    title: 'variables:accumulatedDaily',
  },
  {
    value: 'cm_Vb',
    label: 'variables:cm_Vb',
    title: 'variables:accumulatedMonthly',
  },
  {
    value: 'cm_Vbc',
    label: 'variables:cm_Vbc',
    title: 'variables:accumulatedMonthly',
  },
  {
    value: 'cm_Vn',
    label: 'variables:cm_Vn',
    title: 'variables:accumulatedMonthly',
  },
  {
    value: 'cm_E',
    label: 'variables:cm_E',
    title: 'variables:accumulatedMonthly',
  },
  {
    value: 'cm_Veb',
    label: 'variables:cm_Veb',
    title: 'variables:accumulatedMonthly',
  },
  {
    value: 'cm_Vebc',
    label: 'variables:cm_Vebc',
    title: 'variables:accumulatedMonthly',
  },
  {
    value: 'cm_Ven',
    label: 'variables:cm_Ven',
    title: 'variables:accumulatedMonthly',
  },
  {
    value: 'cm_EE',
    label: 'variables:cm_EE',
    title: 'variables:accumulatedMonthly',
  },
  {
    value: 'EGStatus',
    label: 'variables:equipment-in-error',
    title: 'variables:equipment-state',
    onlyMU: true,
  },
];

const CROMA = [
  {
    value: 'CR_C1',
    label: 'variables:C1',
    title: 'variables:percentage',
  },
  {
    value: 'CR_C2',
    label: 'variables:C2',
    title: 'variables:percentage',
  },
  {
    value: 'CR_C3',
    label: 'variables:C3',
    title: 'variables:percentage',
  },
  {
    value: 'CR_nC4',
    label: 'variables:n-C4',
    title: 'variables:percentage',
  },
  {
    value: 'CR_iC5',
    label: 'variables:i-C5',
    title: 'variables:percentage',
  },
  {
    value: 'CR_nC5',
    label: 'variables:n-C5',
    title: 'variables:percentage',
  },
  {
    value: 'CR_N2',
    label: 'variables:N2',
    title: 'variables:percentage',
  },
  {
    value: 'CR_CO2',
    label: 'variables:CO2',
    title: 'variables:percentage',
  },
  {
    value: 'CR_C6',
    label: 'variables:C6',
    title: 'variables:percentage',
  },
  {
    value: 'CR_Hs',
    label: 'variables:HS',
    title: 'variables:percentage',
  },
  {
    value: 'CR_Hi',
    label: 'variables:HI',
    title: 'variables:percentage',
  },
  {
    value: 'CR_WIs',
    label: 'variables:IWs',
    title: 'variables:percentage',
  },
  {
    value: 'CR_WIi',
    label: 'variables:IWi',
    title: 'variables:percentage',
  },
  {
    value: 'CR_H2',
    label: 'variables:H2',
    title: 'variables:percentage',
  },
  {
    value: 'CR_H2O',
    label: 'variables:H2O',
    title: 'variables:percentage',
  },
  {
    value: 'CR_iC4',
    label: 'variables:i-C4',
    title: 'variables:percentage',
  },
  {
    value: 'CR_He',
    label: 'variables:He',
    title: 'variables:percentage',
  },
  {
    value: 'CR_CO',
    label: 'variables:CO',
    title: 'variables:percentage',
  },
  {
    value: 'CR_rd',
    label: 'variables:Dr',
    title: 'variables:percentage',
  },
  {
    value: 'CR_stream',
    label: 'Stream',
    title: 'variables:percentage',
  },
  {
    value: 'CR_TR_C1',
    label: 'variables:CR_TR_C1',
    title: 'variables:RetentionTime',
  },
  {
    value: 'CR_TR_C2',
    label: 'variables:CR_TR_C2',
    title: 'variables:RetentionTime',
  },
  {
    value: 'CR_TR_C3',
    label: 'variables:CR_TR_C3',
    title: 'variables:RetentionTime',
  },
  {
    value: 'CR_TR_iC4',
    label: 'variables:CR_TR_iC4',
    title: 'variables:RetentionTime',
  },
  {
    value: 'CR_TR_nC4',
    label: 'variables:CR_TR_nC4',
    title: 'variables:RetentionTime',
  },
  {
    value: 'CR_TR_iC5',
    label: 'variables:CR_TR_iC5',
    title: 'variables:RetentionTime',
  },
  {
    value: 'CR_TR_nC5',
    label: 'variables:CR_TR_nC5',
    title: 'variables:RetentionTime',
  },
  {
    value: 'CR_TR_N2',
    label: 'variables:CR_TR_N2',
    title: 'variables:RetentionTime',
  },
  {
    value: 'CR_TR_CO2',
    label: 'variables:CR_TR_CO2',
    title: 'variables:RetentionTime',
  },
  {
    value: 'CR_TR_C6plus',
    label: 'variables:CR_TR_C6plus',
    title: 'variables:RetentionTime',
  },
  {
    value: 'CR_FR_C1',
    label: 'variables:CR_FR_C1',
    title: 'variables:ResponseFactor',
  },
  {
    value: 'CR_FR_C2',
    label: 'variables:CR_FR_C2',
    title: 'variables:ResponseFactor',
  },
  {
    value: 'CR_FR_C3',
    label: 'variables:CR_FR_C3',
    title: 'variables:ResponseFactor',
  },
  {
    value: 'CR_FR_nC4',
    label: 'variables:CR_FR_nC4',
    title: 'variables:ResponseFactor',
  },
  {
    value: 'CR_FR_iC5',
    label: 'variables:CR_FR_iC5',
    title: 'variables:ResponseFactor',
  },
  {
    value: 'CR_FR_nC5',
    label: 'variables:CR_FR_nC5',
    title: 'variables:ResponseFactor',
  },
  {
    value: 'CR_FR_N2',
    label: 'variables:CR_FR_N2',
    title: 'variables:ResponseFactor',
  },
  {
    value: 'CR_FR_iC4',
    label: 'variables:CR_FR_iC4',
    title: 'variables:ResponseFactor',
  },
  {
    value: 'CR_FR_CO2',
    label: 'variables:CR_FR_CO2',
    title: 'variables:ResponseFactor',
  },
  {
    value: 'CR_FR_C6plus',
    label: 'variables:CR_FR_C6plus',
    title: 'variables:ResponseFactor',
  },
  {
    value: 'error_cr_1',
    label: 'Error stream 1',
    title: 'variables:equipment-state',
  },
  {
    value: 'error_cr_2',
    label: 'Error stream 2',
    title: 'variables:equipment-state',
  },
  {
    value: 'error_cr_3',
    label: 'Error stream 3',
    title: 'variables:equipment-state',
  },
  {
    value: 'error_cr_4',
    label: 'Error stream 4',
    title: 'variables:equipment-state',
  },
  {
    value: 'error_cr_5',
    label: 'Error stream 5',
    title: 'variables:equipment-state',
  },
];
const COUNTER = [
  {
    value: 'delta_Vb',
    label: 'variables:DeltaBruteVolume',
  },
  {
    value: 'delta_Vbc',
    label: 'variables:DeltaCorrectedBruteVolume',
  },
  {
    value: 'delta_Vn',
    label: 'variables:DeltaNormalizedVolume',
  },
  {
    value: 'delta_E',
    label: 'variables:DeltaEnergy',
  },
];

const COUNTER_ERROR = [
  {
    value: 'delta_Veb',
    label: 'variables:DeltaBruteErrorVolume',
  },
  {
    value: 'delta_Vebc',
    label: 'variables:DeltaCorrectedBruteErrorVolume',
  },
  {
    value: 'delta_Ven',
    label: 'variables:DeltaNormalizedErrorVolume',
  },
  {
    value: 'delta_EE',
    label: 'variables:DeltaErrorEnergy',
  },
];

const ULTRASONIC = [
  { value: 'Footprint', label: 'variables:Footprint' },
  { value: 'VoS', label: 'variables:VoS' },
  { value: 'cpp', label: 'variables:cpp' },
  { value: 'vpp', label: 'variables:vpp' },
  { value: 'vel_prof', label: 'variables:vel_prof' },
  { value: 'AGCLevelA', label: 'variables:AGCLevelA' },
  { value: 'AGCLevelB', label: 'variables:AGCLevelB' },
  { value: 'AGCLimitA', label: 'variables:AGCLimitA' },
  { value: 'AGCLimitB', label: 'variables:AGCLimitB' },
  { value: 'SNR_a', label: 'variables:SNR_a' },
  { value: 'SNR_b', label: 'variables:SNR_b' },
  { value: 'SNR_Serie_III', label: 'variables:SNR_Serie_III' },
  { value: 'SNR_Serie_III_1', label: 'variables:SNR_Serie_III_1' },
  { value: 'SNR_Serie_IV_a', label: 'variables:SNR_Serie_IV_a' },
  { value: 'SNR_Serie_IV_a_1', label: 'variables:SNR_Serie_IV_a_1' },
  { value: 'SNR_Serie_IV_b', label: 'variables:SNR_Serie_IV_b' },
  { value: 'SNR_Serie_IV_b_1', label: 'variables:SNR_Serie_IV_b_1' },
  { value: 'VoSMeterDev', label: 'variables:VoSMeterDev' },
  { value: 'efficiency', label: 'variables:efficiency' },
];
const FOOTPRINT_TR = [
  { value: 'CR_TR_C1', type: 'TR', label: 'variables:CR_TR_C1' },
  { value: 'CR_TR_C2', type: 'TR', label: 'variables:CR_TR_C2' },
  { value: 'CR_TR_C3', type: 'TR', label: 'variables:CR_TR_C3' },
  { value: 'CR_TR_iC4', type: 'TR', label: 'variables:CR_TR_iC4' },
  { value: 'CR_TR_nC4', type: 'TR', label: 'variables:CR_TR_nC4' },
  { value: 'CR_TR_iC5', type: 'TR', label: 'variables:CR_TR_iC5' },
  { value: 'CR_TR_nC5', type: 'TR', label: 'variables:CR_TR_nC5' },
  { value: 'CR_TR_C6plus', type: 'TR', label: 'variables:CR_TR_C6plus' },
  { value: 'CR_TR_N2', type: 'TR', label: 'variables:CR_TR_N2' },
  { value: 'CR_TR_CO2', type: 'TR', label: 'variables:CR_TR_CO2' },
];

const FOOTPRINT_FR = [
  { value: 'CR_FR_C1', type: 'FR', label: 'variables:CR_FR_C1' },
  { value: 'CR_FR_C2', type: 'FR', label: 'variables:CR_FR_C2' },
  { value: 'CR_FR_C3', type: 'FR', label: 'variables:CR_FR_C3' },
  { value: 'CR_FR_iC4', type: 'FR', label: 'variables:CR_FR_iC4' },
  { value: 'CR_FR_nC4', type: 'FR', label: 'variables:CR_FR_nC4' },
  { value: 'CR_FR_iC5', type: 'FR', label: 'variables:CR_FR_iC5' },
  { value: 'CR_FR_nC5', type: 'FR', label: 'variables:CR_FR_nC5' },
  { value: 'CR_FR_C6plus', type: 'FR', label: 'variables:CR_FR_C6plus' },
  { value: 'CR_FR_N2', type: 'FR', label: 'variables:CR_FR_N2' },
  { value: 'CR_FR_CO2', type: 'FR', label: 'variables:CR_FR_CO2' },
];

const ULTRASONIC_PERMITTED = [
  { value: 'VoS', label: 'variables:VoS' },
  { value: 'VoSMeterDev', label: 'variables:VoSMeterDev' },
];

const CONFIG_VARIABLES_FC = {
  Vb: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[m³]',
    name: 'BruteVolume',
    decimals: 0,
  },
  Vbc: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[m³]',
    name: 'CorrectedBruteVolume',
    decimals: 0,
  },
  Vn: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[m³]',
    name: 'NormalizedVolume',
    decimals: 0,
  },
  E: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[kWh]',
    name: 'Energy',
    decimals: 0,
  },
  E_calc: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[kWh]',
    name: 'EnergyCalc',
    decimals: 0,
    abrev: 'Ec',
  },
  Veb: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[m³]',
    name: 'BruteErrorVolume',
    decimals: 0,
    abrev: 'Vbe',
  },
  Vebc: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[m³]',
    name: 'CorrectedBruteErrorVolume',
    decimals: 0,
    abrev: 'Vbce',
  },
  Ven: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[m³]',
    name: 'NormalizedErrorVolume',
    decimals: 0,
    abrev: 'Vne',
  },
  EE: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[kWh]',
    name: 'ErrorEnergy',
    decimals: 0,
    abrev: 'Ee',
  },
  qn: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[m³/h]',
    name: 'NormalizedFlow',
    decimals: 2,
  },
  qb: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[m³/h]',
    name: 'BruteFlow',
    abrev: 'qb',
    decimals: 2,
  },
  qe: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[kWh/m³]',
    name: 'EnergyFlow',
    decimals: 2,
    abrev: 'qe',
  },
  qbIo: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[m³/h]',
    name: 'qbIo',
    decimals: 2,
  },
  qbpk: {
    colors: ['#2F2E48', '#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[m³/h]',
    name: 'qbpk',
    decimals: 2,
  },
  Temperatura: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[°C]',
    name: 'Temperature',
    decimals: 2,
    abrev: 'T',
  },
  Presion: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[bar]',
    name: 'Pressure',
    decimals: 3,
    abrev: 'P',
  },
  Z: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[-]',
    name: 'Z',
    decimals: 4,
  },
  Zn: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[-]',
    name: 'Zn',
    decimals: 4,
  },
  CFV: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84'],
    unit: '[-]',
    name: 'CFV',
    decimals: 3,
  },
  presSens1: {
    name: 'PressureSense1',
    unit: '[bar]',
    decimals: 3,
  },
  presSens2: {
    name: 'PressureSense2',
    unit: '[bar]',
    decimals: 3,
  },
  tempSens1: {
    name: 'TemperatureSense1',
    unit: '[ºC]',
    decimals: 2,
  },
  tempSens2: {
    name: 'TemperatureSense2',
    unit: '[ºC]',
    decimals: 2,
  },
};
const CONFIG_VARIABLES_CHROMA = {
  c_Hsused_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[kWh/m³]',
    name: 'HS',
    decimals: 4,
    abrev: 'PCS',
  },
  c_Hiused_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[kWh/m³]',
    name: 'HI',
    decimals: 4,
    abrev: 'PCI',
  },
  c_N2used_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[% mol/mol]',
    name: 'N2',
    decimals: 4,
  },
  c_rdused_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[% mol/mol]',
    name: 'Dr',
    decimals: 4,
  },
  c_O2used_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[% mol/mol]',
    name: 'O2',
    decimals: 4,
  },
  c_CO2used_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[% mol/mol]',
    name: 'CO2',
    decimals: 4,
  },
  c_C1used_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[% mol/mol]',
    name: 'C1',
    abrev: 'C1',
    decimals: 3,
  },
  c_C2used_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[% mol/mol]',
    name: 'C2',
    abrev: 'C2',
    decimals: 4,
  },
  c_C3used_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[% mol/mol]',
    name: 'C3',
    abrev: 'C3',
    decimals: 4,
  },
  c_iC4used_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[% mol/mol]',
    name: 'i-C4',
    abrev: 'iC4',
    decimals: 4,
  },
  c_nC4used_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[% mol/mol]',
    name: 'n-C4',
    abrev: 'nC4',
    decimals: 4,
  },
  c_iC5used_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[% mol/mol]',
    name: 'i-C5',
    abrev: 'iC5',
    decimals: 4,
  },
  c_nC5used_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[% mol/mol]',
    name: 'n-C5',
    abrev: 'nC5',
    decimals: 4,
  },
  c_C6plusused_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[% mol/mol]',
    name: 'C6',
    abrev: 'C6',
    decimals: 4,
  },
  c_WobbeSuoused_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[kWh/m³]',
    name: 'IWs',
    abrev: 'IWs',
    decimals: 4,
  },
  c_WobbeIngused_cr: {
    colors: ['#714CE9', '#36BEDF', '#FB8953', '#E94C84', '#FBAC39'],
    unit: '[kWh/m³]',
    name: 'IWi',
    abrev: 'IWi',
    decimals: 4,
  },
};
const CONFIG_VARIABLES_CHROMA_AA = {
  CR_Hs: { ...CONFIG_VARIABLES_CHROMA.c_Hsused_cr, abrev: 'CR_Hs' },
  CR_Hi: { ...CONFIG_VARIABLES_CHROMA.c_Hiused_cr, abrev: 'CR_Hi' },
  CR_N2: { ...CONFIG_VARIABLES_CHROMA.c_N2used_cr, abrev: 'CR_N2' },
  CR_rd: { ...CONFIG_VARIABLES_CHROMA.c_rdused_cr, abrev: 'CR_rd' },
  CR_O2: { ...CONFIG_VARIABLES_CHROMA.c_O2used_cr, abrev: 'CR_O2' },
  CR_CO2: { ...CONFIG_VARIABLES_CHROMA.c_CO2used_cr, abrev: 'CR_CO2' },
  CR_C1: { ...CONFIG_VARIABLES_CHROMA.c_C1used_cr, abrev: 'CR_C1' },
  CR_C2: { ...CONFIG_VARIABLES_CHROMA.c_C2used_cr, abrev: 'CR_C2' },
  CR_C3: { ...CONFIG_VARIABLES_CHROMA.c_C3used_cr, abrev: 'CR_C3' },
  CR_iC4: { ...CONFIG_VARIABLES_CHROMA.c_iC4used_cr, abrev: 'CR_iC4' },
  CR_nC4: { ...CONFIG_VARIABLES_CHROMA.c_nC4used_cr, abrev: 'CR_nC4' },
  CR_iC5: { ...CONFIG_VARIABLES_CHROMA.c_iC5used_cr, abrev: 'CR_iC5' },
  CR_nC5: { ...CONFIG_VARIABLES_CHROMA.c_nC5used_cr, abrev: 'CR_nC5' },
  CR_C6plus: { ...CONFIG_VARIABLES_CHROMA.c_C6plusused_cr, abrev: 'CR_C6plus' },
  CR_WobbeSuo: { ...CONFIG_VARIABLES_CHROMA.c_WobbeSuoused_cr, abrev: 'CR_WobbeSuo' },
  CR_WobbeIng: { ...CONFIG_VARIABLES_CHROMA.c_WobbeIngused_cr, abrev: 'CR_WobbeIng' },
  CR_stream: { abrev: 'CR_stream', decimals: 0 },

  CR_TR_C1: { abrev: 'CR_TR_C1', decimals: 1, unit: '[s]' },
  CR_TR_C2: { abrev: 'CR_TR_C2', decimals: 1, unit: '[s]' },
  CR_TR_C3: { abrev: 'CR_TR_C3', decimals: 1, unit: '[s]' },
  CR_TR_iC4: { abrev: 'CR_TR_iC4', decimals: 1, unit: '[s]' },
  CR_TR_nC4: { abrev: 'CR_TR_nC4', decimals: 1, unit: '[s]' },
  CR_TR_iC5: { abrev: 'CR_TR_iC5', decimals: 1, unit: '[s]' },
  CR_TR_nC5: { abrev: 'CR_TR_nC5', decimals: 1, unit: '[s]' },
  CR_TR_N2: { abrev: 'CR_TR_N2', decimals: 1, unit: '[s]' },
  CR_TR_CO2: { abrev: 'CR_TR_CO2', decimals: 1, unit: '[s]' },
  CR_TR_C6plus: { abrev: 'CR_TR_C6plus', decimals: 1, unit: '[s]' },

  CR_FR_C1: { abrev: 'CR_FR_C1', decimals: 3, unit: '[-]' },
  CR_FR_C2: { abrev: 'CR_FR_C2', decimals: 3, unit: '[-]' },
  CR_FR_C3: { abrev: 'CR_FR_C3', decimals: 3, unit: '[-]' },
  CR_FR_iC4: { abrev: 'CR_FR_iC4', decimals: 3, unit: '[-]' },
  CR_FR_nC4: { abrev: 'CR_FR_nC4', decimals: 3, unit: '[-]' },
  CR_FR_iC5: { abrev: 'CR_FR_iC5', decimals: 3, unit: '[-]' },
  CR_FR_nC5: { abrev: 'CR_FR_nC5', decimals: 3, unit: '[-]' },
  CR_FR_N2: { abrev: 'CR_FR_N2', decimals: 3, unit: '[-]' },
  CR_FR_CO2: { abrev: 'CR_FR_CO2', decimals: 3, unit: '[-]' },
  CR_FR_C6plus: { abrev: 'CR_FR_C6plus', decimals: 3, unit: '[-]' },
};

const CONFIG_VARIABLES_ULTRASONICS = {
  Footprint: { name: 'Footprint', decimals: 2, unit: '[%]' },
  VoS: { name: 'VoS', decimals: 3, unit: '[m/s]' },
  cpp: { name: 'cpp', decimals: 3, unit: '[m/s]' },
  vpp: { name: 'vpp', decimals: 3, unit: '[m/s]' },
  VoSMeterDev: { name: 'VoSMeterDev', decimals: 3, unit: '[m/s]' },
  vel_prof: { name: 'vel_prof', decimals: 3, unit: '[m/s]' },
  efficiency: { name: 'efficiency', decimals: 0, unit: '[-]' },
  snr: { name: 'SNR', decimals: 0, unit: '[dB]' },
  gain: { name: 'Gain', decimals: 0, unit: '[-]' },
  AGCLevelA: { name: 'AGCLevelA', decimals: 0, unit: '[-]' },
  AGCLevelB: { name: 'AGCLevelB', decimals: 0, unit: '[-]' },
  AGCLimitA: { name: 'AGCLimitA', decimals: 0, unit: '[-]' },
  AGCLimitB: { name: 'AGCLimitB', decimals: 0, unit: '[-]' },
  SNR_a: { name: 'SNR_a', decimals: 0, unit: '[-]' },
  SNR_b: { name: 'SNR_b', decimals: 0, unit: '[-]' },
  SNR_Serie_III: { name: 'SNR_Serie_III', decimals: 0, unit: '[-]' },
  SNR_Serie_III_1: { name: 'SNR_Serie_III_1', decimals: 0, unit: '[-]' },
  SNR_Serie_IV_a: { name: 'SNR_Serie_IV_a', decimals: 0, unit: '[-]' },
  SNR_Serie_IV_a_1: { name: 'SNR_Serie_IV_a_1', decimals: 0, unit: '[-]' },
  SNR_Serie_IV_b: { name: 'SNR_Serie_IV_b', decimals: 0, unit: '[-]' },
  SNR_Serie_IV_b_1: { name: 'SNR_Serie_IV_b', decimals: 0, unit: '[-]' },
};

const CONFIG_VARIABLES_AVERAGES = {
  CR_Mean_Hs: { name: 'HS', decimals: 3, unit: '[% mol/mol]' },
  CR_Mean_DR: { name: 'Dr', decimals: 4, unit: '[% mol/mol]' },
  CR_Mean_Hi: { name: 'HI', decimals: 3, unit: '[% mol/mol]' },
  CR_Mean_WI: { name: 'IWi', decimals: 4, unit: '[% mol/mol]' },
  CR_Mean_C1: { name: 'C1', decimals: 4, unit: '[% mol/mol]' },
  CR_Mean_C2: { name: 'C2', decimals: 4, unit: '[% mol/mol]' },
  CR_Mean_C3: { name: 'C3', decimals: 4, unit: '[% mol/mol]' },
  CR_Mean_iC4: { name: 'i-C4', decimals: 4, unit: '[% mol/mol]' },
  CR_Mean_nC4: { name: 'n-C4', decimals: 4, unit: '[% mol/mol]' },
  CR_Mean_iC5: { name: 'i-C5', decimals: 4, unit: '[% mol/mol]' },
  CR_Mean_nC5: { name: 'n-C5', decimals: 4, unit: '[% mol/mol]' },
  CR_Mean_neoC5: { name: 'neo-C5', decimals: 4, unit: '[% mol/mol]' },
  CR_Mean_C6plus: { name: 'C6+', decimals: 4, unit: '[% mol/mol]' },
  CR_Mean_N2: { name: 'N2', decimals: 4, unit: '[% mol/mol]' },
  CR_Mean_CO2: { name: 'CO2', decimals: 4, unit: '[% mol/mol]' },
  ComponentsSum: { decimals: 4, unit: '[% mol/mol]' },
};
const CONFIG_VARIABLES_EXTRA = {
  p_E: {
    colors: ['#4fbb03'],
    unit: '[kWh]',
    name: 'p_E',
    decimals: 0,
    abrev: 'E',
  },
  p_E_max: {
    colors: ['#ec9a2d'],
    unit: '[kWh]',
    name: 'p_E_max',
    decimals: 0,
    abrev: 'E',
  },
  p_E_min: {
    colors: ['#ec9a2d'],
    unit: '[kWh]',
    name: 'p_E_min',
    decimals: 0,
    abrev: 'E',
  },
  p_Vn: {
    colors: ['#4fbb03'],
    unit: '[kWh]',
    name: 'p_Vn',
    decimals: 0,
    abrev: 'Vn',
  },
  p_Vn_max: {
    colors: ['#ec9a2d'],
    unit: '[kWh]',
    name: 'p_Vn_max',
    decimals: 0,
    abrev: 'Vn',
  },
  p_Vn_min: {
    colors: ['#ec9a2d'],
    unit: '[kWh]',
    name: 'p_Vn_min',
    decimals: 0,
    abrev: 'Vn',
  },
};

const CONFIG_VARIABLES = {
  ...CONFIG_VARIABLES_FC,
  ...CONFIG_VARIABLES_CHROMA,
  ...CONFIG_VARIABLES_ULTRASONICS,
  ...CONFIG_VARIABLES_EXTRA,
  ...CONFIG_VARIABLES_CHROMA_AA,
};

const VARIABLES_CHECKBOX = Object.keys(CONFIG_VARIABLES).filter((variable) => !['E_calc'].includes(variable));

const DEFAULT_CONFIG_VARIABLE = {
  colors: ['#ffa800', '#9d6802', '#573a02', '#3c2904', '#3c2904'],
  unit: '[-]',
};
const EXTRA_VARIABLES_CONFIG_COLOR = {
  // PREDICTION
  'pred Vn': '#4fbb03',
  'pred max Vn': '#ec9a2d',
  'pred min Vn': '#ec9a2d',
  'pred E': '#4fbb03',
  'pred max E': '#ec9a2d',
  'pred min E': '#ec9a2d',
};
const VARIABLES_BARS = ['E', 'E_calc', 'Vn', 'Ven', 'Vb', 'Vbc', 'qb', 'Veb', 'Vebc', 'EE'];

const VARIABLES_VALUE_QUALITY = ['EGStatus', 'error_cr_1', 'error_cr_2', 'error_cr_3', 'error_cr_4', 'error_cr_5'];

const replacePrefix = (variable) => {
  return variable.replace(/\w{1,}_/g, '');
};

const getConfigNameVariables = ({ variableData, variableName, measurementUnitName }) =>
  Object.keys(variableData).reduce(
    (acc, line) => ({
      ...acc,
      [line]: variableName.split('c_').length >= 2 ? `${measurementUnitName}` : `${line}`,
    }),
    {}
  );

export {
  FC,
  CROMA,
  COUNTER,
  COUNTER_ERROR,
  ULTRASONIC,
  ULTRASONIC_PERMITTED,
  FOOTPRINT_TR,
  FOOTPRINT_FR,
  CONFIG_VARIABLES_FC,
  CONFIG_VARIABLES_CHROMA,
  CONFIG_VARIABLES,
  CONFIG_VARIABLES_AVERAGES,
  CONFIG_VARIABLES_EXTRA,
  VARIABLES_CHECKBOX,
  DEFAULT_CONFIG_VARIABLE,
  EXTRA_VARIABLES_CONFIG_COLOR,
  VARIABLES_BARS,
  VARIABLES_VALUE_QUALITY,
  replacePrefix,
  getConfigNameVariables,
};
