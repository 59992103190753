import styled from 'styled-components';

const RangePickerStyles = styled.div`
  .ant-picker {
    background: rgb(250, 251, 252);
    border: 1px solid rgb(224, 225, 230);
    border-radius: 4px;
    display: flex;
    height: 38px;
    padding-left: 8px;
  }

  .ant-picker-range .ant-picker-active-bar {
    width: 78px !important;
  }

  .ant-picker-input {
    width: auto;
  }

  .ant-picker-suffix {
    margin-left: auto;
  }

  .ant-picker-range-separator {
    padding: 0 4px;
  }

  .ant-picker-ranges {
    .ant-tag .ant-tag-blue {
      display: flex;
      align-items: center;
      height: 26px;
      font-size: 13px;
    }
  }
`;

export { RangePickerStyles };
