import { createTypesCrud } from 'libs/generate-redux-utils/generate-types';

const SET_MEASURE = '@reports/SET_MEASURE_REPORTS';
const MERGE_STORE = '@reports/MERGE_STORE';
const SET_REPORT = '@reports/SET_REPORTS';
const CLEAN_REPORT = '@reports/CLEAN_REPORT';
const RESTORE_REPORTS = '@reports/RESTORE_REPORTS';
const RESTORE_VARIABLES = '@reports/RESTORE_VARIABLES';
const RESTORE_ADVANCED_FORM = '@reports/RESTORE_ADVANCED_FORM';

const SET_MEASURE_TO_COMPARE = '@reports/SET_MEASURE_TO_COMPARE_REPORTS';
const SET_DATE_INFO = '@reports/SET_DATE_INFO';
const SET_DATE_RANGE = '@reports/SET_DATE_RANGE';
const SET_TYPE = '@reports/SET_TYPE';
const SET_MEASUREMENT_UNIT = '@reports/SET_MEASUREMENT_UNIT';
const SET_CHROMATOGRAPH = '@reports/SET_CHROMATOGRAPH';
const SET_DIFFERENCES = '@reports/SET_DIFFERENCES';
const SET_CORRELATION = '@reports/SET_CORRELATION';
const SET_LAST_MEASURE = '@reports/SET_LAST_MEASURE';
const SET_REPRESENTATION = '@reports/SET_REPRESENTATION';
const SET_LAST_REPRESENTATION = '@reports/SET_LAST_REPRESENTATION';
const SET_ONLY_REPRESENTATION_TABLE = '@reports/SET_ONLY_REPRESENTATION_TABLE';
const SET_TYPE_WINDOW = '@reports/SET_TYPE_WINDOW';
const SET_VARIABLES = '@reports/SET_VARIABLES';
const LIST_REFERENCE_VALUES_REQUESTED = '@reports/REFERENCE_VALUES_REQUESTED';
const LIST_REFERENCE_VALUES_SUCCEEDED = '@reports/REFERENCE_VALUES_SUCCEEDED ';
const LIST_REFERENCE_VALUES_FAILED = '@reports/REFERENCE_VALUES_FAILED';
const SAVE_REFERENCE_VALUES_SUCCEEDED = '@reports/SAVE_REFERENCE_VALUES_SUCCEEDED';
const LIST_SCHEDULE_REPORTS_REQUESTED = '@reports/SCHEDULE_REPORTS_REQUESTED';
const LIST_SCHEDULE_REPORTS_SUCCEEDED = '@reports/SCHEDULE_REPORTS_SUCCEEDED ';
const LIST_SCHEDULE_REPORTS_FAILED = '@reports/SCHEDULE_REPORTS_FAILED';
const LIST_SCHEDULE_REPORTS_FILES_REQUESTED = '@reports/SCHEDULE_REPORTS_FILES_REQUESTED';
const LIST_SCHEDULE_REPORTS_FILES_SUCCEEDED = '@reports/SCHEDULE_REPORTS_FILES_SUCCEEDED ';
const LIST_SCHEDULE_REPORTS_FILES_FAILED = '@reports/SCHEDULE_REPORTS_FILES_FAILED';
const SET_MAP_VARIABLES_ENTITIES = '@reports/MAP_VARIABLES_ENTITIES';
const SET_ADVANCED_PERIOD = '@reports/SET_ADVANCED_PERIOD';
const SET_REPORT_LAYOUT_STATUS_OPEN = '@reports/SET_REPORT_LAYOUT_STATUS_OPEN';
const GET_EVOLUTION_REQUESTED = '@reports/GET_EVOLUTION_REQUESTED';
const GET_EVOLUTION_SUCCEEDED = '@reports/GET_EVOLUTION_SUCCEEDED';
const GET_EVOLUTION_FAILED = '@reports/GET_EVOLUTION_FAILED';
const LIST_ASSIGNMENT_REQUESTED = '@reports/ASSIGNMENT_REQUESTED';
const LIST_ASSIGNMENT_SUCCEEDED = '@reports/ASSIGNMENT_SUCCEEDED ';
const LIST_ASSIGNMENT_FAILED = '@reports/ASSIGNMENT_FAILED';
const RESET_STATUS = '@reports/RESET_STATUS';
const SET_STATUS_CODE = '@reports/SET_STATUS_CODE';
const SET_REPORT_INFO = '@reports/SET_REPORT_INFO';
const SET_REFERENCES_VALUES = '@reports/SET_REFERENCES_VALUES';

export default {
  ...createTypesCrud('reports'),
  SET_REPORT,
  CLEAN_REPORT,
  MERGE_STORE,
  RESTORE_REPORTS,
  RESTORE_VARIABLES,
  RESTORE_ADVANCED_FORM,
  SET_DATE_INFO,
  SET_DATE_RANGE,
  SET_TYPE,
  SET_MEASUREMENT_UNIT,
  SET_CHROMATOGRAPH,
  SET_DIFFERENCES,
  SET_CORRELATION,
  SET_LAST_MEASURE,
  SET_REPRESENTATION,
  SET_LAST_REPRESENTATION,
  SET_ONLY_REPRESENTATION_TABLE,
  SET_TYPE_WINDOW,
  SET_VARIABLES,
  GET_EVOLUTION_REQUESTED,
  GET_EVOLUTION_SUCCEEDED,
  GET_EVOLUTION_FAILED,
  LIST_REFERENCE_VALUES_REQUESTED,
  LIST_REFERENCE_VALUES_SUCCEEDED,
  LIST_REFERENCE_VALUES_FAILED,
  SAVE_REFERENCE_VALUES_SUCCEEDED,
  LIST_SCHEDULE_REPORTS_REQUESTED,
  LIST_SCHEDULE_REPORTS_SUCCEEDED,
  LIST_SCHEDULE_REPORTS_FAILED,
  LIST_SCHEDULE_REPORTS_FILES_REQUESTED,
  LIST_SCHEDULE_REPORTS_FILES_SUCCEEDED,
  LIST_SCHEDULE_REPORTS_FILES_FAILED,
  SET_REPORT_LAYOUT_STATUS_OPEN,
  SET_STATUS_CODE,
  SET_REFERENCES_VALUES,
  MEASURE: {
    ...createTypesCrud('reports', 'measure_reports'),
    SET_MEASURE,
  },
  MEASURE_TO_COMPARE: {
    ...createTypesCrud('reports', 'measure_to_compare_reports'),
    SET_MEASURE_TO_COMPARE,
  },
  SIGNATURE_AUDIT: {
    ...createTypesCrud('reports', 'signature_audit_reports'),
  },
  ADVANCED: {
    SET_MAP_VARIABLES_ENTITIES,
    SET_ADVANCED_PERIOD,
  },
  LIST_ASSIGNMENT_REQUESTED,
  LIST_ASSIGNMENT_SUCCEEDED,
  LIST_ASSIGNMENT_FAILED,
  RESET_STATUS,
  SET_REPORT_INFO,
};
